import React, { useEffect, useState } from 'react';
import Transaction, { getAmountDue } from '../../types/transaction';
import { formatUSD } from '../../utils/util';
import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
} from '@devexpress/dx-react-grid';
import TableComponent from '../admin/TableComponent';
import Fetch from '../ui/Fetch';
import SVG from '../SVG';
import './index.css';
import Auth from '../../types/redux/auth';
import RootState from '../../types/redux/rootState';
import { useSelector } from 'react-redux';
import { DateProvider } from '../../utils/dateUtilities';
import { fetchInvoices } from '../../api/diamonds';
import getEnvConfig from '../../env';
import { compareCurrency, compareLinkText } from '../../utils/tableUtilities';

const columns = [
  {
    name: 'trandate',
    title: 'Date',
  },
  {
    name: 'doc_number',
    title: 'Description',
    getCellValue: (row: Transaction) =>
      `${row.transaction_type} #${row.doc_number}`,
  },
  {
    name: 'netsuite_status',
    title: 'Status',
  },
  {
    name: 'transaction_total',
    title: 'Amount',
    getCellValue: (row: Transaction) =>
      formatUSD(row.transaction_total / 100.0), // convert pennies to dollars.
  },
  {
    name: 'applied_amount',
    title: 'Amount Due',
    getCellValue: (row: Transaction) => formatUSD(getAmountDue(row)),
  },
  {
    name: 'due_at',
    title: 'Due',
  },
  {
    name: 'id',
    title: 'View',
    getCellValue: (row: Transaction) => {
      return (
        <a href={`/statement/transaction/${row.id}`} target="_blank">
          <SVG className="svg-icon-csv" name="eye-open" viewBox="0 0 600 600" />
        </a>
      );
    },
  },
];

const tableColumnExtensions = [
  { columnName: 'doc_number', wordWrapEnabled: true },
  { columnName: 'trandate', wordWrapEnabled: true },
  { columnName: 'due_at', wordWrapEnabled: true },
  { columnName: 'transaction_total', wordWrapEnabled: true },
  { columnName: 'id', wordWrapEnabled: true },
];

export type Props = {
  invoices: Transaction[];
  loading: boolean;
  showToaster: (msg: string) => void;
  closeToaster: () => void;
};

const InvoiceHistory = ({ showToaster, closeToaster }: Props) => {
  const selector = useSelector<RootState, Auth>(state => state.auth);
  const { USER_PRINTABLE_INVOICE, ADMIN_PRINTABLE_INVOICE } = getEnvConfig();

  const [data, setData] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);

  const onLoad = async () => {
    if (selector.user.customer_account.id) {
      try {
        setLoading(true);
        const res = await fetchInvoices(selector.user.customer_account.id);
        setData(res.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        showToaster('Error fetching invoice');
      }
    }
  };

  useEffect(() => {
    const _ = onLoad();
  }, [selector]);

  if (!selector) {
    return null;
  }
  if (selector.userRoute === 'user' && !USER_PRINTABLE_INVOICE) {
    return null;
  }

  if (
    !ADMIN_PRINTABLE_INVOICE &&
    (selector.userRoute === 'salesUser' || selector.userRoute === 'adminUser')
  ) {
    return null;
  }

  const integratedSortingColumnExtensions = [
    { columnName: 'transaction_total', compare: compareCurrency },
    { columnName: 'applied_amount', compare: compareCurrency },
  ];

  return (
    <div>
      <div>
        <h1 className="df-title">
          <span>Invoice History</span>
        </h1>
      </div>
      <Grid rows={data} columns={columns}>
        <DateProvider for={['due_at', 'trandate']} />
        <SortingState
          defaultSorting={[{ columnName: 'trandate', direction: 'desc' }]}
        />
        <SearchState defaultValue="" />
        <IntegratedSorting
          columnExtensions={integratedSortingColumnExtensions}
        />
        <IntegratedFiltering />
        <Fetch
          render={
            <Table
              tableComponent={TableComponent}
              columnExtensions={tableColumnExtensions}
            />
          }
          empty={
            <Table
              tableComponent={TableComponent}
              columnExtensions={tableColumnExtensions}
              noDataCellComponent={({
                colSpan = 20,
              }: Table.NoDataCellProps) => {
                return (
                  <td colSpan={colSpan}>Your account has no open invoices</td>
                );
              }}
            />
          }
          loading={
            <Table
              tableComponent={TableComponent}
              columnExtensions={tableColumnExtensions}
              noDataCellComponent={({
                colSpan = 20,
              }: Table.NoDataCellProps) => {
                return <td colSpan={colSpan}>Loading</td>;
              }}
            />
          }
          isEmpty={data.length === 0}
          isLoading={loading}
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    </div>
  );
};

export default InvoiceHistory;
