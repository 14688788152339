import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import queryString from 'query-string';

import { createNotification } from '../../api/index';
import BackButton from '../../components/BackButton';
import BlankNotificationTemplate from '../../components/BlankNotificationTemplate';

class NotifyUsersPage extends Component {
  constructor(props) {
    super(props);

    // Parse the url parameters.
    // ?all=true = This will be used to determine if notify-all should be checked.
    // ?ar=true = This will be used to determine if the account summary csv should be attached
    const query = queryString.parse(this.props.location.search);

    this.state = {
      title: '',
      message: '',
      fromAddress: 'no-reply@diamondfoundry.com',
      notifyAll: query.all || false,
      attachAccountSummaryCsv: query.ar || false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNotifyAllClick = this.handleNotifyAllClick.bind(this);
    this.handleAttachAccountSummaryCsvClick = this.handleAttachAccountSummaryCsvClick.bind(
      this
    );
    this.submit = this.submit.bind(this);
  }

  // create notification on backend then show a toast
  submit() {
    let params = {
      title: this.state.title,
      message: this.state.message,
      from_address: this.state.fromAddress,
    };

    if (this.props.users.selected && this.props.users.selected.length > 0) {
      let notification_users_attributes = [];
      for (let i = 0; i < this.props.users.selected.length; i++) {
        notification_users_attributes.push({
          user_id: this.props.users.selected[i].id,
        });
      }
      params['notification_users_attributes'] = notification_users_attributes;
    }

    if (this.state.attachAccountSummaryCsv) {
      params['metadata'] = {};
      params['metadata']['attachments'] = ['account_summary_csv'];
    }

    if (this.state.notifyAll) {
      params['notify_all'] = true;
    }

    if (params['title'].length > 0 && params['message'].length > 0) {
      createNotification(params)
        .then(response => {
          this.props.showToaster('Your notification is being sent now.');
        })
        .catch(error => {
          this.props.showToaster(
            'An error occurred while trying to create this notification.'
          );
        });
    } else {
      this.props.showToaster(
        'Unable to create a notification without a title and message.'
      );
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  handleNotifyAllClick(e) {
    this.setState({ notifyAll: !this.state.notifyAll });
  }

  handleNotifyAllChange(e) {
    // React makes you handle this.
  }

  handleAttachAccountSummaryCsvClick(e) {
    this.setState({
      attachAccountSummaryCsv: !this.state.attachAccountSummaryCsv,
    });
  }

  handleAttachAccountSummaryCsvChange(e) {
    // React makes you handle this.
  }

  render() {
    let emails = this.props.users.selected.map(u => u.email);
    let isMarketingUser = this.props.auth.user.roles.includes('marketing');

    return (
      <main>
        <div className="page-wrapper-with-padding CreditCardPage NotifyUsersPage">
          <div className="main-wrapper">
            <BackButton link="/admin/users" text="All Users" />

            <div className="form-wrapper">
              <h1>Notify Users</h1>
              <div className="table-wrapper">
                <div className="row">
                  <div className="column">
                    <label htmlFor="title">Title</label>
                    <input
                      className="df-text-inputs light"
                      name="title"
                      id="title"
                      type="text"
                      value={this.state.title}
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      placeholder="Title"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label htmlFor="message">Message</label>
                    <textarea
                      className="md-textarea form-control user-comment"
                      id="message"
                      name="message"
                      value={this.state.message}
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      placeholder="You can use html in your message. To create paragraphs, use <p>Paragraph</p>. To add a line break, use <br/>."
                      rows="5"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label htmlFor="fromAddress">From Address</label>
                    <input
                      className="df-text-inputs light"
                      name="fromAddress"
                      id="fromAddress"
                      type="text"
                      value={this.state.fromAddress}
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      placeholder="fromAddress"
                    />
                  </div>
                </div>
                {emails.length > 0 && !this.state.notifyAll && (
                  <div className="row">
                    <div className="column">
                      <label>Sending To</label>
                      {emails.join(', ')}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="column">
                    <div className="dashboard-checkbox">
                      <label className="container">
                        <input
                          type="checkbox"
                          name="attachAccountSummaryCsv"
                          checked={
                            this.state.attachAccountSummaryCsv === true ||
                            this.state.attachAccountSummaryCsv === 'true'
                          }
                          onChange={e => {
                            this.handleAttachAccountSummaryCsvChange(e);
                          }}
                          onClick={e => {
                            this.handleAttachAccountSummaryCsvClick(e);
                          }}
                        />
                        <span className="checkbox-checkmark" />
                        <span className="checkbox-label">
                          Attach account summary CSV
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {isMarketingUser && (
                  <div className="row">
                    <div className="column">
                      <div className="dashboard-checkbox">
                        <label className="container">
                          <input
                            type="checkbox"
                            name="notifyAll"
                            checked={
                              this.state.notifyAll === true ||
                              this.state.notifyAll === 'true'
                            }
                            onChange={e => {
                              this.handleNotifyAllChange(e);
                            }}
                            onClick={e => {
                              this.handleNotifyAllClick(e);
                            }}
                          />
                          <span className="checkbox-checkmark" />
                          <span className="checkbox-label">
                            Send this to all B2B Portal users
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="submit">
                <Button
                  className="df-cta btn btn-secondary"
                  type="submit"
                  onClick={() => this.submit()}
                >
                  Create Notification
                </Button>
                <div className="warning">
                  WARNING: Notification will be sent immediately.
                </div>
              </div>
              {(this.state.title.length > 0 ||
                this.state.message.length > 0) && (
                <div className="table-wrapper">
                  <h1>Preview</h1>
                  <div className="warning">
                    This is not an exact copy of what your message will look
                    like. It is only an approximation.
                  </div>
                  <BlankNotificationTemplate
                    title={this.state.title}
                    message={this.state.message}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    users: state.users,
  };
};

export default connect(mapStateToProps)(NotifyUsersPage);
