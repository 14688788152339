import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate } from '../utils/routerHistory';
import { Link } from '@reach/router';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Terms from '../data/terms-conditions';
import { getCartItems, removeItemFromCart } from '../state/action/diamondCart';
import CartListItem from '../components/CartListItem';
import { formatPricingFromPennies } from '../utils/util';
import DFButton from '../components/ui/DFButton';
import FlexCenterContainer from '../components/ui/FlexCenterContainer';
import CanHide from '../components/ui/CanHide';
import { onCreateHold } from '../api/diamonds';
import InventoryHoldList from '../components/holds/InventoryHoldList';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

class Cart extends Component {
  static handleSubmitClick(e) {
    e.preventDefault();
    navigate('/checkout');
  }

  constructor(props) {
    super(props);
    this.unmounted = false;
    this.state = {
      confirmUserAgreement: true,
      modal: false,
      terms: Terms,
      errorMessage: '',
      numberOfHolds: 0,
    };

    this.handleUserAgreement = this.handleUserAgreement.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.props.getCartItems();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  async toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleUserAgreement() {
    const { confirmUserAgreement } = this.state;
    this.setState({
      confirmUserAgreement: !confirmUserAgreement,
    });
  }

  render() {
    const { cart, totalPrice } = this.props;
    const {
      terms,
      modal,
      confirmUserAgreement,
      errorMessage,
      numberOfHolds,
    } = this.state;

    return (
      <main>
        <div className="cart-page">
          <div className="order-again">
            <Link to="/diamonds">
              <Button className="df-cta">CONTINUE SHOPPING</Button>
            </Link>
            <CanHide hidden={cart.length === 0}>
              <span className="tail-message">or checkout below</span>
            </CanHide>
          </div>

          <h1 className="df-title">MY CART</h1>

          <div className="cart-list">
            {cart.length === 0 ? (
              <div className="empty-cart">Your cart is empty</div>
            ) : (
              cart.map(item => (
                <CartListItem
                  key={`cart-item-${item.id}`}
                  checkedOut={false}
                  {...item}
                />
              ))
            )}
          </div>
          {/* because bulk order has no price, if total price is 0, hide the total price */}
          {totalPrice !== 0 && totalPrice !== undefined && (
            <div className="total-price">
              <span className="label">TOTAL: </span>
              <span>{formatPricingFromPennies(totalPrice)}</span>
            </div>
          )}

          {terms && (
            <Modal
              isOpen={modal}
              toggle={this.toggle}
              className="footer-pages cart"
            >
              <ModalHeader className="df-title" toggle={this.toggle} />
              <ModalBody className="df-page-body">
                <Terms />
              </ModalBody>
            </Modal>
          )}

          <CanHide hidden={cart.length === 0}>
            <div>
              <div className="terms form-check">
                <label className="terms-text form-check-label">
                  <label htmlFor="terms" className="container">
                    <input
                      type="checkbox"
                      id="terms"
                      name="terms"
                      onChange={this.handleUserAgreement}
                      checked={confirmUserAgreement}
                    />
                    <span className="checkbox-checkmark" />
                  </label>
                  <span className="terms-detail">
                    I agree to the{' '}
                    <a
                      role="button"
                      tabIndex="0"
                      className="df-link"
                      onClick={this.toggle}
                    >
                      terms and conditions
                    </a>
                  </span>
                </label>
              </div>

              <div className="df-cta-container">
                <div className="checkout-error">{errorMessage}</div>
                <FlexCenterContainer spacing={'5px'} column={true}>
                  <a>
                    <DFButton
                      style={{ width: '200px' }}
                      disabled={!confirmUserAgreement}
                      onClick={e => Cart.handleSubmitClick(e)}
                    >
                      CHECKOUT
                    </DFButton>
                  </a>
                  <CanHide
                    hidden={
                      cart.length !== 1 ||
                      cart.filter(item => item.orderType === 'Diamond')
                        .length !== 1 ||
                      numberOfHolds >= 2
                    }
                  >
                    <a
                      css={css`
                        color: #888888 !important;
                        &:hover {
                          color: #4d4d4f !important;
                        }
                      `}
                      id={'24-hour-hold'}
                      className="df-link"
                      onClick={() => {
                        onCreateHold(cart[0].id)
                          .then(res => {
                            this.props.showToaster(
                              'Successfully created hold!'
                            );
                            this.props.removeItemFromCart(cart[0].id);
                          })
                          .catch(error => {
                            if (error.response && error.response.data.message) {
                              this.props.showToaster(
                                error.response.data.message
                              );
                            } else {
                              this.props.showToaster('Unable to create hold.');
                            }
                          });
                      }}
                    >
                      24 HOUR HOLD
                    </a>
                  </CanHide>
                </FlexCenterContainer>
              </div>
            </div>
          </CanHide>
          <InventoryHoldList
            onNumberOfHolds={numberOfHolds => this.setState({ numberOfHolds })}
            cart={cart}
          />
        </div>
      </main>
    );
  }
}

Cart.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  updated: PropTypes.string.isRequired,
  cart: PropTypes.instanceOf(Array).isRequired,
};

const mapDispatch = { getCartItems, removeItemFromCart };

const mapStateToProps = state => {
  return {
    cart: state.diamondCart.cart,
    totalPrice: state.diamondCart.totalPrice,
    updated: state.diamondCart.updated,
  };
};

export default connect(mapStateToProps, mapDispatch)(Cart);
