import React from 'react';

const Terms = () => {
  return (
    <React.Fragment>
      <h1>Terms And Conditions</h1>
      <p>
        Welcome to the B2B website of Diamond Foundry Inc. This Terms of Use
        Agreement (“Agreement”) constitutes a legally binding contract between
        Diamond Foundry Inc. (“Diamond Foundry Inc.,” “we,” “us,” “our”) and you
        with respect to your use of the Diamond Foundry Inc. website and its
        products and services. It is important that you carefully read and
        understand the terms and conditions of this Agreement.
      </p>
      <p>
        BY USING THE SITE, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.
        If you do not agree to these terms and conditions, please do not use the
        Website or any of the Information or Services. Be advised that this
        Agreement contains disclaimers of warranties and limitations on
        liability that may be applicable to you.
      </p>
      <p>We reserve the right at any time, with or without cause, to:</p>
      <ul>
        <li>change the terms and conditions of this Agreement;</li>
        <li>
          change the Website, including eliminating or discontinuing any
          Information or Services or other feature of the Website; or
        </li>
        <li>deny or terminate your use of and/or access to the Website.</li>
      </ul>
      <p>
        Any changes we make will be effective immediately upon our making such
        changes available on the Website or otherwise providing notice thereof.
        You agree that your continued use of the Website after such changes
        constitutes your acceptance of such changes. You hereby acknowledge that
        you have carefully read all of the terms and conditions of our Privacy
        Policy (which can be accessed at
        https://www.diamondfoundry.com/privacy-policy) and agree to all such
        terms and conditions. Be sure to return to this page periodically to
        ensure familiarity with the most current version of this Agreement.
      </p>
      <h3>LAB-CREATED ORIGIN OF DIAMONDS DISCLOSURE &amp; RESALE</h3>
      <p>
        AS DISCLOSED ON THIS WEBSITE INCLUDING ITS HOME PAGE AT
        WWW.DIAMONDFOUNDRY.COM, B2B PORTAL, AND OTHER PROMINENTLY FEATURED
        PAGES,
        <strong>
          ALL DIAMONDS SOLD BY US ARE “MAN MADE”, “LAB CREATED”, “CULTIVATED”,
          OR WHICHEVER WAY ONE DEEMS TO APPROPRIATELY DESCRIBE A PROCESS OF
          GROWING DIAMOND CRYSTAL IN A FACTORY OR LABORATORY.
        </strong>
      </p>
      <p>
        You hereby agree that you understand the meaning of these descriptions,
        that you have no doubt over the man-made origin of our foundry created
        diamonds as disclosed by us and as mandated by the FTC in the U.S. and
        similar regulatory bodies in other legal territories, and that every
        foundry created diamond offered on our website is “man made” whether or
        not each mention of the word “diamond” includes this qualifier or not.
      </p>
      <p>
        If you do not agree or understand what these terms mean, you should not
        purchase any diamonds or use this Website or any of the Services until
        and unless you either seek legal advice or understand what you are
        purchasing and you accept the terms and conditions expressed hereunder.
      </p>
      <p>
        You further hereby agree that we shall not be liable for any loss, harm
        or liability arising from any deception or relating to the origin of the
        foundry diamonds in excess of the total value paid by you for the
        foundry diamond.
      </p>
      <p>
        Under no circumstance shall we be liable for damages, losses or for any
        liability or penalties of any kind in connection with fraudulent or
        deceptive representation, claims regarding false advertising or unfair
        competition.
      </p>
      <p>
        You further acknowledge that each and every foundry diamond obtained
        from us is laser marked as such and that resale will be subject to such
        marking and disclosure.
      </p>
      <p>
        You agree that you shall not market, resell, distribute, or induce or
        permit others to market, resell, or distribute our foundry diamonds by
        stating, advertising, or implying any different provenance or origin
        than fact and you will make any subsequent purchaser aware of the
        markings and disclosure requirements regarding the origin of the foundry
        diamonds.
      </p>
      <p>
        Under no circumstances shall we be considered liable for misleading
        advertising in connection with a resale transaction or any subsequent
        transfer of the foundry diamonds, and under no circumstance shall we be
        liable for damages, losses or any penalties in connection with
        fraudulent representation or any misrepresentation or confusing
        statements of any kind.
      </p>
      <p>
        You hereby agree to indemnify and hold us harmless for any failure to
        comply with the above disclosure requirements and for any misleading
        statements or inferences made by you or any of your representatives
        regarding the origin of the foundry diamonds.
      </p>
      <h3>PERMITTED USE OF SERVICES AND INFORMATION</h3>
      <p>
        The services made available on, by or through the Website, which include
        or may include, without limitation, Diamond Foundry Inc. registration,
        marketplace for jewelry products, comment boards, as well as any
        information provided on, by or through the Website or as part of or in
        connection with the Services or otherwise, including but not limited to
        data, text, graphics, designs, logos, images, audio/visual materials,
        links and references (collectively, the “Information”), are provided for
        personal use only and not for any for-profit or commercial activities or
        purpose or for resale, except as expressly permitted herein. Without the
        written consent of Diamond Foundry Inc., no Information or any other
        Diamond Foundry Inc. materials or property may be copied, reproduced,
        displayed, republished, downloaded, posted, digitized, translated,
        modified, transmitted, distributed or commercially exploited in any way,
        except as expressly permitted herein. Your rights to use any
        Information, this Website or any Services shall terminate immediately
        upon the violation by you of any terms and conditions of this Agreement.
      </p>
      <p>
        Diamond Foundry Inc. permits you to view and print a reasonable number
        of copies of web pages located on the Website for your own personal use,
        but not for any for-profit or commercial purpose or for resale; provided
        that (a) you retain all trademark, copyright, and other proprietary
        notices contained in the original materials, (b) you provide attribution
        to Diamond Foundry Inc., (c) the material is printed in its entirety
        without modification, reformatting or adaptation of any kind, and (d)
        any such copies are subject to the terms and conditions of this
        Agreement and remain the property of Diamond Foundry Inc. You understand
        and agree that you may not authorize any Information to be reproduced,
        modified, displayed, performed, transferred, distributed or otherwise
        used by any third party, and you agree that you will take all reasonable
        steps to prevent any unauthorized reproduction and/or other use of the
        Information. You further hereby agree that you will at all times make it
        clear to any buyer that the Diamond Foundry Inc. foundry diamonds are
        man-made or cultured or lab grown. You hereby agree to advise Diamond
        Foundry Inc. promptly of any such unauthorized use of which you are
        aware. Failure to abide by these conditions will immediately terminate
        this permission and may result in the infringement of the copyrights
        and/or trademarks and other proprietary rights of Diamond Foundry Inc.
        or others.
      </p>
      <h3>REGISTRATION</h3>
      <p>
        Access to certain functionalities of the Website will require you to
        register with and/or provide certain information to Diamond Foundry
        Inc.. We reserve the right to decline to provide Services to any person
        for any or no reason. If and when you register with or provide
        information to Diamond Foundry Inc., you agree to (a) provide accurate,
        current and complete information about yourself and business as prompted
        (including your email address) and (b) maintain and update your
        information (including your email address) to keep it accurate, current
        and complete. You acknowledge that, if any information provided by you
        is untrue, inaccurate, not current or incomplete, we reserve the right
        to terminate this Agreement and your use of the Website, Services,
        and/or Information. You understand that any information you provide will
        be reasonably relied on by Diamond Foundry Inc. and treated by Diamond
        Foundry Inc. in the manner described in our Privacy Policy, which can be
        found at https://www.diamondfoundry.com/privacypolicy.
      </p>
      <h3>POSTINGS AND OTHER SUBMISSIONS</h3>
      <p>
        As part of your use of the Website, you may participate in certain
        message boards, member communications and/or other public forums. Your
        participation is voluntary; however, by choosing to send any messages,
        submit any ideas or feedback, or otherwise participate in any Diamond
        Foundry Inc. forum, you hereby acknowledge and agree, and your use of
        such is conditioned upon such agreement, that any postings, messages,
        text, photos, audio/visual works, information, suggestions, feedback,
        reviews or content provided by you (collectively, “Content”) may be
        viewed by the general public and will not be treated as private,
        proprietary or confidential, and you hereby license and authorize us and
        our affiliates, licensees and sublicensees, without compensation to you
        or others, to copy, adapt, create derivative works of, reproduce,
        incorporate, distribute, publicly display or otherwise use or exploit
        (“Use”) such Content throughout the world in any format or media
        (whether now known or hereafter created) for the duration of any
        copyright or other rights in such Content, and such permission shall be
        perpetual and may not be revoked for any reason. Further, to the extent
        permitted under applicable law, you waive and release and covenant not
        to assert any moral rights that you may have in any Content posted or
        provided by you.
      </p>
      <p>
        Diamond Foundry Inc. acknowledges and agrees that you, or your licensors
        as applicable, retain ownership of any and all copyrights in the
        Content, subject to the non-exclusive rights granted to Diamond Foundry
        Inc. in the paragraph above, and that no ownership of such copyrights is
        transferred to Diamond Foundry Inc. under these terms. Further, with
        respect to Content in the form of photos, and subject to Diamond Foundry
        Inc. product and user experience considerations (a) Diamond Foundry Inc.
        will use commercially reasonable efforts to maintain the attribution of
        such photos as submitted by you, and (b) Diamond Foundry Inc. will not
        license or sublicense to third parties individual photo Content or
        collections of photo Content submitted by you, except in each case for
        Diamond Foundry Inc. Business Purposes. “Diamond Foundry Inc. Business
        Purposes” means any Use in connection with a Diamond Foundry
        Inc.-branded or co-branded website, application, publication or service,
        or any Use which advertises, markets or promotes Diamond Foundry Inc.,
        or its affiliates.
      </p>
      <p>
        By posting or providing any Content on the Website, you represent and
        warrant to Diamond Foundry Inc. that you own or have the right to Use
        and permit us to Use and license such Content in the manner stated in
        this Agreement. By way of example and not limitation, if you post or
        otherwise provide a photograph which includes an image of any person,
        you need to first obtain permission or a release from such person
        appearing in the photograph to Use their image and permit us to Use and
        license it as stated in this Agreement and you must be the owner or
        licensee of the copyright in the photograph, with the right to permit us
        to Use it as stated in this Agreement. By way of further example and not
        limitation, if you post or otherwise provide a photograph that is
        protected by copyright, you need to first obtain permission or a release
        from the owner of the copyright in such work to reproduce and adapt
        their work and permit us to Use it as stated in this Agreement and you
        must be the owner or licensee of the copyright in the photograph, with
        the right to permit us to Use it as stated in this Agreement.
      </p>
      <p>
        Diamond Foundry Inc. may enable you to post ratings or reviews of
        vendors on the Website (your “Reviews”). Reviews are considered Content.
        You may not post any reviews about a vendor or any of their products or
        services if you are (a) an employee, contractor, officer or director of
        the vendor and/or service provider; (b) an employee, contractor, officer
        or director of a competitor of the vendor and/or service provider; or
        (c) related to the vendor and/or service provider in any way, including
        blood, adoption or by marriage. By posting a Review, you acknowledge and
        agree that such Content is based upon your first-hand experience with
        the vendor, or product that is the subject of the Review; and is
        accurate, truthful and complete.
      </p>
      <p>
        You agree not to post or provide any Content that belongs to any person
        other than yourself or that contains the name, voice or likeness of any
        person other than yourself unless you first obtain permission to do so,
        and to grant to us the rights granted in this agreement, from that
        person.
      </p>
      <p>
        If you identify yourself by name or provide a picture or audio or video
        recording of yourself, you further authorize us and our affiliates,
        licensees and sublicensees, without compensation to you or others, to
        reproduce, print, publish and disseminate in any format or media
        (whether now know or hereafter created) your name, voice and likeness
        throughout the world, and such permission shall be perpetual and cannot
        be revoked for any reason.
      </p>
      <p>
        You further agree that we may Use the posted or provided Content in any
        manner that we deem appropriate or necessary. We reserve the right to
        edit or abridge any Content for any reason, and to edit, refuse to post
        or remove any Content submitted by you or others. We cannot and do not
        guarantee that we will post all Content submitted, in whole or in part,
        or that such content will not be offensive, defamatory or objectionable.
        Although we reserve the right to remove without notice any Content for
        any reason, we have no obligation to delete Content that you personally
        may find objectionable or offensive. We do not control in real time the
        Content posted via the Services and as such do not guarantee the
        accuracy, integrity or quality of such Content. In addition to other
        disclaimers found in this Agreement, we do not endorse or make any
        warranties or representations with respect to the accuracy, completeness
        or timeliness of any Content posted on the Website.
      </p>
      <h3>THIRD-PARTY LINKS</h3>
      <p>
        The Website may contain links to other websites for your convenience. We
        do not control the linked websites or any of the content or offerings
        provided through such websites, and we may not have reviewed such
        websites at all. Your use of linked websites is subject to the privacy
        practices and terms of use established by the specific linked website
        and is entirely at your own risk, and we disclaim all liability for such
        use. The fact that we offer such links does not in any way indicate any
        approval or endorsement by us of any linked website or any content,
        material or offerings contained on any linked website, and we disclaim
        any such approval or endorsement.
      </p>
      <h3>ACCEPTABLE USE POLICY</h3>
      <p>
        You agree not to use the Information, Services or the Website to take
        any action or actions that:
      </p>
      <ol>
        <li>
          are contrary to Diamond Foundry Inc.’s public image, goodwill or
          reputation;
        </li>
        <li>
          infringe on our or any third party’s copyright, patent, trademark,
          trade secret or other proprietary rights or rights of publicity or
          privacy;
        </li>
        <li>
          express or imply that any of your statements, activities or causes are
          endorsed by us, without our prior written consent in each instance;
        </li>

        <li>
          violate any applicable law, statute, ordinance or regulation, or
          encourage any conduct that could constitute a criminal offense or give
          rise to civil liability;
        </li>
        <li>
          are libelous, defamatory, threatening, harassing, invasive of privacy,
          abusive, tortious, hateful, discriminatory, pornographic or obscene;
        </li>
        <li>
          transmit any trade secret or other material, non-public information
          about any person, company or entity without the authorization to do
          so;
        </li>

        <li>
          restrict or inhibit any other visitor from using the Website,
          including, without limitation, by means of “hacking” or defacing any
          portion of the Website;
        </li>
        <li>
          modify, adapt, sub-license, translate, sell, reverse engineer,
          decompile, or disassemble any portion of the Website or Information;
        </li>

        <li>
          remove any copyright, trademark, or other proprietary rights notices
          contained in or on the Information, Website, or Services;
        </li>
        <li>
          sublicense, sell, rent, lease, transfer, assign, or convey any rights
          under this Agreement to any third party, or otherwise commercially
          exploit or profit from the Information or content of the Website, or
          any portion thereof, in any manner whatsoever, except as expressly
          permitted herein;
        </li>
        <li>
          “frame” or “mirror” any part of the Website without our prior written
          authorization;
        </li>
        <li>
          distribute any virus, worm or other similar or deleterious files,
          scripts or programming routines;
        </li>
        <li>
          interfere with or disrupt any services or equipment with the intent of
          causing an excessive or disproportionate load on the infrastructure of
          Diamond Foundry Inc. or its licensors or suppliers;
        </li>
        <li>
          involve the preparation or distribution of junk mail, spam, chain
          letters, pyramid schemes, or other deceptive or unsolicited bulk or
          commercial email, or violate in any way the Controlling the Assault of
          Non-Solicited Pornography and Marketing Act (CAN-SPAM Act of 2003);
        </li>

        <li>
          use any robot, spider, site search/retrieval application, or other
          manual or automatic device or process to retrieve, index, “data mine”
          the Website or in any way reproduce or circumvent the navigational
          structure or presentation of the Website or its contents,
          authentication and security measures;
        </li>
        <li>
          forge headers or otherwise manipulate identifiers in order to disguise
          the origin of any submission;
        </li>
        <li>
          execute any form of network monitoring or run a network analyzer or
          packet sniffer or other technology to intercept, decode, mine or
          display any packets used to communicate between the Website’s servers
          or any data not intended for you; and/or
        </li>
        <li>
          harvest or collect information about any Website visitors or members
          without their express consent.
        </li>
      </ol>
      <p>
        Improper use of the Information, Services and Website may result in
        termination of your access to and use of the Website, and/or civil or
        criminal liabilities.
      </p>
      <h3>OWNERSHIP</h3>
      <p>
        The Website is owned and operated by Diamond Foundry Inc. and its
        licensors, and the Information and Services (and any intellectual
        property and other rights relating thereto) are and will remain the
        property of Diamond Foundry Inc. and its licensors and suppliers. The
        Information and Services are protected by U.S. and international
        copyright, trademark, and other laws, and you acknowledge that these
        rights are valid and enforceable. Except as set forth in this Agreement,
        you may not copy, reproduce, modify, adapt, translate, republish,
        upload, post, display, transmit, distribute, sub-license, sell, reverse
        engineer, decompile, or disassemble any part of the Website or any
        Service or Information without our prior written permission. The
        Information, Website, and Services may be used solely (a) to the extent
        expressly permitted in this Agreement or (b) as expressly authorized in
        writing by Diamond Foundry Inc. or, if so indicated in writing by
        Diamond Foundry Inc., its licensors or suppliers. Use of the Website or
        any Services or Information for any other purpose is strictly
        prohibited. You acknowledge that you do not acquire any ownership rights
        by using the Website or any Services or Information.
      </p>
      <p>
        The trademarks, logos, and service marks displayed on the Website
        (collectively, the “Trademarks”) are the registered and unregistered
        trademarks of Diamond Foundry Inc., Diamond Foundry Inc. licensors and
        suppliers, and/or others. Diamond Foundry Inc.® is a registered
        trademark of Diamond Foundry Inc. Nothing contained in this Agreement or
        the Website should be construed as granting, by implication, estoppel,
        or otherwise, any license or right to use any Trademark(s) without the
        express written permission of Diamond Foundry Inc., Diamond Foundry Inc.
        licensors or suppliers, or the third party owner of any such Trademark,
        except as set forth in the following paragraph.
      </p>
      <h3>NO USE BY CHILDREN UNDER 13</h3>
      <p>
        You hereby affirm that you are 13 years of age or older, as this Website
        is not intended for children under 13. If you are under 13 years of age,
        then you may not use the Website, any of the Services or the Information
        whatsoever. You further affirm that you are fully able and competent to
        enter into the terms, conditions, obligations, affirmations,
        representations, and warranties set forth in this Agreement, and to
        abide by and comply with this Agreement.
      </p>
      <h3>CLAIMS OF COPYRIGHT OR TRADEMARK INFRINGEMENT</h3>
      <p>
        The Digital Millennium Copyright Act (the “DMCA”) provides recourse for
        copyright owners who believe that material appearing on the Internet
        infringes their rights under U.S. copyright law. Diamond Foundry Inc.
        also reviews claims of trademark infringement. If you believe in good
        faith that materials hosted by Diamond Foundry Inc. infringe your
        copyright or trademark rights, you (or your agent) may send us a notice
        requesting that the material be removed, or access to it blocked. The
        notice must include the following information: (a) a physical or
        electronic signature of a person authorized to act on behalf of the
        owner of an exclusive right that is allegedly infringed; (b)
        identification of the copyrighted work or trademark claimed to have been
        infringed (or if multiple copyrighted works or trademarks located on the
        Website are covered by a single notification, a representative list of
        such works); (c) identification of the material that is claimed to be
        infringing or the subject of infringing activity, and information
        reasonably sufficient to allow Diamond Foundry Inc. to locate the
        material on the Website; (d) the name, address, telephone number, and
        email address (if available) of the complaining party; (e) a statement
        that the complaining party has a good faith belief that use of the
        material in the manner complained of is not authorized by the copyright
        or trademark owner, its agent, or the law; and (f) a statement that the
        information in the notification is accurate and, under penalty of
        perjury, that the complaining party is authorized to act on behalf of
        the owner of an exclusive right that is allegedly infringed. Please be
        advised that Diamond Foundry Inc. will not respond to complaints that do
        not meet these requirements. If Diamond Foundry Inc. determines that the
        materials alleged to infringe your copyright or trademark rights do not
        require removal, Diamond Foundry Inc. will remove those materials only
        pursuant to a court order declaring the content or use of the materials
        unlawful.
      </p>
      <p>
        If you believe in good faith that a notice of copyright infringement has
        been wrongly filed against you, the DMCA permits you to send us a
        counter-notice. Counter-notices must include the following information:
        (a) your name, address, and telephone number; (b) the source of the
        content that was removed; (c) a statement under penalty of perjury that
        you have a good faith belief that the content was removed in error; (d)
        a statement that you consent to the jurisdiction of Federal District
        Court for the judicial district in which your address is located, or if
        your address is outside of the United States, for any judicial district
        in which Diamond Foundry Inc. may be found, and that you will accept
        service of process from the person who provided the original complaint;
        and (e) a physical or electronic signature (for example, typing your
        full name).
      </p>
      <p>
        Notices and counter-notices with respect to the Website must meet the
        then-current statutory requirements imposed by the DMCA (see
        http://www.loc.gov/copyright for details) and should be sent to the
        agent identified below. We suggest that you consult your legal advisor
        before filing a notice or counter-notice. Also, be aware that there can
        be penalties for false claims under the DMCA.
      </p>
      <p>
        Agent to Receive Notices of Claimed Infringement
        <br />
        info@diamondfoundry.com
      </p>
      <h3>TERM &amp; TERMINATION</h3>
      <p>
        This Agreement is effective from the date that you first access the
        Website or submit any information to Diamond Foundry Inc., whichever is
        earlier, and shall remain effective until terminated in accordance with
        its terms. Diamond Foundry Inc. may immediately terminate this
        Agreement, and/or your access to and use of the Website, or any portion
        thereof, at any time and for any reason, with or without cause, without
        prior notice. This Agreement will also terminate automatically if you
        fail to comply with any term or provision of this Agreement. Upon
        termination of this Agreement by either party, your right to use the
        Website shall immediately cease, and you shall destroy all copies of
        information that you have obtained from the Website, whether made under
        the terms of this Agreement or otherwise. Notwithstanding the foregoing
        or anything else to the contrary, all disclaimers, all limitations of
        liability and indemnification obligations expressed hereunder and all
        Diamond Foundry Inc. rights of ownership and license rights shall
        survive any termination.
      </p>
      <p>
        We reserve the right at any time and from time to time to modify,
        discontinue, temporarily or permanently, the Website, or any part or
        portion thereof, with or without notice to you. You agree that we shall
        not be liable to you or to any third party for any modification,
        suspension or discontinuance of the Website, or any part of portion
        thereof and you shall not at any time rely upon the Website, or any
        Information or any Services at any time. Nothing in this Agreement shall
        be construed to obligate Diamond Foundry Inc. to maintain and support
        the Website, or any part or portion thereof, during the term of this
        Agreement.
      </p>
      <h3>DISCLAIMERS</h3>
      <p>
        THE WEBSITE, INCLUDING BUT NOT LIMITED TO THE INFORMATION AND SERVICES,
        ARE PROVIDED “AS IS” AND “WITH ALL FAULTS” AND WITHOUT WARRANTIES OF ANY
        KIND, EITHER EXPRESS OR IMPLIED, AND ALL WARRANTIES, EXPRESS OR IMPLIED,
        INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE,
        NON-INFRINGEMENT, ACCURACY, COMPLETENESS, MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, ANY WARRANTIES THAT MAY ARISE FROM COURSE OF
        DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE, AND ANY WARRANTIES
        THAT THE INFORMATION AND WEBSITE IS CURRENT AND/OR UP-TO-DATE ARE HEREBY
        EXPRESSLY DISCLAIMED TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE
        LAW.
      </p>
      <p>
        THERE IS NO WARRANTY, REPRESENTATION OR GUARANTEE THAT THE WEBSITE, OR
        YOUR USE OF THE WEBSITE, WILL BE UNINTERRUPTED, COMPLETE, ACCURATE,
        CURRENT, RELIABLE, ERROR-FREE, SECURE, OR THAT ANY PROBLEMS WILL BE
        CORRECTED, OR THAT THE WEBSITE, OR ANY INFORMATION, SOFTWARE OR OTHER
        MATERIAL ACCESSIBLE FROM THE WEBSITE, IS FREE OF VIRUSES OR OTHER
        HARMFUL COMPONENTS. WE DO NOT WARRANT, GUARANTEE, OR MAKE ANY
        REPRESENTATION REGARDING THE USE OF, OR THE RESULTS OF THE USE OF THE
        WEBSITE EITHER IN TERMS OF ITS COMPATIBILITY WITH HARDWARE OR OTHER
        SOFTWARE OR EQUIPMENT, AND YOU ASSUME ALL RESPONSIBILITY AND RISK FOR
        YOUR USE OF THE WEBSITE AND/OR SERVICES AND INFORMATION AND YOUR
        RELIANCE THEREON.
      </p>
      <p>
        DIAMOND FOUNDRY INC MAKES NO WARRANTY, REPRESENTATION OR GUARANTEE WITH
        RESPECT TO PRODUCTS, INFORMATION OR SERVICES OFFERED BY THIRD-PARTY
        SELLERS ON THE WEBSITE, AND DIAMOND FOUNDRY INC SPECIFICALLY DISCLAIMS
        ANY WARRANTY, REPRESENTATION OR GUARANTEE WITH RESPECT TO THE QUALITY,
        SAFETY, LEGALITY OR OTHER CHARACTERISTICS OF SUCH PRODUCTS OR SERVICES,
        OR WITH RESPECT TO THE CONDUCT OF ANY THIRD-PARTY SELLER IN CONNECTION
        WITH SUCH OFFERS OR SALES, AND ANY USE OF OR RELIANCE THEREOF SHALL BE
        AT YOUR OWN RISK.
      </p>
      <h3>LIMITATION OF LIABILITY</h3>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW AND NOTWITHSTANDING
        THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY OF ANY KIND OR
        ANYTHING ELSE TO THE CONTRARY, NEITHER DIAMOND FOUNDRY INC NOR ANY OF
        ITS LICENSORS, SPONSORS, AGENTS, SUCCESSORS, OR ASSIGNS, NOR OUR OR
        THEIR DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS, OR OTHER
        REPRESENTATIVES, ARE RESPONSIBLE OR LIABLE FOR ANY INDIRECT, INCIDENTAL,
        CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES (INCLUDING
        WITHOUT LIMITATION ANY LOSS OF PROFITS, LOST SAVINGS, OR LOSS OF DATA)
        OR LIABILITIES UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR
        OTHER THEORY ARISING OUT OF OR RELATING IN ANY MANNER TO THE WEBSITE,
        INFORMATION, SERVICES AND/OR ANY LINKED WEBSITE, WHETHER OR NOT WE HAVE
        BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES OR LIABILITIES. TO THE
        MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, YOUR SOLE REMEDY WITH
        RESPECT TO THIS WEBSITE, THE INFORMATION, SERVICES, OR ANY LINKED
        WEBSITE IS TO STOP USING THE WEBSITE, SERVICE, OR LINKED WEBSITE, AS
        APPLICABLE. NEITHER DIAMOND FOUNDRY INC NOR ANY OF ITS LICENSORS,
        SPONSORS, AGENTS, SUCCESSORS, OR ASSIGNS, NOR OUR OR THEIR DIRECTORS,
        OFFICERS, EMPLOYEES, CONSULTANTS, OR OTHER REPRESENTATIVES WILL HAVE ANY
        LIABILITY TO YOU FOR ANY DAMAGES, EXPENSES OR OTHER LIABILITY INCURRED
        BY YOU AS A RESULT OF (1) ANY INACCURACY, INCOMPLETENESS OR
        MISREPRESENTATION OF ANY INFORMATION, CONTENT, POSTINGS OR SUBMISSIONS
        PROVIDED OR POSTED ON THE WEBSITE BY THIRD PARTIES OR (2) YOUR HIRING OR
        ENGAGEMENT OF, OR ASSOCIATION WITH, ANY THIRD PARTY ADVERTISING OR
        OFFERING PRODUCTS OR SERVICES THROUGH THE WEBSITE.
      </p>
      <h3>INDEMNIFICATION</h3>
      <p>
        You agree to fully indemnify, defend, and hold Diamond Foundry Inc., our
        licensors, suppliers, agents, successors, and assigns and our and their
        directors, officers, employees, consultants, and other representatives,
        harmless from and against any and all claims, damages, losses, costs
        (including reasonable attorneys’ fees), and other expenses that arise
        directly or indirectly out of or from: (a) your breach of this
        Agreement, including but not limited to the Acceptable Use Policy, or
        any unauthorized Use of any Information or the Website; (b) any
        allegation that any materials or Content you submit to us or transmit to
        the Website infringe or otherwise violate the copyright, patent,
        trademark, trade secret, or other intellectual property or other rights
        of any third party; (c) your activities in connection with the Website
        or other websites to which the Website is linked; (d) any
        misrepresentation, modification made to marketing materials or
        Information provided by us, or non-compliance with disclosure
        requirements regarding the Products or Services; and/or your negligence
        or willful misconduct.
      </p>
      <h3>JURISDICTIONAL ISSUES</h3>
      <p>
        Diamond Foundry Inc. makes no representation that the Website operates
        (or is legally permitted to operate) in all geographic areas, or that
        the Information, Website, or Services are appropriate or available for
        use in other locations. Accessing the Website from territories where the
        Website or any content or functionality of the Website or portion
        thereof is illegal is expressly prohibited. If you choose to access the
        Website, you agree and acknowledge that you do so on your own initiative
        and at your own risk, and that you are solely responsible for compliance
        with all applicable laws.
      </p>
      <h3>NOTICE FOR CALIFORNIA USERS</h3>
      <p>
        Under California Civil Code Section 1789.3, California Website users are
        entitled to the following specific consumer rights notice: The Complaint
        Assistance Unit of the Division of Consumer Services of the California
        Department of Consumer Affairs may be contacted in writing at 1625 N.
        Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone
        at (800) 952-5210.
      </p>
      <h3>DISPUTE RESOLUTION/GOVERNING LAW</h3>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of the State of California, excluding that body of law relating to
        conflict of laws. The parties agree that any controversy or claim
        arising out of or relating to this Agreement, or the breach thereof,
        will be settled by binding arbitration in accordance with California
        Code of Civil Procedure Section 1280 et seq., and the then current rules
        and procedures of JAMS. The arbitration shall be a confidential
        proceeding, closed to the general public. The arbitration will take
        place in San Francisco, California and be conducted in the English
        language. The decision rendered by the arbitrator will be binding upon
        the parties hereto, and any judgment upon the award rendered by the
        arbitrator may be entered in any court having jurisdiction thereof. The
        parties consent to the jurisdiction of all federal and state courts in
        California. Venue will lie exclusively in San Francisco, California. For
        the sake of clarity, nothing in this paragraph shall affect Diamond
        Foundry Inc.’s ability to seek from a court injunctive or equitable
        relief at any time.
      </p>
      <p>
        If any arbitration or other proceeding is brought to enforce or
        interpret this Agreement or matters relating to it, the substantially
        prevailing party, as determined by the arbitrator’s award, will be
        entitled to recover reasonable attorneys’ fees and other costs and
        expenses incurred in such arbitration or proceeding from the other
        party, in addition to any other relief to which such prevailing party is
        entitled; provided that in no event will the arbitrator have the
        authority to award punitive damages.
      </p>
      <h3>MISCELLANEOUS</h3>
      <p>
        If any provision of this Agreement is found to be unlawful, void, or for
        any reason is unenforceable, then that provision shall be deemed
        severable from this Agreement and shall not affect the validity and
        enforceability of any remaining provisions. This is the entire Agreement
        between you and us relating to the subject matter herein and supersedes
        any and all prior or contemporaneous written or oral agreements between
        you and us with respect to such subject matter. This Agreement may not
        be changed, waived or modified except by Diamond Foundry Inc. as
        provided herein or otherwise by written instrument signed by Diamond
        Foundry Inc. Neither this Agreement nor any right, obligation, or remedy
        hereunder is assignable, transferable, delegatable, or sublicensable by
        you except with Diamond Foundry Inc.’s prior written consent, and any
        attempted assignment, transfer, delegation, or sublicense shall be null
        and void. Diamond Foundry Inc. may assign, transfer, or delegate this
        Agreement or any right or obligation or remedy hereunder in its sole
        discretion. No waiver by either party of any breach or default hereunder
        shall be deemed to be a waiver of any preceding or subsequent breach or
        default. Any heading, caption, or section title contained in this
        Agreement is inserted only as a matter of convenience and in no way
        defines or explains any section or provision hereof.
      </p>
      <p>Diamond Foundry Inc.</p>
    </React.Fragment>
  );
};

export default Terms;
