import React from 'react';
import PolishedDiamondsGroup from '../../types/models/polishedDiamondsGroup';
import Option from '../../types/more/option';
import PolishedDiamondGroupListItem from './PolishedDiamondGroupListItem';
import { useDispatch } from 'react-redux';
import CellEntry from '../../types/more/cellEntry';
import { setPolishedCellEntry } from '../../state/polishedDiamondGroups/actions';

type Props = {
  data: CellEntry<PolishedDiamondsGroup>[];
  header: Option<string, string>[];
};

const TableBody = ({ data, header }: Props) => {
  const dispatch = useDispatch();
  return (
    <div className="tbody">
      {data.map((entry: CellEntry<PolishedDiamondsGroup>, index: number) => (
        <PolishedDiamondGroupListItem
          header={header}
          key={index}
          data={entry}
          onChange={e => {
            dispatch(setPolishedCellEntry(e));
          }}
        />
      ))}
    </div>
  );
};

export default TableBody;
