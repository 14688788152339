import {
  SET_ROUGH_DIAMOND_GROUPS,
  SET_ROUGH_DIAMOND_GROUPS_TO_RENDER,
  SELECT_ROUGH_DIAMOND_GROUP,
  SELECT_ALL_ROUGH_DIAMOND_GROUPS,
  DESELECT_ALL_ROUGH_DIAMOND_GROUPS,
  ADD_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER,
  REMOVE_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER,
  CLEAR_ROUGH_DIAMOND_GROUP_FILTERS,
} from './constants';

const initialState = {
  original: [],
  rendered: [],
  selected: [],
  filters: {
    colorCarat: [],
  },
  searchKey: '',
  isgcalchecked: false,
  diamondToFocus: '',
  loading: true, // This is set to false when we load rough diamonds for the first time
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUGH_DIAMOND_GROUPS:
      return Object.assign({}, state, {
        original: action.payload,
        rendered: action.payload,
        loading: false,
      });
    case SET_ROUGH_DIAMOND_GROUPS_TO_RENDER:
      return Object.assign({}, state, { rendered: action.payload });
    case SELECT_ROUGH_DIAMOND_GROUP:
      return Object.assign({}, state, {
        ...state,
        rendered: state.rendered.map(item => {
          if (item.id === action.payload) {
            item.isSelected = !item.isSelected;
          }
          return item;
        }),
        selected: state.rendered.filter(item => item.isSelected),
      });
    case SELECT_ALL_ROUGH_DIAMOND_GROUPS:
      const rendered = state.rendered.map(item => {
        item.isSelected = true;
        return item;
      });
      return { ...state, rendered: rendered, selected: rendered };
    case DESELECT_ALL_ROUGH_DIAMOND_GROUPS:
      const renderedItems = state.rendered.map(item => {
        item.isSelected = false;
        return item;
      });
      return { ...state, rendered: renderedItems, selected: [] };
    case ADD_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER:
      return {
        ...state,
        filters: { colorCarat: [...state.filters.colorCarat, action.value] },
      };
    case REMOVE_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER:
      // First, find the index of the element to be removed.
      let targetIndex = -1;
      for (let i = 0; i < state.filters.colorCarat.length; i++) {
        if (
          state.filters.colorCarat[i].color === action.value.color &&
          state.filters.colorCarat[i].caratRangeIndex ===
            action.value.caratRangeIndex
        ) {
          targetIndex = i;
          break;
        }
      }
      if (targetIndex === -1) return { ...state };

      // Then slice around the element to be removed
      return {
        ...state,
        filters: {
          colorCarat: [
            ...state.filters.colorCarat.slice(0, targetIndex),
            ...state.filters.colorCarat.slice(targetIndex + 1),
          ],
        },
      };
    case CLEAR_ROUGH_DIAMOND_GROUP_FILTERS:
      return { ...state, filters: { colorCarat: [] } };
    default:
      return state;
  }
};
