import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Button } from 'reactstrap';
import axios from 'axios';
import SVG from '../components/SVG';
import Confirmation from '../components/Confirmation';
import PasswordInput from '../components/PasswordInput';

import queryString from 'query-string';
import getEnvConfig from '../env';
export const { DF_API_HOST } = getEnvConfig();

export class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(this.props.location.search);

    this.state = {
      email: query.email,
      errorMessage: '',
      passwordResetToken: query.reset_password_token,
      password: '',
      passwordConfirmation: '',
      showConfirmation: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    if (this.state.password === this.state.passwordConfirmation) {
      axios
        .put(`${DF_API_HOST}/api/v1/users/password`, {
          user: {
            email: this.state.email,
            password: this.state.password,
            reset_password_token: this.state.passwordResetToken,
          },
        })
        .then(response => {
          this.setState({ showConfirmation: true });
        })
        .catch(error => {
          this.setState({
            errorMessage: 'Unable to update password.',
          });
        });
    } else {
      this.setState({ errorMessage: 'Passwords do not match.' });
    }
  }

  render() {
    return (
      <div className="reset-page df-logout-margin">
        <div className="reset-container">
          <div className="reset-headers">
            <SVG className="Svg df-logo" viewBox="0 0 603.8 40.9" name="logo" />
          </div>
          <div className="reset-form">
            <h1 className="df-title">RESET YOUR PASSWORD</h1>

            <PasswordInput
              inputStyle="df-text-inputs password-input"
              placeholder="Password"
              name="password"
              onChange={this.handleChange}
            />
            <PasswordInput
              inputStyle="df-text-inputs password-input"
              placeholder="Confirm new password"
              name="passwordConfirmation"
              onChange={this.handleChange}
            />

            <span className="error-message">{this.state.errorMessage}</span>

            <Button className="df-cta" onClick={this.handleSubmit}>
              RESET & SAVE
            </Button>
          </div>
          <div className="df-link-container">
            <Link to="/" className="df-link flex">
              <i className="material-icons">arrow_back</i> Back to Login Page
            </Link>
          </div>
          {this.state.showConfirmation && (
            <Confirmation
              parent="reset"
              closeConfirmation={e =>
                this.setState({ showConfirmation: false })
              }
              title="YOUR PASSWORD HAS BEEN RESET!"
            />
          )}
        </div>
      </div>
    );
  }
}

export default ChangePasswordPage;
