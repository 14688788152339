import styled from '@emotion/styled';

type Props = {
  column?: boolean;
};

const FlexCenter = styled.div<Props>`
  display: flex;
  flex-direction: ${prop => (prop.column ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
`;

export default FlexCenter;
