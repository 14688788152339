import React, { useEffect, useState } from 'react';
import InvoiceDetailed from '../components/invoice/InvoiceDetailed';
import Fetch from '../components/ui/Fetch';
import { OnWaitSpinner } from '../utils/orderUtilities';
import styled from '@emotion/styled';
import Transaction from '../types/transaction';
import { onGet } from '../api/helpers';
import { useSelector } from 'react-redux';
import RootState from '../types/redux/rootState';
import Auth from '../types/redux/auth';
import { useParams } from '@reach/router';
import CustomerAccount from '../types/models/customerAccount';

type Props = {
  initLoading?: boolean;
};

type InvoiceResponse = {
  transaction: Transaction;
  customer_account: CustomerAccount;
};

const fetchInvoice = (customer_account_id: string, transaction_id: string) => {
  return onGet<InvoiceResponse>(
    `api/v1/customer_accounts/${customer_account_id}/transactions/${transaction_id}`
  );
};
const MAIN = styled.div``;

// Acts as a null object.
const TRANSACTION_NULL_OBJECT: Transaction = {
  applied_amount: 0,
  shipdate: '',
  doc_number: '',
  due_at: '',
  id: '',
  netsuite_status: '',
  paid_total: 0,
  trandate: '',
  transaction_lines: [],
  transaction_total: 0,
  transaction_type: '',
};

const TransactionPage = ({ initLoading = false }: Props) => {
  const [loading, setIsLoading] = useState(initLoading);
  const [statefulEmpty, setStatefulEmpty] = useState(true);
  const [transaction, setTransaction] = useState<Transaction>(
    TRANSACTION_NULL_OBJECT
  );

  const [
    statefulAccount,
    setStatefulAccount,
  ] = useState<CustomerAccount | null>(null);

  // The transaction id.
  const { id } = useParams();

  const authSelector = useSelector<RootState, Auth>(state => state.auth);

  const onFetch = async () => {
    setIsLoading(true);
    setStatefulEmpty(true);
    try {
      const customerAccountId = authSelector.user.customer_account.id;
      const res = await fetchInvoice(customerAccountId, id);
      if (res.data) {
        setTransaction(res.data.transaction);
        setStatefulAccount(res.data.customer_account);
        setIsLoading(false);
        setStatefulEmpty(false);
      }
    } catch (e) {}
  };

  useEffect(() => {
    onFetch();
  }, [authSelector]);
  return (
    <MAIN>
      <Fetch
        render={
          <InvoiceDetailed
            transaction={transaction}
            customer_account={statefulAccount}
          />
        }
        empty={<OnWaitSpinner />}
        loading={<OnWaitSpinner />}
        isEmpty={statefulEmpty}
        isLoading={loading}
      />
    </MAIN>
  );
};

export default TransactionPage;
