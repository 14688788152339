import { getDiamondPrice } from './util';

/*
  These arrays define the sort order for string values that aren't sorted alphabetically.
*/

// Later in the list is higher in rank. AKA "D" is better color than "L"
const colorMap = [
  'P',
  'O',
  'N',
  'M',
  'L',
  'K',
  'J',
  'I',
  'H',
  'G',
  'F',
  'E',
  'D',
];

const propertyRankMap = {
  estimated_polish_color: colorMap,
};

// Custom comparators for diamond properties
export const roughDiamondComparator = (diamondA, diamondB, property) => {
  switch (property) {
    case 'plan_weight_1':
    case 'plan_weight_2':
    case 'plan_weight_3':
    case 'plan_weight_4':
    case 'carat_weight': {
      const A = parseFloat(diamondA[property]);
      const B = parseFloat(diamondB[property]);

      return B - A;
    }
    case 'price': {
      const costA = parseFloat(getDiamondPrice(diamondA));
      const costB = parseFloat(getDiamondPrice(diamondB));

      return costB - costA;
    }
    case 'estimated_polish_color': {
      const rankA = propertyRankMap[property].indexOf(diamondA[property]);
      const rankB = propertyRankMap[property].indexOf(diamondB[property]);

      return rankB - rankA;
    }
    default: {
      if (!diamondA[property] || !diamondB[property]) return 0;

      const aValue = diamondA[property].toLowerCase();
      const bValue = diamondB[property].toLowerCase();
      if (bValue < aValue) {
        return -1;
      }
      if (bValue > aValue) {
        return 1;
      }
      return 0;
    }
  }
};
