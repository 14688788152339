import React from 'react';
import { Router, Redirect } from '@reach/router';

import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import NotFoundPage from '../pages/NotFoundPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import ForgotPage from '../pages/ForgotPage';
import UnsubscribePage from '../pages/UnsubscribePage';

import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import DiamondsPage from '../pages/DiamondsPage';
import RoughDiamondGroupsPage from '../pages/RoughDiamondGroupsPage';
import DashboardPage from '../pages/DashboardPage';
import CartPage from '../pages/CartPage';
import CheckOutPage from '../pages/CheckOutPage';
import AddShippingAddressPage from '../pages/AddShippingAddressPage';
import RingBuilderPage from '../pages/RingBuilderPage';
import MarketingPage from '../pages/MarketingPage';
import OrdersPage from '../pages/OrdersPage';
import OrderDetailsPage from '../pages/OrderDetailsPage';
import UserEditPage from '../pages/UserEditPage';
import CreditCardPage from '../pages/CreditCardPage';
import InviteSignUpPage from '../pages/InviteSignUpPage';
import WatchesPage from '../pages/WatchesPage';
import CustomOrdersPage from '../pages/CustomOrdersPage';
import CodeOfConductPage from '../pages/CodeOfConductPage';
import AccountStatementPage from '../pages/AccountStatementPage';
import TransactionPage from '../pages/TransactionPage';

export const ProtectedRoutes = ({ showToaster, closeToaster }) => (
  <Router className="main">
    <Redirect noThrow from="/" to="/diamonds" />
    {/* Unprotected Routes */}
    <InviteSignUpPage
      path="/accept"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <RegisterPage
      path="/register"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <LoginPage
      path="/login"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <NotFoundPage
      default
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <ForgotPage
      path="/reset_password"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <UnsubscribePage
      path="/unsubscribe"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <TermsAndConditionsPage
      path="/terms-and-conditions"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <PrivacyPolicyPage
      path="/privacy-policy"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CodeOfConductPage
      path="/supplier-code-of-conduct"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    {/* Protected Routes */}
    <CartPage
      path="/cart"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CheckOutPage
      path="/checkout"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <DiamondsPage
      path="/diamonds"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <RoughDiamondGroupsPage
      path="/rough"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />

    <DashboardPage
      path="/dashboard"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <OrdersPage
      path="/orders"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <OrderDetailsPage
      path="/orders/:orderId"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <AddShippingAddressPage
      path="/addshippingaddress"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <RingBuilderPage
      path="/shopify-app"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <MarketingPage
      path="/marketing-materials"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <UserEditPage
      path="/user/edit"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CreditCardPage
      path="/credit-card"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <WatchesPage
      path="/notifiers"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CustomOrdersPage
      path="/custom-orders"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <AccountStatementPage
      path="/statement"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <TransactionPage
      path="/statement/transaction/:id"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
  </Router>
);
