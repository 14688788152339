import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchUsers } from '../../api/index';
import WaitSpinner from '../../components/WaitSpinner';
import moment from 'moment';
import UserList from '../../components/UserList';
import { Link } from '@reach/router';

class AdminDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeUsers: null,
      inactiveUsers: null,
      loading: true,
    };

    this.fetchUsers = this.fetchUsers.bind(this);
  }

  componentDidMount() {
    this.fetchUsers();
  }

  async fetchUsers() {
    const users = await fetchUsers('?represented=true');
    this.setState({
      activeUsers: this.filterUsers(users, true),
      inactiveUsers: this.filterUsers(users, false),
      loading: false,
    });
  }

  filterUsers(users, active) {
    if (active) {
      return users.filter(
        user => moment(user.last_active_at) > moment().subtract(30, 'days')
      );
    } else {
      return users.filter(
        user =>
          !user.last_active_at ||
          moment(user.last_active_at) < moment().subtract(30, 'days')
      );
    }
  }

  render() {
    return (
      <main>
        <div className="page-wrapper-with-padding">
          <div className="main-wrapper">
            <h1 className="title">B2B Sales Dashboard</h1>

            <ul className="unstyled center">
              <li>
                <Link to="/admin/customers">Customers</Link>
                &nbsp;-&nbsp;
                <Link to="/admin/users">Users</Link>
                &nbsp;-&nbsp;
                <Link to="/inviteuser">User (Invite)</Link>
                &nbsp;-&nbsp;
                <Link to="/admin/orders">Orders</Link>
                &nbsp;-&nbsp;
                <Link to="/admin/notifiers">Notifiers</Link>
                &nbsp;-&nbsp;
                <Link to="/admin/memo-orders">Memo Orders</Link>
                &nbsp;-&nbsp;
                <Link to="/admin/aging-report-page">A/R Aging Report</Link>
                &nbsp;-&nbsp;
                <Link to="/admin/holds">All Holds</Link>
                &nbsp;-&nbsp;
                <Link to="/admin/reminders">Reminders</Link>
              </li>
            </ul>

            {this.state.activeUsers ? (
              <React.Fragment>
                <h1 className="df-title">My Active Users</h1>
                <UserList
                  loading={this.state.loading}
                  showToaster={this.props.showToaster}
                  users={this.state.activeUsers}
                />

                <h1 className="df-title">My Inactive Users</h1>
                <UserList
                  loading={this.state.loading}
                  showToaster={this.props.showToaster}
                  users={this.state.inactiveUsers}
                />
              </React.Fragment>
            ) : (
              <div className="WaitSpinner__container">
                <WaitSpinner />
              </div>
            )}
          </div>
        </div>
      </main>
    );
  }
}

export default connect()(AdminDashboard);
