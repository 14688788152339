import React from 'react';
import { connect } from 'react-redux';
import { formatUSD } from '../utils/util';
import PropTypes from 'prop-types';

// Allow multiple items to be selected and added to cart
export class MultiselectFooter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.items || this.props.items.length === 0) {
      return <div className="multiselect_wrapper" />;
    }

    let total = 0;
    this.props.items.forEach(item => {
      let price =
        ((item.prices || [])[0] || {}).amount_usd ||
        item.unit_price_msrp_usd ||
        item.parcel_price ||
        item.default_price / 100.0;
      total += parseFloat(price);
    });
    total = formatUSD(total);

    return (
      <div className="multiselect_footer_wrapper">
        <div className="multiselect_footer_body">
          <div className="multiselect_footer_items_wrapper">
            <div className="multiselect_footer_items">
              <div className="multiselect_footer_label">Selected:</div>
              <div className="multiselect_footer_value border_right">
                {this.props.items.length}
              </div>
              <div className="multiselect_footer_label df-show-nonmobile-only">
                Total:
              </div>
              <div className="multiselect_footer_value df-show-nonmobile-only">
                {total}
              </div>
              <button
                className="df-btn-red df-cta btn btn-secondary "
                onClick={() => {
                  this.props.dispatch(this.props.addToCart());
                }}
              >
                ADD TO CART
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MultiselectFooter.propTypes = {
  addToCart: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
};

export default connect()(MultiselectFooter);
