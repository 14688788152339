import React from 'react';

const PrivacyPolicyPage = () => (
  <main>
    <div className="page-wrapper-with-padding">
      <div className="main-wrapper terms">
        <h1>Privacy Policy</h1>
        <p>
          This privacy policy is to help you understand what information Diamond
          Foundry collects, how Diamond Foundry uses it, how it may be shared,
          and what choices you have. This privacy policy governs the products
          and services we offer through our website (collectively the “Site” or
          “Diamond Foundry”). When you use the Site, you consent to our
          collection, use and disclosure of information about you as described
          in this privacy policy. Additionally, our Terms of Use are
          incorporated by reference into this privacy policy. The terms “we,” or
          “our,” refer to Diamond Foundry, headquartered in San Francisco,
          California in the United States.
        </p>
        <h3>COLLECTION AND USE OF INFORMATION</h3>
        <p>
          We collect information that you provide us directly or automatically
          when you use our Site. We may use the information for purposes such as
          to: provide the Site’s functionality, improve the Site’s quality,
          personalize your experience, track usage of the Site, provide feedback
          to businesses and professionals whose products or services are listed
          on the Site, display relevant advertising, evaluate the success of our
          advertising, provide customer support, message you (in ways described
          by this privacy policy and managed by you in your account settings),
          back up our systems and allow for disaster recovery, enhance the
          security of the Site, fulfill transactions authorized by you on the
          Site, and comply with legal obligations.
        </p>
        <p>Of further note about the information we collect:</p>
        <ol>
          <ol>
            <li>
              Account and Profile Information:&nbsp;When you create an account
              on Diamond Foundry, then we collect your name and the contact
              information you provide. You also will be asked to create a
              profile on Diamond Foundry. If you do, your username, any photo
              you submit, and information you enter into the fields indicated
              for public display will be displayed publicly on the network. You
              can customize some of the information displayed publicly in your
              Diamond Foundry profile by going to your profile settings. If you
              feel that an unauthorized account has been created depicting you
              or your likeness, please contact support. You have an obligation
              to provide us accurate information.
            </li>
            <li>
              Public Content:<span>&nbsp;</span>The information that you post to
              the Site is intended for public consumption, including your
              photos, comments, Ideabooks, and profile information. By posting
              on the Site, you agree to permit us to disclose publicly the
              material you post.
            </li>
            <li>
              Communications:&nbsp;When you join Diamond Foundry by signing up
              for an account or creating a profile, as permitted by law, you are
              opting in to receive emails from us or about Diamond Foundry. You
              can manage your email and notice preferences in account settings,
              but note that you cannot opt out of receiving certain
              administrative or legal notices from us. Additionally, by joining,
              you are agreeing to receive feedback and communications from
              third-parties within the Site, including third-party providers of
              products or services who may comment on your postings within the
              Site. If you exchange messages with others through the Site, in
              accordance with applicable law, we may store them in order to
              process and deliver them, allow you to manage them, and
              investigate possible wrongdoing in connection with the Site.
              Diamond Foundry may also enable you to connect, at your
              initiation, outside the Site via your personal email address to
              third-party providers of products and services who you wish to
              contact outside of the Site.
            </li>
            <li>
              Transactional Information:<span>&nbsp;</span>If you purchase
              products within the Site, certain information collected for
              processing your request, such as your phone number, address,
              email, and credit card information, may be shared with relevant
              third-parties in order for them to process your request (but not
              for their own marketing or other purposes). When you submit credit
              card numbers, we encrypt that information using industry standard
              technology and use commercially reasonable security measures to
              protect it from misuse.
            </li>
            <li>
              Site Activity/Automatically Collected Information:&nbsp;We collect
              and may store information about your use of the Site, such as your
              search activity, the pages you view, and the date and time of your
              visit. We also collect and may store information that your
              computer or mobile device provides to us in connection with your
              use of the Site, such as your browser type, type of computer or
              mobile device, browser language, IP address, mobile carrier,
              unique device identifier, location, and requested and referring
              URLs.
            </li>
            <li>
              Linked Networks and Contacts:<span>&nbsp;</span>You can elect to
              connect your Diamond Foundry profile to another network like
              Facebook and, if you so choose, you will be asked to enable
              sharing of your contact information from that network to Diamond
              Foundry and to enable sharing of your activities on Diamond
              Foundry on that network
            </li>
          </ol>
        </ol>
        <h3>COOKIES AND OTHER TRACKING TECHNOLOGIES</h3>
        <p>
          We, and third parties we interact with, including our third party
          service providers, use cookies, web beacons, local shared objects
          (sometimes called “flash cookies”), and similar technology in
          connection with your use of the Site and third party websites
          (collectively referred to in this policy as<span>&nbsp;</span>
          “Cookies”). Cookies are small data files that may have unique
          identifiers, and reside, among other places, on your computer or
          mobile device, in emails we send to you, and on our Site.
        </p>
        <p>
          &nbsp;We may use Cookies to transmit information about you and your
          use of the Site, such as your browser type, search preferences, data
          relating to advertisements that have been displayed to you or that you
          have clicked on, and the date and time of your use. When we use
          Cookies, we do so in order to further Site features and processes,
          provide authentication and security for your transactions on the Site,
          store your preferences, facilitate relevant advertising, and help us
          learn more about how users engage with the Site. Our Cookies may be
          persistent or stored only during an individual session.
        </p>
        <p>
          Please note we do not change system behavior within the Site in
          response to browser requests not to be tracked.
        </p>
        <p>
          It may be possible to disable Cookies through your device or browser
          settings. The method for disabling cookies may vary by device and
          browser, but can usually be found in preferences or security settings.
          However, doing so may affect your ability to use the Site. If you are
          not comfortable with the above uses of Cookies, please do not use this
          Site.
        </p>
        <h3>SHARING YOUR INFORMATION</h3>
        <p>
          We do not rent or sell your personal contact information (such as your
          email address associated with your account registration) to
          third-parties to use for their own marketing purposes. We may share
          some information about you with third parties in connection with your
          use of the Site. Our information sharing with third-parties fall into
          these categories and purposes:
        </p>
        <ol>
          <li>
            Agents, Contractors, and Service Providers:<span>&nbsp;</span>We may
            share your information with our agents and contractors or other
            service providers as needed to carry out the performance and
            functionality of the Site, fulfill your requests or authorized
            commercial transactions, or provide customer support.
          </li>
          <li>
            Analytics and Usage:<span>&nbsp;</span>We may share user information
            in the aggregate, with third parties, such as advertisers,
            professionals, and business. For example, we may disclose to a
            business the number of users that have viewed their product.
            Business or professionals that participate on the Diamond Foundry
            network may also access usage data associated with your public
            profile. For example, a business can see that you, as identified by
            your public profile username, any profile photo, and any other
            publicly displayed profile identifiers (but not identified by your
            email or non-public account information), added their product to one
            of your Ideabooks on a particular date.
          </li>
          <li>
            Affiliated Entities<span>&nbsp;</span>We may share information from
            or about you with subsidiaries, joint ventures, or other companies
            under common control, in which case we will require them to honor
            this privacy policy.
          </li>
          <li>
            Business Transfers:&nbsp;If we are acquired by or merged with
            another entity, if substantially all of our assets are transferred
            to another company, or as part of a bankruptcy proceeding, we may
            transfer the information we have collected about you to the other
            company.
          </li>
          <li>
            Other Users<b>:</b>
            <span>&nbsp;</span>Information that you post publicly on Diamond
            Foundry (e.g., photos, comments, your profile) will be made publicly
            available to other users of Diamond Foundry.
          </li>
          <li>
            Investigations:<span>&nbsp;</span>We may investigate and disclose
            information from or about you, as permitted by applicable law, if we
            have a good faith belief that such investigation or disclosure is
            (a) reasonably necessary to comply with legal process and law
            enforcement instructions and orders, such as a search warrant,
            subpoena, statute, judicial proceeding, or other legal process
            served on us; (b) helpful to prevent, investigate, or identify
            possible wrongdoing in connection with the Site; or (c) helpful to
            protect our rights, reputation, property, or that of our users,
            subsidiaries, or the public.
          </li>
          <li>
            European Privacy Rights:
            <b>
              <span>&nbsp;</span>
            </b>
            If you are a data subject in Europe, you have the right to access,
            rectify, or erase any personal data we have collected about you
            through the Service. You also have the right to data portability and
            the right to restrict or object to our processing of personal data
            we have collected about you through the Service. In addition, you
            have the right to ask us not to process your personal data (or
            provide it to third parties to process) for marketing purposes or
            purposes materially different than for which it was originally
            collected or subsequently authorized by you. You may withdraw your
            consent at any time for any data processing we do based on consent
            you have provided to us. To exercise any of these rights, contact us
            as set forth in the section entitled “
            <a
              href="https://www.diamondfoundry.com/pages/contact-us"
              target="_blank"
              title="Contact"
              rel="noopener noreferrer"
              data-no-swup="true"
            >
              Contact
            </a>
            ”&nbsp; and specify which right you intend to exercise. We will
            respond to your request&nbsp;
            <span className="aBn" data-term="goog_1344760832" tabIndex={0}>
              <span className="aQJ">within 30 days</span>
            </span>
            . We may require additional information from you to allow us to
            confirm your identity. Please note that we store information as
            necessary to fulfill the purposes for which it was collected, and
            may continue to retain and use the information even after a data
            subject request for purposes of our legitimate interests, including
            as necessary to comply with our legal obligations, resolve disputes,
            prevent fraud, and enforce our agreements. If you have any issues
            with our compliance, you have the right to lodge a complaint with a
            European supervisory authority.
          </li>
        </ol>
        <h3>ACCOUNT TERMINATION AND DATA RETENTION</h3>
        <p>
          You can close your account at any time by going to account settings.
          When you close your account, you will cease to provide ongoing or
          future data to us. Your public profile will be deactivated. However,
          unless prohibited by applicable law, postings or comments you provided
          to the forum associated with your public profile prior to closing your
          account, will remain public. We may retain information about you for
          the purposes authorized under this privacy policy unless prohibited by
          law.
        </p>
        <h3>CHILDREN</h3>
        <p>
          The Site is intended for general audiences and is not directed to
          children under 13. We do not knowingly collect personal information
          from children under 13. If you become aware that a child has provided
          us with personal information without parental consent, please contact
          us at info@diamondfoundry.com, and we will take steps to remove such
          information and terminate the child’s account.
        </p>
        <h3>SECURITY</h3>
        <p>
          We follow generally accepted industry standards to protect the
          personal information submitted to us, both during transmission and
          once we receive it. However, no method of transmission over the
          Internet or via mobile device, or method of electronic storage, is
          100% secure – so we cannot guarantee its absolute security.
        </p>
        <h3>DATA IN THE UNITED STATES</h3>
        <p>
          Any information you provide through the Site may be transferred to,
          stored in, or processed in the United States.
        </p>
        <h3>SAFE HARBOR ADHERENCE</h3>
        <p>
          We adhere to the Safe Harbor Privacy Principles developed by the U.S.
          Department of Commerce and the European Union (EU), and have Safe
          Harbor self-assessment procedures in place. The Safe Harbor program is
          intended to provide equivalent protections for personal data as exist
          in the EU. We have certified that we adhere to the Safe Harbor Privacy
          Principles of notice, choice, onward transfer, security, data
          integrity, access and enforcement. For more information about the Safe
          Harbor and to view our certification, visit the U.S. Department of
          Commerce’s Safe Harbor website at&nbsp;
          <a href="https://www.export.gov/safeharbor_eu">
            https://www.export.gov/safeharbor_eu
          </a>
          .
        </p>
        <h3>QUESTIONS AND DISPUTE RESOLUTION</h3>
        <p>
          If you have any questions about our Safe Harbor participation, please
          contact us at info@Diamondfoundry.com. If you believe that we have not
          satisfactorily addressed your data privacy concerns related to the
          U.S.-EU Safe Harbor Privacy Principles, and without prejudice to any
          other remedy you may enjoy, you may obtain dispute resolution services
          through the International Centre for Dispute Resolution, a division of
          the American Arbitration Association. To use these services, you can
          file a claim at
          <span>&nbsp;</span>
          <a href="https://apps.adr.org/webfile/">
            https://apps.adr.org/webfile/
          </a>
          .
        </p>
        <p>
          If you are a European or Australian citizen and would like to have
          access to personal information we collect, you contact us with your
          request at info@diamondfoundry.com. A processing fee may apply as
          permitted by applicable law.
        </p>
        <h3>CHOICES</h3>
        <p>
          You have certain choices about how to control your data, including
          publicly displayed profile information, communications settings,
          network linking, and opting out of tracking by third- parties for
          certain advertising purposes. These choices are described throughout
          this privacy policy in the relevant sections.
        </p>
        <h3>LINKS TO OTHER SITES</h3>
        <p>
          Our Site contains links to other sites that are not controlled by us.
          Please be aware that we are not responsible for the privacy practices
          of such other sites. We encourage you to be aware when you leave our
          site and read the privacy policies of each and every site you visit.
          This privacy policy applies only to information collected by our Site.
        </p>
        <h3>MODIFICATIONS AND UPDATES TO THIS PRIVACY POLICY</h3>
        <p>
          We may revise this privacy policy from time to time. If we make
          material changes to this privacy policy, we may notify you by email or
          by posting a notice on the Site. By continuing to access or use the
          Site after those changes become effective, you agree to be bound by
          the revised privacy policy.
        </p>
      </div>
    </div>
  </main>
);

export default PrivacyPolicyPage;
