import moment from 'moment';
import { getDiamondPrice, getDiamondGirdleAbbreviation } from './util';

/*
  These arrays define the sort order for string values that aren't sorted alphabetically.
*/

// Later in the list is higher in rank. AKA "F" is better clarity than "VS1"
const colorMap = [
  'P',
  'O',
  'N',
  'M',
  'L',
  'K',
  'J',
  'I',
  'H',
  'G',
  'F',
  'E',
  'D',
];
const cutMap = [
  'Fair',
  'Good',
  'Very Good',
  'VG',
  'Excellent',
  'EX',
  'Signature Ideal',
  'S Ideal',
  'Ideal',
  'Ideal+Hearts',
  'Hearts',
];
const clarityMap = [
  'I3',
  'I2',
  'I1',
  'SI3',
  'SI2',
  'SI1',
  'VS2',
  'VS1',
  'VVS2',
  'VVS1',
  'IF',
  'F',
];
const girdleMap = [
  'ETN',
  'VTN',
  'VTN-ETK',
  'TN',
  'TN-M',
  'TN-STK',
  'TN-TK',
  'TN-VTK',
  'TN-ETK',
  'M',
  'M-STK',
  'M-TK',
  'M-VTK',
  'STK',
  'STK-TK',
  'STK-VTK',
  'STK-ETK',
  'TK',
  'TK-STK',
  'TK-VTK',
  'TK-ETK',
  'VTK',
  'VTK-ETK',
  'ETK',
  'M-ETK',
];

const propertyRankMap = {
  color: colorMap,
  cut: cutMap,
  clarity: clarityMap,
  polish: cutMap,
  girdle: girdleMap,
  symmetry: cutMap,
};

// Custom comparators for diamond properties
export const diamondComparator = (diamondA, diamondB, property) => {
  switch (property) {
    case 'carat':
    case 'crown_angle':
    case 'crown_height':
    case 'depth_pct':
    case 'pavilion_angle':
    case 'pavilion_height':
    case 'table_size': {
      const A = parseFloat(diamondA[property]);
      const B = parseFloat(diamondB[property]);

      return B - A;
    }
    case 'cost': {
      const costA = parseFloat(getDiamondPrice(diamondA));
      const costB = parseFloat(getDiamondPrice(diamondB));

      return costB - costA;
    }
    case 'shape': {
      const shapeA = diamondA.shape.toLowerCase();
      const shapeB = diamondB.shape.toLowerCase();
      if (shapeB < shapeA) {
        return -1;
      }
      if (shapeB > shapeA) {
        return 1;
      }

      return 0;
    }
    case 'cut': {
      const cutRankA = propertyRankMap[property].indexOf(diamondA.cut_grade);
      const cutRankB = propertyRankMap[property].indexOf(diamondB.cut_grade);

      return cutRankB - cutRankA;
    }
    case 'girdle': {
      const cutRankA = propertyRankMap[property].indexOf(
        getDiamondGirdleAbbreviation(diamondA[property])
      );
      const cutRankB = propertyRankMap[property].indexOf(
        getDiamondGirdleAbbreviation(diamondB[property])
      );

      return cutRankB - cutRankA;
    }
    case 'date': {
      const dateA = moment(diamondA.created_at);
      const dateB = moment(diamondB.created_at);

      return (dateB._d - dateA._d) / (1000 * 60 * 60 * 24);
    }
    default: {
      const rankA = propertyRankMap[property].indexOf(diamondA[property]);
      const rankB = propertyRankMap[property].indexOf(diamondB[property]);

      return rankB - rankA;
    }
  }
};
