import React, { Component } from 'react';
import { connect } from 'react-redux';
import { merge } from 'lodash';
import { fetchCustomerAccount } from '../../api/index';
import { formatUSDWithNoDecimals } from '../../utils/util';
import UserList from '../../components/UserList';
import OrderList from '../../components/OrderList';
import AccountStatement from '../../components/AccountStatement';
import WaitSpinner from '../../components/WaitSpinner';

const DetailRow = ({ name, value }) => {
  return (
    <div className="row">
      <div className="col">{name}</div>
      <div className="col">{value}</div>
    </div>
  );
};

class CustomerAccountDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerAccount: null,
    };

    this.fetchCustomerAccount = this.fetchCustomerAccount.bind(this);
  }

  componentDidMount() {
    this.fetchCustomerAccount();
  }

  componentDidUpdate(prevProps) {
    // Fetch customerAccount after user is loaded. Required when loading this page directly from a link.
    if (prevProps.auth.user.id !== this.props.auth.user.id) {
      this.fetchCustomerAccount();
    }
  }

  async fetchCustomerAccount() {
    const customerId = this.props.customerId;
    const customerAccount = await fetchCustomerAccount(customerId);

    // merge with existing redux customerAccounts data
    let idxCustomerAccountData = this.props.customers.customerAccounts.filter(
      customerAccount => customerAccount.id === customerId
    );

    let combinedCustomerAccountData = merge(
      idxCustomerAccountData[0],
      customerAccount
    );

    this.setState({
      customerAccount: combinedCustomerAccountData,
    });
  }

  salesRepValue() {
    if (this.state.customerAccount && this.state.customerAccount.sales_rep) {
      return this.state.customerAccount.sales_rep;
    }
    const rep = (this.state.customerAccount || {}).sales_representative || {};

    return rep.first_name && rep.last_name
      ? `${rep.first_name} ${rep.last_name}`
      : 'N/A';
  }

  render() {
    const customer = this.state.customerAccount;

    return (
      <main>
        <div className="container">
          <h2 className="title">Customer Details</h2>
          {!customer && <WaitSpinner />}
          {!!customer && (
            <React.Fragment>
              <div className="user-details-page main-wrapper padded">
                <div className="table-wrapper">
                  <DetailRow
                    name="Company Name:"
                    value={customer.company_name}
                  />
                  <DetailRow
                    name="Credit Limit:"
                    value={formatUSDWithNoDecimals(
                      customer.credit_limit / 100.0
                    )}
                  />
                  <DetailRow
                    name="Open Balance:"
                    value={formatUSDWithNoDecimals(
                      customer.open_balance / 100.0
                    )}
                  />

                  <DetailRow
                    name="Unbilled Orders:"
                    value={formatUSDWithNoDecimals(
                      customer.unbilled_orders / 100.0
                    )}
                  />
                  {customer.quarters && (
                    <DetailRow
                      name="$AvgQtr:"
                      value={
                        (customer.quarters || {}).length < 1
                          ? formatUSDWithNoDecimals(0)
                          : formatUSDWithNoDecimals(
                              customer.quarters[0].avg_qtr / 100.0
                            )
                      }
                    />
                  )}
                  <React.Fragment>
                    {!!customer.qtd && (
                      <DetailRow
                        name="$QTD:"
                        value={formatUSDWithNoDecimals(customer.qtd / 100.0)}
                      />
                    )}

                    {!!customer.assigned_pricing_class && (
                      <DetailRow
                        name="Assigned Pricing:"
                        value={
                          !customer.base_price_level
                            ? 'Partner'
                            : customer.base_price_level
                        }
                      />
                    )}
                  </React.Fragment>

                  <DetailRow name="Sales Rep:" value={this.salesRepValue()} />
                </div>
              </div>
              <AccountStatement
                agingReportPeriods={(customer || {}).aging_report_periods}
                agingReportTransactions={
                  (customer || {}).aging_report_transactions
                }
                openBalance={customer.open_balance || 0}
                customerAccountId={this.props.customerId}
              />
              <h2>Users</h2>
              <UserList
                users={customer.users}
                showToaster={this.props.showToaster}
                loading={false}
              />
              <h2>Orders</h2>
              <OrderList
                orders={this.state.customerAccount.orders.filter(
                  order => order.grand_total_in_cents !== 0
                )}
              />
            </React.Fragment>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    customers: state.customerAccounts,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(CustomerAccountDetailsPage);
