import React from 'react';
import CellEntry from '../../types/more/cellEntry';
import PolishedDiamondsGroup from '../../types/models/polishedDiamondsGroup';
import Fetch from '../ui/Fetch';
import { formatPricing } from '../../utils/util';

type Props = {
  selected: CellEntry<PolishedDiamondsGroup>[];
  onAddToCart: () => void;
};

const PolishedMultiselectFooter = ({ selected, onAddToCart }: Props) => {
  const totalPrice = selected.reduce(
    (accumulator: number, item: CellEntry<PolishedDiamondsGroup>) =>
      +item.entry.parcel_price + accumulator,
    0
  );
  return (
    <Fetch
      render={
        <>
          <div className="multiselect_footer_wrapper">
            <div className="multiselect_footer_body">
              <div className="multiselect_footer_items_wrapper">
                <div className="multiselect_footer_items">
                  <div className="multiselect_footer_label">Selected:</div>
                  <div className="multiselect_footer_value border_right">
                    {selected.length}
                  </div>
                  <div className="multiselect_footer_label df-show-nonmobile-only">
                    Total:
                  </div>
                  <div className="multiselect_footer_value df-show-nonmobile-only">
                    {formatPricing(totalPrice)}
                  </div>
                  <button
                    className="df-btn-red df-cta btn btn-secondary "
                    onClick={onAddToCart}
                  >
                    ADD TO CART
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      empty={
        <>
          <div className="multiselect_wrapper" />
        </>
      }
      isEmpty={selected.length === 0}
    />
  );
};

export default PolishedMultiselectFooter;
