import { Link } from '@reach/router';
import React from 'react';
import { formatUSD } from './util';
import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';

import TimestampProvider from '../components/admin/TimestampProvider';
import { getTwoLetterShapeAbbreviation } from './shapeUtilities';
import WaitSpinner from '../components/WaitSpinner';
import TableComponent from '../components/admin/TableComponent';
import { compareCurrency } from './tableUtilities';

const OrderItemAbbreviatedColumn = ({ orderItem }) => {
  if (orderItem.item_type === 'RoughDiamondGroup') {
    return `${orderItem.item.name}, ${
      orderItem.item.quantity
    }x ${orderItem.item.carat_weight_mean.toPrecision(2)} ct blocks`;
  }

  if (orderItem.item_type === 'PolishedParcel') {
    return `Parcel: ${orderItem.item.name}, Items Qty: ${orderItem.item.total_program_count}, ${orderItem.item.netsuite_program_type}`;
  }

  if (orderItem.item_type === 'Diamond') {
    return `F${orderItem.item.lot_id}, ${orderItem.item.carat} ct ${
      orderItem.item.clarity
    }, ${orderItem.item.color}, ${getTwoLetterShapeAbbreviation(
      orderItem.item.shape
    )}`;
  }

  if (orderItem.item_type === 'RoughDiamond') {
    return `${orderItem.item.item_name} ${orderItem.item.carat_weight}`;
  }
};

export const OrderSummaryTableColumn = ({ order }) => {
  if (order && order.order_items) {
    return (
      <React.Fragment>
        <Link className="df-link" to={`/orders/${order.id}`}>
          {order.order_items.map(orderItem => {
            if (order.order_items.indexOf(orderItem) <= 1) {
              return (
                <React.Fragment key={`frag-${orderItem.id}`}>
                  <br key={`br-${orderItem.id}`} />
                  <OrderItemAbbreviatedColumn
                    key={`abbr-${orderItem.id}`}
                    orderItem={orderItem}
                  />
                </React.Fragment>
              );
            }
          })}
          {order.order_items.length > 2 && `...`}
        </Link>
      </React.Fragment>
    );
  }
  return (
    <Link className="df-link" to={`/orders/${order.id}`}>
      {order.id}
    </Link>
  );
};

const orderColumns = [
  {
    name: 'id',
    title: 'ORDER',
    getCellValue: row => <OrderSummaryTableColumn order={row} key={row.id} />,
  },
  {
    name: 'items',
    title: 'ITEMS',
    getCellValue: row => row.order_items.length,
  },
  {
    name: 'total',
    title: 'TOTAL',
    getCellValue: row =>
      formatUSD(
        row.order_items.reduce((total, orderItem) => {
          return Number.parseFloat(orderItem.sold_price) + total;
        }, 0)
      ),
  },
  { name: 'created_at', title: 'DATE' },
];

/* 
  Columns used by AdminOrdersSummaryTable
*/
const adminOrderPageColumns = [
  {
    name: 'id',
    title: 'ORDER',
    getCellValue: row => <OrderSummaryTableColumn order={row} key={row.id} />,
  },
  {
    name: 'items',
    title: 'ITEMS',
    getCellValue: row => row.order_items.length,
  },
  {
    name: 'total',
    title: 'TOTAL',
    getCellValue: row =>
      formatUSD(
        row.order_items.reduce((total, orderItem) => {
          return Number.parseFloat(orderItem.sold_price) + total;
        }, 0)
      ),
  },
  {
    name: 'type',
    title: 'TYPE',
    getCellValue: row => (row.is_consignment ? 'Memo' : 'Order'),
  },
  { name: 'created_at', title: 'DATE' },
];

const integratedSortingColumnExtensions = [
  { columnName: 'total', compare: compareCurrency },
];

const SummaryTable = ({ columns, orders }) => (
  <Grid
    rows={orders.filter(order => order.order_items.length > 0)}
    columns={columns}
  >
    <SortingState
      defaultSorting={[{ columnName: 'created_at', direction: 'desc' }]}
    />
    <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />

    <TimestampProvider for={['created_at']} />
    <Table
      tableComponent={TableComponent}
      columnExtensions={[{ columnName: 'id', width: '40%' }]}
    />
    <TableHeaderRow showSortingControls />
  </Grid>
);

export const OnWaitSpinner = () => (
  <div className="WaitSpinner__container">
    <WaitSpinner />
  </div>
);

export const OrdersSummaryTable = ({ orders }) => (
  <SummaryTable columns={orderColumns} orders={orders} />
);

export const AdminOrdersSummaryTable = ({ orders }) => (
  <SummaryTable columns={adminOrderPageColumns} orders={orders} />
);
