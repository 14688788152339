export const ADD_ADDRESS = 'ADD_ADDRESS';
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const DEAUTHENTICATE = 'DEAUTHENTICATE';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const ERROR = 'ERROR';
export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';
export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REGISTER = 'REGISTER';
export const SET_USER_ROUTE = 'SET_USER_ROUTE';
export const UPDATE_METADATA = 'UPDATE_METADATA';
export const USER = 'USER';
