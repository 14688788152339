import React, { Component } from 'react';
import { connect } from 'react-redux';

import BackButton from '../components/BackButton';
import WaitSpinner from '../components/WaitSpinner';
import {
  fetchOrder,
  requestReturnAuthorization,
  convertConsignedItems,
} from '../api/index';
import { formatDate } from '../utils/dateUtilities';
import { formatUSD } from '../utils/util';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  SelectionState,
} from '@devexpress/dx-react-grid';
import TableComponent from '../components/admin/TableComponent';
import OrderDetails from '../components/OrderDetails';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class OrderDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: null,
      isOpen: false,
      selectedItems: [],
      // because NetSuite Invoice Restlet round-trip is slow we disable the button once submitted until a response returns
      waitingForInvoiceCreateRoundtrip: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleReturnClick = this.handleReturnClick.bind(this);
    this.requestReturn = this.requestReturn.bind(this);
    this.handleConvertItemsClick = this.handleConvertItemsClick.bind(this);
    this.convertSelectedMemoItemsToOrderedItems = this.convertSelectedMemoItemsToOrderedItems.bind(
      this
    );
    this.itemColumns = this.itemColumns.bind(this);
  }

  componentDidMount() {
    this.fetchOrder();
  }

  async fetchOrder() {
    const order = await fetchOrder(this.props.orderId);
    this.setState({ order: order });
  }

  getDetails(row) {
    switch (row.item_type) {
      case 'RoughDiamondGroup':
        return (
          <div>
            Blocks: {row.item.quantity}
            <br />
            Carats: {row.item.carat_weight_sum}
            <br />
            Avg. Carats: {row.item.carat_weight_mean}
            <br />
            Color: {row.item.estimated_polish_color}
            <br />
          </div>
        );
      case 'PolishedParcel':
        return (
          <div>
            Items: {row.item.total_program_count}
            <br />
            Kind: {row.item.netsuite_program_type}
          </div>
        );
      case 'RoughDiamond':
        return (
          <div>
            Item: {row.item.item_name}
            <br />
            Quantity: {row.item.quantity}
          </div>
        );
      default:
        return (
          <div>
            Carat: {row.item.carat}
            <br />
            Clarity: {row.item.clarity}
            <br />
            Color: {row.item.color}
            <br />
            Cut: {row.item.cut_grade}
            <br />
            Shape: {row.item.shape}
            <br />
          </div>
        );
    }
  }

  itemColumns() {
    let columns = [
      {
        name: 'lot_id',
        title: 'ID',
        getCellValue: row =>
          row.item.lot_id || row.item.name || row.item.item_name,
      },
      {
        name: 'memo_convert_status',
        title: 'Status',
        getCellValue: row => {
          if (row.invoiced_from_memo) {
            return 'Invoice Requested';
          }
          if (this.state.order.is_consignment && !row.invoiced_from_memo) {
            return 'On Memo';
          }
          if (!this.state.order.is_consignment) {
            return 'Purchased';
          }
        },
      },
      {
        name: 'type',
        title: 'Type',
        getCellValue: row => {
          switch (row.item_type) {
            case 'RoughDiamondGroup':
              return 'Rough Parcel';
            case 'RoughDiamond':
              return 'Rough';
            case 'PolishedParcel':
              return 'Polished Parcel';
            default:
              return 'Diamond';
          }
        },
      },
      {
        name: 'sold_price',
        title: 'Price',
        getCellValue: row => formatUSD(row.sold_price),
      },
      {
        name: 'details',
        title: 'Details',
        getCellValue: row => this.getDetails(row),
      },
    ];

    if (this.convertableFromInvoice()) {
      return columns;
    }
    // Only include memo conversion/status when it is a consignment/memo order that is not 'Billed' or 'Closed' already
    return columns.filter(column => column.name !== 'memo_convert_status');
  }

  // When there is more than one invoice, the invoices will be displayed in a table
  invoiceColumns() {
    return [
      {
        name: 'trandate',
        title: 'Date',
        getCellValue: row => formatDate(row.trandate),
      },
      {
        name: 'netsuite_status',
        title: 'Status',
      },
      {
        name: 'transaction_total',
        title: 'Amount',
        getCellValue: row =>
          formatUSD((row.transaction_total + row.paid_total) / 100.0),
      },
    ];
  }

  // When there is more than one fulfillment, the details will be displayed in a table
  fulfillmentColumns() {
    return [
      {
        name: 'trandate',
        title: 'Date',
        getCellValue: row => formatDate(row.trandate),
      },
      {
        name: 'tracking_number',
        title: 'Tracking Number',
      },
      {
        name: 'carrier',
        title: 'Carrier',
      },
    ];
  }

  filterTransactions(transactions, transactionType) {
    return transactions.filter(
      transaction =>
        transaction.transaction_type.toLowerCase() ===
        transactionType.toLowerCase()
    );
  }

  handleSelect(value) {
    let newSelectedItems = [...value];
    this.setState({ selectedItems: newSelectedItems });
    return newSelectedItems;
  }

  handleReturnClick() {
    this.requestReturn();
  }

  requestReturn() {
    // converted the selected array indexes into selected item ids
    let order_items_ids = [];
    for (let i = 0; i < this.state.selectedItems.length; i++) {
      order_items_ids.push(
        this.state.order.order_items[this.state.selectedItems[i]].id
      );
    }

    // make the params for the request
    let params = {
      order_id: this.state.order.id,
      order_item_ids: order_items_ids,
    };

    requestReturnAuthorization(params)
      .then(response => {
        if (response.statusText === 'OK') {
          this.setState({ order: response.data });
        }
      })
      .catch(error => {
        this.props.showToaster('Error while requesting return!');
      });
  }

  handleConvertItemsClick() {
    this.convertSelectedMemoItemsToOrderedItems();
  }

  convertSelectedMemoItemsToOrderedItems() {
    // converted the selected array indexes into selected item ids
    let order_items_ids = [];
    for (let i = 0; i < this.state.selectedItems.length; i++) {
      order_items_ids.push(
        this.state.order.order_items[this.state.selectedItems[i]].id
      );
    }

    // make the params for the request
    let params = {
      order_id: this.state.order.id,
      order_item_ids: order_items_ids,
    };

    this.setState(
      { waitingForInvoiceCreateRoundtrip: true, isOpen: true },
      () => {
        convertConsignedItems(params)
          .then(response => {
            if (response.statusText === 'OK') {
              // this.props.showToaster('Items successfully added to invoice.');

              this.setState({
                order: response.data,
                selectedItems: [],
                waitingForInvoiceCreateRoundtrip: false,
              });
            }
          })
          .catch(error => {
            if (error.response && error.response.data.message) {
              this.props.showToaster(error.response.data.message);
            } else {
              this.props.showToaster(
                'Error while attempting to convert to invoice!'
              );
            }
            this.setState({
              waitingForInvoiceCreateRoundtrip: false,
              isOpen: false,
            });
          });
      }
    );
  }

  invoices = () => {
    return this.filterTransactions(this.state.order.transactions, 'invoice');
  };

  salesOrder = () => {
    let salesOrder = this.filterTransactions(
      this.state.order.transactions,
      'Sales Order'
    );
    if (salesOrder && salesOrder.length === 1) {
      return salesOrder[0];
    }
    return salesOrder;
  };

  convertableFromInvoice = () => {
    if (!this.state.order.is_consignment) {
      return false;
    }
    let salesOrder = this.salesOrder();
    let invoices = this.invoices();

    if (
      (salesOrder && salesOrder.netsuite_status === 'Closed') ||
      salesOrder.netsuite_status === 'Billed' ||
      invoices.length > 0
    ) {
      return false;
    }
    return (
      this.state.order.order_items.filter(
        orderItem => orderItem.invoiced_from_memo === false
      ).length > 0
    );
  };

  render() {
    if (!this.state.order) {
      return <WaitSpinner />;
    }

    const { order, isOpen } = this.state;

    // These arrays will hold the various types of transactions associated with an order.
    let invoices = [];
    let salesOrders = [];
    let returnAuthorizations = [];
    let fulfillments = [];

    // These arrays will hold non-returned and returned items.
    let orderItems = [];
    let returnedItems = [];

    if (order) {
      // pull out the order items and returned items into separate arrays
      orderItems = order.order_items.filter(
        item => !item.netsuite_return_authorization_id
      );
      returnedItems = order.order_items.filter(
        item => !!item.netsuite_return_authorization_id
      );

      // pull out the individual transaction types
      if (order.transactions.length > 0) {
        invoices = this.filterTransactions(order.transactions, 'invoice');
        salesOrders = this.filterTransactions(
          order.transactions,
          'sales order'
        );
        returnAuthorizations = this.filterTransactions(
          order.transactions,
          'return auth approval'
        );
        fulfillments = this.filterTransactions(
          order.transactions,
          'item fulfillment'
        );
      }
    }

    let invoiceable = this.convertableFromInvoice();

    return (
      <main>
        <div className="page-wrapper-with-padding order-details-page">
          <div className="user-details-page main-wrapper">
            {order.customer_account_id ===
              this.props.auth.user.customer_account.id && (
              <BackButton link="/orders" text="Orders" />
            )}

            <OrderDetails
              order={order}
              invoices={invoices}
              salesOrders={salesOrders}
              fulfillments={fulfillments}
              returnAuthorizations={returnAuthorizations}
            />

            {orderItems && orderItems.length > 0 && (
              <React.Fragment>
                <h1 className="df-title">ITEMS</h1>
                <Grid rows={orderItems} columns={this.itemColumns()}>
                  <SortingState
                    defaultSorting={[
                      { columnName: 'lot_id', direction: 'asc' },
                    ]}
                  />

                  <IntegratedSorting />
                  <IntegratedFiltering />
                  <Table tableComponent={TableComponent} />
                  <TableHeaderRow showSortingControls />

                  <SelectionState
                    selection={this.state.selectedItems}
                    onSelectionChange={this.handleSelect}
                  />
                  {(!!order.return_permitted ||
                    (order.is_consignment && invoiceable)) && (
                    <TableSelection />
                  )}
                </Grid>
                <div className="order-details-actions-container">
                  {/* {this.state.selectedItems.length > 0 &&
                    order.return_permitted && (
                      <button
                        onClick={this.handleReturnClick}
                        className="df-cta btn-request-return"
                      >
                        Request Return for Selected Items
                      </button>
                    )} */}

                  {this.state.selectedItems.length > 0 &&
                    order.is_consignment &&
                    invoiceable && (
                      <button
                        onClick={this.handleConvertItemsClick}
                        className="df-cta btn-convert-items"
                        disabled={this.state.waitingForInvoiceCreateRoundtrip}
                      >
                        Convert Items to Invoice
                      </button>
                    )}
                </div>
              </React.Fragment>
            )}

            {returnedItems && returnedItems.length > 0 && (
              <React.Fragment>
                <h1 className="df-title">ITEMS AUTHORIZED FOR RETURN</h1>
                <Grid rows={returnedItems} columns={this.itemColumns()}>
                  <SortingState
                    defaultSorting={[
                      { columnName: 'lot_id', direction: 'asc' },
                    ]}
                  />

                  <IntegratedSorting />
                  <IntegratedFiltering />
                  <Table tableComponent={TableComponent} />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </React.Fragment>
            )}

            {invoices.length > 1 && (
              <React.Fragment>
                <h1 className="df-title">INVOICES</h1>
                <Grid rows={invoices} columns={this.invoiceColumns()}>
                  <SortingState
                    defaultSorting={[
                      { columnName: 'trandate', direction: 'asc' },
                    ]}
                  />

                  <IntegratedSorting />
                  <IntegratedFiltering />
                  <Table tableComponent={TableComponent} />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </React.Fragment>
            )}

            {fulfillments.length > 1 && (
              <React.Fragment>
                <h1 className="df-title">SHIPMENTS</h1>
                <Grid rows={fulfillments} columns={this.fulfillmentColumns()}>
                  <SortingState
                    defaultSorting={[
                      { columnName: 'trandate', direction: 'asc' },
                    ]}
                  />

                  <IntegratedSorting />
                  <IntegratedFiltering />
                  <Table tableComponent={TableComponent} />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </React.Fragment>
            )}
          </div>

          <Modal
            isOpen={isOpen}
            toggle={() => {
              this.setState({ isOpen: false });
            }}
            className="footer-pages"
          >
            <ModalHeader
              className="df-title"
              toggle={() => {
                this.setState({ isOpen: false });
              }}
            >
              Success
            </ModalHeader>
            <ModalBody className="df-page-body text-align-center">
              <p>Items successfully added to invoice.</p>
            </ModalBody>
          </Modal>
        </div>
      </main>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(OrderDetailsPage);
