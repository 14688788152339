import { RootAction } from '../actionTypes';
import * as types from './constants';
import PolishedDiamondsGroup from '../../types/models/polishedDiamondsGroup';
import PolishedStatsMap from '../../types/polishedStatsMap';
import PolishedFilter from '../../types/more/polishedFilter';
import CellEntry from '../../types/more/cellEntry';

export type PolishedDiamondGroupState = {
  loading: boolean;
  polishedDiamondsGroups: PolishedDiamondsGroup[];
  polishedParcelsStats: PolishedStatsMap;
  filters: PolishedFilter[];
  cellEntries: CellEntry<PolishedDiamondsGroup>[];
  color: string[];
};

const initialState: PolishedDiamondGroupState = {
  loading: true,
  polishedDiamondsGroups: [],
  cellEntries: [],
  polishedParcelsStats: {},
  filters: [],
  color: [],
};

export default (state = initialState, action: RootAction) => {
  switch (action.type) {
    case types.SET_POLISHED_CELL_ENTRY:
      return {
        ...state,
        cellEntries: state.cellEntries.map(e => {
          if (e.entry.id === action.payload.entry.id) {
            return action.payload;
          }
          return e;
        }),
      };
    case types.SET_POLISHED_CELL_ENTRIES:
      return {
        ...state,
        cellEntries: action.payload,
      };
    case types.SET_POLISHED_DIAMOND_GROUPS:
      return {
        ...state,
        cellEntries: action.payload.map(p => {
          const S: CellEntry<PolishedDiamondsGroup> = {
            entry: p,
            selected: false,
          };
          return S;
        }),
        polishedDiamondsGroups: action.payload,
        loading: false,
      };
    case types.SET_POLISHED_DIAMOND_STATS:
      return {
        ...state,
        polishedParcelsStats: action.payload,
      };

    case types.CLEAR_FILTER:
      return {
        ...state,
        filters: [],
      };

    case types.REMOVE_FILTER:
      return {
        ...state,
        filters: state.filters.filter(
          ft =>
            !(
              ft.shape === action.payload.shape &&
              ft.carat.max === action.payload.carat.max &&
              ft.carat.min === action.payload.carat.min
            )
        ),
      };

    case types.ADD_FILTER:
      return {
        ...state,
        filters: state.filters.concat(action.payload),
      };

    case types.REMOVE_COLOR:
      return {
        ...state,
        color: state.color.filter(c => c !== action.payload),
      };

    case types.ADD_COLOR:
      return {
        ...state,
        color: state.color.concat(action.payload),
      };
    default:
      return state;
  }
};
