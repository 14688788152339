import React, { Component } from 'react';
import { connect } from 'react-redux';
import WaitSpinner from '../../components/WaitSpinner';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';

import {
  SortingState,
  IntegratedSorting,
  FilteringState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';

import { fetchCustomerAccounts } from '../../api/index';
import {
  toMonthDayYearTime,
  compareTimestamps,
} from '../../utils/dateUtilities';
import { compareCurrency, compareLinkText } from '../../utils/tableUtilities';
import { debounce, formatUSDWithNoDecimals } from '../../utils/util';
import { bindActionCreators } from 'redux';
import { storeCustomerAccountsFromApi } from '../../state/customers/actions';
import { Link } from '@reach/router';

/*
  WARNING: This page has been heavily  customized to meet unanticipated external style demands.
           The code here does not represent 'patterns' that we should follow in the future.
           This code was written quickly out of necessity.
           When we anticipate needing a grid/table component that is this heavily customized, 
           we should build one from scratch, similar to our DiamondList component,
           rather than using a 3rd party grid that wasn't really made for this purpose.
*/

// This does not start with 0 because 0 is falsey, so it would screw up the || in compareAssignedPricingClass
const pricingClassPriority = {
  'Exclusive Key Account': 1,
  Distributor: 2,
  'Key Account': 3,
  Platinum: 4,
  'Exclusive Premium Partner': 5,
  Gold: 6,
  'Premium Partner': 7,
  Silver: 8,
  Partner: 9,
  'DF B2B': 10,
  'Vrai MSRP': 11,
  'eCom Net MSRP': 12,
};

const compareAssignedPricingClass = (pricingClassA, pricingClassB) => {
  let a = pricingClassPriority[pricingClassA] || 1000;
  let b = pricingClassPriority[pricingClassB] || 1000;
  if (a === b) return 0;
  return a < b ? -1 : 1;
};

const boolColumnValueSorter = (a, b) => {
  if (a === b) {
    return 0;
  }
  return a === 'Yes' ? 1 : -1;
};

const getRowId = row => row.id;

const filterCompanyName = (value, filter) => {
  return value.props.children
    .toLowerCase()
    .includes(filter.value.toLowerCase());
};

const tableHeaderCellClass = {
  company_name: 'wide',
  assigned_pricing_class: 'wide',
  sales_rep: 'wide',
  d2c: 'narrow',
  ecom_net: 'narrow',
  custom_orders_count: 'normal',
  open_balance: 'normal',
  qtd: 'normal',
  quarters: 'normal',
  price_per_carat: 'normal',
  max_last_active_at: 'normal',
};

const tableHeaderCell = props => {
  let className = tableHeaderCellClass[props.column.name];
  return <TableHeaderRow.Cell className={className} {...props} />;
};

class CustomerAccountsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: [],
    };

    this.fetchCustomers = this.fetchCustomers.bind(this);
    this.handleFiltersChange = this.handleFiltersChange.bind(this);

    this.handleScroll = this.handleScroll.bind(this);
    this.updateHeader = this.updateHeader.bind(this);
    this.debouncedUpdateHeader = debounce(this.updateHeader, 50);
    this.showFixedHeader = false;
  }

  handleFiltersChange(value) {
    let newFilters = [...value];
    this.setState({ filters: newFilters });
    return newFilters;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    this.debouncedUpdateHeader();
  }

  // This is terrible and hacky. DO NOT COPY OR EXTEND THIS. FIX IT FIRST.
  //
  // The problem is that setState is not guaranteed to occur synchronously. In fact, it usually occurs asynchronously.
  // BUT I want the header to pop to the top IMMEDIATELY when the user scrolls.
  //
  // So then why apply the fixed class manually?
  // Because dx-react-grid / material ui ignores the className on TableHeaderRow.
  updateHeader() {
    if (window.scrollY > 200 && !this.showFixedHeader) {
      let elements = document.getElementsByClassName('MuiTableHead-root');
      if (elements[0].classList) elements[0].classList.add('fixed');
      this.showFixedHeader = true;
    } else if (window.scrollY < 150 && this.showFixedHeader) {
      let elements = document.getElementsByClassName('MuiTableHead-root');
      if (elements[0].classList) elements[0].classList.remove('fixed');
      this.showFixedHeader = false;
    }
  }

  componentDidMount() {
    this.fetchCustomers();
    window.addEventListener('scroll', this.handleScroll);
  }

  columns() {
    return [
      {
        name: 'company_name',
        title: 'Company',
        getCellValue: row => {
          return (
            <Link to={`/admin/customers/${row.id}`}>{row.company_name}</Link>
          );
        },
      },
      {
        name: 'assigned_pricing_class',
        title: 'Assigned Pricing',
        getCellValue: row => {
          // Ignore Customer Price Groups and Only use Header assigned Price Level here per Mike C's request
          // This will return the Price Level assigned at the CustomerAccount level not the actual pricing assigned per the customer price groups
          // We intentionally display only base/header price level instead of actual per request
          // return 'Partner' as default when no pricing is assigned per Mike C's request
          if (!!row.base_price_level) {
            return row.base_price_level;
          } else {
            return 'Partner';
          }
        },
      },
      {
        name: 'sales_rep',
        title: 'Sales Rep',
      },
      {
        name: 'memo_permitted',
        title: 'Memo?',
        getCellValue: row => (row.memo_permitted ? 'Yes' : 'No'),
      },
      {
        name: 'open_balance',
        title: 'Balance',
        getCellValue: row => {
          return formatUSDWithNoDecimals(row.open_balance / 100.0);
        },
      },
      {
        name: 'qtd',
        title: '$QTD',
        getCellValue: row => {
          // json qtd is in cents (integer) hence the conversion to dollars
          return formatUSDWithNoDecimals(row.qtd / 100.0);
        },
      },
      {
        name: 'quarters',
        title: '$AvgQtr',
        getCellValue: row => {
          if (row.quarters === null || row.quarters.length < 1) {
            return formatUSDWithNoDecimals(0);
          } else {
            // up to the last 4 non zero quarters are included in the array (the values are in cents)
            // each of those individual quarter elements contains the avg_qtr, qtr_total and year_quarter eg ('2020-2', '2019-4' etc)
            return formatUSDWithNoDecimals(row.quarters[0].avg_qtr / 100.0);
          }
        },
      },
      {
        name: 'faas',
        title: 'FaaS',
        getCellValue: row => (row.faas ? 'Yes' : 'No'),
      },
      {
        name: 'price_per_carat',
        title: 'Polished $/Carat',
        getCellValue: row => {
          if (!row.price_per_carat) {
            return formatUSDWithNoDecimals(0);
          }
          return formatUSDWithNoDecimals(row.price_per_carat);
        },
      },
      {
        name: 'max_last_active_at',
        title: 'Last Active',
        getCellValue: row =>
          !!row.max_last_active_at
            ? toMonthDayYearTime(row.max_last_active_at)
            : '',
      },
    ];
  }

  async fetchCustomers() {
    const customerAccounts = await fetchCustomerAccounts();
    this.props.dispatch(storeCustomerAccountsFromApi(customerAccounts));
  }

  render() {
    const customerAccounts = this.props.customers.customerAccounts || [];

    const tableColumnExtensions = [
      { columnName: 'company_name', width: '240px', wordWrapEnabled: true },
      {
        columnName: 'assigned_pricing_class',
        width: '130px',
        wordWrapEnabled: true,
      },
      { columnName: 'sales_rep', width: '130px', wordWrapEnabled: true },
      { columnName: 'memo_permitted', width: '95px' },
      { columnName: 'open_balance', width: '100px', wordWrapEnabled: true },
      { columnName: 'qtd', width: '100px', wordWrapEnabled: true },
      { columnName: 'quarters', width: '100px', wordWrapEnabled: true },
      { columnName: 'price_per_carat', width: '100px', wordWrapEnabled: true },
      { columnName: 'faas', width: '80px' },
      {
        columnName: 'max_last_active_at',
        width: '160px',
        wordWrapEnabled: true,
      },
    ];

    const filterColumnExtensions = [
      { columnName: 'company_name', predicate: filterCompanyName },
    ];

    const integratedSortingColumnExtensions = [
      { columnName: 'company_name', compare: compareLinkText },
      { columnName: 'qtd', compare: compareCurrency },
      {
        columnName: 'assigned_pricing_class',
        compare: compareAssignedPricingClass,
      },
      { columnName: 'quarters', compare: compareCurrency },
      { columnName: 'price_per_carat', compare: compareCurrency },
      { columnName: 'open_balance', compare: compareCurrency },
      { columnName: 'max_last_active_at', compare: compareTimestamps },
      { columnName: 'd2c', compare: boolColumnValueSorter },
      { columnName: 'ecom_net', compare: boolColumnValueSorter },
      { columnName: 'memo_permitted', compare: boolColumnValueSorter },
    ];

    // TO DO: Setup/utilize dx-react-grid custom filtering capabilities
    // https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/filtering/
    return (
      <div className="main-wrapper CustomerAccountsPage">
        <h1 className="title">Customer Accounts</h1>

        {customerAccounts.length > 0 ? (
          <Grid
            rows={customerAccounts}
            columns={this.columns()}
            getRowId={getRowId}
          >
            <SortingState
              defaultSorting={[
                { columnName: 'max_last_active_at', direction: 'desc' },
              ]}
            />

            <IntegratedSorting
              columnExtensions={integratedSortingColumnExtensions}
            />

            <FilteringState
              filters={this.state.filters}
              onFiltersChange={this.handleFiltersChange}
            />
            <IntegratedFiltering columnExtensions={filterColumnExtensions} />
            <Table columnExtensions={tableColumnExtensions} />
            <TableHeaderRow
              className={this.showFixedHeader ? 'fixed' : ''}
              cellComponent={tableHeaderCell}
              showSortingControls
            />
            <TableFilterRow />
          </Grid>
        ) : (
          <div className="WaitSpinner__container">
            <WaitSpinner />
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ storeCustomerAccountsFromApi }, dispatch),
  };
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    customers: state.customerAccounts,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAccountsPage);
