import React from 'react';
import Terms from '../data/terms-conditions';

const TermsAndConditionsPage = () => (
  <main>
    <div className="page-wrapper-with-padding">
      <div className="main-wrapper terms">
        <Terms />
      </div>
    </div>
  </main>
);

export default TermsAndConditionsPage;
