import React from 'react';

const DiamondDetails = ({ diamond, salesOrAdmin }) => (
  <div className="info more-info">
    <div className="tr clearfix">
      <div className="td">
        <span>POLISH:</span>
      </div>
      <div className="td">
        <span>{diamond.polish}</span>
      </div>
    </div>
    <div className="tr clearfix">
      <div className="td">
        <span>SYMMETRY:</span>
      </div>
      <div className="td">
        <span>{diamond.symmetry}</span>
      </div>
    </div>
    <div className="tr clearfix">
      <div className="td">
        <span>CUT:</span>
      </div>
      <div className="td">
        <span>{diamond.cut_grade}</span>
      </div>
    </div>
    <div className="tr clearfix">
      <div className="td">
        <span>DEPTH:</span>
      </div>
      <div className="td">
        <span>{diamond.depth_pct}%</span>
      </div>
    </div>
    <div className="tr clearfix">
      <div className="td">
        <span>TABLE DIAMETER:</span>
      </div>
      <div className="td">
        <span>{diamond.table_size}%</span>
      </div>
    </div>
    <div className="tr clearfix">
      <div className="td">
        <span>GIRDLE:</span>
      </div>
      <div className="td">
        <span>{diamond.girdle}</span>
      </div>
    </div>
    <div className="tr clearfix">
      <div className="td">
        <span>CROWN ANGLE:</span>
      </div>
      <div className="td">
        <span>{diamond.crown_angle}</span>
      </div>
    </div>
    <div className="tr clearfix">
      <div className="td">
        <span>CROWN HEIGHT:</span>
      </div>
      <div className="td">
        <span>{diamond.crown_height}%</span>
      </div>
    </div>
    <div className="tr clearfix">
      <div className="td">
        <span>PAVILION ANGLE:</span>
      </div>
      <div className="td">
        <span>{diamond.pavilion_angle}</span>
      </div>
    </div>
    <div className="tr clearfix">
      <div className="td">
        <span>PAVILION HEIGHT:</span>
      </div>
      <div className="td">
        <span>{diamond.pavilion_height}%</span>
      </div>
    </div>
    {!!diamond.length_mm && !!diamond.width_mm && !!diamond.depth_mm && (
      <div className="tr clearfix">
        <div className="td">
          <span>MEASUREMENTS:</span>
        </div>
        <div className="td">
          <span>
            {diamond.length_mm}&nbsp; &#10005; {diamond.width_mm}&nbsp; &#10005;
            &nbsp; {diamond.depth_mm}
          </span>
        </div>
      </div>
    )}

    {salesOrAdmin && !!diamond.tint && (
      <div className="tr clearfix">
        <div className="td">
          <span>TINT:</span>
        </div>
        <div className="td">
          <span>{diamond.tint}</span>
        </div>
      </div>
    )}
  </div>
);

export default DiamondDetails;
