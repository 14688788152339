import React from 'react';
import { connect } from 'react-redux';
import Nav from '../components/Nav';
import DfNav from '../components/DfNav';

const adminNavItems = [
  { title: 'Dashboard', url: '/dashboard' },
  { title: 'Diamonds', url: '/diamonds' },
  { title: 'Notifiers', url: '/notifiers' },
  { title: 'Account Statement', url: '/statement' },
  { title: 'Cart', url: '/cart' },
  { title: 'Shopify App & API', url: '/shopify-app' },
  { title: 'Marketing', url: '/marketing-materials' },
];

const salesNavItems = [
  { title: 'Dashboard', url: '/dashboard' },
  { title: 'Diamonds', url: '/diamonds' },
  { title: 'Notifiers', url: '/notifiers' },
  { title: 'Account Statement', url: '/statement' },
  { title: 'Cart', url: '/cart' },
  { title: 'Shopify App & API', url: '/shopify-app' },
  { title: 'Marketing', url: '/marketing-materials' },
];

const userNavItems = [
  { title: 'Dashboard', url: '/dashboard' },
  { title: 'Diamonds', url: '/diamonds' },
  { title: 'Notifiers', url: '/notifiers' },
  { title: 'Account Statement', url: '/statement' },
  { title: 'Cart', url: '/cart' },
  { title: 'Shopify App & API', url: '/shopify-app' },
  { title: 'Marketing', url: '/marketing-materials' },
];
// This switches between headers for logged in users and non-logged in users.
const NavSwitcher = ({ userRoute, auth, loading }) => {
  if (loading) {
    return null;
  }

  if (
    auth &&
    auth.user &&
    auth.user.customer_account &&
    ['adminUser'].includes(userRoute) // TODO: Change back to this when this feature is enabled: (auth.user.customer_account.key_account || ['adminUser', 'salesUser'].includes(userRoute))
  ) {
    const navLinks = [
      { title: 'Dashboard', url: '/dashboard' },
      { title: 'Diamonds', url: '/diamonds' },
      { title: 'Rough', url: '/rough' },
      { title: 'Notifiers', url: '/notifiers' },
      { title: 'Account Statement', url: '/statement' },
      { title: 'Cart', url: '/cart' },
      { title: 'Shopify App & API', url: '/shopify-app' },
      { title: 'Marketing', url: '/marketing-materials' },
    ];
    return <Nav navItems={navLinks} userRoute={userRoute} />;
  }

  switch (userRoute) {
    case 'adminUser':
      return <Nav navItems={adminNavItems} userRoute={userRoute} />;
    case 'salesUser':
      return <Nav navItems={salesNavItems} userRoute={userRoute} />;
    case 'user':
      return <Nav navItems={userNavItems} userRoute={userRoute} />; // if the user is logged in then show the hamburger menu in the upper right.

    default:
      return <DfNav />; // otherwise, show a basic header
  }
};

const mapStateToProps = state => {
  return {
    userRoute: state.auth.userRoute,
    auth: state.auth,
    loading: state.auth.loading,
  };
};

export default connect(mapStateToProps)(NavSwitcher);
