import React, { Component } from 'react';

class DiamondDetailsButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 'info',
    };
    this.toggleActiveState = this.toggleActiveState.bind(this);
  }
  async toggleActiveState(e) {
    const active = e.currentTarget.getAttribute('name');
    await this.setState({ active });
  }
  render() {
    const { active } = this.state;
    return (
      <ul className="unstyled DiamondListItem__ctas">
        {this.props.digital_assets.length > 0 &&
          this.props.digital_assets[0].url !== '' && (
            <li
              name="video"
              onClick={this.toggleActiveState}
              className={
                'DiamondListItem__cta' + (active === 'video' ? ' active' : '')
              }
            >
              <button
                className="button-cta"
                onClick={e => this.props.showDetail(e, true, 'video')}
              >
                <img
                  src="https://static.diamondfoundry.com/portal/df/shapes/ico-360v.jpg"
                  alt="360"
                />
                <div className="cta-label">Video</div>
              </button>
            </li>
          )}
        <li className="DiamondListItem__cta">
          <a
            href={`https://certificate.diamondfoundry.com/download/${this.props.lot_id}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://static.diamondfoundry.com/portal/df/shapes/df-certificate-seal.png"
              alt="Certificate"
            />
            <div className="cta-label">Certificate</div>
          </a>
        </li>
        <li
          name="info"
          onClick={this.toggleActiveState}
          className={
            'DiamondListItem__cta' + (active === 'info' ? ' active' : '')
          }
        >
          <button
            className="button-cta"
            onClick={e => this.props.showDetail(e, true, 'info')}
          >
            <img
              src="https://static.diamondfoundry.com/portal/df/shapes/ico-info.png"
              alt="Details"
            />
            <div className="cta-label">Details</div>
          </button>
        </li>
      </ul>
    );
  }
}

export default DiamondDetailsButtons;
