import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import SVG from './SVG';
import FilterColor from './FilterColor';
import FilterShapeCarat from './FilterShapeCarat';
import { fetchDiamondStats } from '../api/index';
import { addSearchKey, setFilters } from '../state/action/diamondFilters';
import { setDiamondStats } from '../state/stats/actions';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const diamondsFilterHeaders = css`
  @media screen and (max-width: 640px) {
      display: flex;
      flex-direction: column;
    }
  }
`;

const diamondsFilterHeaderColumnStyles = css`
  @media screen and (max-width: 640px) {
      margin: auto !important;
      width: 80%;
      
      .Svg {
        top: 0 !important;
      } 
    }
  }
`;

// This is the top-level component for the filter grid user interface.
class DiamondFilterGrid extends Component {
  constructor(props) {
    super(props);

    this.fetchStats = this.fetchStats.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.filterByDiamondId = this.filterByDiamondId.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showCtaButton = this.showCtaButton.bind(this);
  }

  componentDidMount() {
    this.fetchStats();
  }

  async fetchStats() {
    const stats = await fetchDiamondStats();
    this.props.dispatch(setDiamondStats(stats));
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  clearFilters() {
    const { filters } = this.props;
    const emptied = {};
    for (const filter in filters) {
      emptied[filter] = [];
    }
    this.props.dispatch(setFilters(emptied));
  }

  filterByDiamondId(e) {
    const searchValue = e.target.value;
    this.props.dispatch(
      addSearchKey({
        searchKey: searchValue,
      })
    );
  }

  showCtaButton() {
    const allSelectedFilters = [].concat(...Object.values(this.props.filters));
    const hasSelectedFilters = Boolean(allSelectedFilters.length);
    return hasSelectedFilters;
  }

  render() {
    return (
      <div className="DiamondFilters DiamondFilterGrid">
        <div css={diamondsFilterHeaders} className="DiamondFilters__header">
          <div className="DiamondFilters__header-column">
            <FilterColor />
          </div>

          {this.props.hasSearch && (
            <div
              css={diamondsFilterHeaderColumnStyles}
              className="DiamondFilters__header-column"
            >
              <input
                type="text"
                placeholder="Diamond ID#"
                className="df-text-inputs"
                value={this.props.searchKey}
                onChange={this.filterByDiamondId}
              />
              <SVG name="search" className="Svg" viewBox="0 0 600 600" />
            </div>
          )}
        </div>

        <div className="DiamondFilters-dropdown">
          <FilterShapeCarat />

          <div className="save-filter">
            {this.showCtaButton() && (
              <React.Fragment>
                {this.props.hasNotifierButton && (
                  <Button
                    className="df-cta SaveNotifier"
                    onClick={this.props.saveNotifier}
                  >
                    SAVE NOTIFIER
                  </Button>
                )}
                <Button
                  className="df-cta"
                  viewBox="0 0 600 600"
                  onClick={this.clearFilters}
                >
                  CLEAR
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.filters, // filters here includes only the current set of filters being constructed not the users existing saved filters
    searchKey: state.diamonds.searchKey,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(DiamondFilterGrid);
