import React from 'react';
import DiamondListSortIndicator from '../DiamondListSortIndicator';
import Option from '../../types/more/option';

type Props = {
  option: Option<string, string>;
  reverseSort: boolean;
  activeSortParameter: string | null;
  onSort: (arg: string) => void;
};

const TableHeaderColumn = ({
  option,
  reverseSort,
  activeSortParameter,
  onSort,
}: Props) => (
  <div
    onClick={() => {
      onSort(option.key);
    }}
    className="th"
  >
    <div className="shape__container">
      {option.label}
      <DiamondListSortIndicator
        fieldName={option.key}
        activeSortParam={activeSortParameter}
        isReversedParam={reverseSort}
      />
    </div>
  </div>
);

export default TableHeaderColumn;
