import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DiamondFilterGrid from '../components/DiamondFilterGrid';
import DiamondList from '../components/DiamondList';
import MultiselectFooter from '../components/MultiselectFooter';
import { focusField, debounce } from '../utils/util';
import {
  storeDiamondsFromAPI,
  updateDiamondToFocus,
} from '../state/action/diamondSearch';
import { fetchDiamonds, createWatch, updateUser } from '../api/index';
import { saveMetadata } from '../state/auth/actions';
import { diamondComparator } from '../utils/diamondComparison';
import { addMultipleItemsToCart } from '../state/action/diamondCart';
import WaitSpinner from '../components/WaitSpinner';
import { Link } from '@reach/router';
import { isInternalUser } from '../utils/util';

class DiamondsPage extends Component {
  constructor(props) {
    super(props);
    this.unmounted = false;

    this.state = {
      activeDiamondSortParameter: null,
      reverseSort: false,
      index: null,
      isSorting: false,
      detailedTable: this.props.auth.user.metadata
        ? this.props.auth.user.metadata.detailedTable
        : false,
    };

    // set up a debounced method for saving user state once even if the user hits the toggle a million times
    this.updateUserMetadata = debounce(function() {
      const requestBody = {
        user: {
          metadata: this.props.auth.user.metadata,
        },
      };
      updateUser(requestBody, this.props.auth.user);
    }, 3000);

    this.getDiamonds = this.getDiamonds.bind(this);
    this.getDiamondsToRender = this.getDiamondsToRender.bind(this);
    this.handleApplyDiamondSort = this.handleApplyDiamondSort.bind(this);
    this.setIndex = this.setIndex.bind(this);
    this.handleToggleDetailedTable = this.handleToggleDetailedTable.bind(this);
    this.updateUserMetadata = this.updateUserMetadata.bind(this);
    this.saveNotifier = this.saveNotifier.bind(this);
  }

  componentDidMount() {
    if (!this.unmounted) {
      this.getDiamonds();
      this.handleApplyDiamondSort('carat');
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.diamonds.diamondToFocus !== this.props.diamonds.diamondToFocus
    ) {
      this.handleScroll(this.props.diamonds.diamondToFocus);
    }
  }

  // When the user navigates away from this page.
  componentWillUnmount() {
    this.props.dispatch(updateDiamondToFocus(''));
    this.props.dispatch({ type: 'DESELECT_ALL_DIAMONDS' });
  }

  async getDiamonds() {
    const { diamonds, dispatch } = this.props;
    let isFocused = false;

    if (diamonds && diamonds.rendered && diamonds.rendered.length > 0) {
      this.handleScroll(diamonds.diamondToFocus);
      isFocused = true;
    }

    // Fetch the diamonds from the API and load them into Redux.
    const diamondList = await fetchDiamonds();
    dispatch(storeDiamondsFromAPI({ diamonds: diamondList }));

    if (!isFocused) {
      this.handleScroll(diamonds.diamondToFocus);
    }
  }

  // This stores the id of the diamond that is currently open for more info.
  setIndex(index) {
    this.setState(() => {
      return {
        index,
      };
    });
  }

  saveNotifier() {
    let properties = this.props.filters;
    if (
      !!properties &&
      Object.keys(properties).length > 0 &&
      (properties['color'].length > 0 || properties['shapeCarat'].length > 0)
    ) {
      createWatch(this.props.auth.user.id, {
        inventory_type: 'diamonds',
        properties: properties,
      })
        .then(response => {
          this.props.showToaster(
            'You will be notified when diamonds of that type become available.'
          );
        })
        .catch(error => {
          this.props.showToaster('Unable to create notification!');
        });
    } else {
      this.props.showToaster('Unable to create notification without a filter.');
    }
  }

  handleScroll(id) {
    const element = window.document.getElementById(id);
    if (element) {
      this.setState({ index: '' }, () => {
        focusField(id, 90);
        this.setIndex(id);
      });
    }
  }

  handleApplyDiamondSort(diamondSortParameter) {
    const { activeDiamondSortParameter, reverseSort } = this.state;
    if (diamondSortParameter === activeDiamondSortParameter) {
      this.setState({
        activeDiamondSortParameter: diamondSortParameter,
        reverseSort: !reverseSort,
        isSorting: true,
      });
    } else {
      this.setState({
        activeDiamondSortParameter: diamondSortParameter,
        reverseSort: false,
        isSorting: true,
      });
    }
  }

  // When the detail columns are toggled on/off this method is called.
  // See src/components/DiamondList.js for usage.
  handleToggleDetailedTable() {
    let newValue = !this.state.detailedTable;
    this.setState({ detailedTable: newValue });
    this.props.dispatch(saveMetadata({ detailedTable: newValue }));

    // save the user after a short delay, if they don't toggle the switch again
    this.updateUserMetadata();
  }

  getDiamondsToRender() {
    if (!this.state.activeDiamondSortParameter) {
      return this.props.diamonds.rendered;
    }

    if (this.state.isSorting === true) {
      const sortedDiamonds = this.props.diamonds.rendered.sort(
        (diamondA, diamondB) => {
          const parameterToSortBy = this.state.activeDiamondSortParameter;

          return diamondComparator(diamondA, diamondB, parameterToSortBy);
        }
      );

      if (this.state.reverseSort) {
        sortedDiamonds.reverse();
        this.setState({
          isSorting: false,
        });
      }
      return sortedDiamonds;
    }
    return this.props.diamonds.rendered;
  }

  render() {
    // We MUST have a user in order to know whether to show the detailed table or not
    if (!this.props.auth.user) {
      return <WaitSpinner />;
    }

    const { showToaster, filters, isDiamondsLoading, dispatch } = this.props;
    const { reverseSort, activeDiamondSortParameter, index } = this.state;
    let detailedTable = this.props.auth.user.metadata
      ? this.props.auth.user.metadata.detailedTable
      : false;

    let internalUser = isInternalUser(this.props.auth.user.roles);

    return (
      <main>
        <div className="Diamonds">
          <div className="df-headline-container">
            {internalUser && (
              <div className="df-headline">
                Our Diamonds:&nbsp;Uniques |<Link to="/polished"> Program</Link>
              </div>
            )}
            {!internalUser && <div className="df-headline">Our Diamonds</div>}
          </div>
          <DiamondFilterGrid
            hasSearch={true}
            hasNotifierButton={true}
            saveNotifier={this.saveNotifier}
            showToaster={this.props.showToaster}
          />
          <DiamondList
            isReversedParam={reverseSort}
            activeSortParam={activeDiamondSortParameter}
            applyDiamondSort={this.handleApplyDiamondSort}
            selectAllDiamond={() => dispatch({ type: 'SELECT_ALL_DIAMONDS' })}
            deselectAllDiamond={() =>
              dispatch({ type: 'DESELECT_ALL_DIAMONDS' })
            }
            diamonds={this.getDiamondsToRender()}
            filters={filters}
            isDiamondsLoading={isDiamondsLoading}
            showToaster={showToaster}
            saveNotifier={this.saveNotifier}
            setIndex={this.setIndex}
            index={index}
            detailedTable={detailedTable}
            toggleDetailedTable={this.handleToggleDetailedTable}
          />
        </div>
        <MultiselectFooter
          items={this.props.diamonds.selected}
          addToCart={addMultipleItemsToCart}
        />
      </main>
    );
  }
}

DiamondsPage.propTypes = {
  diamonds: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  showToaster: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  isDiamondsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    diamonds: state.diamonds, // This loads the diamonds from Redux into the component's state. See src/state/action/diamondSearch.js
    filters: state.filters,
    isDiamondsLoading: state.isDiamondsLoading,
  };
};

export default connect(mapStateToProps)(DiamondsPage);
