import React from 'react';
import Diamond from '../../../types/models/diamond';
import Option from '../../../types/more/option';
import PolishedDiamondListItem from './PolishedDiamondListItem';

type Props = {
  data: Diamond[];
  header: Option<string, string>[];
};

const TableBody = ({ data, header }: Props) => {
  return (
    <div className="tbody">
      {data.map((diamond, index) => (
        <PolishedDiamondListItem header={header} data={diamond} key={index} />
      ))}
    </div>
  );
};

export default TableBody;
