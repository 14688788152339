import React from 'react';
import { Link } from '@reach/router';
import FooterLinks from '../data/footer-links';
const Footer = ({ className }) => (
  <footer>
    <div className={`footer ${className ? className : ''}`}>
      <div className="footer-col">
        <ul className="unstyled footer-links">
          {FooterLinks.links.map(({ title, url }, index) => (
            <li className="footer-link" key={index}>
              <Link to={url}>{title}</Link>
            </li>
          ))}
          <li className="df-show-mobile">{FooterLinks.copyright}</li>
        </ul>
      </div>
      <div className="footer-col df-show-nonmobile-only">
        {FooterLinks.copyright}
      </div>
      <div className="footer-col">
        <a
          // eslint-disable-next-line max-len
          href="https://diamondfoundry.com/blogs/the-foundry-journal/we-are-now-the-worlds-first-certified-100-carbon-neutral-diamond-producer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="footer-img carbon-neutral-logo" />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
