import React, { Component } from 'react';
import { Redirect, Link } from '@reach/router';
import { connect } from 'react-redux';
import { login } from '../state/auth/actions';
import { loginPageType } from '../propTypes';
import PasswordInput from '../components/PasswordInput';
import { Button } from 'reactstrap';
import Footer from '../components/Footer';

export class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: this.props.error,
      loggedIn: this.props.loggedIn,
    };
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  submit(e) {
    e.preventDefault();
    this.props.dispatch(login(this.state.email, this.state.password));
  }

  render() {
    let email = this.state.email;

    const { userRoute } = this.props;
    const loggedIn =
      userRoute === 'adminUser' ||
      userRoute === 'salesUser' ||
      userRoute === 'user';
    if (loggedIn) {
      return <Redirect to="/" noThrow />; // This will automatically redirect to the default page for a user who is logged in already.
    }

    return (
      <main>
        <div className="index-page">
          <div className="df-logout-margin">
            <div className="index-container">
              <section className="login-desktop df-show-desktop">
                <div className="description-container">
                  <div className="df-title">
                    WELCOME TO THE DIAMOND FOUNDRY BUSINESS PORTAL
                  </div>

                  <div className="restriction">
                    Access is restricted to registered Diamond Foundry accounts.
                  </div>
                </div>
              </section>
              <section className="login">
                <div className="login-headers">
                  <h2 className="login-subtitle">Business Portal</h2>
                </div>
                <form onSubmit={e => e.preventDefault()}>
                  <input
                    className="df-text-inputs username-input"
                    name="email"
                    placeholder="Login"
                    value={email}
                    onChange={e => this.handleChange(e)}
                  />
                  <PasswordInput
                    inputStyle="df-text-inputs password-input"
                    placeholder="Password"
                    name="password"
                    onChange={e => this.handleChange(e)}
                  />

                  {this.state.error && (
                    <div className="error-message auth">{this.state.error}</div>
                  )}

                  <Button
                    type="submit"
                    className="sign-in-btn df-cta"
                    onClick={e => this.submit(e)}
                  >
                    SIGN IN
                  </Button>
                </form>
                <div className="df-link-container df-link-container--small">
                  <Link to="/reset_password">Forgot Login or Password</Link>
                </div>
              </section>
            </div>
          </div>
          <Footer className="footer-index-page" />
        </div>
      </main>
    );
  }
}

LoginPage.propTypes = loginPageType;

const mapState = state => ({
  auth: state.auth,
  userRoute: state.auth.userRoute,
  error: state.auth.error,
});

export default connect(mapState)(LoginPage);
