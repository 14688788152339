import React from 'react';
import Option from '../../types/more/option';
import { TransactionLine, displayLine } from '../../types/transaction';
import { formatUSD } from '../../utils/util';

export interface InvoiceOption extends Option<string, string> {
  colSpan: number;
  getCellValue: (row: TransactionLine) => React.ReactNode;
}

const headers: InvoiceOption[] = [
  {
    key: 'item',
    label: 'Item',
    colSpan: 0.5,
    getCellValue: (row: TransactionLine) => displayLine(row.item),
  },
  {
    key: 'item_count',
    label: 'Quantity',
    colSpan: 1,
    getCellValue: (row: TransactionLine) => Math.abs(+row.item_count),
  },
  {
    key: 'carat',
    label: 'Carat',
    colSpan: 1,
    getCellValue: (row: TransactionLine) => {
      if (row.item?.carat) {
        return <> {row.item.carat}</>;
      }
      return null;
    },
  },
  {
    key: 'shape',
    label: 'Shape',
    colSpan: 2,
    getCellValue: (row: TransactionLine) => {
      if (row.item?.shape) {
        return <> {row.item.shape} </>;
      }
      return null;
    },
  },
  {
    key: 'cut_grade',
    label: 'Cut',
    colSpan: 1,
    getCellValue: (row: TransactionLine) => {
      if (row.item?.cut_grade) {
        return <>{row.item.cut_grade}</>;
      }
      return null;
    },
  },
  {
    key: 'color',
    label: 'Color',
    colSpan: 1,
    getCellValue: (row: TransactionLine) => {
      if (row.item?.color) {
        return <>{row.item?.color}</>;
      }
      return null;
    },
  },
  {
    key: 'clarity',
    label: 'Clarity',
    colSpan: 1,
    getCellValue: (row: TransactionLine) => {
      if (row.item?.clarity) {
        return <>{row.item.clarity}</>;
      }
      return null;
    },
  },
  {
    key: 'amount',
    label: 'Amount',
    colSpan: 1,
    getCellValue: (row: TransactionLine) =>
      formatUSD(row.amount ? Math.abs(+row.amount) : 0),
  },
];

export type Props = {
  data: TransactionLine[];
};

const InvoiceTable = ({ data }: Props) => {
  return (
    <table className="invoice-table">
      <thead>
        <tr className="invoice-table-header-row">
          {headers.map((option, index) => (
            <th
              colSpan={option.colSpan}
              className="invoice-table-header"
              key={index}
            >
              {option.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((obj, index) => {
          return (
            <tr className="invoice-table-row" key={index}>
              {headers.map((header, index) => {
                return (
                  <td
                    className="invoice-table-data"
                    colSpan={header.colSpan}
                    key={index}
                  >
                    {header.getCellValue(obj)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default InvoiceTable;
