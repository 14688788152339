import React, { useEffect } from 'react';
import './index.css';
import Icon from './icon.svg';
import Address from '../../types/address';
import Transaction, {
  removeDuplicateItems,
  getAmountDue,
} from '../../types/transaction';
import { toMonthDayYear } from '../../utils/dateUtilities';

import InvoiceTable from './InvoiceTable';
import {
  exists,
  formatPricing,
  formatPricingFromPennies,
} from '../../utils/util';
import CustomerAccount from '../../types/models/customerAccount';
import { hasSalesRepresentative } from './helpers';

type Props = {
  transaction: Transaction;
  shipAddress?: Address | null;
  billingAddress?: Address | null;
  customer_account?: CustomerAccount | null;
};

const InvoiceDetailed = ({ transaction, customer_account }: Props) => {
  // filter out undefined values.
  const filteredLines = removeDuplicateItems(
    transaction.transaction_lines.filter(t => exists(t.item))
  );
  /*
  This hook hides the Nav bar. This is for printing.
   */
  useEffect(() => {
    const elements = document.getElementsByClassName('Nav_wrapper');
    for (let i = 0; i < elements.length; i++) {
      // @ts-ignore
      elements[i].classList.add('transaction-details-on');
    }
    return () => {
      for (let i = 0; i < elements.length; i++) {
        // @ts-ignore
        elements[i].classList.remove('transaction-details-on');
      }
    };
  }, []);
  return (
    <div id="invoice-details-container">
      <div className="invoice-top">
        <div className="invoice-top__item-1">
          <img className="invoice-df-logo-styled" src={Icon} alt="React Logo" />
        </div>
        <div className="invoice-top__item-2">
          <h2 className="invoice-header">{transaction.transaction_type}</h2>
          <div className="invoice-info-row">
            <h5 className="invoice-info-left">Date</h5>
            <p className="invoice-info-right">
              {toMonthDayYear(transaction.trandate)}
            </p>
          </div>
          <div className="invoice-info-row">
            <h5 className="invoice-info-left">Invoice #</h5>
            <p className="invoice-info-right">{transaction.doc_number}</p>
          </div>
          {customer_account && customer_account.payment_terms_days_until_due && (
            <div className="invoice-info-row">
              <h5 className="invoice-info-left">Terms</h5>
              <p className="invoice-info-right">
                NET&nbsp;{customer_account!.payment_terms_days_until_due}
              </p>
            </div>
          )}
          <div className="invoice-info-row ">
            <h5 className="invoice-info-left">Due Date</h5>
            <p className="invoice-info-right">
              {toMonthDayYear(transaction.due_at)}
            </p>
          </div>
          <div className="invoice-info-row">
            <h5 className="invoice-info-left">Ship Date</h5>
            <p className="invoice-info-right">
              {toMonthDayYear(transaction.shipdate)}
            </p>
          </div>
          {hasSalesRepresentative(customer_account) && (
            <div className="invoice-info-row ">
              <h5 className="invoice-info-left">Sales Rep</h5>
              <p className="invoice-info-right">
                {customer_account?.sales_representative?.first_name}{' '}
                {customer_account?.sales_representative?.last_name}
              </p>
            </div>
          )}
          {customer_account && (
            <div className="invoice-info-row ">
              <h5 className="invoice-info-left">Subsidiary</h5>
              <p className="invoice-info-right">
                {customer_account!.company_name}
              </p>
            </div>
          )}
        </div>
        <div className="invoice-top__item-3">
          <h6 className="address-header">Payment Address:</h6>
          <p className="address-header-info">Diamond Foundry Inc.</p>
          <p className="address-header-info">322 E Grand Ave</p>
          <p className="address-header-info">South San Francisco, CA 94080</p>
          <p className="address-header-info"> U.S.A</p>
        </div>

        <div className="invoice-top__item-4">
          <h6 className="address-header">Returns Address:</h6>
          <p className="address-header-info">Diamond Foundry Inc.</p>
          <p className="address-header-info">731 South Spring St #500</p>
          <p className="address-header-info">Los Angeles, CA 90014</p>
          <p className="address-header-info">U.S.A</p>
        </div>

        <div className="invoice-top__item-6">
          <h5 className="customer-address-header">Bill To</h5>

          <p className="customer-address-info">
            <span style={{ whiteSpace: 'pre-wrap' }}>
              {transaction.bill_address}
            </span>
          </p>
        </div>

        <div className="invoice-top__item-6">
          <h5 className="customer-address-header">Ship To</h5>
          <p className="customer-address-info">
            <span style={{ whiteSpace: 'pre-wrap' }}>
              {transaction.ship_address}
            </span>
          </p>
        </div>
      </div>
      <div>
        <InvoiceTable data={filteredLines} />
      </div>

      <div className="total-info-panel">
        <div>
          <h5 className="total-info">Total</h5>
          <h5 className="total-info">
            {formatPricingFromPennies(transaction?.transaction_total)}
          </h5>
        </div>
        <div>
          <h5 className="total-info">Amount Due</h5>
          <h5 className="total-info">
            {formatPricing(getAmountDue(transaction))}
          </h5>
        </div>
      </div>

      {transaction.transaction_type === 'Invoice' && (
        <div className="panel">
          <p className="license">
            This invoice represents a final sale. No returns are accepted other
            than warranty claims. Our grading warranty references IGI standards.
            By accepting this invoice, you agree to the following:
          </p>
          <p className="license">
            Late Payments: With respect to the unpaid amount of this invoice not
            paid in full within the defined term, a finance charge of 1.5% per
            month, payable from the date of the invoice to the date payment is
            received, shall be due and payable to Diamond Foundry, Inc. In
            addition, Purchaser shall indemnify Diamond Foundry Inc. for its
            costs, including reasonable attorneys’ fees and disbursements,
            incurred to collect any unpaid amount.
          </p>
          <p className="license">
            Advertising: THE PRODUCT SOLD IS “LAB CREATED DIAMOND”. You shall
            appropriately disclose the product’s man-made nature during resale.
            Under no circumstance shall Diamond Foundry Inc. be liable for any
            loss, penalty, or harm in connection with deceptive advertising. The
            Terms & Conditions available at www.diamondfoundry.com are included
            by reference.
          </p>
          <div className="panel">
            <p className="account-number">Remittance Information:</p>
            <p className="account-number">Account Name: Diamond Foundry Inc.</p>
            <p className="account-number">Bank: JPMorgan Chase Bank</p>
            <p className="account-number">Account Number 120039570</p>
            <p className="account-number">ABA Routing No.: 322271627</p>
            <p className="account-number">Swift Code: CHASUS33</p>
          </div>
        </div>
      )}

      <div className="invoice-footer">
        <p>322 E Grand Avenue South San Francisco, CA 94080</p>
      </div>
    </div>
  );
};

export default InvoiceDetailed;
