import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate } from '../../utils/routerHistory';
import { Button } from 'reactstrap';

import FilterColorCarat from '../FilterColorCarat';
import { fetchRoughDiamondGroupStats } from '../../api';
import { setRoughDiamondGroupStats } from '../../state/stats/actions';

// This is the top-level component for the filter grid user interface.
class RoughDiamondGroupFilterGrid extends Component {
  constructor(props) {
    super(props);

    this.fetchStats = this.fetchStats.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showClearFiltersButton = this.showClearFiltersButton.bind(this);
  }

  componentDidMount() {
    this.fetchStats();
  }

  async fetchStats() {
    const stats = await fetchRoughDiamondGroupStats();
    this.props.dispatch(setRoughDiamondGroupStats(stats));
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  showClearFiltersButton() {
    return this.props.filters.colorCarat.length > 0;
  }

  render() {
    return (
      <div className="DiamondFilters DiamondFilterGrid RoughDiamondGroupFilterGrid">
        <div className="DiamondFilters-dropdown">
          <FilterColorCarat />

          <div className="save-filter">
            {this.showClearFiltersButton() && (
              <React.Fragment>
                <Button
                  className="df-cta"
                  viewBox="0 0 600 600"
                  onClick={this.props.clearFilters}
                >
                  CLEAR
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

RoughDiamondGroupFilterGrid.propTypes = {
  clearFilters: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    filters: state.roughDiamondGroups.filters, // filters here includes only the current set of filters being constructed not the users existing saved filters
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(RoughDiamondGroupFilterGrid);
