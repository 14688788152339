import React from 'react';
import FlexCenter from './FlexCenter';
import styled from '@emotion/styled';

type Props = {
  column?: boolean;
  children: React.ReactChildren[];
  spacing?: string;
};

type OuterProps = {
  spacing?: string;
  column?: boolean;
};

const ROW = styled.div<OuterProps>`
  & > * {
    margin-right: ${props => (props.spacing ? props.spacing : '0')};
  }
`;

const COLUMN = styled.div<OuterProps>`
  & > * {
    margin-bottom: ${props => (props.spacing ? props.spacing : '0')};
  }
`;

const FlexCenterContainer = ({ column = false, spacing, children }: Props) => {
  if (column) {
    return (
      <COLUMN spacing={spacing}>
        {children.map((child, index) => (
          <FlexCenter key={index} column={column}>
            {child}
          </FlexCenter>
        ))}
      </COLUMN>
    );
  }
  return (
    <ROW spacing={spacing}>
      {children.map((child, index) => (
        <FlexCenter key={index} column={column}>
          {child}
        </FlexCenter>
      ))}
    </ROW>
  );
};

export default FlexCenterContainer;
