import React, { Component } from 'react';
import { connect } from 'react-redux';
import SVG from './SVG';

import { removeItemFromCart } from '../state/action/diamondCart';
import { formatFloat, formatPricing } from '../utils/util';

class CartListItemRoughGroup extends Component {
  render() {
    return (
      <div className="CartListItem">
        <div className="diamond-info-left">
          <ul className="unstyled">
            <li>
              <span className="shape">Rough Parcel</span>
            </li>
            {this.props.name && (
              <li>
                <span className="fieldName">ID:</span> {this.props.name}
              </li>
            )}
            {this.props.quantity && (
              <li>
                <span className="fieldName">Blocks:</span> {this.props.quantity}
              </li>
            )}
            {this.props.carat_weight_sum && (
              <li>
                <span className="fieldName">Total Carats:</span>{' '}
                {formatFloat(this.props.carat_weight_sum)}
              </li>
            )}
            {this.props.estimated_polish_color && (
              <li>
                <span className="fieldName">Est. Color:</span>{' '}
                {this.props.estimated_polish_color}
              </li>
            )}
          </ul>
        </div>
        <ul className="unstyled diamond-info-right">
          {!this.props.checkedOut && (
            <li>
              <SVG
                className="delete"
                name="trash"
                viewBox="0 0 600 600"
                onClick={e => {
                  e.preventDefault();
                  this.props.dispatch(removeItemFromCart(this.props.id));
                }}
              />
            </li>
          )}

          {this.props.price && (
            <li className="diamond-price">{formatPricing(this.props.price)}</li>
          )}
        </ul>
      </div>
    );
  }
}

export default connect()(CartListItemRoughGroup);
