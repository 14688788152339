import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import { Button } from 'reactstrap';

import PropTypes from 'prop-types';
import BackButton from '../components/BackButton';
import WaitSpinner from '../components/WaitSpinner';
import PasswordInput from '../components/PasswordInput';
import ShippingAddress from '../components/ShippingAddress';
import BillToAddress from '../components/BillToAddress';

import { updateUser } from '../api/index';
import { setAuthUser } from '../state/auth/actions';

class UserEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      password_confirmation: '',
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      inventory_notifications_enabled: true,
    };
  }

  componentDidMount() {
    this.setUserInfo();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (prevProps.user.email !== user.email) {
      this.setUserInfo();
    }
  }

  async setUserInfo() {
    const { user } = this.props;
    this.setState({
      ...user,
    });
  }

  saveEdited() {
    const { showToaster } = this.props;

    let updated_user = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      inventory_notifications_enabled: this.state
        .inventory_notifications_enabled,
    };

    if (this.state.password && this.state.password_confirmation) {
      updated_user.password = this.state.password;
      updated_user.password_confirmation = this.state.password_confirmation;
    }

    if (updated_user.password !== updated_user.password_confirmation) {
      showToaster('Passwords do not match!');
      return;
    }

    updateUser({ user: updated_user }, this.props.user)
      .then(response => {
        if (response && response.status === 200) {
          this.props.dispatch(setAuthUser(response.data)); // update state.auth.user
          showToaster('Profile updated.');
        }
      })
      .catch(() => {
        showToaster('Unable to update user.');
      });
  }

  handleChange(e) {
    if (e.target.name === 'phone') {
      e.target.value = e.target.value.replace(new RegExp('[+]*'), '');
    }

    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  handleAllowInventoryNotificationsClick(e) {
    this.setState({
      inventory_notifications_enabled: !this.state
        .inventory_notifications_enabled,
    });
  }
  handleAllowInventoryNotificationsChange(e) {
    // React makes you handle this.
  }

  render() {
    const { customer_account } = this.props;

    const { company_name = '', addresses = [] } = customer_account || {};

    let first_name = '',
      last_name = '',
      email = '',
      phone = '';

    if (!!this.state.first_name) {
      first_name = this.state.first_name;
      last_name = this.state.last_name;
      email = this.state.email;
      phone = this.state.phone;
    }

    return (
      <main>
        {this.state.email === '' && <WaitSpinner />}
        <div className="page-wrapper-with-padding UserEditPage">
          <div className="main-wrapper">
            <BackButton link="/dashboard" text="Dashboard" />

            <div className="form-wrapper">
              <div className="address-wrapper">
                <div className="company-name">{company_name}</div>

                {this.props.customer_account && (
                  <div className="credit-card-link">
                    <Link to="/credit-card" className="df-link flex">
                      Click here to add or edit your credit card.
                    </Link>
                  </div>
                )}

                {addresses && addresses.length > 0 && (
                  <BillToAddress
                    address={addresses[0]}
                    companyName={company_name}
                  />
                )}

                <ShippingAddress
                  addresses={addresses}
                  company_name={company_name}
                  isUserEditPage={true}
                  showToaster={this.props.showToaster}
                  page="/user/edit"
                />
              </div>

              <div className="user-info-wrapper">
                <div className="user-email-head">
                  Your preferred contact info:
                </div>
                <div className="email-email-body">{email}</div>

                <div className="user-info-head">User details:</div>
                <div className="user-info-body">
                  <div className="user-info">
                    <input
                      className="edit-box"
                      name="first_name"
                      type="text"
                      value={first_name}
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      placeholder="First name*"
                    />
                  </div>

                  <div className="user-info">
                    <input
                      className="edit-box"
                      type="text"
                      value={last_name}
                      name="last_name"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      placeholder="Last name*"
                    />
                  </div>

                  <div className="user-info">
                    <input
                      className="edit-box"
                      name="phone"
                      type="phone"
                      value={phone}
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      placeholder="Phone*"
                    />
                  </div>

                  <div className="user-info">
                    <PasswordInput
                      className="edit-box"
                      type="password"
                      name="password"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      placeholder="New Password"
                    />
                    <PasswordInput
                      className="edit-box"
                      type="password"
                      name="password_confirmation"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      placeholder="Confirm Password"
                    />
                  </div>

                  <div className="user-info">
                    <div className="dashboard-checkbox">
                      <label className="container">
                        <input
                          type="checkbox"
                          name="notifyAll"
                          checked={
                            this.state.inventory_notifications_enabled ===
                              true ||
                            this.state.inventory_notifications_enabled ===
                              'true'
                          }
                          onChange={e => {
                            this.handleAllowInventoryNotificationsChange(e);
                          }}
                          onClick={e => {
                            this.handleAllowInventoryNotificationsClick(e);
                          }}
                        />
                        <span className="checkbox-checkmark" />
                        <span className="checkbox-label">
                          Allow email notifications about new inventory
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="df-cta-container">
                    <Button
                      className="df-cta btn btn-secondary"
                      type="submit"
                      onClick={() => this.saveEdited()}
                    >
                      SAVE CHANGES
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
UserEditPage.propTypes = {
  showToaster: PropTypes.func.isRequired,
};
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    customer_account: state.auth.user.customer_account,
  };
};

export default connect(mapStateToProps)(UserEditPage);
