import 'fix-date'; // this modules fixes an issue on Safari always displaying 'Invalid Date'
import moment from 'moment';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import React from 'react';

/*
Standard timestamp format YYYYMMDDTHHMMSSZ
Used for google.
 */
export const makeStandardTimestamp = date => {
  return date.toISOString().replace(/[-.:]/gi, '');
};

// This is for dates that need to be specific.
export const formatDate = value => {
  return moment(value).format('YYYY/MM/DD h:mm a'); // This format allows for lexical sorting of dates.
};

// Returns a relative date such as '10 minutes ago' or '' if a falsey value is passed in.
export const formatDateRelative = value => {
  if (!value) return ''; // This used to return 'Never', but that is misleading, because we may just lack the information.
  return moment(value).fromNow();
};

export const dateFormatter = ({ value }) => {
  return formatDate(value);
};

export const toMonthDayYear = timestamp => {
  if (timestamp) {
    return moment(timestamp).format('MM/DD/YYYY');
  } else {
    return '_';
  }
};

export const toMonthDayYearTime = timestamp => {
  return moment(timestamp).format('MM/DD/YYYY h:mm a');
};

// This conversion function takes a string value previously formatted by moment and converts to unix epoch an integer representation of the timestamp value
export const convertToUnixEpoch = value => {
  if (value === '') {
    return 0;
  } else {
    return moment(value, 'MM/DD/YYYY h:mm a').unix();
  }
};

// Here we convert from string with format 'MM/DD/YYYY h:mm a' to unix epoch which is an integer representation of the timestamp
// We convert so this compare function can be used to make the column sortable while using a standard presentable format instead of lexical
// This puts the more recent date before the later date.
export const compareTimestamps = (valueA, valueB) => {
  const a = convertToUnixEpoch(valueA);
  const b = convertToUnixEpoch(valueB);

  if (a === b) {
    return 0;
  }
  return a > b ? -1 : 1;
};

// This is specifically for display of invoice dates (trandate and due_at)
// for these, only date should be displayed to remain consistent with Account Summary pdf's
export const formatDateWithoutTime = timestamp => {
  return moment(timestamp).format('M/D/YYYY');
};

const DateFormatter = ({ value }) => {
  if (!value) return '';

  return formatDateWithoutTime(value);
};

export const DateProvider = props => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

// This is for sorting by relative dates.
const calculateRelativeDateTimePriority = relativeTime => {
  if (relativeTime.includes('minute ago')) return 1;
  if (relativeTime.includes('minutes ago')) return 2;
  if (relativeTime.includes('hour ago')) return 3;
  if (relativeTime.includes('hours ago')) return 4;
  if (relativeTime.includes('day ago')) return 5;
  if (relativeTime.includes('days ago')) return 6;
  if (relativeTime.includes('week ago')) return 7;
  if (relativeTime.includes('weeks ago')) return 8;
  if (relativeTime.includes('month ago')) return 9;
  if (relativeTime.includes('months ago')) return 10;
  if (relativeTime.includes('year ago')) return 11;
  if (relativeTime.includes('years ago')) return 12;
  return 1000;
};

// Extract digits from a relative date/time
const extractDigits = relativeTime => {
  const match = relativeTime.match(/^([0-9]+)\s.+$/);
  if (match) {
    return Number(match[1]);
  }
  return null;
};

// Comparison method for sorting by a relative date
export const compareRelativeTimes = (relativeDateTimeA, relativeDateTimeB) => {
  let a = calculateRelativeDateTimePriority(relativeDateTimeA);
  let b = calculateRelativeDateTimePriority(relativeDateTimeB);
  if (a === b) {
    a = extractDigits(relativeDateTimeA);
    b = extractDigits(relativeDateTimeB);
    if (a === b) return 0;
  }
  return a < b ? -1 : 1;
};
