// Please for the love of god use only localhost or staging in development...
// No bad data in prod please!
// const DF_API_HOST = 'https://assets-micro-staging.herokuapp.com';
const DF_API_HOST = 'http://localhost:9000';
const STRIPE_PUBLIC_KEY = 'pk_test_TOXZ5DoIDWqbWYKETDag4aLe';
const USER_PRINTABLE_INVOICE = true;
const ADMIN_PRINTABLE_INVOICE = true;

module.exports = {
  DF_API_HOST,
  STRIPE_PUBLIC_KEY,
  USER_PRINTABLE_INVOICE,
  ADMIN_PRINTABLE_INVOICE,
};
