import React from 'react';

import { getCaratRangeLabel } from '../../utils/caratUtilities';

const WatchPropertiesFormatter = ({ value }) => {
  function formatProperty(attribute, propertyValue) {
    if (['caratRangeIndex'].includes(attribute)) return ''; // Hide some properties

    const attributeJoinSeperators = {
      carat: ' - ',
      carat_weight_mean: ' - ',
    };

    if (Array.isArray(propertyValue)) {
      // formatter for arrays
      const seperator = attributeJoinSeperators[attribute] || ', ';
      const newPropertyValue = propertyValue.join(seperator);

      propertyValue = newPropertyValue;
    }

    if (attribute === 'carat' && !!value.caratRangeIndex) {
      propertyValue = getCaratRangeLabel(value.caratRangeIndex);
    }

    let formattedAttributeName = attribute;
    if (attribute === 'estimated_polish_color') {
      formattedAttributeName = 'est. color';
    } else if (attribute === 'carat_weight_mean') {
      formattedAttributeName = 'carat weight';
    }

    return `${formattedAttributeName}: ${propertyValue}`; // formatter for single values
  }
  const watchProperties = value;

  return Object.keys(watchProperties).map((key, index) => (
    <div key={index}>{formatProperty(key, watchProperties[key])}</div>
  ));
};

export default WatchPropertiesFormatter;
