import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import WaitSpinner from '../components/WaitSpinner';
import { createCreditCard, fetchCreditCard } from '../api/index';
import getEnvConfig from '../env';
import BackButton from '../components/BackButton';

const { STRIPE_PUBLIC_KEY } = getEnvConfig();

class CreditCardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      loading: true,
      currentCreditCard: null,
      name: '',
    };

    this.handleTokenResponse = this.handleTokenResponse.bind(this);
    this.renderStripeElements = this.renderStripeElements.bind(this);
  }

  componentDidMount() {
    this.getCreditCard();
    this.renderStripeElements();
  }

  async getCreditCard() {
    const cc = await fetchCreditCard();
    this.setState({ currentCreditCard: cc });
    this.setState({ loading: false });
  }

  submit() {
    // see https://stripe.com/docs/stripe-js/reference#stripe-create-token
    this.stripe
      .createToken(this.card, { name: this.state.name })
      .then(this.handleTokenResponse);
  }

  async handleTokenResponse(response) {
    if (response.error) {
      // Inform the customer that there was an error.
      this.setState({ errorMessage: response.error.message });
    } else {
      let newCreditCard = await createCreditCard(response.token.id); // Send the token to the server to link customer and credit card.
      this.setState({ currentCreditCard: newCreditCard, name: '' }); // update the current card and blank out the name field
      this.card.clear(); // clear the stripe elements form
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  renderStripeElements() {
    // Connect to Stripe.js library/API.
    this.stripe = window.Stripe(STRIPE_PUBLIC_KEY);
    this.elements = this.stripe.elements({});

    // Styles via javascript, apparently. See https://stripe.com/docs/payments/accept-a-payment-charges#web-create-payment-form
    var style = {
      base: {
        fontSize: '16px',
        borderBottom: '1px solid #000',
      },
    };

    // Create an instance of the card Element.
    this.card = this.elements.create('card', { style: style });

    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount('#card');
  }

  render() {
    // set the page title and button title based on whether the user has an existing card
    let formTitle = 'Add Credit Card';
    if (this.state.currentCreditCard) {
      formTitle = 'Update Credit Card';
    }

    return (
      <main>
        <div className="page-wrapper-with-padding CreditCardPage">
          <div className="main-wrapper">
            <BackButton link="/user/edit" text="User Profile" />

            <div className="form-wrapper">
              {this.state.loading && <WaitSpinner />}
              {this.state.currentCreditCard && (
                <div className="table-wrapper">
                  <h1>Existing Credit Card</h1>
                  <div className="row">
                    <div className="column left">Card:</div>
                    <div className="column right">
                      {this.state.currentCreditCard.brand}
                    </div>
                  </div>
                  <div className="row">
                    <div className="column left">Last 4 Digits:</div>
                    <div className="column right">
                      {this.state.currentCreditCard.last4}
                    </div>
                  </div>
                  <div className="row">
                    <div className="column left">Expiration Date:</div>
                    <div className="column right">
                      {this.state.currentCreditCard.exp_month} /{' '}
                      {this.state.currentCreditCard.exp_year}
                    </div>
                  </div>
                </div>
              )}

              <h1>{formTitle}</h1>
              <div className="table-wrapper">
                <div className="row">
                  <div className="column">
                    <label htmlFor="card">Credit card</label>
                    <div id="card"></div>
                    <div id="card-errors" role="alert">
                      {this.state.errorMessage}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="column left">
                    <label htmlFor="name">Name on card</label>
                    <input
                      className="df-text-inputs light"
                      name="name"
                      id="name"
                      type="text"
                      value={this.state.name}
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      placeholder="Name on card"
                    />
                  </div>
                </div>
              </div>
              <div className="submit">
                <Button
                  className="df-cta btn btn-secondary"
                  type="submit"
                  onClick={() => this.submit()}
                >
                  {formTitle}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    customer_account: state.auth.user.customer_account,
  };
};

export default connect(mapStateToProps)(CreditCardPage);
