import { getPriceRange, getCaratRange } from './diamondRangeFilter';
import { applySearchFilters } from './diamondFilters';

export const storeDiamondsFromAPI = ({ diamonds = [] } = {}) => {
  diamonds = updateDiamondList(diamonds);

  return (dispatch, getState) => {
    const state = getState();
    const toRender = diamonds.slice();

    dispatch({
      type: 'IS_DIAMONDS_LOADING',
      payload: { isDiamondsLoading: true },
    });

    // stash original list of diamonds
    dispatch({ type: 'STORE_DIAMONDS_FROM_API', diamonds });

    // set initial list of diamonds to render
    const filteredDiamonds = applySearchFilters(toRender, {
      ...state.filters,
      searchKey: state.diamonds.searchKey,
    });
    dispatch({ type: 'SET_DIAMONDS_TO_RENDER', diamonds: filteredDiamonds });

    // set carat range based on list
    if (toRender.length > 0) {
      dispatch(getCaratRange({ diamonds: toRender }));
      dispatch(getPriceRange({ diamonds: toRender }));
    } else {
      dispatch(getPriceRange({ diamonds }));
      dispatch(getCaratRange({ diamonds }));
    }

    dispatch({
      type: 'IS_DIAMONDS_LOADING',
      payload: { isDiamondsLoading: false },
    });
  };
};

// This does some basic mapping on diamond values
const updateDiamondList = diamonds => {
  let updatedDiamonds = diamonds.map(item => {
    if (item.shape === 'Brilliant Round') {
      item.shape = 'Round Brilliant';
    }
    return item;
  });

  return updatedDiamonds;
};

export const setDiamondsToRender = ({ diamonds = [] } = {}) => ({
  type: 'SET_DIAMONDS_TO_RENDER',
  diamonds,
});

export const updateDiamondToFocus = id => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_DIAMOND_TO_FOCUS',
      id,
    });
  };
};
