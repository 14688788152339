import React from 'react';

import DiamondFilterButton from './DiamondFilterButton'; // This component communicates with the filters stored in Redux.
import { getDiamondColorCatalogForFilter } from '../utils/util';
import styled from '@emotion/styled';

const COLORS = getDiamondColorCatalogForFilter();

const ColorHeader = styled.div`
  margin-right: 15px;
`;

const FilterColor = () => (
  <div className="FilterColor">
    <ColorHeader className="legend">Color</ColorHeader>
    {COLORS.map(color => (
      <DiamondFilterButton
        key={`${color}-color-filter`}
        name={color}
        type="color"
      />
    ))}
  </div>
);

export default FilterColor;
