import React from 'react';
import Option from '../../types/more/option';
import TableHeaderColumn from './TableHeaderColumn';

type Props = {
  headers: Option<string, string>[];
  reverseSort: boolean;
  activeSortParameter: string | null;
  onSort: (arg: string) => void;
};

const PolishedDiamondGroupHeader = ({
  headers,
  onSort,
  reverseSort,
  activeSortParameter,
}: Props) => (
  <div className="thead">
    <div className="tr">
      <div className="th checkbox-header" />

      {headers.map((option: Option<string, string>, index: number) => (
        <TableHeaderColumn
          key={index}
          option={option}
          reverseSort={reverseSort}
          activeSortParameter={activeSortParameter}
          onSort={onSort}
        />
      ))}
    </div>
  </div>
);

export default PolishedDiamondGroupHeader;
