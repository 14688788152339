import React, { useState } from 'react';
// @ts-ignore
import { Row, Col } from 'reactstrap';
import { remove } from 'lodash';
import PolishedDiamondGroupGridButton from './PolishedDiamondGroupGridButton';
import PolishedStatsMap from '../../types/polishedStatsMap';
import PolishedFilter from '../../types/more/polishedFilter';
import PolishedDiamondsGroup from '../../types/models/polishedDiamondsGroup';
import WithDisabled from '../ui/WithDisabled';
import Option from '../../types/more/option';
import CaratRange from '../../types/more/caratRange';
import { useSelector } from 'react-redux';
import RootState from '../../types/redux/rootState';
import { PolishedDiamondGroupState } from '../../state/polishedDiamondGroups/reducer';
import { getDiamondColorCatalog } from '../../utils/util';

type shapes = string[];
const VERTICAL_LABEL: shapes = ['Round', 'Baguette', 'Princess'];

export const filterNameStore: Record<string, string> = {
  Round: 'Brilliant Round',
  Baguette: 'Baguette',
  Princess: 'Princess',
};
// The weight
type weight = number;
type label = string;
/* weight: key, label: label */
const VERTICAL_INFO: Option<label, weight>[] = [
  { key: 0.01, label: '1.2mm' },
  { key: 0.02, label: '1.7mm' },
  { key: 0.1, label: '0.1' },
  { key: 0.15, label: '0.15' },
  { key: 0.25, label: '0.25' },
  { key: 0.4, label: '0.4' },
  { key: 0.5, label: '0.5' },
  { key: 0.6, label: '0.6' },
  { key: 0.75, label: '0.75' },
];
/*
Round: .01, .02, .1, .15, .25, .4, .5, .6, .75
Baguette: .1, .15, .25, .4, .6
Princess: .25, .5, .75
 */
type ShapeName = string;
type collection = number[];
const HAS: Record<ShapeName, collection> = {
  Round: [0.01, 0.02, 0.1, 0.15, 0.25, 0.4, 0.5, 0.6, 0.75],
  Baguette: [0.1, 0.15, 0.25, 0.4, 0.6],
  Princess: [0.25, 0.5, 0.75],
};

const CARTS = VERTICAL_INFO.map(o => o.key);

const HORIZONTAL_LABEl = VERTICAL_INFO.map(s => s.label);

const makeFilterCartRanges = () => {
  let prev = 0.0;
  return CARTS.map(cart => {
    const M = [prev, cart];
    prev = cart;
    return M;
  });
};

const FILTER_CARAT_RANGES = makeFilterCartRanges();
type CaratHeaderColumnProps = {
  index: number;
};

const CartHeaderColumn = ({ index }: CaratHeaderColumnProps) => (
  <span>
    <span className="caratRange df-show-desktop-only">
      {HORIZONTAL_LABEl[index]}
    </span>
    <span className="df-show-nondesktop-only">{HORIZONTAL_LABEl[index]}</span>
  </span>
);

type Props = {
  stats: PolishedStatsMap;
  filters: PolishedFilter[];
  polishedDiamondGroups: PolishedDiamondsGroup[];
  onRemove: (f: PolishedFilter) => void;
  onAdd: (f: PolishedFilter) => void;
};

const PolishedDiamondsFilterColorCart = ({
  stats,
  filters,
  onRemove,
  onAdd,
}: Props) => {
  const selector = useSelector<RootState, PolishedDiamondGroupState>(
    state => state.polishedDiamondGroups
  );

  const calculateCount = (shape: string, caratRangeIndex: number) => {
    if (!selector || !selector.color) {
      return 0;
    }
    const selectedColors =
      selector.color.length > 0
        ? [...selector.color]
        : getDiamondColorCatalog();

    const counts = selector.polishedParcelsStats;

    if (Object.keys(counts).length === 0) {
      return 0;
    }
    if (selectedColors.indexOf('J+') > -1) {
      remove(selectedColors, n => {
        return n === 'J+';
      });
      selectedColors.push('J', 'K', 'L', 'M'); // add J, K, L, M
    }

    let sum = 0;
    for (let i = 0; i < selectedColors.length; i++) {
      const key = `${selectedColors[i]}-${shape}-${caratRangeIndex}`;
      if (counts[key]) {
        sum += counts[key].count;
      }
    }

    return sum;
  };
  const getLabel = (shape: string, index: number) => {
    return calculateCount(shape, index);
  };
  const toggleCartRanges = (index: number) => {
    const carat: CaratRange = {
      min: FILTER_CARAT_RANGES[index][0],
      max: FILTER_CARAT_RANGES[index][1],
    };
    const collections = filters.filter(
      f => f.carat.max === carat.max && carat.min === f.carat.min
    );
    const found = collections.length > 0;
    collections.forEach(f => {
      onRemove(f);
    });
    if (!found) {
      VERTICAL_LABEL.forEach(v => {
        const filter: PolishedFilter = {
          carat: carat,
          shape: v,
          caratRangeIndex: index,
        };
        const toBeDisabled = isDisabled(v, carat.max);
        if (!toBeDisabled) onAdd(filter);
      });
    }
  };
  const toggleShapes = (index: number) => {
    const shape = VERTICAL_LABEL[index];
    const collections = filters.filter(f => f.shape === shape);
    const found = collections.length > 0;
    collections.forEach(f => {
      onRemove(f);
    });
    if (!found) {
      FILTER_CARAT_RANGES.forEach((f, caratRangeIndex) => {
        const carat: CaratRange = {
          min: f[0],
          max: f[1],
        };
        const toBeDisabled = isDisabled(shape, carat.max);
        if (!toBeDisabled)
          onAdd({
            carat,
            shape,
            caratRangeIndex,
          });
      });
    }
  };
  const isDisabled = (shape: string, cart: number) => {
    if (!Object.keys(HAS).includes(shape)) {
      return true;
    }
    const doesHaveCart = HAS[shape].includes(cart);
    const disabled = !doesHaveCart;
    return disabled;
  };
  return (
    <div className="FilterColorCarat">
      <Row key="carat-filter">
        <Col sm="1" className="" />
        {FILTER_CARAT_RANGES.map((c, index) => (
          <Col key={`carat-${index}-filter`} sm="1" className="header">
            <a onClick={() => toggleCartRanges(index)}>
              <CartHeaderColumn index={index} />
            </a>
          </Col>
        ))}
      </Row>
      {VERTICAL_LABEL.map((v, vIndex) => (
        <Row key={`${vIndex}-filter`}>
          <Col sm="1" className="legend">
            <a onClick={() => toggleShapes(vIndex)}>
              <div>{v}</div>
            </a>
          </Col>
          {FILTER_CARAT_RANGES.map((r, fIndex) => (
            <Col
              key={`${v}-${fIndex}-filter`}
              sm="1"
              className="DiamondGridButton-container"
            >
              <WithDisabled disabled={isDisabled(v, r[1])}>
                <PolishedDiamondGroupGridButton
                  disabled={isDisabled(v, r[1])}
                  selected={
                    filters.filter(
                      f =>
                        f.caratRangeIndex === fIndex &&
                        f.shape === v &&
                        f.carat.min === r[0] &&
                        f.carat.max === r[1]
                    ).length === 1
                  }
                  key={fIndex}
                  filter={{
                    carat: {
                      min: r[0],
                      max: r[1],
                    },
                    shape: v,
                    caratRangeIndex: fIndex,
                  }}
                  onRemove={onRemove}
                  onAdd={onAdd}
                >
                  <>{getLabel(v, fIndex)}</>
                </PolishedDiamondGroupGridButton>
              </WithDisabled>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default PolishedDiamondsFilterColorCart;
