import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'reactstrap';

class SignUpTerms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  handleCheckboxChange(e) {
    this.props.setParentState({ businessTerms: { agree: e.target.checked } });
  }

  render() {
    const id = this.state.modalOpen ? 'simple-popper' : undefined;

    return (
      <div className="sign-up-terms-container">
        <input
          type="checkbox"
          aria-label="agree"
          onClick={e => this.handleCheckboxChange(e)}
        />{' '}
        I agree to{' '}
        <a href="#" onClick={e => this.handleClick(e)} aria-describedby={id}>
          Sales Agreement
        </a>
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={this.state.modalOpen}
          onClose={this.closeModal}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle>Sales Agreement</DialogTitle>
          <DialogContent>
            <p>1. General Conditions </p>
            <p>
              a. All customers must complete, sign, and return a wholesale
              application provided by Diamond Foundry.
              <br />
              b. This agreement pertains to the sale of any goods with the
              &quot;DIAMOND FOUNDRY&quot; branded name.
              <br />
              c. All orders are subject to approval by Diamond Foundry, in its
              sole discretion.
              <br />
              d. No Diamond Foundry products are to be exported by Retailer, or
              sold for by Retailer.
              <br />
              e. Diamond Foundry products are not to be resold to any
              Retailer/distributor except by Diamond Foundry. <br />
              f. Goods are not to be sold on-line or on Internet auction sites,
              without advance written authorization from Diamond Foundry, which
              authorization shall be at Diamond Foundry’s sole and absolute
              discretion.
              <br />
              g. Any violation of the terms and conditions of this agreement may
              result in termination of the retailer’s authorization to sell
              Diamond Foundry products.
              <br />
              h. The terms and conditions in this agreement are going to and
              will apply to all future transactions. Terms are subject to change
              at the sole and absolute discretion of Diamond Foundry, without
              notice. <br />
              i. This agreement and authorization is not assignable without
              prior written consent by Diamond Foundry, which consent shall be
              at Diamond Foundry’s. sole and absolute discretion.
              <br />
              j. All merchandising materials provided by Diamond Foundry to
              retailer, including but not limited to permanent fixtures,
              signage, POP items and the like, remain the property of Diamond
              Foundry and may be removed from the retailer’s store at any time
              and at the sole and absolute discretion of Diamond Foundry.
              <br />
              k. Retailer has no rights to the use of any trade names or
              trademarks of Diamond Foundry, except as such marks may appear on
              merchandise purchased hereunder. Retailer agrees to refrain from
              directly or indirectly using any of the marks except in
              advertising and promoting Diamond Foundry products as otherwise
              authorized in writing by Diamond Foundry in its sole absolute
              discretion. This includes the use of Diamond Foundry logos or
              artwork on retailer&apos;s company business cards or brochures
              without written consent from Diamond Foundry.
              <br />
              l. Purchase of Diamond Foundry branded goods from a third party is
              prohibited. <br />
              m. AAccess to Diamond Foundry retailer resources portal on
              https://portal.diamondfoundry.com is strictly limited to your
              account. Giving access unauthorized persons is prohibited.
              <br />
            </p>
            <p>2. Orders</p>
            <p>
              a. To maintain an active account with Diamond Foundry, all
              customers must order at least once every 90 days unless specified
              otherwise.
              <br />
              b. If a customer fails to preorder by the specified due date,
              their order and shipping date is not guaranteed.
              <br />
              c. By placing a preorder, customers commit to accepting and paying
              for the full amount.
              <br />
            </p>
            <p>3. Payment Policy</p>
            <p>
              a. All new customers are on a prepaid, cashier&apos;s check or
              money order basis.
              <br />
              b. Customers requesting terms must have a credit application and
              personal guarantor form filled out completely and signed.
              <br />
              c. For marketing consignments VISA, MC, & AMEX are accepted forms
              of payment. Customer must pay a surcharge of 3% for credit cards.
              <br />
              d. Any other desired terms must be approved by corporate.
              <br />
            </p>
            <p>4. Return Policy </p>
            <p>
              a. All goods returned must be approved by Diamond Foundry
              corporate.
              <br />
              b. incoming returns must have an RA# associated and written
              clearly on the box.
              <br />
              c. All incoming returns must come back in its original condition.
              <br />
              d. Diamond Foundry reserves the right to refuse any returns beyond
              seven (7) days of when the RA# was issued.
              <br />
              e. No returns on promotional, sales, or discounted products.
              <br />
              f. No returns of product due to misuse, abuse, and negligence.
              <br />
              g. Credit will be issued once the product has been received and
              inspected.
              <br />
              h. There will be a 30% restocking fee on all approved returned
              items.
              <br />
              i. No cash refunds on any return goods.
              <br />
            </p>
            <p> 5. Missing/Damaged Policy</p>
            <p>
              a. All missing and damaged goods must be reported within seven (7)
              days upon receipt and shipped back within seven (7) days of the
              RA#.
              <br />
              b. Any products shipped back freight collect will be refused.
              <br />
              c. Credit will be issued once the product has been received and
              inspected.
              <br />
              d. No cash refunds on any missing/damaged goods.
            </p>
            <p>6. Delinquency & Penalty </p>
            <p>
              a. Past Due: All accounts past due over 30 days are subject to
              reporting and collections. The customer is subject to pay any
              fines and charges that Diamond Foundry may incur, including
              attorney&apos;s fees.
              <br />
              b. NSF Checks: Non-Sufficient Funds Checks are subjected to a
              $25.00 fee for every occurence.
              <br />
              c. Collections: The customer must agree to pay for any fees that
              Diamond Foundry may incur (i.e. collection agency, attorney fees).
              <br />
              d. Customer agrees to pay for freight costs on goods that are
              returned due to COD refusal or canceled orders. Customer must
              notify Diamond Foundry of all cancellations prior to shipment
              schedule.
              <br />
            </p>
            <p>7. Shipment Policy (FOB: Los Angeles, CA)</p>
            <p>
              All customers are required to pay for shipping unless otherwise
              specified by Diamond Foundry corporate.
              <br />
              b. All shipments will go out UPS or FEDEX OVERNIGHT unless the
              customer specifies otherwise.
              <br />
              c. Each shipment must be approved by credit before it leaves.
              <br />
              d. Diamond Foundry is responsible for delivering goods within the
              shipment date assuming the order was received on time.
              <br />
              e. At no time will retailer ask shipping agent or carrier to hold
              shipment, as the goods may be returned to Diamond Foundry, in
              which case you will be subject to a 30% restocking fee.
              <br />
              f. Diamond Foundry will not accept requests for partial shipments.
              <br />
            </p>
            <p>8. Resale Certificate</p>
            <p>
              a. By providing the Resale Certificate number, you certify you
              hold a valid seller&apos;s permit/certificate and are engaged in
              selling the following type of tangible: personal property of
              Jewelry and Accessories. You agree that you will resell the
              item(s) purchased from Diamond Foundry under this resale
              certificate in the form of Jewelry and Accessories in the regular
              course of your business operations and will do so prior to making
              any use of the item(s) other than demonstration and display while
              holding the item(s) for sale in the regular course of your
              business.
              <br />
              You understand that if you use the item(s) purchased under this
              certificate in any manner other than as just described, you will
              owe use tax based on each item’s purchase price or as otherwise
              provided by law. You also understand that: A person may be guilty
              of a misdemeanor under Revenue and Taxation Code section 6094.5 if
              the purchaser knows at the time of purchase that he or she will
              not resell the purchased item prior to any use (other than
              retention, demonstration, or display while holding it for resale)
              and he or she furnishes a resale certificate to avoid payment to
              the seller of an amount as tax. Additionally, a person misusing a
              resale certificate for personal gain or to evade the payment of
              tax is liable, for each purchase, for the tax that would have been
              due, plus a penalty of 10 percent of the tax or $500, whichever is
              more.
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeModal}
              className="df-cta account-type-btn mx-auto"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default SignUpTerms;
