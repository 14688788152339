import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';
import RoughDiamondGroupGridButton from './rough/RoughDiamondGroupGridButton'; // This component communicates with the filters stored in Redux.
import { getRoughDiamondCaratRanges } from '../utils/caratUtilities';
import {
  removeRoughDiamondGroupColorCaratFilter,
  addRoughDiamondGroupColorCaratFilter,
} from '../state/roughDiamondGroups/actions';

const FILTER_COLORS = ['GHI', 'JK', 'EF', 'R&D'];
const FILTER_CARAT_RANGES = getRoughDiamondCaratRanges();
const FILTER_CARAT_RANGE_HEADERS = [
  '<4',
  '4.x',
  '5.x',
  '6.x',
  '7.x',
  '8.x',
  '9.x',
  '>10.0',
  'PR',
  'Wafer',
];

const FILTER_CARAT_RANGE_HEADERS_SHORT = [
  '<4',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '>10',
  'PR',
  'Wafer',
];

class FilterColorCarat extends Component {
  constructor(props) {
    super(props);
    this.calculateCount = this.calculateCount.bind(this);
    this.getCaption = this.getCaption.bind(this);
    this.toggleColor = this.toggleColor.bind(this);
  }

  // This method returns the count for the given color and carat range.
  // The counts and selected colors MUST be passed in as props.
  calculateCount(color, caratRangeIndex) {
    const counts = this.props.stats.roughDiamondGroups || {};
    return counts[`${color}-${caratRangeIndex}`] || 0;
  }

  getCaption(color, caratRangeIndex) {
    return this.calculateCount(color, caratRangeIndex);
  }

  getColorHeader(color) {
    return color;
  }

  getCaratRangeHeader(caratRangeIndex) {
    return (
      <span>
        <span className="caratRange df-show-desktop-only">
          {FILTER_CARAT_RANGE_HEADERS[caratRangeIndex]}
        </span>
        <span className="df-show-nondesktop-only">
          {FILTER_CARAT_RANGE_HEADERS_SHORT[caratRangeIndex]}
        </span>
      </span>
    );
  }

  // Select or deselect all items in a row
  toggleColor(color) {
    // First check if any buttons are selected for this color
    let anySelected = false;
    let colorCaratFilters = this.props.filters.colorCarat;
    for (let i = 0; i < colorCaratFilters.length; i++) {
      if (colorCaratFilters[i].color === color) {
        anySelected = true;
        break;
      }
    }

    // Then select or deselect them all
    if (anySelected) {
      for (let i = 0; i < FILTER_CARAT_RANGES.length; i++) {
        this.props.dispatch(
          removeRoughDiamondGroupColorCaratFilter({
            color: color,
            carat: FILTER_CARAT_RANGES[i],
            caratRangeIndex: i,
          })
        );
      }
    } else {
      for (let i = 0; i < FILTER_CARAT_RANGES.length; i++) {
        this.props.dispatch(
          addRoughDiamondGroupColorCaratFilter({
            color: color,
            carat: FILTER_CARAT_RANGES[i],
            caratRangeIndex: i,
          })
        );
      }
    }
  }

  // Select or deselect all items in a column
  toggleCaratRange(caratRangeIndex) {
    // First check if any buttons are selected for this carat range
    let anySelected = false;
    let colorCaratFilters = this.props.filters.colorCarat;
    for (let i = 0; i < colorCaratFilters.length; i++) {
      if (colorCaratFilters[i].caratRangeIndex === caratRangeIndex) {
        anySelected = true;
        break;
      }
    }

    // Then select or deselect them all
    if (anySelected) {
      for (let i = 0; i < FILTER_COLORS.length; i++) {
        this.props.dispatch(
          removeRoughDiamondGroupColorCaratFilter({
            color: FILTER_COLORS[i],
            carat: FILTER_CARAT_RANGES[caratRangeIndex],
            caratRangeIndex: caratRangeIndex,
          })
        );
      }
    } else {
      for (let i = 0; i < FILTER_COLORS.length; i++) {
        this.props.dispatch(
          addRoughDiamondGroupColorCaratFilter({
            color: FILTER_COLORS[i],
            carat: FILTER_CARAT_RANGES[caratRangeIndex],
            caratRangeIndex: caratRangeIndex,
          })
        );
      }
    }
  }

  render() {
    return (
      <div className="FilterColorCarat">
        <Row key="carat-filter">
          <Col sm="1" className="" />
          {FILTER_CARAT_RANGES.map((caratRange, index) => {
            return (
              <Col key={`carat-${index}-filter`} sm="1" className="header">
                <a
                  onClick={e => {
                    this.toggleCaratRange(index);
                  }}
                >
                  {this.getCaratRangeHeader(index)}
                </a>
              </Col>
            );
          })}
        </Row>

        {FILTER_COLORS.map(color => {
          return (
            <Row key={`${color}-filter`}>
              <Col sm="1" className="legend">
                <a
                  onClick={e => {
                    this.toggleColor(color);
                  }}
                >
                  <div>{this.getColorHeader(color)}</div>
                </a>
              </Col>
              {FILTER_CARAT_RANGES.map((caratRange, index) => {
                return (
                  <Col
                    key={`${color}-${index}-filter`}
                    sm="1"
                    className="DiamondGridButton-container"
                  >
                    <RoughDiamondGroupGridButton
                      color={color}
                      caratRange={caratRange}
                      caratRangeIndex={index}
                      caption={this.getCaption(color, index)}
                    />
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.roughDiamondGroups.filters,
    stats: state.stats,
  };
};

export default connect(mapStateToProps)(FilterColorCarat);
