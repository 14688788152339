import React, { useRef, useEffect } from 'react';

import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import SVG from './SVG';

import CartListItem from './CartListItem';
import { formatPricingFromPennies } from '../utils/util';

const useOutsideClick = (ref, closeMiniCart) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      closeMiniCart();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

const Minicart = props => {
  const wrapperRef = useRef(null);
  const { closeMiniCart } = props;
  useOutsideClick(wrapperRef, closeMiniCart);

  return (
    <div id="Minicart__container" ref={wrapperRef}>
      <div className="close__container">
        <SVG
          className="Svg close-minicart"
          viewBox="0 0 100 100"
          name="close"
          onClick={closeMiniCart}
        />
      </div>

      <div className="minicart-list">
        {props.cart.map((item, index) => {
          return (
            <CartListItem
              key={`cart-item-${index}`}
              checkedOut={false}
              {...item}
            />
          );
        })}

        {props.cart.length === 0 && (
          <div className="empty-cart">Your cart is empty</div>
        )}
      </div>
      {props.cart.length > 0 && (
        <div className="total-price-container">
          <div className="total-price">
            <span className="label">TOTAL: </span>
            <span>{formatPricingFromPennies(props.totalPrice)}</span>
          </div>

          <Button className="df-cta" onClick={props.goToCartFromMinicart}>
            CART
          </Button>

          <Button className="df-cta" onClick={props.goToCheckoutFromMinicart}>
            CHECKOUT
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    cart: state.diamondCart.cart,
    totalPrice: state.diamondCart.totalPrice,
    diamondToFocus: state.diamondCart.diamondToFocus,
  };
};

export default connect(mapStateToProps)(Minicart);
