import React from 'react';
import Option from '../../../types/more/option';
import DiamondListSortIndicator from '../../DiamondListSortIndicator';

type Props = {
  header: Option<string, string>[];
  reverseSort: boolean;
  activeSortParameter: string | null;
  onSort: (parameter: string) => void;
};

const PolishedDiamondListHeader = ({
  header,
  activeSortParameter,
  reverseSort,
  onSort,
}: Props) => {
  return (
    <div className="thead">
      <div className="tr">
        {header.map((h: Option<string, string>, index: number) => (
          <div onClick={() => onSort(h.key)} className="th" key={index}>
            {h.label}
            <DiamondListSortIndicator
              activeSortParam={activeSortParameter}
              isReversedParam={reverseSort}
              fieldName={h.key}
            />
          </div>
        ))}
        <div onClick={() => onSort('measurements')} className="th">
          Measurements
          <DiamondListSortIndicator
            activeSortParam={activeSortParameter}
            isReversedParam={reverseSort}
            fieldName={'measurements'}
          />
        </div>
      </div>
    </div>
  );
};

export default PolishedDiamondListHeader;
