import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RoughDiamondGroupList from '../components/rough/RoughDiamondGroupList';
import RoughDiamondGroupFilterGrid from '../components/rough/RoughDiamondGroupFilterGrid';
import {
  setRoughDiamondGroups,
  clearRoughDiamondGroupFilters,
} from '../state/roughDiamondGroups/actions';
import { fetchRoughDiamondGroups } from '../api/index';
import { roughDiamondGroupComparator } from '../utils/roughDiamondGroupComparison';
import MultiselectFooter from '../components/MultiselectFooter';
import { addMultipleRoughDiamondGroupsToCart } from '../state/action/diamondCart';

class RoughDiamondGroupsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSortParameter: null,
      reverseSort: false,
      index: null,
      isSorting: false,
    };

    this.getRoughDiamondGroups = this.getRoughDiamondGroups.bind(this);
    this.getRoughDiamondGroupsToRender = this.getRoughDiamondGroupsToRender.bind(
      this
    );
    this.handleApplyRoughDiamondGroupSort = this.handleApplyRoughDiamondGroupSort.bind(
      this
    );
    this.setIndex = this.setIndex.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidMount() {
    this.getRoughDiamondGroups();
    this.handleApplyRoughDiamondGroupSort('carat');
  }

  async getRoughDiamondGroups() {
    // Fetch the rough diamonds from the API and load them into Redux.
    const newRoughDiamondGroups = await fetchRoughDiamondGroups();
    this.props.dispatch(setRoughDiamondGroups(newRoughDiamondGroups));
  }

  // This stores the id of the diamond that is currently open for more info.
  setIndex(index) {
    this.setState(() => {
      return {
        index,
      };
    });
  }

  clearFilters() {
    this.props.dispatch(clearRoughDiamondGroupFilters());
  }

  handleApplyRoughDiamondGroupSort(newSortParameter) {
    const { activeSortParameter, reverseSort } = this.state;
    if (newSortParameter === activeSortParameter) {
      this.setState({
        activeSortParameter: newSortParameter,
        reverseSort: !reverseSort,
        isSorting: true,
      });
    } else {
      this.setState({
        activeSortParameter: newSortParameter,
        reverseSort: false,
        isSorting: true,
      });
    }
  }

  getRoughDiamondGroupsToRender() {
    if (!this.state.activeSortParameter) {
      return this.props.roughDiamondGroups.rendered;
    }

    if (this.state.isSorting) {
      const sortedDiamondGroups = this.props.roughDiamondGroups.rendered.sort(
        (diamondA, diamondB) => {
          const parameterToSortBy = this.state.activeSortParameter;
          return roughDiamondGroupComparator(
            diamondA,
            diamondB,
            parameterToSortBy
          );
        }
      );

      if (this.state.reverseSort) {
        sortedDiamondGroups.reverse();
        this.setState({
          isSorting: false,
        });
      }

      return sortedDiamondGroups;
    }

    return this.props.roughDiamondGroups.rendered;
  }

  render() {
    const { showToaster, filters, dispatch } = this.props;
    const { reverseSort, activeSortParameter, index } = this.state;
    return (
      <main>
        <div className="Diamonds">
          <div className="df-headline-container">
            <div className="df-headline">Rough Diamonds</div>
          </div>
          <RoughDiamondGroupFilterGrid
            showToaster={this.props.showToaster}
            clearFilters={this.clearFilters}
          />
          <RoughDiamondGroupList
            isReversedParam={reverseSort}
            activeSortParam={activeSortParameter}
            applyRoughDiamondGroupSort={this.handleApplyRoughDiamondGroupSort}
            selectAllDiamond={() =>
              dispatch({ type: 'SELECT_ALL_ROUGH_DIAMOND_GROUPS' })
            }
            deselectAllDiamond={() =>
              dispatch({ type: 'DESELECT_ALL_ROUGH_DIAMOND_GROUPS' })
            }
            roughDiamondGroups={this.getRoughDiamondGroupsToRender()}
            loading={this.props.roughDiamondGroups.loading}
            filters={filters}
            showToaster={showToaster}
            setIndex={this.setIndex}
            index={index}
          />
        </div>
        <MultiselectFooter
          items={this.props.roughDiamondGroups.selected}
          addToCart={addMultipleRoughDiamondGroupsToCart}
        />
      </main>
    );
  }
}

RoughDiamondGroupsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  showToaster: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    roughDiamondGroups: state.roughDiamondGroups, // This loads the diamonds from Redux into the component's state.
  };
};

export default connect(mapStateToProps)(RoughDiamondGroupsPage);
