/*
  These arrays define the sort order for string values that aren't sorted alphabetically.
*/

// Later in the list is higher in rank. AKA "D" is better color than "L"
const colorMap = ['GHI', 'JK', 'EF', 'R&D'];

const propertyRankMap = {
  estimated_polish_color: colorMap,
};

// Custom comparators for rough diamond group properties
export const roughDiamondGroupComparator = (diamondA, diamondB, property) => {
  switch (property) {
    case 'quantity':
    case 'default_price':
    case 'parcel_price':
    case 'carat_weight_sum':
    case 'carat_weight_mean': {
      const A = parseFloat(diamondA[property]);
      const B = parseFloat(diamondB[property]);

      return B - A;
    }
    case 'estimated_polish_color': {
      const rankA = propertyRankMap[property].indexOf(diamondA[property]);
      const rankB = propertyRankMap[property].indexOf(diamondB[property]);

      return rankB - rankA;
    }
    default: {
      if (!diamondA[property] || !diamondB[property]) return 0;

      const aValue = diamondA[property].toLowerCase();
      const bValue = diamondB[property].toLowerCase();

      if (bValue < aValue) {
        return -1;
      }
      if (bValue > aValue) {
        return 1;
      }
      return 0;
    }
  }
};
