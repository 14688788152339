import React from 'react';
import DiamondListSortIndicator from './DiamondListSortIndicator';

// This is the header row for the DiamondList component.
const DiamondListHeader = ({
  applyDiamondSort,
  activeSortParam,
  isReversedParam,
}) => (
  <div className="thead">
    <div className="tr">
      <div className="th checkbox-header" />
      <div className="th detailed-hide shape-icon-header" />
      <div onClick={() => applyDiamondSort('shape')} className="th">
        <div className="shape__container">
          Shape
          <DiamondListSortIndicator
            fieldName="shape"
            activeSortParam={activeSortParam}
            isReversedParam={isReversedParam}
          />
        </div>
      </div>
      <div onClick={() => applyDiamondSort('carat')} className="th">
        Carat
        <DiamondListSortIndicator
          fieldName="carat"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div onClick={() => applyDiamondSort('color')} className="th">
        Color
        <DiamondListSortIndicator
          fieldName="color"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div onClick={() => applyDiamondSort('clarity')} className="th">
        Clarity
        <DiamondListSortIndicator
          fieldName="clarity"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div onClick={() => applyDiamondSort('cut')} className="th">
        Cut
        <DiamondListSortIndicator
          fieldName="cut"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div onClick={() => applyDiamondSort('cost')} className="th">
        Wholesale
        <DiamondListSortIndicator
          fieldName="cost"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div
        onClick={() => applyDiamondSort('polish')}
        className="th detailed-only"
      >
        Polish
        <DiamondListSortIndicator
          fieldName="polish"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div
        onClick={() => applyDiamondSort('symmetry')}
        className="th detailed-only"
      >
        Symmetry
        <DiamondListSortIndicator
          fieldName="symmetry"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div
        onClick={() => applyDiamondSort('depth_pct')}
        className="th detailed-only"
      >
        Depth
        <DiamondListSortIndicator
          fieldName="depth_pct"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div
        onClick={() => applyDiamondSort('table_size')}
        className="th detailed-only"
      >
        Table
        <DiamondListSortIndicator
          fieldName="table_size"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div
        onClick={() => applyDiamondSort('girdle')}
        className="th detailed-only"
      >
        Girdle
        <DiamondListSortIndicator
          fieldName="girdle"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div
        onClick={() => applyDiamondSort('crown_angle')}
        className="th detailed-only"
      >
        C Angle
        <DiamondListSortIndicator
          fieldName="crown_angle"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div
        onClick={() => applyDiamondSort('crown_height')}
        className="th detailed-only"
      >
        C Height
        <DiamondListSortIndicator
          fieldName="crown_height"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div
        onClick={() => applyDiamondSort('pavilion_angle')}
        className="th detailed-only"
      >
        P Angle
        <DiamondListSortIndicator
          fieldName="pavilion_angle"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
      <div
        onClick={() => applyDiamondSort('pavilion_height')}
        className="th detailed-only"
      >
        P Height
        <DiamondListSortIndicator
          fieldName="pavilion_height"
          activeSortParam={activeSortParam}
          isReversedParam={isReversedParam}
        />
      </div>
    </div>
  </div>
);

export default DiamondListHeader;
