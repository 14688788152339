import { AUTHENTICATE, DEAUTHENTICATE } from './auth/constants';
import { setToken } from '../api/index';

export const saveAuthToken = store => next => action => {
  if (action.type === AUTHENTICATE) {
    setToken(action.payload);
  }

  if (action.type === DEAUTHENTICATE) {
    setToken(null);
  }
  next(action);
};
