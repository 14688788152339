import React from 'react';
import PolishedFilter from '../../types/more/polishedFilter';
import styled from '@emotion/styled';

type Props = {
  disabled?: boolean;
  selected: boolean;
  filter: PolishedFilter;
  onRemove?: (filter: PolishedFilter) => void;
  onAdd?: (filter: PolishedFilter) => void;
  children: React.ReactNode | string;
};
const dummy = (f: PolishedFilter) => {};
const A = styled.a`
  height: 100%;
  width: 100%;
`;
// Shape + carat
const PolishedDiamondGroupGridButton = ({
  disabled = false,
  filter,
  selected,
  onAdd = dummy,
  onRemove = dummy,
  children,
}: Props) => (
  <A
    onClick={() => {
      if (selected) {
        onRemove(filter);
      } else {
        onAdd(filter);
      }
    }}
    style={disabled ? { background: 'grey' } : {}}
    className={
      selected && !disabled ? 'DiamondGridButton selected' : 'DiamondGridButton'
    }
  >
    {!disabled ? children : ''}
  </A>
);

export default PolishedDiamondGroupGridButton;
