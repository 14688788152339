import React from 'react';
import { formatUSD } from '../utils/util';

const AgingReportPeriods = ({ arPeriods, openBalance }) => {
  return (
    <table className="table table-bordered bordered-ar-periods">
      <thead>
        <tr>
          {Object.keys(arPeriods).map(periodName => {
            return <th key={`${periodName}`}>{periodName.toUpperCase()}</th>;
          })}
          <th>AMOUNT DUE</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          {Object.keys(arPeriods).map(periodName => {
            return (
              <td key={`${periodName}-${arPeriods[periodName]}`}>
                {formatUSD(arPeriods[periodName] / 100.0)}
              </td>
            );
          })}
          <td>{formatUSD(openBalance / 100.0)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default AgingReportPeriods;
