// mui.js = Utility methods for Material UI.
import { createMuiTheme } from '@material-ui/core/styles';

// Material UI Theme.
// See https://stackoverflow.com/questions/49535551/change-secondary-and-primary-colors-in-materual-ui and https://material-ui.com/customization/theming/
// We only have one real color in our theme, so both primary and secondary will be the same color.
export const muiTheme = createMuiTheme({
  // See https://material-ui.com/customization/palette/
  palette: {
    primary: {
      main: '#ff3c00',
    },
    secondary: {
      main: '#ff3c00',
    },
  },
  // See https://material-ui.com/customization/typography/
  typography: {
    fontFamily: 'Helvetica Now Text,system',
    fontSize: 18,
  },
  props: {
    MuiButtonBase: {
      // No more ripple, on the whole application. See https://material-ui.com/getting-started/faq/#how-can-i-disable-the-ripple-effect-globally
      disableRipple: true,
    },
  },
});
