import React, { Component } from 'react';

import { Button } from 'reactstrap';

import axios from 'axios';
import Confirmation from '../components/Confirmation';
import SVG from '../components/SVG';

import { Link } from '@reach/router';

import getEnvConfig from '../env';

export const { DF_API_HOST } = getEnvConfig();

class ForgotPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmation: false,
      email: '',
      errorMessage: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    axios
      .post(`${DF_API_HOST}/api/v1/users/password`, {
        user: {
          email: this.state.email,
        },
      })
      .then(response => {
        this.setState({ showConfirmation: true, email: '' });
      })
      .catch(error => {
        this.setState({
          errorMessage: `An error has occurred; ${error.message}`,
        });
      });
  }

  render() {
    return (
      <main>
        <div className="forgot-page df-logout-margin">
          <div className="forgot-container">
            <div className="forgot-headers">
              <SVG
                className="Svg df-logo"
                viewBox="0 0 603.8 40.9"
                name="logo"
              />
            </div>
            <div className="forgot-form">
              {/* <h1 className="df-logo">DIAMOND FOUNDRY</h1> */}

              <h1 className="df-title">RESET YOUR PASSWORD</h1>

              <input
                className="df-text-inputs username-input"
                name="email"
                placeholder="Email address"
                onChange={this.handleChange}
              />

              <span className="error-message">{this.state.errorMessage}</span>

              <Button className="df-cta" onClick={this.handleSubmit}>
                SEND
              </Button>
            </div>
            <div className="df-link-container">
              <Link className="df-link flex" to="/login">
                <i className="material-icons">arrow_back</i> Back to Login Page
              </Link>
            </div>
            {this.state.showConfirmation && (
              <Confirmation
                parent="forgot"
                closeConfirmation={e =>
                  this.setState({ showConfirmation: false })
                }
                title="A link has been sent to your email address"
                subtitle="After a few minutes if you do not receive a link, please check your spam folder in your inbox."
              />
            )}
          </div>
        </div>
      </main>
    );
  }
}

export default ForgotPage;
