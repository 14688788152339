import React from 'react';
import { connect } from 'react-redux';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { CSVLink } from 'react-csv';
import DiamondListItem from './DiamondListItem';
import SVG from './SVG';
import { debounce } from '../utils/util';
import { getCSVHeaders, getCSVData } from '../utils/csvUtilities';
import WaitSpinner from './WaitSpinner';
import DiamondListHeader from './DiamondListHeader';
import { salesOrAdmin } from '../utils/util';

// This is a custom-build diamonds table that was initially created by contractors.
class DiamondList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedRows: 25, // the number of diamonds that should be loaded into the diamonds list
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.updateDisplayedRows = this.updateDisplayedRows.bind(this);

    // set up a debounced method for loading more rows into the diamonds list
    this.debouncedUpdateDisplayedRows = debounce(this.updateDisplayedRows, 50);
    this.debouncedUpdateDisplayedRows = this.debouncedUpdateDisplayedRows.bind(
      this
    );
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.debouncedUpdateDisplayedRows();
  }

  updateDisplayedRows() {
    let newDisplayedRows = 25 + window.scrollY / 50;
    if (newDisplayedRows > this.state.displayedRows)
      this.setState({ displayedRows: newDisplayedRows });
  }

  // props will have props.diamonds (array)
  render() {
    if (
      !this.props.isDiamondsLoading &&
      this.props.diamonds &&
      this.props.diamonds.length === 0
    )
      return (
        <div className="no-diamond-data">
          {' '}
          No diamonds currently fit your criteria, try expanding your search
          with our filter options.
          <div className="notify-text">
            {' '}
            If you cannot find what you are looking for, be notified when the
            items become available.{' '}
          </div>
          <button
            type="button"
            className="df-cta btn btn-secondary notify-button"
            onClick={e => this.props.saveNotifier(this.props)}
          >
            NOTIFY ME
          </button>
        </div>
      );

    return (
      <div className="DiamondList">
        <div className="Diamond_selectors">
          <div className="DiamondList__selector-container">
            Select:
            <div
              className="DiamondList__selectors allselect"
              onClick={e => this.props.selectAllDiamond(e)}
            >
              <i className="material-icons check">check</i>
              All
            </div>
            <div
              className="DiamondList__selectors clearall"
              onClick={e => this.props.deselectAllDiamond(e)}
            >
              <i className="material-icons clear">clear</i>
              Clear
            </div>
          </div>
          <div className="details-toggle-container">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.props.detailedTable}
                    onChange={this.props.toggleDetailedTable}
                    value="detailed-view"
                    color="secondary"
                  />
                }
                label="Show Details"
              />
            </FormGroup>
          </div>
          {(salesOrAdmin(this.props.user.roles) && (
            <CSVLink
              headers={getCSVHeaders()}
              data={getCSVData(this.props.diamonds)}
              filename="Diamond list.csv"
              target="_blank"
            >
              {<SVG className="Svg" name="csv" viewBox="0 0 600 600" />}
            </CSVLink>
          )) || <div style={{ width: '25px' }} />}
        </div>

        <div className={this.props.detailedTable ? 'table detailed' : 'table'}>
          <DiamondListHeader
            applyDiamondSort={this.props.applyDiamondSort}
            activeSortParam={this.props.activeSortParam}
            isReversedParam={this.props.isReversedParam}
          />
          <div className="tbody">
            {!this.props.isDiamondsLoading &&
              this.props.diamonds &&
              this.props.diamonds.map((diamond, index) => {
                if (index <= this.state.displayedRows) {
                  return (
                    <DiamondListItem
                      key={index}
                      {...diamond}
                      setIndex={this.props.setIndex}
                      currentIndex={index}
                      showToaster={this.props.showToaster}
                      expanded={diamond.id === this.props.index}
                      selected={diamond.isSelected}
                      salesOrAdmin={salesOrAdmin(this.props.user.roles)}
                    />
                  );
                }
              })}
            {(this.props.isDiamondsLoading ||
              this.state.displayedRows < this.props.diamonds.length) && (
              <WaitSpinner />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(DiamondList);
