import React from 'react';
import SVG from './SVG';

// This is the sort indicator arrow for the DiamondListHeader component.
const DiamondListSortIndicator = ({
  activeSortParam,
  fieldName,
  isReversedParam,
}) => {
  if (activeSortParam === fieldName) {
    if (isReversedParam) {
      return (
        <span className="table__filter-icon">
          <SVG className="Svg" name="angle-up" viewBox="0 0 1792 1792" />
        </span>
      );
    }
    return (
      <span className="table__filter-icon">
        <SVG className="Svg" name="angle-down" viewBox="0 0 1792 1792" />
      </span>
    );
  }

  return null;
};

export default DiamondListSortIndicator;
