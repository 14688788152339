import React from 'react';
import Diamond from '../../types/models/diamond';
import {
  formatPricing,
  getDiamondIconSrc,
  getDiamondPrice,
  shapeHasDiamondPhoto,
} from '../../utils/util';
import SVG from '../SVG';
import CanHide from '../ui/CanHide';
import FlexEnd from '../ui/FlexEnd';
import InventoryHold, {
  getTimeWithZone,
} from '../../types/models/inventoryHold';
import { useSelector } from 'react-redux';
import RootState from '../../types/redux/rootState';
import Auth from '../../types/redux/auth';
import User from '../../types/redux/user';
import moment from 'moment';
import {
  convertToUnixEpoch,
  formatDate,
  formatDateRelative,
  makeStandardTimestamp,
} from '../../utils/dateUtilities';

export type DateTimeUTC = string;

export type CellEntry = {
  diamond: Diamond;
  hold?: InventoryHold;
};

type Props = {
  entry: CellEntry;
  onAddToCart?: () => void;
  onDelete?: () => void;
};

const makeGoogleMeeting = (
  hold: InventoryHold,
  diamond: Diamond,
  user: User
): string => {
  const text = `${diamond.lot_id} Hold Expiring`;
  const endDate = new Date(hold.expires_at);

  // Set one hour before expires.
  const start = new Date(hold.expires_at);
  start.setHours(start.getHours() - 1);

  const startFormatted = makeStandardTimestamp(start);
  const endFormatted = makeStandardTimestamp(endDate);
  const TIMEZONE = 'America/Los_Angeles';

  const { email, customer_account } = user;
  const { sales_representative } = customer_account;
  return `https://www.google.com/calendar/render?action=TEMPLATE&ctz=${TIMEZONE}&text=${text}&dates=${startFormatted}/${endFormatted}&sf=true&output=xml&src=${email}&add=${sales_representative?.email}`;
};

const DiamondHoldItem = ({
  entry,
  onAddToCart,
  onDelete = undefined,
}: Props) => {
  const selector = useSelector<RootState, Auth>(state => state.auth);
  const { diamond, hold = undefined } = entry;
  if (!hold) {
    return null;
  }

  const expires = hold.expires_at;
  const price = +getDiamondPrice(diamond);
  return (
    <div className="CartListItem">
      <div className="diamond-info-left">
        <div className="diamond-icon-container">
          {shapeHasDiamondPhoto(diamond.shape) && (
            <img
              className="diamond-icon"
              src={getDiamondIconSrc(diamond.shape)}
              alt="Diamond Icon"
            />
          )}
        </div>
        <ul className="unstyled">
          <li>
            <span className="shape">{diamond.shape}</span>
          </li>
          {diamond.carat && (
            <li>
              <span className="fieldName">Carat:</span> {diamond.carat}
            </li>
          )}
          {diamond.color && (
            <li>
              <span className="fieldName">Color:</span> {diamond.color}
            </li>
          )}
          {!diamond.color && (
            <li>
              <span className="fieldName">Color:</span> I+
            </li>
          )}
          {diamond.clarity && (
            <li>
              <span className="fieldName">Clarity:</span> {diamond.clarity}
            </li>
          )}
          {!diamond.clarity && (
            <li>
              <span className="fieldName">Clarity:</span> SI1+
            </li>
          )}
          {diamond.quantity && (
            <li className="quantity">
              <span className="fieldName">Qty:</span>
              {diamond.quantity}
            </li>
          )}

          {diamond.lot_id && (
            <li>
              <span className="fieldName">Lot Id: </span>
              {diamond.lot_id}
            </li>
          )}

          {diamond.sku && <li className="diamond-sku">{diamond.sku}</li>}
          <li>
            <span className="fieldName">Ends:</span>
            {getTimeWithZone(expires)}
          </li>
        </ul>
      </div>
      <ul className="unstyled diamond-info-right">
        {diamond.orderType !== 'bulk' && (
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={diamond.cert_url}
              className="df-link diamond-certificate"
            >
              Diamond Certificate
              <i className="material-icons chevron_right">chevron_right</i>
            </a>
          </li>
        )}

        <FlexEnd>
          <CanHide hidden={onAddToCart === undefined}>
            <li>
              <SVG
                onClick={onAddToCart}
                className="delete"
                viewBox="0 0 600 600"
                name="plus"
              />
            </li>
          </CanHide>

          <CanHide hidden={onDelete === undefined}>
            <li>
              <SVG
                onClick={onDelete}
                className="delete"
                viewBox="0 0 600 600"
                name="trash"
              />
            </li>
          </CanHide>

          <li>
            <a
              href={makeGoogleMeeting(hold, diamond, selector.user)}
              target="_blank"
              rel="nofollow"
            >
              <SVG className="delete" viewBox="0 0 24 24" name="google" />
            </a>
          </li>
        </FlexEnd>

        {price && <li className="diamond-price">{formatPricing(price)}</li>}
      </ul>
    </div>
  );
};

export default DiamondHoldItem;
