import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { addItemToCart } from '../../state/action/diamondCart';
import { formatPricing, formatFloat, roughGroupPrice } from '../../utils/util';
import { selectRoughDiamondGroup } from '../../state/roughDiamondGroups/actions';
import { fetchRoughDiamonds } from '../../api';
import SVG from '../SVG';
import DiamondFoundryCaption from '../DiamondFoundryCaption';
import RoughDiamondList from './RoughDiamondList';
import { roughDiamondComparator } from '../../utils/roughDiamondComparison';

class RoughDiamondGroupListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      roughDiamonds: [],
      activeSortParameter: null,
      reverseSort: false,
      isSorting: false,
    };

    this.addToCart = this.addToCart.bind(this);
    this.getRoughDiamonds = this.getRoughDiamonds.bind(this);
    this.handleApplyRoughDiamondSort = this.handleApplyRoughDiamondSort.bind(
      this
    );
    this.handleRowClick = this.handleRowClick.bind(this);
    this.selectRoughDiamondGroup = this.selectRoughDiamondGroup.bind(this);
    this.sortRoughDiamonds = this.sortRoughDiamonds.bind(this);
  }

  addToCart(e) {
    this.props.dispatch(
      addItemToCart(
        {
          id: this.props.id,
          name: this.props.name,
          netsuite_id: this.props.netsuite_id,
          price: roughGroupPrice(
            this.props.parcel_price,
            this.props.default_price
          ),
          carat_weight_mean: this.props.carat_weight_mean,
          carat_weight_sum: this.props.carat_weight_sum,
          estimated_polish_color: this.props.estimated_polish_color,
          quantity: this.props.quantity,
          orderType: 'RoughDiamondGroup',
        },
        isDuplicate => {
          if (isDuplicate) {
            this.props.showToaster('Item already exists!');
          } else {
            this.props.showToaster('This item has been added to your cart.');
            setTimeout(() => {
              document
                .querySelector('.cart-icon-wrapper')
                .dispatchEvent(new Event('mouseenter'));
            });
          }
        }
      )
    );
  }

  async getRoughDiamonds() {
    if (this.state.roughDiamonds.length > 0) return null;

    const roughDiamonds = await fetchRoughDiamonds(this.props.id);
    this.setState({ roughDiamonds: roughDiamonds });
  }

  sortRoughDiamonds() {
    // If there is no sort parameter, then there is no need to sort.
    if (!this.state.activeSortParameter) {
      return;
    }

    if (this.state.isSorting === true) {
      const sortedRoughDiamonds = this.state.roughDiamonds.sort(
        (diamondA, diamondB) => {
          return roughDiamondComparator(
            diamondA,
            diamondB,
            this.state.activeSortParameter
          );
        }
      );

      if (this.state.reverseSort) {
        sortedRoughDiamonds.reverse();
      }

      this.setState({
        roughDiamonds: sortedRoughDiamonds,
        isSorting: false,
      });
    }
  }

  handleApplyRoughDiamondSort(newSortParameter) {
    const { activeSortParameter, reverseSort } = this.state;

    if (newSortParameter === activeSortParameter) {
      this.setState({
        activeSortParameter: newSortParameter,
        reverseSort: !reverseSort,
        isSorting: true,
      });
    } else {
      this.setState({
        activeSortParameter: newSortParameter,
        reverseSort: false,
        isSorting: true,
      });
    }
    this.sortRoughDiamonds();
  }

  handleChange() {
    /*
      This is necessary because React enforces having an onChange handler on a checkbox
      where you are setting the checked value programmatically. We want to handle
      the click event, rather than the change event, because the click event is also 
      used to expand the row. So we need to handle both events in either case.
    */
  }

  // This is fired when the row is clicked. It toggles the more-info panel.
  handleRowClick() {
    if (!this.state.expanded) {
      this.getRoughDiamonds();
    }
    this.setState({ expanded: !this.state.expanded });
  }

  // This is fired when the check box is clicked.
  // This marks the rough diamond as selected.
  selectRoughDiamondGroup(e, id) {
    e.stopPropagation();
    this.props.dispatch(selectRoughDiamondGroup(id));
  }

  render() {
    let isSelected = this.props.selected || false;
    let price = formatPricing(
      roughGroupPrice(this.props.parcel_price, this.props.default_price)
    );

    // Below, it is necessary to prepend "r" to the rough diamond id, because HTML element IDs cannot start with a number. So if we want to generate valid HTML, then we must start with a letter.
    return (
      <div
        className={
          this.state.expanded
            ? 'RoughDiamondGroupListItem DiamondListItem DiamondListItem__expanded'
            : 'RoughDiamondGroupListItem DiamondListItem'
        }
        id={'p' + this.props.id}
      >
        <div className="tr" onClick={this.handleRowClick}>
          <div className="td checkbox-cell">
            <div className="checkbox-container">
              <input
                type="checkbox"
                className="form-check-input"
                checked={isSelected}
                onChange={this.handleChange}
                onClick={e => this.selectRoughDiamondGroup(e, this.props.id)}
              />
            </div>
          </div>
          <div className="td">{this.props.name}</div>
          <div className="td">{this.props.quantity}</div>
          <div className="td">{formatFloat(this.props.carat_weight_sum)}</div>
          <div className="td">{formatFloat(this.props.carat_weight_mean)}</div>
          <div className="td">{this.props.estimated_polish_color}</div>
          <div className="td">{price}</div>
        </div>

        {this.state.expanded && (
          <div className="DiamondListItem__dropdown">
            <div className="close__container">
              <SVG
                className="Svg close"
                onClick={this.handleRowClick}
                name="close-diamond-table"
                viewBox="0 0 34 32"
              />
            </div>

            <div className="DiamondListItem__column detail">
              <span className="diamond-header headerinfo">{`Rough Parcel ${this.props.name.toUpperCase()} - ${price}`}</span>
              <RoughDiamondList
                activeSortParameter={this.state.activeSortParameter}
                applyRoughDiamondSort={this.handleApplyRoughDiamondSort}
                loading={this.state.roughDiamonds.length === 0}
                reverseSort={this.state.reverseSort}
                roughDiamonds={this.state.roughDiamonds}
              />
              <div className="cart-cta__container">
                <Button className="df-cta" onClick={this.addToCart}>
                  ADD TO CART
                </Button>
                <DiamondFoundryCaption />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect()(RoughDiamondGroupListItem);
