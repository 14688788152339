import React, { Component } from 'react';
import PropTypes from 'prop-types';
const $ = window.$;

const propTypes = {
  spriteImageUrl: PropTypes.string.isRequired,
  isOpenedRow: PropTypes.bool.isRequired,
};

class DiamondSprite extends Component {
  constructor(props) {
    super(props);
    this.$spinnerEl = null;
    this.startSpinner = this.startSpinner.bind(this);
    this.stopSpinner = this.stopSpinner.bind(this);
  }

  componentDidMount() {
    this.$spinnerEl = $(this.spriteDivRef);
    if (this.props.isOpenedRow) {
      this.startSpinner();
    }
  }

  componentWillUnmount() {
    this.stopSpinner();
  }

  // listens to opening and closing of rows to tear down spinner as well to not impact performance
  componentDidUpdate(prevProps) {
    if (this.props.isOpenedRow && !prevProps.isOpenedRow) {
      this.startSpinner();
    } else if (!this.props.isOpenedRow && prevProps.isOpenedRow) {
      this.stopSpinner();
    }
  }

  startSpinner() {
    this.$spinnerEl.spritespin({
      source: this.props.spriteImageUrl,
      frames: 100,
      framesX: 6,
      width: 293,
      height: 293,
      responsive: true,
      retainAnimate: true,
      frameTime: 160,
      sense: -1,
      plugins: ['360', 'move'],
    });
  }

  stopSpinner() {
    this.$spinnerEl.spritespin('destroy');
  }

  render() {
    // Jquery and SpriteSpinner plugin come from _document.js - loaded as classic scripts in the head
    // eslint-disable-next-line no-undef
    return (
      <div
        id="diamond-video-360"
        ref={spriteDivRef => (this.spriteDivRef = spriteDivRef)}
      />
    );
  }
}

DiamondSprite.propTypes = propTypes;

export default DiamondSprite;
