import { SET_WATCHES, DELETE_WATCH } from './constants';
import { deleteUserWatch } from '../../api/index';

// updates/sets redux store watches
export const setWatches = watches => {
  return {
    type: SET_WATCHES,
    payload: watches.fetchedWatches,
  };
};

export const deleteWatch = watch => {
  const updatedWatches = deleteUserWatch(watch.user_id, watch.id);

  if (updatedWatches) {
    return {
      type: DELETE_WATCH,
      payload: watch,
    };
  }
};
