import React, { Component } from 'react';
import { connect } from 'react-redux';

import { formatFloat, formatPlanDiamonds } from '../../utils/util';

class RoughDiamondListItem extends Component {
  constructor(props) {
    super(props);
  }

  handleChange() {
    /*
      This is necessary because React enforces having an onChange handler on a checkbox
      where you are setting the checked value programmatically. We want to handle
      the click event, rather than the change event, because the click event is also 
      used to expand the row. So we need to handle both events in either case.
    */
  }

  render() {
    // Below, it is necessary to prepend "r" to the rough diamond id, because HTML element IDs cannot start with a number. So if we want to generate valid HTML, then we must start with a letter.
    return (
      <div className="DiamondListItem" id={'r' + this.props.id}>
        <div className="tr">
          <div className="td">
            {this.props.block_id || this.props.item_name}
          </div>
          <div className="td">{formatFloat(this.props.carat_weight)}</div>
          <div className="td">{this.props.estimated_polish_color}</div>
          <div className="td">
            {formatPlanDiamonds(
              this.props.plan_quantity_1,
              this.props.plan_weight_1,
              this.props.plan_shape_1
            )}
          </div>
          <div className="td">
            {formatPlanDiamonds(
              this.props.plan_quantity_2,
              this.props.plan_weight_2,
              this.props.plan_shape_2
            )}
          </div>
          <div className="td">
            {formatPlanDiamonds(
              this.props.plan_quantity_3,
              this.props.plan_weight_3,
              this.props.plan_shape_3
            )}
          </div>
          <div className="td">
            {formatPlanDiamonds(
              this.props.plan_quantity_4,
              this.props.plan_weight_4,
              this.props.plan_shape_4
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(RoughDiamondListItem);
