import React from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import { navigate } from '../utils/routerHistory';
import SVG from './SVG';
import Sidenav from './Sidenav';
import Minicart from './Minicart';
import OnBehalfOfBanner from './OnBehalfOfBanner';
import { disableScrollY, enableScrollY } from '../utils/util';
import { salesOrAdmin } from '../utils/util';

// This is the top bar that contains the cart icon and the hamburger menu.
class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navClickEnabled: true,
      navOpen: false,
      searchOpen: false,
      miniCartOpen: false,
    };

    this.disableNavClick = this.disableNavClick.bind(this);
    this.enableNavClick = this.enableNavClick.bind(this);

    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.toggleSidenav = this.toggleSidenav.bind(this);
    this.openSearch = this.openSearch.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.openMiniCart = this.openMiniCart.bind(this);
    this.closeMiniCart = this.closeMiniCart.bind(this);
    this.goToCartFromMinicart = this.goToCartFromMinicart.bind(this);
    this.goToCheckoutFromMinicart = this.goToCheckoutFromMinicart.bind(this);
  }

  disableNavClick() {
    if (this.state.navClickEnabled) {
      this.setState({ navClickEnabled: false });
    }
  }

  enableNavClick() {
    if (!this.state.navClickEnabled) {
      this.setState({ navClickEnabled: true });
    }
  }

  openNav(e) {
    e.preventDefault();
    document.getElementById('root').style.marginLeft = '-250px';
    disableScrollY();
    this.setState({ navOpen: true });
  }

  closeNav(e) {
    e.preventDefault();
    document.getElementById('root').style.marginLeft = '0';
    enableScrollY();
    this.setState({ navOpen: false });
  }

  toggleSidenav(e) {
    this.state.navOpen ? this.closeNav(e) : this.openNav(e);
  }

  openSearch(e) {
    if (this.state.navClickEnabled) {
      this.setState({ searchOpen: true });
      this.disableNavClick();
    }
  }

  closeSearch(e) {
    e.preventDefault();
    this.setState({ searchOpen: false });
    this.enableNavClick();
  }

  openMiniCart() {
    this.setState({ miniCartOpen: true });
  }

  closeMiniCart() {
    this.setState({ miniCartOpen: false });
  }

  goToCart() {
    navigate('/cart');
  }

  goToCartFromMinicart() {
    this.setState({ miniCartOpen: false });
    this.goToCart();
  }

  goToCheckoutFromMinicart() {
    this.setState({ miniCartOpen: false });
    navigate('/checkout');
  }

  render() {
    const { auth } = this.props;
    const { navOpen } = this.state;

    return (
      <div className="Nav_wrapper" onMouseLeave={this.closeMiniCart}>
        {navOpen && (
          <div
            className={`Sidebar__overlay ${navOpen && 'active'}`}
            onClick={this.closeNav}
          />
        )}

        <div className="Nav">
          <div className="Navbar">
            <Link to="/">
              <SVG
                className="Navbar__logo df-show-nonmobile-only "
                viewBox="0 0 400 45"
                name="logo-transparent"
              />

              <SVG
                className="Navbar__logo__mobile df-show-mobile"
                viewBox="0 0 400 400"
                name="logo-transparent-collapse"
              />
            </Link>
            <div className="Navbar_menu">
              <ul id="Desktopnav" className="unstyled df-show-desktop-only">
                {this.props.navItems.forEach((navItem, index) => {
                  if (navItem.title !== 'cart') {
                    return (
                      <li key={`nav-item-${index}`} className="nav-link">
                        <Link
                          getProps={({ isCurrent }) => {
                            return {
                              className: isCurrent ? 'active' : '',
                            };
                          }}
                          to={navItem.url}
                        >
                          {navItem.title}
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>

            <div className="Navbar__icons">
              <OnBehalfOfBanner
                user={this.props.auth.user}
                onBehalfOfUserName={this.props.auth.user.on_behalf_of_user_name}
              />

              {this.props.totalCartItems > 0 && (
                <span id="cart-count">{this.props.totalCartItems}</span>
              )}
              <>
                <span
                  className="df-show-desktop-only cart-icon-wrapper"
                  onMouseEnter={this.openMiniCart}
                  onClick={this.goToCart}
                >
                  <SVG
                    className={
                      this.state.miniCartOpen
                        ? 'Svg cart df-show-desktop-only active'
                        : 'Svg cart df-show-desktop-only'
                    }
                    name="cart"
                    viewBox="0 0 600 600"
                  />
                </span>

                <span onClick={this.goToCart}>
                  <SVG
                    className="Svg cart df-show-nondesktop-only cart-icon-wrapper"
                    name="cart"
                    viewBox="0 0 600 600"
                  />
                </span>
              </>

              <span onClick={this.openNav}>
                <SVG
                  className="Svg hamburger"
                  name="hamburger"
                  viewBox="0 0 600 600"
                />
              </span>
            </div>
          </div>

          <Sidenav
            navOpen={navOpen}
            toggleNav={this.toggleSidenav}
            closeNav={this.closeNav}
            salesOrAdmin={salesOrAdmin(auth.user.roles)}
            navItems={this.props.navItems}
          />

          {this.state.miniCartOpen && (
            <Minicart
              closeMiniCart={this.closeMiniCart}
              goToCartFromMinicart={this.goToCartFromMinicart}
              goToCheckoutFromMinicart={this.goToCheckoutFromMinicart}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    totalCartItems: state.diamondCart.cart.length,
  };
};

export default connect(mapStateToProps)(Nav);
