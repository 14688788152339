import React from 'react';
import { Link } from '@reach/router';
import { formatDateRelative } from './dateUtilities';
import currency from 'currency.js';

export const userColumns = () => {
  return [
    {
      name: 'User',
      title: 'User',
      getCellValue: row => {
        if (!row.first_name || !row.last_name) {
          return row.email;
        }

        return row.first_name + ' ' + row.last_name;
      },
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: row =>
        row.pending_confirmation ? 'PENDING CONFIRMATION' : row.status,
    },
    {
      name: 'company_name',
      title: 'Company',
      getCellValue: row => {
        const customerAccount = row.customer_account;

        if (customerAccount) {
          return (
            <Link
              className="df-link flex"
              to={`/admin/customers/${customerAccount.id}`}
            >
              {row.customer_account ? customerAccount.company_name : ''}
            </Link>
          );
        } else {
          return '';
        }
      },
    },
    {
      name: 'last_active_at',
      title: 'Last Active At',
      getCellValue: row =>
        row.last_active_at ? formatDateRelative(row.last_active_at) : 'Never',
    },
    {
      name: 'created_at',
      title: 'Created At',
      getCellValue: row =>
        row.created_at ? formatDateRelative(row.created_at) : '',
    },
  ];
};

export const compareLinkText = (valueA, valueB) => {
  let a = (((valueA || {}).props || {}).children || '').toLowerCase() || '';
  let b = (((valueB || {}).props || {}).children || '').toLowerCase() || '';

  if (a === b) return 0;
  // put empty value at the end
  if (a === '') return 1;
  if (b === '') return -1;
  return a < b ? -1 : 1;
};

// This method puts the highest amount first.
export const compareCurrency = (currencyValueA, currencyValueB) => {
  // here currency.js currency method is used to go from String currency representation with non numeric characters to numeric
  // ex: "-$4,520,234.34" to -4520234.34
  const a = currency(currencyValueA).value;
  const b = currency(currencyValueB).value;

  if (a === b) {
    return 0;
  }
  return a > b ? -1 : 1;
};
