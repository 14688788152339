import { getPriceRange, getCaratRange } from './diamondRangeFilter';
import { getFilterGridShapeListLowercaseWithoutOther } from '../../utils/shapeUtilities';

const addActionType = {
  shape: 'ADD_SHAPE_FILTER',
  color: 'ADD_COLOR_FILTER',
  cut: 'ADD_CUT_FILTER',
  clarity: 'ADD_CLARITY_FILTER',
  price: 'ADD_PRICE_FILTER',
  carat: 'ADD_CARAT_FILTER',
};

const removeActionType = {
  shape: 'REMOVE_SHAPE_FILTER',
  color: 'REMOVE_COLOR_FILTER',
  cut: 'REMOVE_CUT_FILTER',
  clarity: 'REMOVE_CLARITY_FILTER',
  price: 'REMOVE_PRICE_FILTER',
  carat: 'REMOVE_CARAT_FILTER',
};

export const addSimpleFilter = (type, value) => {
  return (dispatch, getState) => {
    dispatch({ type: addActionType[type], value: value });

    const state = getState();
    const diamondsToFilter = state.diamonds.original;

    const filteredDiamonds = applySearchFilters(diamondsToFilter, {
      ...state.filters,
      searchKey: state.diamonds.searchKey,
      isgcalchecked: state.diamonds.isgcalchecked,
    });

    dispatch({ type: 'SET_DIAMONDS_TO_RENDER', diamonds: filteredDiamonds });
    dispatch(getCaratRange({ diamonds: filteredDiamonds }));
    dispatch(getPriceRange({ diamonds: filteredDiamonds }));
  };
};

export const removeSimpleFilter = (type, value) => {
  return (dispatch, getState) => {
    dispatch({ type: removeActionType[type], value: value });

    const state = getState();
    const diamondsToFilter = state.diamonds.original;

    const filteredDiamonds = applySearchFilters(diamondsToFilter, {
      ...state.filters,
      searchKey: state.diamonds.searchKey,
      isgcalchecked: state.diamonds.isgcalchecked,
    });

    dispatch({ type: 'SET_DIAMONDS_TO_RENDER', diamonds: filteredDiamonds });
    dispatch(getCaratRange({ diamonds: filteredDiamonds }));
    dispatch(getPriceRange({ diamonds: filteredDiamonds }));
  };
};

export const addShapeCaratFilter = value => {
  return (dispatch, getState) => {
    dispatch({ type: 'ADD_SHAPECARAT_FILTER', value: value });

    const state = getState();
    const diamondsToFilter = state.diamonds.original;

    const filteredDiamonds = applySearchFilters(diamondsToFilter, {
      ...state.filters,
      searchKey: state.diamonds.searchKey,
      isgcalchecked: state.diamonds.isgcalchecked,
    });

    dispatch({ type: 'SET_DIAMONDS_TO_RENDER', diamonds: filteredDiamonds });
    dispatch(getCaratRange({ diamonds: filteredDiamonds }));
    dispatch(getPriceRange({ diamonds: filteredDiamonds }));
  };
};

export const removeShapeCaratFilter = value => {
  return (dispatch, getState) => {
    dispatch({ type: 'REMOVE_SHAPECARAT_FILTER', value: value });

    const state = getState();
    const diamondsToFilter = state.diamonds.original;

    const filteredDiamonds = applySearchFilters(diamondsToFilter, {
      ...state.filters,
      searchKey: state.diamonds.searchKey,
      isgcalchecked: state.diamonds.isgcalchecked,
    });

    dispatch({ type: 'SET_DIAMONDS_TO_RENDER', diamonds: filteredDiamonds });
    dispatch(getCaratRange({ diamonds: filteredDiamonds }));
    dispatch(getPriceRange({ diamonds: filteredDiamonds }));
  };
};

export const applySearchFilters = (diamonds, filters) => {
  let diamondsToFilter = diamonds;

  // apply search on the list
  if (filters.searchKey !== '') {
    diamondsToFilter = diamondsToFilter.filter(
      diamond =>
        String(diamond.lot_id).startsWith(filters.searchKey.toLowerCase()) ||
        String(`f${diamond.lot_id}`).startsWith(filters.searchKey.toLowerCase())
    );
  }

  // filter by gcal
  if (filters.isgcalchecked === true) {
    diamondsToFilter = diamondsToFilter.filter(
      diamond => diamond.gcal_cert_id !== null
    );
  }

  // filter by clarity - DEPRECATED
  if (filters.clarity.length > 0) {
    diamondsToFilter = diamondsToFilter.filter(
      diamond => filters.clarity.indexOf(diamond.clarity) > -1
    );
  }

  // filter by cut - DEPRECATED
  if (filters.cut.length > 0) {
    diamondsToFilter = diamondsToFilter.filter(
      diamond => filters.cut.indexOf(diamond.cut_grade) > -1
    );
  }

  // filter by price - DEPRECATED
  if (filters.price.length > 0) {
    diamondsToFilter = diamondsToFilter.filter(
      diamond =>
        filters.price[0] <= diamond.unit_price_msrp_usd * 1 &&
        diamond.unit_price_msrp_usd * 1 <= filters.price[1]
    );
  }

  // filter by carat weight - DEPRECATED
  if (filters.carat.length > 0) {
    diamondsToFilter = diamondsToFilter.filter(
      diamond =>
        filters.carat[0] <= diamond.carat && diamond.carat <= filters.carat[1]
    );
  }

  // filter by selected shapes - DEPRECATED
  if (filters.shape.length > 0) {
    diamondsToFilter = diamondsToFilter.filter(
      diamond => filters.shape.indexOf(diamond.shape) > -1
    );
  }

  // filter by color
  if (filters.color.length > 0) {
    diamondsToFilter = diamondsToFilter.filter(diamond =>
      matchesColorFilters(diamond, filters.color)
    );
  }

  // filter by shape AND carat
  if (!!filters.shapeCarat && filters.shapeCarat.length > 0) {
    diamondsToFilter = diamondsToFilter.filter(diamond =>
      matchesShapeCaratFilters(diamond, filters.shapeCarat)
    );
  }

  return diamondsToFilter;
};

// Does this diamond match the selected shape and carat filters?
const matchesShapeCaratFilters = (diamond, shapeCaratFilters) => {
  // get the list of supported shapes
  let shapeList = getFilterGridShapeListLowercaseWithoutOther();

  // for each selected shapeCarat filter item
  for (let i = 0; i < shapeCaratFilters.length; i++) {
    // if this is the other category
    if (
      shapeCaratFilters[i].shape.toLowerCase() == 'other' &&
      shapeList.indexOf(diamond.shape.toLowerCase()) == -1 &&
      shapeCaratFilters[i].carat[0] <= diamond.carat &&
      diamond.carat < shapeCaratFilters[i].carat[1]
    ) {
      return true;
    } else if (
      shapeCaratFilters[i].shape.toLowerCase() == diamond.shape.toLowerCase() &&
      shapeCaratFilters[i].carat[0] <= diamond.carat &&
      diamond.carat < shapeCaratFilters[i].carat[1]
    ) {
      return true;
    }
  }
  return false;
};

const matchesColorFilters = (diamond, colors) => {
  return (
    colors.indexOf(diamond.color) > -1 ||
    (['J', 'K', 'L', 'M'].indexOf(diamond.color) > -1 &&
      colors.indexOf('J+') > -1)
  );
};

export const setFilters = filters => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_FILTERS', filters });

    const state = getState();
    const diamondsToFilter = state.diamonds.original;

    const filteredDiamonds = applySearchFilters(diamondsToFilter, {
      ...state.filters,
      searchKey: state.diamonds.searchKey,
      isgcalchecked: state.diamonds.isgcalchecked,
    });

    dispatch({ type: 'SET_DIAMONDS_TO_RENDER', diamonds: filteredDiamonds });
    dispatch(getCaratRange({ diamonds: filteredDiamonds }));
    dispatch(getPriceRange({ diamonds: filteredDiamonds }));
  };
};

export const addSearchKey = ({ searchKey = '' }) => {
  return (dispatch, getState) => {
    dispatch({ type: 'ADD_SEARCH_KEY', searchKey });

    const state = getState();
    const diamondsToFilter = state.diamonds.original;

    const filteredDiamonds = applySearchFilters(diamondsToFilter, {
      ...state.filters,
      searchKey: state.diamonds.searchKey,
      isgcalchecked: state.diamonds.isgcalchecked,
    });
    dispatch({ type: 'SET_DIAMONDS_TO_RENDER', diamonds: filteredDiamonds });
    dispatch(getCaratRange({ diamonds: filteredDiamonds }));
    dispatch(getPriceRange({ diamonds: filteredDiamonds }));
  };
};

export const addgcalSearch = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'ADD_GCAL_CHECK' });

    const state = getState();
    const diamondsToFilter = state.diamonds.original;

    const filteredDiamonds = applySearchFilters(diamondsToFilter, {
      ...state.filters,
      searchKey: state.diamonds.searchKey,
      isgcalchecked: state.diamonds.isgcalchecked,
    });
    dispatch({ type: 'SET_DIAMONDS_TO_RENDER', diamonds: filteredDiamonds });
    dispatch(getCaratRange({ diamonds: filteredDiamonds }));
    dispatch(getPriceRange({ diamonds: filteredDiamonds }));
  };
};
