import styled from '@emotion/styled';

type Props = {
  column?: boolean;
};

const FlexEnd = styled.div<Props>`
  display: flex;
  flex-direction: ${prop => (prop.column ? 'column' : 'row')};
  justify-content: flex-end;
  align-items: center;
`;

export default FlexEnd;
