import React, { Component } from 'react';

// Consignment / Memo Terms of Service
// See https://sites.google.com/diamondfoundry.com/df-dashboard/hr/sales-policies?authuser=0
class ConsignmentTerms extends Component {
  render() {
    return (
      <div className="consignment-terms">
        <h2>Loose Diamond Memo Policy</h2>
        <ul>
          <li>
            Loose Diamonds can be ordered on memo (&quot;consignment&quot;) in
            the US and Canada only.
          </li>
          <li>
            Diamonds will be shipped on memo only upon request and subject to
            prior approval of the Diamond Foundry credit department. A credit
            card deposit may be required and will be at the sole discretion of
            Diamond Foundry.
          </li>
          <li>
            Diamonds on memo will not be shipped with the original certificates.
          </li>
          <li>
            Diamonds on memo must be shipped back to Diamond Foundry within 10
            business days of receipt, unless other terms have been agreed to by
            Diamond Foundry in advance. Diamonds on memo shipped by customer to
            Diamond Foundry must be shipped overnight or second day.
          </li>
          <li>
            Diamonds on memo not received back within 10 business days may be
            billed to the customer at Diamond Foundry’s sole discretion.
          </li>
          <li>
            The customer shall be responsible for all shipping and handling
            charges. Diamond Foundry will invoice the customer all freight
            charges incurred by Diamond Foundry, for unsold diamonds on memo
            returned to Diamond Foundry, including freight charges both ways.
            Freight charges will be waived for diamonds invoiced by Diamond
            Foundry to customer.
          </li>
          <li>
            The acceptance of a return of a memo diamond after 10 business days
            and the fees involved will be at the sole discretion of Diamond
            Foundry.
          </li>
        </ul>
      </div>
    );
  }
}

export default ConsignmentTerms;
