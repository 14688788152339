import React, { Component } from 'react';
import { connect } from 'react-redux';

import WatchList from './WatchList';

import { setWatches } from '../state/watches/actions';
import { bindActionCreators } from 'redux';
import { salesOrAdmin } from '../utils/util';

import { fetchWatches } from '../api/index';

class WatchesIndex extends Component {
  constructor(props) {
    super(props);

    this.fetchWatches = this.fetchWatches.bind(this);
  }

  componentDidMount() {
    this.fetchWatches();
  }

  componentDidUpdate(prevProps) {
    // Fetch watches after the user is loaded. This is necessary when loading this page directly from a link.
    if (prevProps.auth.user.id !== this.props.auth.user.id) {
      this.fetchWatches();
    }
  }

  async fetchWatches() {
    if (!!this.props.auth.user.id) {
      let userId = this.props.auth.user.id;

      // For admin/UserWatchesPage any user id can be passed as prop userId
      // Alternatively, when no userId is passed down the accessing user's watches will be fetched
      // The userId prop will be undefined when the user is not an admin, or when an admin wishes to view their own watch collection as a customer would
      if (
        this.props.userId &&
        this.props.auth.user &&
        salesOrAdmin(this.props.auth.user.roles)
      ) {
        userId = this.props.userId;
      }

      const fetchedWatches = await fetchWatches(userId);
      this.props.dispatch(setWatches({ fetchedWatches }));
    }
  }

  render() {
    let watches = this.props.watches || [];

    return (
      <main>
        <h1 className="title">
          {this.props.userId ? 'User Notifiers' : 'My Notifiers'}
        </h1>

        {watches && <WatchList watches={watches} />}
      </main>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ setWatches }, dispatch),
  };
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.auth.user,
    watches: state.watches,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchesIndex);
