import React from 'react';
import DiamondListSortIndicator from '../DiamondListSortIndicator';

// This is the header row for the RoughDiamondList component.
class RoughDiamondListHeader extends React.Component {
  render() {
    return (
      <div className="thead">
        <div className="tr">
          <div
            onClick={() => this.props.applyRoughDiamondSort('block_id')}
            className="th"
          >
            ID
            <DiamondListSortIndicator
              fieldName="block_id"
              activeSortParam={this.props.activeSortParameter}
              isReversedParam={this.props.reverseSort}
            />
          </div>

          <div
            onClick={() => this.props.applyRoughDiamondSort('carat_weight')}
            className="th"
          >
            Carat
            <DiamondListSortIndicator
              fieldName="carat_weight"
              activeSortParam={this.props.activeSortParameter}
              isReversedParam={this.props.reverseSort}
            />
          </div>

          <div
            onClick={() =>
              this.props.applyRoughDiamondSort('estimated_polish_color')
            }
            className="th"
          >
            Est. Color
            <DiamondListSortIndicator
              fieldName="estimated_polish_color"
              activeSortParam={this.props.activeSortParameter}
              isReversedParam={this.props.reverseSort}
            />
          </div>

          <div
            onClick={() => this.props.applyRoughDiamondSort('plan_weight_1')}
            className="th"
          >
            Plan Dmnd 1
            <DiamondListSortIndicator
              fieldName="plan_weight_1"
              activeSortParam={this.props.activeSortParameter}
              isReversedParam={this.props.reverseSort}
            />
          </div>

          <div
            onClick={() => this.props.applyRoughDiamondSort('plan_weight_2')}
            className="th"
          >
            Plan Dmnd 2
            <DiamondListSortIndicator
              fieldName="plan_weight_2"
              activeSortParam={this.props.activeSortParameter}
              isReversedParam={this.props.reverseSort}
            />
          </div>

          <div
            onClick={() => this.props.applyRoughDiamondSort('plan_weight_3')}
            className="th"
          >
            Plan Dmnd 3
            <DiamondListSortIndicator
              fieldName="plan_weight_3"
              activeSortParam={this.props.activeSortParameter}
              isReversedParam={this.props.reverseSort}
            />
          </div>

          <div
            onClick={() => this.props.applyRoughDiamondSort('plan_weight_4')}
            className="th"
          >
            Plan Dmnd 4
            <DiamondListSortIndicator
              fieldName="plan_weight_4"
              activeSortParam={this.props.activeSortParameter}
              isReversedParam={this.props.reverseSort}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RoughDiamondListHeader;
