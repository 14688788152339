import { SET_WATCHES, DELETE_WATCH } from './constants';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WATCHES:
      return [...action.payload];
    case DELETE_WATCH:
      return state.filter(watch => watch.id !== action.payload.id);
    default:
      return state;
  }
};
