import React, { Component } from 'react';
import { connect } from 'react-redux';
import BackButton from '../components/BackButton';
import WatchesIndex from '../components/WatchesIndex';
import { isInternalUser } from '../utils/util';

class WatchesPage extends Component {
  render() {
    let internalUser = isInternalUser(this.props.auth.user.roles);
    return (
      <main>
        <div className="user-details-page main-wrapper">
          {!!this.props.userId &&
            this.props.userId !== this.props.auth.user.id &&
            internalUser && (
              <BackButton
                link={`/admin/users/${this.props.userId}`}
                text="User Details"
              />
            )}

          <WatchesIndex
            // passed down as prop from User Details page (when admin is viewing an user)
            userId={this.props.userId}
          />
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(WatchesPage);
