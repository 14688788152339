import { formatUSD, formatFloat, roughGroupPrice } from './util';
import { cloneDeep } from 'lodash';

// This is the columns that go into the CSV.
export const getCSVHeaders = () => {
  return [
    { label: 'lot_id', key: 'lot_id' },
    { label: 'shape', key: 'shape' },
    { label: 'carat', key: 'carat' },
    { label: 'color', key: 'color' },
    { label: 'clarity', key: 'clarity' },
    { label: 'cut', key: 'cut_grade' },
    { label: 'polish', key: 'polish' },
    { label: 'symmetry', key: 'symmetry' },
    { label: 'depth_pct', key: 'depth_pct' },
    { label: 'table diameter', key: 'table_size' },
    { label: 'girdle', key: 'girdle' },
    { label: 'crown_angle', key: 'crown_angle' },
    { label: 'crown_height', key: 'crown_height' },
    { label: 'pavilion_angle', key: 'pavilion_angle' },
    { label: 'pavilion_height', key: 'pavilion_height' },
    { label: 'length_mm', key: 'length_mm' },
    { label: 'width_mm', key: 'width_mm' },
    { label: 'depth_mm', key: 'depth_mm' },
    { label: 'quantity', key: 'quantity' },
    { label: 'cert_url', key: 'cert_url' },
    { label: 'graded_by', key: 'graded_by' },
    { label: 'msrp', key: 'unit_price_msrp_usd' },
    { label: 'discount_level', key: 'price_name' },
    { label: 'cost', key: 'price_amount_usd' },
    { label: 'video', key: 'video_url' },
  ];
};

export const getCSVData = diamonds => {
  const data = cloneDeep(diamonds);
  return data.map(item => {
    item.lot_id = `F${item.lot_id}`;
    item.depth_pct += '%';
    item.crown_height = `${item.crown_height}%`;
    item.pavilion_height = `${item.pavilion_height}%`;
    item.table_size = `${item.table_size}%`;
    item.unit_price_msrp_usd = formatUSD(item.unit_price_msrp_usd).split(
      '.'
    )[0];

    // Calculate the price_amount_usd field
    if (item.prices[0] !== null) {
      item.price_amount_usd = formatUSD(item.prices[0].amount_usd).split(
        '.'
      )[0];
      item.price_name = item.prices[0].name;
    }

    // Calculate video_url
    item.video_url = '';
    if (
      item.digital_assets &&
      item.digital_assets[0] !== null &&
      typeof item.digital_assets[0] !== 'undefined'
    ) {
      item.video_url = item.digital_assets[0].url;
    }
    return item;
  });
};

// This is the columns that go into the CSV.
export const getRoughCSVHeaders = () => {
  return [
    { label: 'id', key: 'netsuite_id' },
    { label: 'blocks', key: 'quantity' },
    { label: 'carats', key: 'carat_weight_sum' },
    { label: 'avg. carats', key: 'carat_weight_mean' },
    { label: 'est. color', key: 'estimated_polish_color' },
    { label: 'price', key: 'default_price' },
  ];
};

export const getRoughCSVData = diamonds => {
  const data = cloneDeep(diamonds);
  return data.map(item => {
    item.carat_weight_sum = formatFloat(item.carat_weight_sum);
    item.carat_weight_mean = formatFloat(item.carat_weight_mean);
    item.default_price = formatUSD(
      roughGroupPrice(item.parcel_price || item.default_price)
    );

    return item;
  });
};
