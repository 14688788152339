const initialState = true;

export default (state = initialState, action) => {
  switch (action.type) {
    case 'IS_DIAMONDS_LOADING': {
      const { isDiamondsLoading } = action.payload;

      return isDiamondsLoading;
    }
    case 'USER_SIGNOUT':
      return action.state;
    default: {
      return state;
    }
  }
};
