const diamondRangeFilterReducerDefaultState = {
  carat: [],
  price: [],
};

// const diamondCaratFilterReducerDefaultState = [];
export default (state = diamondRangeFilterReducerDefaultState, action) => {
  switch (action.type) {
    case 'GET_CARAT_RANGE':
      // action.diamonds should have arrays
      let minCarat = 0;
      let maxCarat = 0;
      if (action.diamonds && action.diamonds.length > 0) {
        minCarat = action.diamonds.reduce(
          (min, diamond) => (diamond.carat < min ? diamond.carat : min),
          action.diamonds[0].carat
        );
        // This sets the max to the maximum in the available diamonds.
        // maxCarat = action.diamonds.reduce(
        //   (max, diamond) => (diamond.carat > max ? diamond.carat : max),
        //   action.diamonds[0].carat
        // );

        // We want to use a larger max just in case people want to set a notifier/watch for a larger diamond.
        maxCarat = 6.0;
      }
      return { ...state, carat: [minCarat, maxCarat] };
    case 'SET_CARAT_FILTER':
      // TODO: FILTER
      return {
        ...state,
        carat: [action.rangeFilter[0], action.rangeFilter[1]],
      };
    case 'GET_PRICE_RANGE':
      let minPrice = 0;
      let maxPrice = 0;
      // action.diamonds should have arrays
      if (action.diamonds && action.diamonds.length > 0) {
        minPrice = action.diamonds.reduce(
          (min, diamond) =>
            diamond.unit_price_msrp_usd * 1 < min
              ? diamond.unit_price_msrp_usd * 1
              : min * 1,
          action.diamonds[0].unit_price_msrp_usd * 1
        );
        maxPrice = action.diamonds.reduce(
          (max, diamond) =>
            diamond.unit_price_msrp_usd * 1 > max
              ? diamond.unit_price_msrp_usd * 1
              : max * 1,
          action.diamonds[0].unit_price_msrp_usd * 1
        );
      }
      return { ...state, price: [minPrice, maxPrice] };
    case 'SET_PRICE_FILTER':
      // TODO: FILTER

      return {
        ...state,
        price: [action.rangeFilter[0], action.rangeFilter[1]],
      };
    default:
      return state;
  }
};
