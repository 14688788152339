import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InnerFilterColorCell from './ui/InnerFilterColorCell';
import {
  addSimpleFilter,
  removeSimpleFilter,
} from '../state/action/diamondFilters';
import RootState, { DiamondFilterState } from '../types/redux/rootState';

type Props = {
  name: string;
  type: string;
};

// This is only used by FilterColor as of Spring 2020.
const DiamondFilterButton = ({ name, type }: Props) => {
  const dispatch = useDispatch();
  const selector = useSelector<RootState, DiamondFilterState>(
    state => state.filters
  );
  const isSelected = () => {
    return selector.color.includes(name);
  };
  const toggleFilter = () => {
    if (isSelected()) {
      dispatch(removeSimpleFilter(type, name));
    } else {
      dispatch(addSimpleFilter(type, name));
    }
  };

  return (
    <a className="DiamondFilter-color" onClick={toggleFilter}>
      <InnerFilterColorCell selected={isSelected()} className={`df-cta`}>
        {name}
      </InnerFilterColorCell>
    </a>
  );
};

export default DiamondFilterButton;
