const DF_API_HOST = 'https://rest.diamondfoundry.com';
const STRIPE_PUBLIC_KEY = 'pk_live_Q1p2gFrka31ivzvZ1WBLIFca';

const USER_PRINTABLE_INVOICE = true;
const ADMIN_PRINTABLE_INVOICE = true;

module.exports = {
  DF_API_HOST,
  STRIPE_PUBLIC_KEY,
  USER_PRINTABLE_INVOICE,
  ADMIN_PRINTABLE_INVOICE,
};
