import React from 'react';
import PropTypes from 'prop-types';

const BillToAddress = ({ address, companyName }) => {
  const addressLine1 = [];
  const { name, street_address, country, phone } = address;
  const { state = '', city = '', zip = '' } = address;

  if (city) {
    addressLine1.push(city);
  }
  if (state) {
    addressLine1.push(state);
  }
  if (zip) {
    addressLine1.push(zip);
  }
  return (
    <div className="bill-to-address">
      <div className="bill-address-heading">Bill To:</div>
      <div className="bill-address-detail">
        <div>{companyName}</div>
        <div>{name}</div>
        <div>{street_address}</div>
        <div>{addressLine1.toString()}</div>
        <div>{country}</div>
        <div>{phone}</div>
      </div>
    </div>
  );
};

BillToAddress.propTypes = {
  address: PropTypes.shape({
    name: PropTypes.string,
    street_address: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    phone: PropTypes.string,
    state: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired,
  companyName: PropTypes.string.isRequired,
};

export default BillToAddress;
