export const SET_ROUGH_DIAMOND_GROUPS = 'SET_ROUGH_DIAMOND_GROUPS';
export const SET_ROUGH_DIAMOND_GROUPS_TO_RENDER =
  'SET_ROUGH_DIAMOND_GROUPS_TO_RENDER';
export const SELECT_ROUGH_DIAMOND_GROUP = 'SELECT_ROUGH_DIAMOND_GROUP';
export const SELECT_ALL_ROUGH_DIAMOND_GROUPS =
  'SELECT_ALL_ROUGH_DIAMOND_GROUPS';
export const DESELECT_ALL_ROUGH_DIAMOND_GROUPS =
  'DESELECT_ALL_ROUGH_DIAMOND_GROUPS';
export const ADD_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER =
  'ADD_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER';
export const REMOVE_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER =
  'REMOVE_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER';
export const CLEAR_ROUGH_DIAMOND_GROUP_FILTERS =
  'CLEAR_ROUGH_DIAMOND_GROUP_FILTERS';
