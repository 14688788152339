import React from 'react';
// @ts-ignore
import { Button } from 'reactstrap';
import PolishedDiamondsFilterColorCart from './PolishedDiamondsFilterColorCart';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../types/redux/rootState';
import { PolishedDiamondGroupState } from '../../state/polishedDiamondGroups/reducer';
import {
  addFilter,
  removeFilter,
} from '../../state/polishedDiamondGroups/actions';
import PolishedFilterColor from './PolishedFilterColor';

const OuterContainer = styled.div`
  .FilterColor {
    display: flex;
    justify-content: left;
    margin: 10px 0;
    padding: 0;
    .legend {
      font-size: 1.4rem;
      font-family: 'Helvetica Now Display';
      font-weight: 700;
      max-width: 94px;
      width: 94px;
      padding: 5px 5px;
    }
    .DiamondFilter-color {
      font-size: 1.6rem;
      margin: 0 10px 0 0;
      .active {
        background-color: #ff3c00;
        color: white;
      }
    }
  }
  .SpecificationsLinkContainer {
    text-align: center;
    margin-top: 10px;
  }
`;
type Props = {
  onClearFilters: () => void;
  showClearFiltersButton: boolean;
  onPlaceOneTimeOrder?: () => void;
};

const PolishedDiamondGroupFilterGrid = ({
  showClearFiltersButton,
  onClearFilters,
}: Props) => {
  const dispatch = useDispatch();
  const selector = useSelector<RootState, PolishedDiamondGroupState>(
    state => state.polishedDiamondGroups
  );

  return (
    <OuterContainer className="DiamondFilters DiamondFilterGrid">
      <div className="DiamondFilters__header">
        <div className="DiamondFilters__header-column">
          <PolishedFilterColor />
        </div>
      </div>
      <div className="DiamondFilters-dropdown">
        <PolishedDiamondsFilterColorCart
          filters={selector.filters}
          polishedDiamondGroups={selector.polishedDiamondsGroups}
          onRemove={f => {
            dispatch(removeFilter(f));
          }}
          onAdd={f => {
            dispatch(addFilter(f));
          }}
          stats={selector.polishedParcelsStats}
        />

        {showClearFiltersButton && (
          <div className="save-filter">
            <Button
              className="df-cta"
              viewBox="0 0 600 600"
              onClick={onClearFilters}
            >
              CLEAR
            </Button>
          </div>
        )}
      </div>
      <div className="SpecificationsLinkContainer">
        <a
          href="https://s3.amazonaws.com/static.diamondfoundry.com/portal/documents/Program+Diamonds.pdf"
          target="_blank"
        >
          Specifications
        </a>
      </div>
    </OuterContainer>
  );
};

export default PolishedDiamondGroupFilterGrid;
