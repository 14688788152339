import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  removeRoughDiamondGroupColorCaratFilter,
  addRoughDiamondGroupColorCaratFilter,
} from '../../state/roughDiamondGroups/actions';

class RoughDiamondGroupGridButton extends Component {
  constructor(props) {
    super(props);

    this.toggleFilter = this.toggleFilter.bind(this);
    this.className = this.className.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  // determine if this button is selected or not
  isSelected() {
    let colorCaratFilters = this.props.filters.colorCarat;
    if (colorCaratFilters.length > 0) {
      for (let i = 0; i < colorCaratFilters.length; i++) {
        if (
          this.colorMatch(colorCaratFilters[i].color, this.props.color) &&
          colorCaratFilters[i].caratRangeIndex === this.props.caratRangeIndex
        ) {
          return true;
        }
      }
    }

    return false;
  }

  colorMatch(filterColor, buttonColor) {
    return (
      filterColor === buttonColor ||
      (filterColor === 'J+' && ['J', 'K', 'L', 'M'].includes(buttonColor))
    );
  }

  toggleFilter(e) {
    // dispatch action that filters diamond depending on the state
    if (this.isSelected()) {
      this.props.dispatch(
        removeRoughDiamondGroupColorCaratFilter({
          color: this.props.color,
          carat: this.props.caratRange,
          caratRangeIndex: this.props.caratRangeIndex,
        })
      );
    } else {
      this.props.dispatch(
        addRoughDiamondGroupColorCaratFilter({
          color: this.props.color,
          carat: this.props.caratRange,
          caratRangeIndex: this.props.caratRangeIndex,
        })
      );
    }
  }

  className() {
    return this.isSelected() ? 'selected' : '';
  }

  render() {
    return (
      <a
        className={`DiamondGridButton DiamondGridButton-${this.props.color}-${
          this.props.caratRangeIndex
        } ${this.className()}`}
        onClick={this.toggleFilter}
      >
        {this.props.caption}
      </a>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.roughDiamondGroups.filters,
  };
};

export default connect(mapStateToProps)(RoughDiamondGroupGridButton);
