import React, { Component } from 'react';
import { connect } from 'react-redux';
import CartListItemDiamond from './CartListItemDiamond';
import CartListItemRoughGroup from './CartListItemRoughGroup';
import CartListItemPolishedGroup from './polished/CartListItemPolishedGroup';

class CartListItem extends Component {
  render() {
    if (this.props.orderType === 'RoughDiamondGroup') {
      return <CartListItemRoughGroup {...this.props} />;
    }
    if (this.props.orderType === 'PolishedParcel') {
      const item = {
        ...this.props,
      };
      const checkedOut = this.props.checkedOut;
      return <CartListItemPolishedGroup item={item} checkedOut={checkedOut} />;
    }
    return <CartListItemDiamond {...this.props} />;
  }
}

export default connect()(CartListItem);
