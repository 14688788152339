import React from 'react';
import DiamondListSortIndicator from '../DiamondListSortIndicator';

// This is the header row for the RoughDiamondGroupList component.
class RoughDiamondGroupListHeader extends React.Component {
  render() {
    return (
      <div className="thead">
        <div className="tr">
          <div className="th checkbox-header" />

          <div
            onClick={() => this.props.applyRoughDiamondGroupSort('name')}
            className="th"
          >
            <div className="shape__container">
              ID
              <DiamondListSortIndicator
                fieldName="name"
                activeSortParam={this.props.activeSortParam}
                isReversedParam={this.props.isReversedParam}
              />
            </div>
          </div>

          <div
            onClick={() => this.props.applyRoughDiamondGroupSort('quantity')}
            className="th"
          >
            Blocks
            <DiamondListSortIndicator
              fieldName="quantity"
              activeSortParam={this.props.activeSortParam}
              isReversedParam={this.props.isReversedParam}
            />
          </div>

          <div
            onClick={() =>
              this.props.applyRoughDiamondGroupSort('carat_weight_sum')
            }
            className="th"
          >
            Carats
            <DiamondListSortIndicator
              fieldName="carat_weight_sum"
              activeSortParam={this.props.activeSortParam}
              isReversedParam={this.props.isReversedParam}
            />
          </div>

          <div
            onClick={() =>
              this.props.applyRoughDiamondGroupSort('carat_weight_mean')
            }
            className="th"
          >
            Avg. Carats
            <DiamondListSortIndicator
              fieldName="carat_weight_mean"
              activeSortParam={this.props.activeSortParam}
              isReversedParam={this.props.isReversedParam}
            />
          </div>

          <div
            onClick={() =>
              this.props.applyRoughDiamondGroupSort('estimated_polish_color')
            }
            className="th"
          >
            Est. Color
            <DiamondListSortIndicator
              fieldName="estimated_polish_color"
              activeSortParam={this.props.activeSortParam}
              isReversedParam={this.props.isReversedParam}
            />
          </div>

          <div
            onClick={() =>
              this.props.applyRoughDiamondGroupSort('default_price')
            }
            className="th"
          >
            Price
            <DiamondListSortIndicator
              fieldName="default_price"
              activeSortParam={this.props.activeSortParam}
              isReversedParam={this.props.isReversedParam}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RoughDiamondGroupListHeader;
