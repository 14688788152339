import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../utils/routerHistory';
import { Button } from 'reactstrap';
import { deleteShippingAddress } from '../state/auth/actions';
import { deleteAddress } from '../api/index';

const initSelectedAddress = {
  index: '',
  address: null,
};
const AddressBlock = ({
  index,
  selectedAddress,
  setSelectedAddress,
  company_name,
  address,
}) => {
  const { city, state, zip } = address;
  const addressLine = [];
  if (city) addressLine.push(city);
  if (state) addressLine.push(state);
  if (zip) addressLine.push(zip);
  return (
    <div className="address-block">
      <div className="address-checkbox">
        <label className="container">
          <input
            type="checkbox"
            key={index}
            checked={selectedAddress.index === String(index)}
            onChange={e => setSelectedAddress(index, address)}
          />
          <span className="checkbox-checkmark" />
        </label>
      </div>
      <div className="address-details">
        {company_name || ''}
        <div>{address.name || ''}</div>
        <div>{address.street_address || ''}</div>
        <div>{addressLine.join(', ')}</div>
        <div>{address.country || ''}</div>
        <div>{address.phone || ''}</div>
      </div>
      <div className="dashboard-checkbox">
        <label className="container">
          <input
            type="checkbox"
            key={index}
            checked={selectedAddress.index === String(index)}
            onChange={e => {
              setSelectedAddress(index, address);
            }}
          />
          <span className="checkbox-checkmark" />
          <span className="checkbox-label">Deliver to this address</span>
        </label>
      </div>
    </div>
  );
};

class ShippingAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOtherAddressShown: true,
      selectedAddress: initSelectedAddress,
    };
    this.setSelectedAddress = this.setSelectedAddress.bind(this);
    this.setDefaultAddress = this.setDefaultAddress.bind(this);
    this.deleteShippingAddress = this.deleteShippingAddress.bind(this);
    this.redirectToShipping = this.redirectToShipping.bind(this);
  }

  componentDidMount() {
    this.setDefaultAddress(); // ensure we have a default address

    if (this.props.openToggle === true) {
      this.toggleShowOtherAddress();
      this.setSelectedAddress(0, this.props.addresses[0]);
    }
  }

  componentDidUpdate() {
    this.setDefaultAddress();
  }

  toggleShowOtherAddress(e) {
    this.setState({
      isOtherAddressShown: !this.state.isOtherAddressShown,
    });
  }

  redirectToShipping() {
    if (this.props.page) {
      navigate('/addshippingaddress?prev=' + this.props.page);
    } else {
      navigate('/addshippingaddress');
    }
  }

  setSelectedAddress(index, address) {
    this.setState({
      selectedAddress: {
        index: String(index),
        address: address,
      },
    });

    if (this.props.onAddressSelect) {
      this.props.onAddressSelect(address);
    }
  }

  setDefaultAddress() {
    if (this.state.selectedAddress.index === '') {
      const customerAccount = this.props.customerAccount;
      if (customerAccount && customerAccount.addresses.length > 0) {
        this.setSelectedAddress('default', customerAccount.addresses[0]);
      }
    }
  }

  async deleteShippingAddress() {
    const selectedAddress = this.state.selectedAddress;
    const customer_account_id = this.props.user.customer_account.id;
    const id = selectedAddress.address.id;

    const payload = await deleteAddress(customer_account_id, id);

    if (payload.status === 200) {
      this.props.deleteShippingAddress(id);
      this.props.showToaster('Address deleted successfully.');
      this.setState({ selectedAddress: initSelectedAddress });
    } else {
      this.props.showToaster("This address couldn't be deleted.");
    }
  }

  render() {
    const { company_name, addresses, isUserEditPage } = this.props;
    const { selectedAddress, isOtherAddressShown } = this.state;
    return (
      <div className={'shipping-address-component'}>
        {addresses && addresses.length > 0 && (
          <div className="shipping-address">
            <div className="shipping-address-heading">Ship to:</div>
            <AddressBlock
              index="default"
              selectedAddress={this.state.selectedAddress}
              setSelectedAddress={this.setSelectedAddress}
              company_name={company_name}
              address={addresses[0]}
            />
          </div>
        )}

        {addresses.length > 1 && (
          <div className="shipping-address-list">
            <div className="shipping-address-list-head">
              <div className="text">Other Shipping addresses</div>
              <div
                className={
                  'toggle' +
                  (addresses.length > 1
                    ? ' show-hide-button'
                    : ' show-hide-button not-show-button')
                }
                onClick={e => this.toggleShowOtherAddress(e)}
              >
                {isOtherAddressShown ? '-' : '+'}
              </div>
            </div>
            <div className="shipping-address-list-body">
              {isOtherAddressShown &&
                addresses.map((address, index) => {
                  return (
                    index > 0 && (
                      <AddressBlock
                        key={index}
                        index={index}
                        isOtherAddressShown={isOtherAddressShown}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={this.setSelectedAddress}
                        company_name={company_name}
                        address={address}
                      />
                    )
                  );
                })}
            </div>
          </div>
        )}

        <div className="df-cta-container">
          <Button className="df-cta" onClick={this.redirectToShipping}>
            ADD NEW SHIPPING ADDRESS
          </Button>

          {isUserEditPage && selectedAddress.index.length > 0 && (
            <Button
              className="df-cta btn btn-secondary btn-address-delete"
              onClick={this.deleteShippingAddress}
            >
              DELETE ADDRESS
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  deleteShippingAddress,
};
const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddress);
