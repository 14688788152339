import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from '@reach/router';

import { fetchUsers } from '../../api/index';
import { storeUsersFromApi } from '../../state/users/actions';
import UserList from '../../components/UserList';

class UsersIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.fetchUsers = this.fetchUsers.bind(this);
  }

  componentDidMount() {
    this.fetchUsers();
  }

  async fetchUsers() {
    const users = await fetchUsers();

    this.props.dispatch(storeUsersFromApi(users));

    this.setState({
      loading: false,
    });
  }

  render() {
    const users = this.props.users || [];
    const isMarketingUser =
      this.props.auth.user.roles &&
      this.props.auth.user.roles.includes('marketing');

    return (
      <div className="main-wrapper">
        <h1 className="title">All Users</h1>
        {isMarketingUser && (
          <Link to={'/admin/notify-users?all=true'}>Email All Users</Link>
        )}
        <UserList
          readOnly={true}
          showToaster={this.props.showToaster}
          loading={this.state.loading}
          users={users}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ storeUsersFromApi }, dispatch),
  };
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    users: state.users.original,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersIndex);
