import {
  SET_ROUGH_DIAMOND_GROUPS,
  SET_ROUGH_DIAMOND_GROUPS_TO_RENDER,
  SELECT_ROUGH_DIAMOND_GROUP,
  DESELECT_ALL_ROUGH_DIAMOND_GROUPS,
  ADD_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER,
  REMOVE_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER,
  CLEAR_ROUGH_DIAMOND_GROUP_FILTERS,
} from './constants';

import { estimatedPolishColorToLabel } from '../../utils/caratUtilities';

export const setRoughDiamondGroups = roughDiamondGroups => {
  return {
    type: SET_ROUGH_DIAMOND_GROUPS,
    payload: roughDiamondGroups,
  };
};

export const setRoughDiamondGroupsToRender = filteredRoughDiamondGroups => {
  return {
    type: SET_ROUGH_DIAMOND_GROUPS_TO_RENDER,
    payload: filteredRoughDiamondGroups,
  };
};

export const selectRoughDiamondGroup = roughDiamondGroupId => {
  return {
    type: SELECT_ROUGH_DIAMOND_GROUP,
    payload: roughDiamondGroupId,
  };
};

export const deselectAllRoughDiamondGroups = () => {
  return {
    type: DESELECT_ALL_ROUGH_DIAMOND_GROUPS,
  };
};

export const addRoughDiamondGroupColorCaratFilter = value => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER, value: value });

    const state = getState();
    const itemsToFilter = state.roughDiamondGroups.original;
    const filteredItems = applySearchFilters(
      itemsToFilter,
      state.roughDiamondGroups.filters
    );
    dispatch({
      type: SET_ROUGH_DIAMOND_GROUPS_TO_RENDER,
      payload: filteredItems,
    });
  };
};

export const removeRoughDiamondGroupColorCaratFilter = value => {
  return (dispatch, getState) => {
    dispatch({
      type: REMOVE_ROUGH_DIAMOND_GROUP_COLORCARAT_FILTER,
      value: value,
    });

    const state = getState();
    const itemsToFilter = state.roughDiamondGroups.original;
    const filteredItems = applySearchFilters(
      itemsToFilter,
      state.roughDiamondGroups.filters
    );
    dispatch({
      type: SET_ROUGH_DIAMOND_GROUPS_TO_RENDER,
      payload: filteredItems,
    });
  };
};

export const clearRoughDiamondGroupFilters = value => {
  return (dispatch, getState) => {
    dispatch({ type: CLEAR_ROUGH_DIAMOND_GROUP_FILTERS });

    const state = getState();
    const itemsToFilter = state.roughDiamondGroups.original;
    const filteredItems = applySearchFilters(
      itemsToFilter,
      state.roughDiamondGroups.filters
    );
    dispatch({
      type: SET_ROUGH_DIAMOND_GROUPS_TO_RENDER,
      payload: filteredItems,
    });
  };
};

export const applySearchFilters = (items, filters) => {
  let itemsToFilter = items;

  // filter by shape AND carat
  if (!!filters.colorCarat && filters.colorCarat.length > 0) {
    itemsToFilter = itemsToFilter.filter(item =>
      matchesColorCaratFilters(item, filters.colorCarat)
    );
  }
  return itemsToFilter;
};

// This filters by a shape AND a carat range
const matchesColorCaratFilters = (item, colorCaratFilters) => {
  for (let i = 0; i < colorCaratFilters.length; i++) {
    if (
      colorCaratFilters[i].color.toLowerCase() ===
        estimatedPolishColorToLabel(
          item.estimated_polish_color
        ).toLowerCase() &&
      colorCaratFilters[i].carat[0] <= item.carat_weight_mean &&
      item.carat_weight_mean < colorCaratFilters[i].carat[1]
    ) {
      return true;
    }
  }
  return false;
};
