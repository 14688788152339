import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Confirmation from '../components/Confirmation';
import SVG from '../components/SVG';
import { postUnsubscribe, postUnsubscribeWithPhone } from '../api/index';

class UnsubscribePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmation: false,
      email: '',
      errorMessage: '',
      phone: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    if (this.state.email.length > 0) {
      postUnsubscribe(this.state.email)
        .then(() => {
          this.setState({ showConfirmation: true, email: '' });
        })
        .catch(error => {
          this.setState({ errorMessage: 'Unsubscribe failed.' });
        });
    } else if (this.state.phone.length > 0) {
      postUnsubscribeWithPhone(this.state.phone)
        .then(() => {
          this.setState({ showConfirmation: true, phone: '' });
        })
        .catch(error => {
          this.setState({ errorMessage: 'Unsubscribe failed.' });
        });
    }
  }

  render() {
    return (
      <main>
        <div className="forgot-page df-logout-margin">
          <div className="forgot-container">
            <div className="forgot-headers">
              <SVG
                className="Svg df-logo"
                viewBox="0 0 603.8 40.9"
                name="logo"
              />
            </div>
            <div className="forgot-form">
              <h1 className="df-title">UNSUBSCRIBE</h1>
              <p>
                You can unsubscribe your email address or your phone number.
              </p>
              <input
                className="df-text-inputs username-input with-margin"
                name="email"
                placeholder="Email address"
                onChange={this.handleChange}
              />
              <input
                className="df-text-inputs username-input with-margin"
                name="phone"
                placeholder="Phone number"
                onChange={this.handleChange}
              />

              <span className="error-message">{this.state.errorMessage}</span>

              <Button className="df-cta" onClick={this.handleSubmit}>
                SUBMIT
              </Button>
            </div>

            {this.state.showConfirmation && (
              <Confirmation
                parent="forgot"
                closeConfirmation={e =>
                  this.setState({ showConfirmation: false })
                }
                title="SUCCESS"
                subtitle="You have been unsubscribed."
              />
            )}
          </div>
        </div>
      </main>
    );
  }
}

export default UnsubscribePage;
