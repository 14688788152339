import React, { Component } from 'react';
import { connect } from 'react-redux';
import { salesOrAdmin, formatUSD } from '../utils/util';
import { toMonthDayYear } from '../utils/dateUtilities';
import { compareLinkText } from '../utils/tableUtilities';
import {
  fetchCustomOrders,
  fetchOrders,
  fetchConsignments,
  deleteCustomOrder,
  updateCustomOrder,
} from '../api';
import BackButton from '../components/BackButton';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { OrdersSummaryTable, OnWaitSpinner } from '../utils/orderUtilities';

const DeleteCell = props => {
  return (
    <IconButton
      onClick={() => props.onDeleteClick(props.recurringOrder)}
      title="Delete Custom Order"
    >
      <DeleteIcon />
    </IconButton>
  );
};

const UpdateCustomOrder = props => {
  const statuses = {
    pending: [
      { actionText: 'Approve', newStatus: 'approved' },
      { actionText: 'Reject', newStatus: 'rejected' },
    ],
    rejected: [{ actionText: 'Approve', newStatus: 'approved' }],
    approved: [{ actionText: 'Reject', newStatus: 'rejected' }],
  };

  const transitionSet = statuses[props.customOrder.status];

  return transitionSet.map(transition => (
    <Button
      title={transition.actionText}
      onClick={() =>
        props.onUpdateCustomOrderClick(props.customOrder, transition.newStatus)
      }
      key={`${props.customOrderId}-${transition.newStatus}-${transition.actionText}`}
      className="df-cta monthly-order-btn"
    >
      {transition.actionText}
    </Button>
  ));
};
const ActionsRow = props => {
  return (
    <React.Fragment>
      <DeleteCell
        id={props.id}
        recurringOrder={props.customOrder}
        onDeleteClick={props.onDeleteClick}
      />
      {props.adminOrSales && (
        <UpdateCustomOrder
          id={props.id}
          customOrder={props.customOrder}
          onUpdateCustomOrderClick={props.onUpdateCustomOrderClick}
        />
      )}
    </React.Fragment>
  );
};

class OrdersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      ordersLoading: false,
      customOrders: null,
      consignments: [],
      consignmentsLoading: false,
    };

    this.getOrders = this.getOrders.bind(this);
  }

  componentDidMount() {
    this.getOrders();
    this.getConsignments();
  }

  getOrders() {
    this.setState({ ordersLoading: true }, () => {
      fetchOrders().then(res => {
        this.setState({ ordersLoading: false, orders: res });
      });
    });
  }

  async getCustomOrders() {
    const { customOrders } = this.state;
    const { auth } = this.props;

    if (!!auth.user.id && !customOrders) {
      const customOrders = await fetchCustomOrders(auth.user.id);
      this.setState({ customOrders });
    }
  }

  getConsignments() {
    this.setState({ consignmentsLoading: true }, () => {
      fetchConsignments().then(res => {
        const consignments = res;
        this.setState({ consignmentsLoading: false, consignments });
      });
    });
  }

  render() {
    const { auth } = this.props;
    const {
      ordersLoading,
      orders,
      consignments,
      consignmentsLoading,
    } = this.state;
    const user = auth && auth.user;
    const customerAccount = user && user.customer_account;
    const adminOrSales = user && salesOrAdmin(user.roles);

    let customOrderTableColumnExtensions = [
      { columnName: 'properties', width: '200px' },
      { columnName: 'order_type', width: '100px' },
      { columnName: 'quantity', width: '100px' },
      { columnName: 'discount_code', width: '120px' },
      { columnName: 'created_at', width: '130px' },
      { columnName: 'need_by', width: '130px' },
    ];
    let integratedSortingColumnExtensions = [];

    if (adminOrSales) {
      customOrderTableColumnExtensions = customOrderTableColumnExtensions.concat(
        { columnName: 'company_name', width: '130px', wordWrapEnabled: true },
        { columnName: 'name', width: '130px', wordWrapEnabled: true },
        { columnName: 'Actions', width: '230px', wordWrapEnabled: true },
        { columnName: 'order_type', width: '130px', wordWrapEnabled: true },
        { columnName: 'name', width: '130px', wordWrapEnabled: true },
        {
          columnName: 'sales_representative',
          width: '130px',
          wordWrapEnabled: true,
        }
      );
      integratedSortingColumnExtensions = integratedSortingColumnExtensions.concat(
        { columnName: 'name', compare: compareLinkText },
        { columnName: 'company_name', compare: compareLinkText }
      );
    }
    return (
      <main>
        <div className="page-wrapper-with-padding">
          <div className="orders-page main-wrapper">
            <BackButton link="/dashboard" text="Dashboard" />
            <div className="dashboard-header">
              <div className="dashboard-header-column name">
                <div className="company-name">
                  {customerAccount ? customerAccount.company_name : ''}
                </div>
              </div>
              <div className="dashboard-header-column">
                {!!customerAccount && (
                  <ul className="unstyled">
                    {!!customerAccount.credit_limit && (
                      <li>
                        {`Credit Limit: ${formatUSD(
                          customerAccount.credit_limit / 100.0
                        )}`}
                      </li>
                    )}
                    {!!customerAccount.balance && (
                      <li>
                        {`Balance: ${formatUSD(
                          customerAccount.balance / 100.0
                        )}`}
                      </li>
                    )}
                  </ul>
                )}
                <ul className="unstyled">
                  {!customerAccount && (
                    <li>
                      Your affiliation has not yet been approved by your sales
                      representative.
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <h1 className="df-title">MY ORDERS</h1>
            {ordersLoading ? (
              <OnWaitSpinner />
            ) : (
              <OrdersSummaryTable orders={orders} />
            )}

            {!!customerAccount.memo_permitted && consignments.length > 0 && (
              <React.Fragment>
                <h1 className="df-title">MY MEMOS</h1>
                {consignmentsLoading ? (
                  <OnWaitSpinner />
                ) : (
                  <OrdersSummaryTable orders={consignments} />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(OrdersPage);
