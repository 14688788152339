import React, { Component } from 'react';
import TradeReferenceInputs from '../components/TradeReferenceInputs';
import SignupReferenceInput from '../components/SignupReferenceInput';
import countries from '../utils/countries.json';

import SimpleReactValidator from 'simple-react-validator';

const placeHolders = {
  company_name: 'Legal Business Name*',
  phone: 'Business Phone*',
  tax_identifier: 'TAX ID/EID/VAT*',
  jbt_id: 'JBT ID',
};

const addressAttributes = {
  name: 'Billing Contact*',
  phone: 'Billing Contact Phone*',
  street_address: 'Street Address*',
  city: 'City*',
  state: 'State*',
  zip: 'Zip Code*',
};

// The form that a new user must fill out during registration if they are adding a new company / customer_account.
class NewPartnerForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ locale: 'en' });
  }

  validatorRule(attr) {
    if (attr === 'phone') {
      return `required|${attr}`;
    } else {
      return 'required';
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="register-headers">
          <h2 className="register-subtitle">
            DIAMOND FOUNDRY RETAILER APPLICATION
          </h2>
        </div>
        <div className="registration-form">
          {Object.keys(placeHolders).map((attr, index) => {
            const attributeRequired = placeHolders[attr].includes('*');

            return (
              <div
                className={`registration-form-elements ${
                  index % 2 === 0 ? 'left' : 'right'
                }`}
                key={`${attr}-new-partner-input`}
              >
                <label htmlFor={`customer_account_attributes-${attr}`}>
                  {placeHolders[attr]}
                </label>
                <SignupReferenceInput
                  key={`customer_account_attributes-${attr}`}
                  inputID={`customer_account_attributes-${attr}`}
                  inputName={`customer_account_attributes[${attr}]`}
                  onChangeHandler={e => {
                    this.props.setParentState(
                      {
                        customer_account_attributes: {
                          [attr]: e.target.value,
                        },
                      },
                      null
                    );
                    if (attributeRequired) {
                      this.validator.showMessages(attr);
                    }
                  }}
                />
                {attributeRequired &&
                  this.validator.message(
                    attr,
                    this.props.customer_account_attributes[attr],
                    this.validatorRule(attr)
                  )}
              </div>
            );
          })}

          <div className="registration-form-elements left">
            <label htmlFor={`customer_account_attributes-website`}>
              Website
            </label>

            <SignupReferenceInput
              key="customer_account_attributes-website"
              inputID="customer_account_attributes-website"
              inputName="customer_account_attributes[website}]"
              onChangeHandler={e =>
                this.props.setParentState({
                  customer_account_attributes: {
                    metadata: { website: e.target.value },
                  },
                })
              }
            />
          </div>

          <div className="registration-form-elements right" />

          {Object.keys(addressAttributes).map((attr, index) => {
            const attributeRequired = addressAttributes[attr].includes('*');

            return (
              <div
                className={`registration-form-elements ${
                  index % 2 === 0 ? 'left' : 'right'
                }`}
                key={`${attr}-new-partner-address-input`}
              >
                <label htmlFor={`customer_account_attributes-address-${attr}`}>
                  {addressAttributes[attr]}
                </label>
                <SignupReferenceInput
                  key={`customer_account_attributes-address-${attr}`}
                  inputID={`customer_account_attributes-address-${attr}`}
                  inputType="text"
                  inputName={`customer_account_attributes[address][${attr}]`}
                  onChangeHandler={e => {
                    this.props.setParentState(
                      {
                        customer_account_attributes: {
                          addresses_attributes: [{ [attr]: e.target.value }],
                        },
                      },
                      0
                    );
                    if (attributeRequired) {
                      this.validator.showMessages(addressAttributes[attr]);
                    }
                  }}
                />
                {attributeRequired &&
                  this.validator.message(
                    addressAttributes[attr],
                    this.props.customer_account_attributes
                      .addresses_attributes[0][attr],
                    'required'
                  )}
              </div>
            );
          })}

          <div className="registration-form-elements left">
            <label htmlFor={`customer_account_attributes-address-country`}>
              Country*
            </label>

            <select
              id="customer_account_attributes-address-country"
              className="df-text-inputs adduser-input"
              onChange={e =>
                this.props.setParentState(
                  {
                    customer_account_attributes: {
                      addresses_attributes: [{ country: e.target.value }],
                    },
                  },
                  0
                )
              }
            >
              {Object.entries(countries).map(([countryCode, countryName]) => {
                return (
                  <option key={`cc-${countryCode}`} value={countryCode}>
                    {countryName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="register-headers">
          <h2 className="register-subtitle">TRADE REFERENCES</h2>
        </div>

        {[1, 2, 3].map(idx => (
          <TradeReferenceInputs
            key={idx}
            tradeReferenceKey={idx}
            setParentState={this.props.setParentState}
            trade_references_attributes={
              this.props.customer_account_attributes
                .trade_references_attributes[idx - 1]
            }
          />
        ))}
      </React.Fragment>
    );
  }
}

export default NewPartnerForm;
