import styled from '@emotion/styled';

type Props = {
  selected: boolean;
};
const InnerFilterColorCell = styled.div<Props>`
  color: ${props => (props.selected ? '#f1f2f2' : 'black')} !important;
  background-color: ${props =>
    props.selected ? '#ff3c00' : '#fff'} !important;
`;

export default InnerFilterColorCell;
