import React, { useState } from 'react';
import { connect } from 'react-redux';
import SVG from '../components/SVG';
import useForm from 'react-hook-form';
import PasswordInput from '../components/PasswordInput';
import { Button, Alert } from 'reactstrap';
import queryString from 'query-string';
import { registerInviteUser } from '../state/auth/actions';
import { registerPageType } from '../propTypes';

const InviteSignUpPage = ({ error, registerInviteUser, location }) => {
  const { register, handleSubmit, errors } = useForm();
  const query = queryString.parse(location.search);
  const { invitation_token } = query;

  const onSubmit = data => {
    const { first_name, last_name, phone, password } = data;
    const phoneNo = phone.replace('+', '');

    registerInviteUser({
      user: {
        first_name,
        last_name,
        phone: phoneNo,
        password,
        invitation_token,
      },
    });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    password: '',
  });

  return (
    <main>
      <div className="register-page df-logout-margin">
        <div className="register-container">
          <div className="register-headers">
            <SVG className="Svg df-logo" viewBox="0 0 603.8 40.9" name="logo" />

            <h2 className="register-subtitle">Invited User Sign Up</h2>
          </div>
          <div className="register-form">
            <form
              className="registration-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="registration-form-elements left">
                {errors.first_name && errors.first_name.type === 'required' && (
                  <span className="error-message">This is required</span>
                )}
                {errors.first_name &&
                  errors.first_name.type === 'maxLength' && (
                    <span className="error-message">Max length exceeded</span>
                  )}
                <input
                  className="df-text-inputs adduser-input"
                  name="first_name"
                  placeholder="First Name*"
                  onChange={handleInputChange}
                  defaultValue={values.first_name}
                  ref={register({ required: true, maxLength: 80 })}
                />
              </div>
              <div className="registration-form-elements right">
                {errors.last_name && errors.last_name.type === 'required' && (
                  <span className="error-message">This is required</span>
                )}
                {errors.last_name && errors.last_name.type === 'maxLength' && (
                  <span className="error-message">Max length exceeded</span>
                )}
                <input
                  className="df-text-inputs adduser-input"
                  name="last_name"
                  placeholder="Last Name*"
                  onChange={handleInputChange}
                  defaultValue={values.last_name}
                  ref={register({ required: true, maxLength: 100 })}
                />
              </div>

              <div className="registration-form-elements left">
                {errors.phone && errors.phone.type === 'required' && (
                  <span className="error-message">This is required</span>
                )}
                {errors.phone && errors.phone.type === 'maxLength' && (
                  <span className="error-message">Max length exceeded</span>
                )}
                {errors.phone && errors.phone.type === 'minLength' && (
                  <span className="error-message">Min length not met</span>
                )}
                <input
                  className="df-text-inputs adduser-input"
                  name="phone"
                  type="tel"
                  placeholder="Phone*"
                  onChange={handleInputChange}
                  defaultValue={`+${values.phone}`}
                  ref={register({
                    required: true,
                    minLength: 6,
                    maxLength: 12,
                  })}
                />
              </div>
              <div className="registration-form-elements right">
                {errors.password && errors.password.type === 'required' && (
                  <span className="error-message">This is required</span>
                )}
                {errors.password && errors.password.type === 'pattern' && (
                  <span className="error-message">
                    Min. 8 characters, 1 lower & uppercase, and 1 number
                    required.
                  </span>
                )}
                <PasswordInput
                  inputStyle="df-text-inputs adduser-input"
                  placeholder="Create your password"
                  name="password"
                  onChange={handleInputChange}
                  defaultValue={values.password}
                  inputRef={register({
                    required: true,
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                  })}
                />
              </div>

              <div className="adduser-label">* these fields are mandatory</div>

              <Button type="submit" className="sign-in-btn df-cta">
                SEND
              </Button>
            </form>
          </div>
          {error && <Alert color="warning">{error}</Alert>}
        </div>
      </div>
    </main>
  );
};

InviteSignUpPage.propTypes = registerPageType;

const mapState = state => ({
  error: state.auth.error,
});

const mapDispatch = { registerInviteUser };

export default connect(mapState, mapDispatch)(InviteSignUpPage);
