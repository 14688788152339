import React from 'react';
import styled from '@emotion/styled';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
};

const DIV = styled.div`
  width: 100%;
  height: 100%;
`;

const DisableDIV = styled(DIV)`
  pointer-events: none;
  opacity: 0.5;
`;

const WithDisabled = ({ children, disabled = false }: Props) =>
  disabled ? <DisableDIV>{children}</DisableDIV> : <DIV>{children}</DIV>;

export default WithDisabled;
