import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAccountStatement } from '../api/index';
import AccountStatement from '../components/AccountStatement';
import InvoiceHistory from '../components/invoice/InvoiceHistory';

class AccountStatementPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statement: null,
    };

    this.fetchAccountStatement = this.fetchAccountStatement.bind(this);
    this.noDataCell = this.noDataCell.bind(this);
  }

  componentDidMount() {
    this.fetchAccountStatement();
  }

  componentDidUpdate(prevProps) {
    // Fetch statement after user is loaded. Required when loading this page directly from a link.
    if (prevProps.auth.user.id !== this.props.auth.user.id) {
      if (!!this.props.user.on_behalf_of) {
        this.fetchAccountStatement(
          this.props.user.on_behalf_of.customer_account.id
        );
      } else {
        this.fetchAccountStatement(this.props.auth.user.customer_account.id);
      }
    }
  }

  async fetchAccountStatement(id) {
    const customerAccountId =
      id ||
      (((this.props.user || {}).on_behalf_of || {}).customer_account || {})
        .id ||
      this.props.auth.user.customer_account.id;

    if (customerAccountId == null || customerAccountId == 'undefined') {
      return;
    }

    const statement = await fetchAccountStatement(customerAccountId);
    this.setState({ statement: statement });
  }

  noDataCell(tableProps) {
    return !this.state.statement ? (
      <td colSpan={tableProps.colSpan}>Loading...</td>
    ) : (
      <td colSpan={tableProps.colSpan}>Your account has no open invoices</td>
    );
  }

  render() {
    const statement = this.state.statement;
    const agingReportPeriods = (statement || {}).aging_report_periods;
    const id = (statement || {}).id;

    return (
      <main>
        <div className="main-wrapper padded">
          {statement && (
            <AccountStatement
              agingReportPeriods={agingReportPeriods}
              agingReportTransactions={
                (statement || {}).aging_report_transactions || []
              }
              openBalance={statement.open_balance || 0}
              noDataCell={this.noDataCell}
              customerAccountId={id}
            />
          )}
          <InvoiceHistory
            showToaster={this.props.showToaster}
            closeToaster={this.props.closeToaster}
          />
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(AccountStatementPage);
