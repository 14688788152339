import React, { Component } from 'react';
import { connect } from 'react-redux';
import { storeDiamondsFromAPI } from '../state/action/diamondSearch';
import { setDiamondStats } from '../state/stats/actions';
import { fetchDiamondStats, updateUser, fetchDiamonds } from '../api/index';

class OnBehalfOfBanner extends Component {
  constructor(props) {
    super(props);

    this.clearOnBehalfOf = this.clearOnBehalfOf.bind(this);
  }

  clearOnBehalfOf() {
    const requestBody = {
      user: {
        on_behalf_of_user_id: 'clear',
      },
    };

    updateUser(requestBody, this.props.user)
      .then(response => {
        if (response.statusText === 'OK') {
          // Update the current user using response.data
          this.props.dispatch({ type: 'USER', payload: response.data });
        }
      })
      .then(async response => {
        // clear the cart because prices are different for different users
        this.props.dispatch({ type: 'RESET_CART' });
        // reload the diamonds since prices will change
        this.props.dispatch({
          type: 'IS_DIAMONDS_LOADING',
          payload: { isDiamondsLoading: true },
        });
        // Fetch the diamonds from the API and load them into Redux.
        const diamondList = await fetchDiamonds();
        this.props.dispatch(storeDiamondsFromAPI({ diamonds: diamondList }));
        // Fetch diamonds stats
        const stats = await fetchDiamondStats();
        this.props.dispatch(setDiamondStats(stats));
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    const { onBehalfOfUserName } = this.props;

    if (!onBehalfOfUserName) {
      return null;
    }

    return (
      <div className="on-behalf-of-banner">
        Ordering for {onBehalfOfUserName}
        <span className="on-behalf-of-banner-close">
          <a onClick={this.clearOnBehalfOf}>x</a>
        </span>
      </div>
    );
  }
}

export default connect()(OnBehalfOfBanner);
