interface Transaction {
  id: string;
  trandate: string;
  doc_number: string;
  netsuite_status: string;
  transaction_total: number;
  due_at: string;
  paid_total: number;
  transaction_type: string;
  transaction_lines: TransactionLine[];
  shipdate: string;
  applied_amount?: number;
  ship_address?: string;
  bill_address?: string;
}

export interface Item {
  carat: number;
  clarity: string;
  color: string;
  cut_grade: string;
  id: string;
  lot_id: number;
  shape: string;
  name?: string;
  item_name?: string;
}

// To do: This only produces a readable Item display line for diamonds, and there are customers with transactions with non diamond items
// this should instead be removed to just use the transaction_line 'memo' attribute once it's added
export const displayLine = (item?: Item) => {
  if (!item) {
    return '';
  }
  const tmp: string[] = [];
  const { shape, carat, cut_grade, clarity, name, item_name } = item;
  if (shape) {
    tmp.push(shape);
  }
  if (carat) {
    tmp.push(carat.toString());
  }
  if (cut_grade) {
    tmp.push(cut_grade);
  }
  if (clarity) {
    tmp.push(clarity);
  }

  let n = name;
  // The backend may have it's name as `item_name`.
  if (item_name) {
    n = item_name;
  }
  if (n) {
    tmp.push(n);
  }
  if (item.lot_id) {
    tmp.push(`F${item.lot_id}`);
  }

  return tmp.join(',');
};

export const removeDuplicateItems = (transactionLines: TransactionLine[]) => {
  const seed: TransactionLine[] = [];
  const history: Set<string> = new Set([]);
  return transactionLines.reduce(
    (unique: TransactionLine[], o: TransactionLine) => {
      if (o.item === undefined) {
        return unique;
      }
      if (history.has(o.item?.id)) {
        return unique;
      }

      history.add(o.item.id);
      unique.push(o);

      return unique;
    },
    seed
  );
};

export const getAmountDue = (transaction: Transaction) => {
  if (!transaction) {
    return 0;
  }
  if (transaction.applied_amount) {
    return transaction.transaction_total - transaction.applied_amount;
  }
  return (transaction.transaction_total - transaction.paid_total) / 100;
};

export interface TransactionLine {
  amount?: string;
  id: string;
  item_count: number;
  item_id?: string;
  item_type?: string;
  ns_customer_id: number;
  ns_item_id: number;
  ns_transaction_id: number;
  transaction_id: string;
  transaction_line_id: number;
  transaction_order: number;
  item?: Item;
}
export default Transaction;
