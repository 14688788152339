import React from 'react';

type Props = {
  render: React.ReactChild;
  empty: React.ReactChild;
  loading?: React.ReactChild;
  isEmpty: boolean;
  isLoading?: boolean;
};

const Fetch = ({
  render,
  empty,
  isEmpty,
  isLoading = false,
  loading,
}: Props) => {
  if (isLoading && loading) {
    return <>{loading} </>;
  }
  if (isEmpty) {
    return <>{empty}</>;
  }

  return <>{render}</>;
};

export default Fetch;
