import axios from 'axios';
import { getCookie } from '../utils/cookie';
import { download } from '../utils/util';
import getEnvConfig from '../env';

export const { DF_API_HOST } = getEnvConfig();

let authToken = null;

export const setToken = token => {
  authToken = token;
};

// avoid preflight browser OPTIONS trip by not including content-type
// should be used for GET requests
export const headersAuthOnly = () => {
  const token = getCookie('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

function handleErrors(response) {
  if (!response.statusText === 'OK') {
    return response.then(body => {
      // TODO: What do we want to do here?
      //throw new Error(body.data.message || body.message);
    });
  } else {
    return response;
  }
}

// helper method to replace repeated code to do a GET request to our API
export function apiGet(url) {
  return axios
    .get(`${DF_API_HOST}/${url}`, headersAuthOnly())
    .then(handleErrors)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      console.error(e);

      if (e.response.status === 401) {
        return window.location.reload();
      }
    });
}

export const fetchDiamonds = () => {
  return apiGet('api/v2/diamonds');
};

// This fetches the diamond counts used in the filter grid.
export const fetchDiamondStats = () => {
  return apiGet('api/v1/stats/diamonds');
};

export const fetchRoughDiamonds = roughDiamondGroupId => {
  if (roughDiamondGroupId) {
    return apiGet(
      `api/v1/rough_diamond_groups/${roughDiamondGroupId}/rough_diamonds`
    );
  }
  return apiGet('api/v1/rough_diamonds');
};

export const fetchRoughDiamondGroups = () => {
  return apiGet('api/v1/rough_diamond_groups');
};

// This fetches the rough diamond group counts used in the filter grid.
export const fetchRoughDiamondGroupStats = () => {
  return apiGet('api/v1/stats/rough_diamond_groups');
};

// We do NOT want to catch errors here because they need to propagate.
export const loginUser = (email, password) => {
  return axios
    .post(`${DF_API_HOST}/login`, { email, password })
    .then(handleErrors)
    .then(response => {
      return response;
    });
};

export const logoutUser = () => {
  return axios
    .delete(`${DF_API_HOST}/logout`, {}, headersAuthOnly())
    .then(handleErrors)
    .then(response => {
      return response;
    });
};

export const fetchUser = userId => {
  // if there is no userId passed into this method, then fetch the current user
  // if userId was passed in, then fetch the desired user
  let userUrl = userId
    ? `${DF_API_HOST}/api/v2/users/${userId}`
    : `${DF_API_HOST}/api/v1/user`;

  return axios
    .get(userUrl, headersAuthOnly())
    .then(handleErrors)
    .then(response => {
      return response;
    })
    .catch(e => {
      console.error(e);
    });
};

export const fetchUsers = (paramsAsString = '') => {
  return axios
    .get(`${DF_API_HOST}/api/v2/users${paramsAsString}`, headersAuthOnly())
    .then(handleErrors)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      console.error(e);
    });
};

export const updateUser = (requestBody, user) => {
  return axios
    .put(
      `${DF_API_HOST}/api/v2/users/${user.id}`,
      requestBody,
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => response)
    .catch(e => {
      console.error(e);
    });
};

export const inviteUser = user => {
  return axios
    .post(`${DF_API_HOST}/api/v1/admin/invitation`, user, headersAuthOnly())
    .then(response => {
      return response;
    });
};

export const fetchCart = userId => {
  let cartUrl = userId
    ? `${DF_API_HOST}/api/v2/cart?user_id=${userId}`
    : `${DF_API_HOST}/api/v2/cart`;

  return axios
    .get(cartUrl, headersAuthOnly())
    .then(handleErrors)
    .then(response => {
      if (response) {
        return response.data;
      }
    })
    .catch(e => {
      console.error(e);
      if (e.response && e.response.status === 404) {
        return { grand_total: 0.0, items: [] };
      }
    });
};

export const updateCart = cart => {
  return axios
    .put(`${DF_API_HOST}/api/v2/cart`, { cart: cart }, headersAuthOnly())
    .then(handleErrors)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      console.error(e);
    });
};

// fetch the orders from the DF API
export const fetchOrders = userId => {
  if (!userId) {
    return apiGet('api/v2/orders');
  }
  return apiGet(`api/v2/orders?user_id=${userId}`);
};

// fetch a single order from the DF API
export const fetchOrder = orderId => {
  return apiGet(`api/v2/orders/${orderId}`);
};

// fetch consignment orders from the DF API
export const fetchConsignments = userId => {
  if (!userId) {
    return apiGet('api/v2/orders?is_consignment=true');
  }
  return apiGet(`api/v2/orders?user_id=${userId}&is_consignment=true`);
};

export const postOrder = order => {
  return axios
    .post(`${DF_API_HOST}/api/v1/orders`, order, headersAuthOnly())
    .then(handleErrors)
    .then(response => {
      return response;
    });
};

export const postSpecialOrder = bulkCartItems => {
  return axios
    .post(
      `${DF_API_HOST}/api/v2/special_orders`,
      bulkCartItems,
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => {
      return response;
    })
    .catch(e => {
      console.error(e);
    });
};

export const requestReturnAuthorization = params => {
  return axios
    .post(
      `${DF_API_HOST}/api/v1/return_authorizations`,
      params,
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => {
      return response;
    })
    .catch(e => {
      console.error(e);
    });
};

export const fetchCompanyList = () => {
  return axios
    .get(`${DF_API_HOST}/api/v1/admin/customer_accounts`, headersAuthOnly())
    .then(handleErrors)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      console.error(e);
    });
};

export const fetchWatches = userId => {
  return apiGet(`/api/v2/users/${userId}/watches`);
};

// This method is called "download" because it downloads a CSV. As opposed to "fetch", which returns json.
export const downloadWatches = () => {
  return axios
    .get(`${DF_API_HOST}/api/v1/admin/watches.csv`, headersAuthOnly())
    .then(handleErrors)
    .then(response => {
      download('notifiers.csv', response.data);
      return response.data;
    })
    .catch(e => {
      console.error(e);
    });
};

export const adminFetchWatches = () => {
  return apiGet(`/api/v1/admin/watches`);
};

export const createWatch = (userId, watch) => {
  return axios
    .post(
      `${DF_API_HOST}/api/v2/users/${userId}/watches`,
      { watch: watch },
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      console.error(e);
    });
};

// IMPORTANT: This endpoint will return the credit card for the on_behalf_of user
//            if the current user is shopping on behalf of someone else.
export const fetchCreditCard = () => {
  return apiGet('api/v2/credit_card');
};

export const createCreditCard = token => {
  return axios
    .post(
      `${DF_API_HOST}/api/v2/credit_card`,
      { token: token },
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      console.error(e);
    });
};

export const deleteUserWatch = (userId, id) => {
  return axios
    .delete(
      `${DF_API_HOST}/api/v2/users/${userId}/watches/${id}`,
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => {
      // on successful delete the endpoint responds w/ the user's current watches set
      return response.data;
    })
    .catch(e => {
      console.error(e);
    });
};

export const createCustomOrders = (userId, orders) => {
  return axios
    .post(
      `${DF_API_HOST}/api/v1/users/${userId}/custom_orders`,
      { custom_orders: orders },
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => {
      return response;
    });
};

export const fetchCustomOrders = userId => {
  return apiGet(`api/v1/users/${userId}/custom_orders`);
};

export const deleteCustomOrder = (userId, recurringOrderId) => {
  return axios
    .delete(
      `${DF_API_HOST}/api/v1/users/${userId}/custom_orders/${recurringOrderId}`,
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => {
      return response;
    })
    .catch(e => {
      console.error(e);
    });
};

export const fetchAllCustomOrders = () => {
  return apiGet(`api/v1/custom_orders/all`);
};

export const createNotification = params => {
  return axios
    .post(
      `${DF_API_HOST}/api/v1/notifications`,
      { notification: params },
      headersAuthOnly()
    )
    .then(response => {
      return response.data;
    });
};

export const fetchCustomerAccounts = () => {
  return apiGet(`api/v1/admin/customer_accounts?extended=t`);
};

export const fetchCustomerAccount = id => {
  return apiGet(`api/v1/admin/customer_accounts/${id}`);
};

export const fetchAccountStatement = id => {
  return apiGet(`api/v2/customer_accounts/${id}/statement`);
};

export const downloadStatementCsv = id => {
  let options = headersAuthOnly();
  options['headers']['Accept'] = 'text/csv';

  return axios
    .get(`${DF_API_HOST}/api/v2/customer_accounts/${id}/statement`, options)
    .then(handleErrors)
    .then(response => {
      download('DF Account Statement.csv', response.data);
      return response.data;
    })
    .catch(e => {
      console.error(e);
    });
};

export const updateCustomOrder = (userId, customOrderId, customOrderParams) => {
  return axios
    .put(
      `${DF_API_HOST}/api/v1/users/${userId}/custom_orders/${customOrderId}`,
      customOrderParams,
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => {
      return response;
    })
    .catch(e => {
      console.error(e);
    });
};

export const fetchAgingReport = () => {
  return apiGet(`api/v1/admin/customer_accounts/ar_aging_report`);
};

export const fetchConsignmentOrders = () => {
  return apiGet('api/v2/orders/consignment');
};

export const createAddress = (customer_account_id, address) => {
  return axios
    .post(
      `${DF_API_HOST}/api/v1/customer_accounts/${customer_account_id}/addresses`,
      address,
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => response)
    .catch(e => e.response);
};

export const deleteAddress = (customer_account_id, id) => {
  return axios
    .delete(
      `${DF_API_HOST}/api/v1/customer_accounts/${customer_account_id}/addresses/${id}`,
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => response)
    .catch(e => e.response);
};

// fetch all order's from the DF API, accessible by admin's only
export const adminFetchAllOrders = days => {
  return apiGet(`api/v2/orders/all?days=${days}`);
};

export const postUnsubscribe = email => {
  return axios
    .post(`${DF_API_HOST}/api/v1/b2c/notifications/unsubscribe`, { email })
    .then(handleErrors)
    .then(response => {
      return response;
    });
};

export const postUnsubscribeWithPhone = phone => {
  return axios
    .post(`${DF_API_HOST}/api/v1/b2c/notifications/unsubscribe`, { phone })
    .then(handleErrors)
    .then(response => {
      return response;
    });
};

export const convertConsignedItems = params => {
  return axios
    .post(
      `${DF_API_HOST}/api/v1/orders/${params.order_id}/convert`,
      params,
      headersAuthOnly()
    )
    .then(response => {
      return response;
    });
};

// This method is called "download" because it downloads a CSV. As opposed to "fetch", which returns json.
export const downloadReminders = () => {
  return axios
    .get(`${DF_API_HOST}/api/v1/admin/reminders.csv`, headersAuthOnly())
    .then(handleErrors)
    .then(response => {
      download('reminders.csv', response.data);
      return response.data;
    })
    .catch(e => {
      console.error(e);
    });
};

export const adminFetchReminders = () => {
  return apiGet(`/api/v1/admin/reminders`);
};

export const deleteReminderByEmail = (customer_email, id) => {
  return axios
    .delete(
      `${DF_API_HOST}/api/v1/admin/reminders/${id}?customer_email=${customer_email}`,
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => response)
    .catch(e => e.response);
};

export const deleteReminderByPhone = (customer_phone, id) => {
  return axios
    .delete(
      `${DF_API_HOST}/api/v1/admin/reminders/${id}?customer_email=${customer_phone}`,
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => response)
    .catch(e => e.response);
};

export const createReminder = reminder => {
  return axios
    .post(
      `${DF_API_HOST}/api/v1/b2c/reminders`,
      { reminder: reminder },
      headersAuthOnly()
    )
    .then(handleErrors)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      console.error(e);
    });
};

export const adminSendReminder = reminder => {
  return apiGet(`/api/v1/admin/reminders/${reminder.id}/send_reminder`);
};

export const adminSendOutreachEmail = emailAddress => {
  return apiGet(
    `/api/v1/admin/reminders/send_outreach_email?email=${emailAddress}`
  );
};
