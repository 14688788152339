import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import DiamondSprite from './DiamondSprite';
import DiamondDetails from './DiamondDetails';
import DiamondDetailsButtons from './DiamondDetailsButtons';
import { addItemToCart } from '../state/action/diamondCart';
import WaitSpinner from './WaitSpinner';
import SVG from './SVG';
import {
  getDiamondPhotoSrc,
  formatPricing,
  getDiamondPrice,
  getDiamondCutAbbreviation,
  getDiamondGirdleAbbreviation,
  shapeHasDiamondPhoto,
} from '../utils/util';
import { getDiamondShapeAbbreviation } from '../utils/shapeUtilities';
import DiamondFoundryCaption from './DiamondFoundryCaption';

class DiamondListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetail: true,
      item: 'info',
    };

    this.addToCart = this.addToCart.bind(this);
    this.selectDiamond = this.selectDiamond.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.setShowDetail = this.setShowDetail.bind(this);
    this.setShowItemDetail = this.setShowItemDetail.bind(this);
  }

  // This is used for showing the photo, 360 view, and the details.
  setShowDetail(e, showDetail, item) {
    e.preventDefault();
    this.setState({ showDetail, item });
  }

  // This is fired when the diamond icon is clicked.
  // This marks the diamond as selected, but does not toggle the more-info panel.
  selectDiamond(e, id) {
    e.stopPropagation();
    this.props.dispatch({ type: 'SELECT_A_DIAMOND', id });
  }

  // This is fired when the row is clicked. It toggles the more-info panel.
  handleRowClick() {
    if (this.props.expanded) {
      return this.props.setIndex(null);
    }
    this.props.setIndex(this.props.id);
  }

  handleChange() {
    /*
      This is necessary because React enforces having an onChange handler on a checkbox
      where you are setting the checked value programmatically. We want to handle
      the click event, rather than the change event, because the click event is also 
      used to expand the row. So we need to handle both events in either case.
    */
  }

  setShowItemDetail(showDetail, item) {
    if (showDetail) {
      switch (item) {
        case 'info':
          return (
            <DiamondDetails
              diamond={this.props}
              salesOrAdmin={this.props.salesOrAdmin}
            />
          );
        case 'video':
          return (
            <div className="vision360-wrp">
              <WaitSpinner />
              <DiamondSprite
                spriteImageUrl={
                  this.props.lot_id &&
                  `https://s3.amazonaws.com/videos.diamondfoundry.com/${this.props.lot_id}.jpg`
                }
                isOpenedRow={this.props.expanded}
              />
            </div>
          );
        case 'photo':
        default:
          return (
            <img
              alt="Diamond"
              className="diamond__img"
              src={getDiamondPhotoSrc(this.props.shape.toLowerCase())}
            />
          );
      }
    }
  }

  addToCart(e) {
    this.props.dispatch(
      addItemToCart(
        {
          id: this.props.id,
          name: this.props.shape,
          shape: this.props.shape.toLowerCase(),
          color: this.props.color,
          carat: this.props.carat.toString(),
          clarity: this.props.clarity,
          quantity: this.props.quantity,
          price: +getDiamondPrice(this.props),
          gcal_cert_id: this.props.gcal_cert_id,
          cert_url: this.props.cert_url,
          lot_id: this.props.lot_id,
          orderType: 'Diamond',
          updated: 'unique',
        },
        isDuplicate => {
          if (isDuplicate) {
            this.props.showToaster('Item already exists!');
          } else {
            this.props.showToaster('This item has been added to your cart.');
            setTimeout(() => {
              document
                .querySelector('.cart-icon-wrapper')
                .dispatchEvent(new Event('mouseenter'));
            });
          }
        }
      )
    );
  }

  render() {
    let isSelected = this.props.selected || false;
    let msrp = this.props.unit_price_msrp_usd
      ? formatPricing(this.props.unit_price_msrp_usd.split('.')[0])
      : '';
    let price = this.props.unit_price_msrp_usd
      ? formatPricing(getDiamondPrice(this.props).split('.')[0])
      : '';

    // Below, it is necessary to prepend "d" to the diamond id, because HTML element IDs cannot start with a number. So if we want to generate valid HTML, then we must start with a letter.
    return (
      <div
        className={
          this.props.expanded
            ? 'DiamondListItem DiamondListItem__expanded'
            : 'DiamondListItem'
        }
        id={'d' + this.props.id}
      >
        <div className="tr" onClick={this.handleRowClick}>
          <div className="td checkbox-cell">
            <div className="checkbox-container">
              <input
                type="checkbox"
                className="form-check-input"
                checked={isSelected}
                onChange={this.handleChange}
                onClick={e => this.selectDiamond(e, this.props.id)}
              />
            </div>
          </div>
          <div className="td diamond-shape detailed-hide">
            <div className="diamond-shape__image">
              <div className="image-wrapper">
                <i className="material-icons">check</i>
                {shapeHasDiamondPhoto(this.props.shape) && (
                  <img
                    src={getDiamondPhotoSrc(this.props.shape, true)}
                    alt="Diamond Icon"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="td df-show-nonmobile-only detailed-hide">
            {this.props.shape}
          </div>
          <div className="td detailed-or-mobile">
            {getDiamondShapeAbbreviation(this.props.shape)}
          </div>
          <div className="td">{this.props.carat}</div>
          <div className="td">{this.props.color}</div>
          <div className="td">{this.props.clarity}</div>
          <div className="td df-show-nonmobile-only detailed-hide">
            {this.props.cut_grade}
          </div>
          <div className="td detailed-or-mobile">
            {getDiamondCutAbbreviation(this.props.cut_grade)}
          </div>
          <div className="td">{price}</div>
          <div className="td detailed-only">
            {getDiamondCutAbbreviation(this.props.polish)}
          </div>
          <div className="td detailed-only">
            {getDiamondCutAbbreviation(this.props.symmetry)}
          </div>
          <div className="td detailed-only">{this.props.depth_pct}%</div>
          <div className="td detailed-only">{this.props.table_size}%</div>
          <div className="td detailed-only">
            {getDiamondGirdleAbbreviation(this.props.girdle)}
          </div>
          <div className="td detailed-only">{this.props.crown_angle}&deg;</div>
          <div className="td detailed-only">{this.props.crown_height}%</div>
          <div className="td detailed-only">
            {this.props.pavilion_angle}&deg;
          </div>
          <div className="td detailed-only">{this.props.pavilion_height}%</div>
        </div>

        {this.props.expanded && (
          <div className="DiamondListItem__dropdown">
            <div className="close__container">
              <SVG
                className="Svg close"
                onClick={this.props.setIndex}
                name="close-diamond-table"
                viewBox="0 0 34 32"
              />
            </div>

            <div className="DiamondListItem__column detail df-show-nonmobile-only">
              <span className="diamond-header headerinfo">{`${this.props.shape.toUpperCase()} DIAMOND`}</span>
              <DiamondDetailsButtons
                cert_url={this.props.cert_url}
                digital_assets={this.props.digital_assets}
                graded_by={this.props.graded_by}
                lot_id={this.props.lot_id}
                shape={this.props.shape}
                showDetail={this.setShowDetail}
              />
              <div className="info infocolor">
                <div className="tr clearfix">
                  <div className="td">ID:</div>
                  <div className="td">
                    <a
                      href={this.props.cert_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="diamond-id">{`F${this.props.lot_id}`}</span>
                    </a>
                  </div>
                </div>
                <div className="tr clearfix">
                  <div className="td">MSRP:</div>
                  <div className="td">{msrp}</div>
                </div>
                <div className="tr clearfix">
                  <div className="td">Wholesale:</div>
                  <div className="td">{price}</div>
                </div>
              </div>
              <div className="cart-cta__container df-show-nonmobile-only">
                <Button className="df-cta" onClick={this.addToCart}>
                  ADD TO CART
                </Button>
                <DiamondFoundryCaption />
              </div>
            </div>

            <div className="DiamondListItem__column">
              <span className="diamond-header df-show-mobile headerinfo">{`${this.props.shape.toUpperCase()} DIAMOND`}</span>
              <span className="diamond-header price  df-show-mobile">
                {this.props.unit_price_msrp_usd && `MSRP - ${msrp}`}
                {this.props.unit_price_msrp_usd && ` / Wholesale - ${price}`}
              </span>
              <a
                href={this.props.cert_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="diamond-id df-show-mobile">{`F${this.props.lot_id}`}</span>
              </a>
              <div
                className={`diamond-dynamic-info diamond-info__${this.state.item}`}
              >
                {this.setShowItemDetail(this.state.showDetail, this.state.item)}
              </div>
              <div className="cart-cta__container df-show-mobile">
                <DiamondDetailsButtons
                  cert_url={this.props.cert_url}
                  digital_assets={this.props.digital_assets}
                  graded_by={this.props.graded_by}
                  lot_id={this.props.lot_id}
                  shape={this.props.shape}
                  showDetail={this.setShowDetail}
                />
                <Button onClick={this.addToCart} className="df-cta">
                  ADD TO CART
                </Button>
                <DiamondFoundryCaption />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect()(DiamondListItem);
