import styled from '@emotion/styled';

type Props = {
  primary?: boolean;
};

const makeColorHover = ({ primary }: Props) => {
  if (!primary) {
    return '#fff';
  }
  return 'black';
};

const makeBackgroundColorHover = ({ primary = false }: Props) => {
  if (!primary) {
    return '#ff3c00';
  }
  return '#fff';
};

const makeBorderColorHover = ({ primary = false }: Props) => {
  if (!primary) {
    return '#ff3c00';
  }
  return '#ff3c00';
};

const makeBackgroundColor = ({ primary = false }: Props) => {
  if (primary) {
    return '#ff3c00';
  }
  return '#fff';
};

const makeBorderColor = ({ primary = false }: Props) => {
  if (primary) {
    return '#ff3c00';
  }
  return '#ff3c00';
};

const makeColor = ({ primary = false }: Props) => {
  if (primary) {
    return '#fff';
  }
  return 'black';
};

const DFButton = styled.button<Props>`
  color: ${makeColor};
  background-color: ${makeBackgroundColor};
  border-color: ${makeBorderColor};
  border-radius: 0;
  font-size: 1.6rem;
  -ms-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  border-width: 1px;
  border-style: solid;
  padding: 0 1rem;
  margin: 0;
  height: 50px;
  &:hover {
    color: ${makeColorHover};
    background-color: ${makeBackgroundColorHover};
    border-color: ${makeBorderColorHover};
  }
`;

export default DFButton;
