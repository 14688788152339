import React, { Component } from 'react';
import PropTypes from 'prop-types';

class UserListActions extends Component {
  render() {
    return (
      <div className="UserListActions">
        {this.props.showInternalActions && (
          <React.Fragment>
            <button
              onClick={this.props.handleEmailUsersClick}
              className="df-cta"
            >
              Email Users
            </button>
            <button
              onClick={this.props.handleSendAccountSummaryClick}
              className="df-cta"
            >
              Send Account Summary
            </button>
            <button
              onClick={this.props.handleCopyEmailAddressesClick}
              className="df-cta"
            >
              Copy Emails
            </button>
          </React.Fragment>
        )}
      </div>
    );
  }
}

UserListActions.propTypes = {
  handleSendAccountSummaryClick: PropTypes.func.isRequired,
  handleCopyEmailAddressesClick: PropTypes.func.isRequired,
  handleEmailUsersClick: PropTypes.func.isRequired,
  showInternalActions: PropTypes.bool.isRequired,
};
export default UserListActions;
