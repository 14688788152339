import React, { Component } from 'react';
import { connect } from 'react-redux';
import { remove } from 'lodash';

import { Row, Col } from 'reactstrap';
import DiamondGridButton from './DiamondGridButton'; // This component communicates with the filters stored in Redux.
import { getDiamondColorCatalog } from '../utils/util';
import { getFilterGridCaratRanges } from '../utils/caratUtilities';
import { getFilterGridShapeList } from '../utils/shapeUtilities';
import {
  removeShapeCaratFilter,
  addShapeCaratFilter,
} from '../state/action/diamondFilters';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const filterShapeCaratStyles = css`
  @media screen and (max-width: 640px) {
    .col-sm-1 {
      &:first-child {
        margin-right: 30px;
      }
    }
  }

  @media screen and (min-width: 641px) and (max-width: 1280px) {
    .col-sm-1 {
      &:first-child {
        margin-right: 5px;
      }
    }
  }
`;

class FilterShapeCarat extends Component {
  constructor(props) {
    super(props);

    this.shapes = getFilterGridShapeList();
    this.carats = getFilterGridCaratRanges();

    this.calculateCount = this.calculateCount.bind(this);
    this.getCaption = this.getCaption.bind(this);
    this.toggleShape = this.toggleShape.bind(this);
  }

  // This method returns the count for the given shape and carat range,
  // across all selected colors. The counts and selected colors MUST be
  // passed in as props.
  calculateCount(shape, caratRangeIndex) {
    let selectedColors =
      this.props.filters.color.length > 0
        ? [...this.props.filters.color]
        : getDiamondColorCatalog();
    let counts = this.props.stats.diamonds || {};
    let sum = 0;

    // Ensure that we have counts to sum up
    if (Object.keys(counts).length === 0) {
      return 0;
    }

    // Do this awkward conversion
    if (shape === 'Round Brilliant') shape = 'Brilliant Round';

    // replace the J+ color with the colors it represents
    if (selectedColors.indexOf('J+') > -1) {
      remove(selectedColors, function(n) {
        return n === 'J+';
      }); // remove J+
      selectedColors.push('J', 'K', 'L', 'M'); // add J, K, L, M
    }

    for (let i = 0; i < selectedColors.length; i++) {
      let key = `${selectedColors[i]}-${shape}-${caratRangeIndex}`;
      sum += counts[key] || 0;
    }

    return sum;
  }

  getCaption(shape, caratRangeIndex) {
    return this.calculateCount(shape, caratRangeIndex);
  }

  getShapeHeader(shape) {
    if (shape == 'Round Brilliant') return 'Round';
    return shape;
  }

  getCaratRangeHeader(caratRangeIndex) {
    let caratRangeHeaders = [
      '0.80 - 0.99',
      '1.00 - 1.39',
      '1.40 - 1.79',
      '1.80 - 2.49',
      '2.5 - 3.99',
      '4 - 5.99',
      '6+',
    ];
    let caratRangeHeadersShort = [
      '0.8+',
      '1.0+',
      '1.4+',
      '1.8+',
      '2.5+',
      '4+',
      '6+',
    ];

    return (
      <span>
        <span className="caratRange df-show-desktop-only">
          {caratRangeHeaders[caratRangeIndex]}
        </span>
        <span className="df-show-nondesktop-only">
          {caratRangeHeadersShort[caratRangeIndex]}
        </span>
      </span>
    );
  }

  // Select or deselect all items in a row
  toggleShape(shape) {
    // First check if any buttons are selected for this shape
    let anySelected = false;
    let shapeCaratFilters = this.props.filters.shapeCarat;
    for (let i = 0; i < shapeCaratFilters.length; i++) {
      if (shapeCaratFilters[i].shape === shape) {
        anySelected = true;
        break;
      }
    }

    // Then select or deselect them all
    if (anySelected) {
      for (let i = 0; i < this.carats.length; i++) {
        this.props.dispatch(
          removeShapeCaratFilter({
            shape: shape,
            carat: this.carats[i],
            caratRangeIndex: i,
          })
        );
      }
    } else {
      for (let i = 0; i < this.carats.length; i++) {
        this.props.dispatch(
          addShapeCaratFilter({
            shape: shape,
            carat: this.carats[i],
            caratRangeIndex: i,
          })
        );
      }
    }
  }

  // Select or deselect all items in a column
  toggleCaratRange(caratRangeIndex) {
    // First check if any buttons are selected for this shape
    let anySelected = false;
    let shapeCaratFilters = this.props.filters.shapeCarat;
    for (let i = 0; i < shapeCaratFilters.length; i++) {
      if (shapeCaratFilters[i].caratRangeIndex === caratRangeIndex) {
        anySelected = true;
        break;
      }
    }

    // Then select or deselect them all
    if (anySelected) {
      for (let i = 0; i < this.shapes.length; i++) {
        this.props.dispatch(
          removeShapeCaratFilter({
            shape: this.shapes[i],
            carat: this.carats[caratRangeIndex],
            caratRangeIndex: caratRangeIndex,
          })
        );
      }
    } else {
      for (let i = 0; i < this.shapes.length; i++) {
        this.props.dispatch(
          addShapeCaratFilter({
            shape: this.shapes[i],
            carat: this.carats[caratRangeIndex],
            caratRangeIndex: caratRangeIndex,
          })
        );
      }
    }
  }

  render() {
    return (
      <div css={filterShapeCaratStyles} className="FilterShapeCarat">
        <Row key="carat-filter">
          <Col sm="1" className=""></Col>
          {this.carats.map((caratRange, index) => {
            return (
              <Col key={`carat-${index}-filter`} sm="1" className="header">
                <a
                  onClick={e => {
                    this.toggleCaratRange(index);
                  }}
                >
                  {this.getCaratRangeHeader(index)}
                </a>
              </Col>
            );
          })}
        </Row>

        {this.shapes.map(shape => {
          return (
            <Row key={`${shape}-filter`}>
              <Col sm="1" className="legend">
                <a
                  onClick={e => {
                    this.toggleShape(shape);
                  }}
                >
                  <div className="df-show-nonmobile-only">
                    {this.getShapeHeader(shape)}
                  </div>
                  <div className="df-show-mobile">
                    {this.getShapeHeader(shape)}
                  </div>
                </a>
              </Col>
              {this.carats.map((caratRange, index) => {
                return (
                  <Col
                    key={`${shape}-${index}-filter`}
                    sm="1"
                    className="DiamondGridButton-container"
                  >
                    <DiamondGridButton
                      shape={shape}
                      caratRange={caratRange}
                      caratRangeIndex={index}
                      caption={this.getCaption(shape, index)}
                    />
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.filters,
    stats: state.stats,
  };
};

export default connect(mapStateToProps)(FilterShapeCarat);
