import React, { Component } from 'react';
import { Link } from '@reach/router';

class BackButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      param: null,
    };
  }

  async componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const prevPage = urlParams.get('prev');
    if (prevPage) await this.setState({ param: prevPage });
  }

  render() {
    const { param } = this.state;
    const { text, link } = this.props;
    return (
      <div className="back-link">
        <Link to={param || link} className="df-link flex">
          <i className="material-icons">arrow_back</i>
          {text}
        </Link>
      </div>
    );
  }
}

export default BackButton;
