import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { navigate } from '../utils/routerHistory';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { addShippingAddress } from '../state/auth/actions';
import { createAddress } from '../api/index';
import PropTypes from 'prop-types';
import BackButton from '../components/BackButton';

class AddShippingAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {
        attention: '',
        city: '',
        country: '',
        line_2: '',
        line_3: '',
        name: '',
        phone: '',
        state: '',
        street_address: '',
        zip: '',
      },
    };
    this.validator = new SimpleReactValidator({
      messages: {
        size: 'The number must be 10 characters.',
      },
    });
    this.addAddress = this.addAddress.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async addAddress(e) {
    const { address, prevPage } = this.state;
    const customer_account_id = this.props.user.customer_account.id;
    if (this.validator.allValid()) {
      const payload = await createAddress(customer_account_id, address);
      if (payload.status === 200) {
        this.props.addShippingAddress(payload.data);
        this.props.showToaster('New address has been created.');

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const prevPage = urlParams.get('prev') || '/checkout';
        navigate(prevPage);
      } else {
        this.props.showToaster('New address create failed.');
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleInputChange(e) {
    if (e.target.name === 'phone') {
      e.target.value = e.target.value.replace(new RegExp('[+]*'), '');
    }
    const { name, value } = e.target;
    const { address } = this.state;
    address[name] = value;
    this.setState({ address });
  }

  render() {
    const phone = this.state.address.phone.replace('+', '');
    return (
      <div className="add_shipping_address_page">
        <div className="back">
          <BackButton link="/checkout" text="Back" />
        </div>
        <div className="center-container">
          <div className="title">ADD NEW SHIPPING ADDRESS</div>
          <div className="input-boxes">
            <div className="box">
              <div className="row-box">
                <div className="input-field">
                  <input
                    className="df-text-inputs"
                    name="name"
                    placeholder="Contact Name*"
                    onChange={e => {
                      this.handleInputChange(e);
                      this.validator.showMessageFor('name');
                    }}
                  />
                  {this.validator.message(
                    'name',
                    this.state.address.name,
                    'required|alpha_num_space'
                  )}
                </div>
                <div className="input-field">
                  <input
                    className="df-text-inputs"
                    name="street_address"
                    placeholder="Street Address (Line 1)*"
                    onChange={e => {
                      this.handleInputChange(e);
                      this.validator.showMessageFor('street_address');
                    }}
                  />
                  {this.validator.message(
                    'street_address',
                    this.state.address.street_address,
                    'required|string'
                  )}
                </div>
              </div>

              <div className="row-box">
                <div className="input-field">
                  <input
                    className="df-text-inputs"
                    name="line_2"
                    placeholder="Street Address (Line 2 - Optional)"
                    onChange={e => {
                      this.handleInputChange(e);
                      this.validator.showMessageFor('line_2');
                    }}
                  />
                  {this.validator.message(
                    'line_2',
                    this.state.address.line_2,
                    'string'
                  )}
                </div>
                <div className="input-field">
                  <input
                    className="df-text-inputs"
                    name="city"
                    placeholder="City*"
                    onChange={e => {
                      this.handleInputChange(e);
                      this.validator.showMessageFor('city');
                    }}
                  />
                  {this.validator.message(
                    'city',
                    this.state.address.city,
                    'required|alpha_space'
                  )}
                </div>
              </div>
              <div className="row-box">
                <div className="input-field">
                  <input
                    className="df-text-inputs"
                    name="state"
                    placeholder="State*"
                    onChange={e => {
                      this.handleInputChange(e);
                      this.validator.showMessageFor('state');
                    }}
                  />
                  {this.validator.message(
                    'state',
                    this.state.address.state,
                    'required|alpha_space'
                  )}
                </div>
                <div className="input-field">
                  <input
                    className="df-text-inputs"
                    name="zip"
                    placeholder="Postal Code*"
                    onChange={e => {
                      this.handleInputChange(e);
                      this.validator.showMessageFor('zip');
                    }}
                  />
                  {this.validator.message(
                    'zip',
                    this.state.address.zip,
                    'required'
                  )}
                </div>
              </div>

              <div className="row-box">
                <div className="input-field">
                  <input
                    className="df-text-inputs"
                    name="country"
                    placeholder="Country Code*"
                    onChange={e => {
                      this.handleInputChange(e);
                      this.validator.showMessageFor('country');
                    }}
                  />
                  {this.validator.message(
                    'country',
                    this.state.address.country,
                    'required|alpha'
                  )}
                </div>
                <div className="input-field">
                  <input
                    className="df-text-inputs"
                    name="phone"
                    value={phone ? `+${phone}` : ''}
                    onChange={e => {
                      this.handleInputChange(e);
                      this.validator.showMessageFor('phone');
                    }}
                    placeholder="Phone*"
                  />
                  {this.validator.message(
                    'phone',
                    this.state.address.phone,
                    'required'
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mandatory">* Mandatory</div>
          <div className="button-class">
            <Button
              className="button df-cta"
              color="primary"
              onClick={() => this.addAddress()}
            >
              CONFIRM & SAVE
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

AddShippingAddress.propTypes = {
  showToaster: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addShippingAddress,
};
const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddShippingAddress);
