import React, { Component } from 'react';
import { connect } from 'react-redux';
import { adminFetchAllOrders } from '../../api/index';
import {
  AdminOrdersSummaryTable,
  OnWaitSpinner,
} from '../../utils/orderUtilities';

// Admin orders page accessible via /admin/orders
class AdminOrdersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      loading: false, // Used for fetching orders.
    };

    this.adminFetchAllOrders = this.adminFetchAllOrders.bind(this);
    this.onAdminFetchAllOrdersChanged = this.onAdminFetchAllOrdersChanged.bind(
      this
    );
  }

  componentDidMount() {
    this.adminFetchAllOrders();
  }

  adminFetchAllOrders(days = '15') {
    this.setState({ loading: true }, () => {
      adminFetchAllOrders(days).then(res => {
        this.setState({ orders: res, loading: false });
      });
    });
  }

  async onAdminFetchAllOrdersChanged(event) {
    await this.adminFetchAllOrders(event.target.value);
  }

  render() {
    const { orders, loading } = this.state;
    return (
      <div className="main-wrapper">
        <h1 className="title">All Orders</h1>
        <div className="df-select__control">
          <label className="container">
            Days:&nbsp;
            <select
              onChange={this.onAdminFetchAllOrdersChanged}
              className="df-select-container"
            >
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="45">45</option>
              <option value="60">60</option>
              <option value="90">90</option>
              <option value="180">180</option>
              <option value="365">365</option>
            </select>
          </label>
        </div>
        {loading ? (
          <OnWaitSpinner />
        ) : (
          <AdminOrdersSummaryTable orders={orders} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AdminOrdersPage);
