import React, { Component } from 'react';
import SignupReferenceInput from '../components/SignupReferenceInput';

import SimpleReactValidator from 'simple-react-validator';

class AffiliationReferenceForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ locale: 'en' });
  }

  render() {
    return (
      <div className="registration-form">
        <div className="registration-form-elements left">
          <label htmlFor="affiliation_reference[company_name]">
            Company Name*
          </label>
          <SignupReferenceInput
            key="affiliation_reference-company_name"
            inputID="affiliation_reference-company_name"
            inputType="text"
            inputName="affiliation_reference[company_name]"
            onChangeHandler={e => {
              this.props.setParentState(
                {
                  affiliation_reference_attributes: {
                    company_name: e.target.value,
                  },
                },
                null
              );
              this.validator.showMessages('company_name');
            }}
          />
          {this.validator.message(
            'company_name',
            this.props.affiliation_reference_attributes.company_name,
            'required'
          )}
        </div>

        <div className="registration-form-elements right">
          <label htmlFor="affiliation_reference[account_manager]">
            Account Manager
          </label>
          <SignupReferenceInput
            key="affiliation_reference-account_manager"
            inputID="affiliation_reference-account_manager"
            inputType="text"
            inputName="affiliation_reference[account_manager]"
            onChangeHandler={e =>
              this.props.setParentState(
                {
                  affiliation_reference_attributes: {
                    df_account_manager_name: e.target.value,
                  },
                },
                null
              )
            }
          />
        </div>
      </div>
    );
  }
}

export default AffiliationReferenceForm;
