import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import auth from './auth/reducer';
import { saveAuthToken } from './middleware';

import usersReducer from '../state/users/reducer';
import diamondSearchReducer from '../state/reducer/diamondSearch';
import diamondFilterReducer from '../state/reducer/diamondFilters';
import diamondRangeFilterReducer from '../state/reducer/diamondRangeFilter';
import diamondCartReducer from '../state/reducer/diamondCart';
import diamondLoadingReducer from '../state/reducer/diamondLoading';
import roughDiamondGroupsReducer from '../state/roughDiamondGroups/reducer';
import savedFiltersReducer from '../state/reducer/savedFilters';
import watchesReducer from './watches/reducer';
import statsReducer from './stats/reducer';
import customerAccountsReducer from './customers/reducer';
import polishedDiamondGroupsReducer from '../state/polishedDiamondGroups/reducer';

const reducers = {
  polishedDiamondGroups: polishedDiamondGroupsReducer,
  customerAccounts: customerAccountsReducer,
  auth,
  diamonds: diamondSearchReducer,
  diamondCart: diamondCartReducer,
  filters: diamondFilterReducer,
  isDiamondsLoading: diamondLoadingReducer, // This code is inherited from contractors. I think we can refactor or remove it.
  rangeFilters: diamondRangeFilterReducer, // This code is inherited from contractors. I think we can refactor or remove it.
  roughDiamondGroups: roughDiamondGroupsReducer,
  savedFilters: savedFiltersReducer,
  stats: statsReducer,
  users: usersReducer,
  watches: watchesReducer,
};

const reducer = combineReducers(reducers);

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk, saveAuthToken));

export const store = createStore(reducer, enhancer);
