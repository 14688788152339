import { getTwoLetterShapeAbbreviation } from './shapeUtilities';

/*
@object: any
@return: boolean

Check if object exists.
 */
export const exists = object => {
  return object !== undefined && object !== null;
};

export const compare = (map1, map2, key) => {
  if (map1[key] === undefined || map2[key] === undefined) {
    throw new Error(`${key} undefined`);
  }

  const a = map1[key];
  const b = map2[key];

  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  return 0;
};
export const disableScrollY = () => {
  const root = document.body;
  const hasClass = root.classList.contains('disable-scroll');

  if (!hasClass) {
    root.classList.add('disable-scroll');
  }
};

export const enableScrollY = () => {
  const root = document.body;
  const hasClass = root.classList.contains('disable-scroll');

  if (hasClass) {
    root.classList.remove('disable-scroll');
  }
};

// Return true if this user is permitted to see admin UIs.
export const isInternalUser = roles => {
  return salesOrAdmin(roles);
};

// This is the underscore.js debounce function.
// I didn't want to add underscore.js just for this function.
// See https://davidwalsh.name/javascript-debounce-function
//
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, wait, immediate) => {
  var timeout;
  return function() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

// There might be a better way to do this. I liked the encapsulation of this solution.
// This was taken from here: https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
export const download = (filename, text, type = 'text/csv') => {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:' + type + ';charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const formatCurrency = value => {
  if (value == null || isNaN(value)) {
    // do not return the empty string if value is 0, which is falsey
    return '';
  } else if (parseInt(value) == 0) {
    return '$0.00';
  }
  return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const formatPlanDiamonds = (quantity, weight, shape) => {
  if (!!quantity && quantity > 0) {
    return `${formatFloat(weight)}ct. ${getTwoLetterShapeAbbreviation(
      shape
    )} x ${quantity}`;
  }
  return '';
};

export const getDiamondColorCatalog = () => {
  return ['M', 'L', 'K', 'J', 'I', 'H', 'G', 'F', 'E', 'D'];
};

export const getDiamondColorCatalogForFilter = () => {
  return ['D', 'E', 'F', 'G', 'H', 'I', 'J+'];
};

export const getDiamondClarityCatalog = () => {
  return ['SI2', 'SI1', 'VS2', 'VS1', 'VVS2', 'VVS1', 'IF'];
};
export const getDiamondClarityDetail = name =>
  ({
    VVS1: '',
    VVS2:
      'Very very slightly included. Very difficult to see under 10× magnification.',
    VS1: 'Very slightly included. Difficult even for a gemologist to see.',
    VS2: 'Very slightly included. Difficult even for a gemologist to see.',
    SI1:
      'Slightly included. An imperfection can be seen by a gemologist under 10× magnification.',
    SI2:
      'Slightly included. An imperfection can be seen by a gemologist under 10× magnification.',
    I1: '',
  }[name]);
export const getDiamondColorDetail = name =>
  ({
    M: '',
    K:
      'Faint. Slight tint noticeable by a gemologist. White to non-gemologists. Excellent value.',
    J:
      'Near-colorless. Slight color noticeable only when compared side-by-side to diamonds of better grades.',
    I:
      'Near-colorless. Slight color noticeable only when compared side-by-side to diamonds of better grades.',
    H: 'Near-colorless. No color noticeable by non-experts. Excellent value.',
    G: 'Near-colorless. No color noticeable by non-experts. Excellent value.',
    F:
      'Faint. Slight tint noticeable by a gemologist. White to non-gemologists. Excellent value.',
    L: '',
  }[name]);
export const getDiamondCutAbbreviation = cut => {
  switch (cut) {
    case 'Very Good':
      return 'VG';
    case 'Excellent':
      return 'EX';
    case 'Signature Ideal':
      return 'S Ideal';
    case 'Ideal+Hearts':
      return 'Hearts';
    default:
      return cut;
  }
};
export const getDiamondCutCatalog = () => {
  return ['Good', 'Very Good', 'Excellent', 'Ideal', 'Ideal+Hearts'];
};
export const getDiamondCutDetail = name =>
  ({
    'Ideal+Hearts': 'Cut to perfection. The rarest of all cuts.',
    Ideal:
      'Cut to maximize brilliance. Perfect proportions. Rare quality held by <1% of all diamonds.',
    Excellent:
      'Perfect premium cut. Proportioned to return the maximum brilliance possible.',
    'Very Good':
      'High quality cut. Proportioned to return nearly all light that enters.',
    Good: 'Good cut. Proportioned to reflect most of the light that enters.',
  }[name]);

export const getDiamondIconSrc = name => {
  if (!name) return '';

  // "round-brilliant" shape is the only shape that does not adhere to normalized icon naming
  if (RegExp(/(round)/im).test(name)) {
    return `https://static.diamondfoundry.com/portal/df/shapes/brilliant-round-top-sm.png`;
  }
  const normalizedName = name.replace(' ', '-');

  return `https://static.diamondfoundry.com/portal/df/shapes/${normalizedName}-top-sm.png`;
};

export const shapeHasDiamondPhoto = shape => {
  return !shape || !!getDiamondPhotoSrc(shape, false);
};

// shape = string = shape name
// list = boolean = is this the small image shown in the diamond list?
export const getDiamondPhotoSrc = (shape, list) => {
  let url = 'https://static.diamondfoundry.com/portal/diamonds/';
  if (list) {
    url = url + 'icons/';
  }

  switch (shape.toLowerCase()) {
    case 'aquila':
      return url + 'aquila-top-ph.png';
    case 'asscher':
      return url + 'asscher.png';
    case 'baguette':
      return url + 'baguette-top-ph.png';
    case 'bright square':
      return url + 'bright-square-top-ph.png';
    case 'brilliant emerald':
      return url + 'brilliant-emerald-top-ph.png';
    case 'brilliant round':
    case 'round brilliant':
      return url + 'round-brilliant.png';
    case 'capri':
      return url + 'capri-top-ph.png';
    case 'cushion':
      return url + 'cushion.png';
    case 'cushion princess':
      return url + 'cushion-princess-top-ph.png';
    case 'delphine':
      return url + 'delphine-top-ph.png';
    case 'emerald':
      return url + 'emerald.png';
    case 'epaulette':
      return url + 'epaulette-top-ph.png';
    case 'felix':
      return url + 'felix-top-ph.png';
    case 'flanders':
      return url + 'flanders-top-ph.png';
    case 'half moon':
      return url + `half-moon-top-ph.png`;
    case 'hexagon rose':
      return url + `hexagon-rose-top-ph.png`;
    case 'long hexagon':
      return url + `long-hexagon-top-ph.png`;
    case 'long octagon':
      return url + `long-octagon-top-ph.png`;
    case 'marquise':
      return url + `marquise.png`;
    case 'oval':
      return url + `oval.png`;
    case 'oval rose':
      return url + `oval-rose.png`;
    case 'princess':
      return url + `princess.png`;
    case 'pear':
      return url + `pear.png`;
    case 'radiant':
      return url + `radiant.png`;
    case 'rose':
      return url + `round-rose.png`;
    case 'super emerald':
      return url + `super-emerald-top-ph.png`;
    case 'fusion':
    case 'harmonia':
    case 'hexagon':
    case 'keystone':
    case 'kite':
    case 'lozenge':
    case 'lucky':
    case 'octavia':
    case 'passion':
    case 'rand':
    case 'regulus':
    case 'rio':
    case 'shield':
    case 'trillion':
      return url + `${shape.toLowerCase()}-top-ph.png`;
    default:
      return null;
  }
};

// This belongs in a dedicated Diamond model, but we are centralizing
// it into a helper method while we don't have models.
export const getDiamondPrice = diamond => {
  if (diamond.prices && diamond.prices[0] && diamond.prices[0].amount_usd) {
    return diamond.prices[0].amount_usd;
  }
  return diamond.unit_price_msrp_usd || diamond.default_price;
};

// TODO: Refactor this to use string replacement
export const getDiamondGirdleAbbreviation = girdle => {
  switch (
    girdle
      .toString()
      .toLowerCase()
      .trim()
  ) {
    case 'extremely thin':
      return 'ETN';
    case 'very thin':
      return 'VTN';
    case 'very thin to extremely thick':
      return 'VTN-ETK';
    case 'thin':
      return 'TN';
    case 'thin to medium':
      return 'TN-M';
    case 'thin to slightly thick':
      return 'TN-STK';
    case 'thin to thick':
      return 'TN-TK';
    case 'thin to very thick':
      return 'TN-VTK';
    case 'thin to extremely thick':
      return 'TN-ETK';
    case 'medium':
      return 'M';
    case 'medium to slightly thick':
      return 'M-STK';
    case 'medium to thick':
      return 'M-TK';
    case 'medium to very thick':
      return 'M-VTK';
    case 'slightly thick':
      return 'STK';
    case 'slightly thick to thick':
      return 'STK-TK';
    case 'slightly thick to very thick':
      return 'STK-VTK';
    case 'slightly thick to extremely thick':
      return 'STK-ETK';
    case 'thick':
      return 'TK';
    case 'thick':
      return 'TK';
    case 'thick to slightly thick':
      return 'TK-STK';
    case 'thick to very thick':
      return 'TK-VTK';
    case 'thick to extremely thick':
      return 'TK-ETK';
    case 'very thick':
      return 'VTK';
    case 'very thick to extremely thick':
      return 'VTK-ETK';
    case 'extremely thick':
      return 'ETK';
    case 'medium to extremely thick':
      return 'M-ETK';
    default:
      return girdle;
  }
};

export const getItemPrice = (carat, quantity) => {
  const QUANTITY = +quantity;

  switch (carat) {
    case '0.25':
      return QUANTITY * 174;
    case '0.30':
      return QUANTITY * 229;
    case '0.40':
      return QUANTITY * 353;
    case '0.50':
      return QUANTITY * 493;
    case '0.60':
      return QUANTITY * 648;
    case '0.75':
      return QUANTITY * 906;
    case '1.00':
      return QUANTITY * 1395;
    case '1.50':
      return QUANTITY * 2563;
    case '2.00':
      return QUANTITY * 3946;
    case '3.00':
      return QUANTITY * 7249;
    case '4.00':
      return QUANTITY * 11160;
    default:
      return '';
  }
};

export const formatFloat = input => {
  if (!input) return '';
  return `${input.toFixed(2)}`;
};

export const formatPricing = pricing => {
  return formatUSD(pricing);
};

export const formatPricingFromPennies = priceInPennies => {
  return formatUSD(priceInPennies / 100.0);
};

export const roughGroupPrice = (parcelPrice, defaultPrice) => {
  // the assigned customer price 'parcel_price' is the real price and is a currency numeric like all item prices
  // default_price is in pennies it is a fallback only and should be used only when 'parcel_price' is not present
  return parcelPrice || defaultPrice / 100.0;
};

export const formatPhoneNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

// Return the array of possible quantities when purchasing bulk diamonds.
export const getQuantityRange = maximum => {
  let values = [];
  let i = 1;
  while (i <= maximum) {
    values.push({ label: String(i), value: i });
    i++;
  }
  return values;
};

/**
 *
 * @param {node} element
 * @param {number} to
 * @param {number} duration
 */
export const scrollTo = (element, to, duration) => {
  if (duration <= 0) return;
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 5; // calculating top per tick
  setTimeout(function() {
    element.scrollTop += perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 5); // recursion
  }, 10);
};

/**
 * This method focus the invalid field, and scrolls the body element
 */
export const focusField = (id, bufferTop = 200) => {
  const element = document.getElementById(id);
  if (element) {
    // if the concern element found then
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = element.getBoundingClientRect();
    const offset = elemRect.top - bodyRect.top;
    if (offset) {
      /* top constraint from the boundary of the element to the top of the body */
      scrollTo(document.body, offset - bufferTop, 200); // for mac browsers
      scrollTo(document.documentElement, offset - bufferTop, 200); // for webkit browsers
      setTimeout(() => {
        if (element.focus) {
          element.focus();
        }
      }, 400);
    }
  }
};

export const salesOrAdmin = roles => {
  if (roles) {
    return roles.some(role => role === 'admin' || role === 'sales');
  }
  return false;
};

/**
 * getPropValue: Pass in an object and a key, and return the value of that key from the object
 * @param { Object } obj
 * @param { String } key similar to accessing a js obj ex. 'main.content.title'
 * @returns { String } value of key
 */
export const getPropValue = (obj, key) =>
  key.split('.').reduce((o, x) => (o === undefined ? o : o[x]), obj);

export function formatUSD(value) {
  // in built JS currency formatter
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
}

export function formatUSDWithNoDecimals(value) {
  // in built JS currency formatter
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
}

const salesRepAssignedToCustomer = (user, customer) => {
  return ((customer || {}).sales_representative || {}).email === user.email;
};

export const permittedToMemo = (user, customerAccount) => {
  const roles = (user || []).roles || [];

  if (
    !customerAccount ||
    !customerAccount.memo_permitted ||
    roles.length === 0
  ) {
    return false;
  }
  // admins are permitted to create memo's for any memo_permitted customer
  if (roles.includes('admin')) {
    return true;
  }

  return (
    roles.includes('sales') && salesRepAssignedToCustomer(user, customerAccount)
  );
};
