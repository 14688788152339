import React, { Component } from 'react';

// This class provides an email preview of the Blank_Notification_Template email on Mandrill.
// See https://mandrillapp.com/templates/code?id=blank-notification-template
class BlankNotificationTemplate extends Component {
  render() {
    return (
      <div className="BlankNotificationTemplate">
        <link
          href="https://fonts.googleapis.com/css?family=Roboto&display=swap"
          rel="stylesheet"
        />
        <div className="bodyContent">
          <div className="body__logo">
            <img
              className="logo--image"
              src="https://static.diamondfoundry.com/icons/DF_Logotype.png"
            />
          </div>
          <p className="sub-heading">{this.props.title}</p>
          <div className="mailContent">
            <div dangerouslySetInnerHTML={{ __html: this.props.message }}></div>
            <br />
          </div>
          <div className="email-footer">
            <table className="footer__table">
              <tbody className="footer--table--content">
                <tr className="footer--content">
                  <td>
                    <a
                      className="remove--underline"
                      href="https://portal.diamondfoundry.com/diamonds"
                    >
                      Our Diamonds
                    </a>
                  </td>
                  <td>
                    <a
                      className="remove--underline"
                      href="https://diamondfoundry.com/pages/about-diamond-foundry"
                    >
                      Our Foundry
                    </a>
                  </td>
                  <td>
                    <a
                      className="remove--underline"
                      href="https://portal.diamondfoundry.com/page/privacy"
                    >
                      Privacy Policy
                    </a>
                  </td>
                  <td>
                    <a
                      className="remove--underline"
                      href="https://portal.diamondfoundry.com/page/terms"
                    >
                      Terms & Conditions
                    </a>
                  </td>
                  <td>
                    <a
                      className="remove--underline"
                      href="https://portal.diamondfoundry.com/page/contact"
                    >
                      Contact Us
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="copyright">©2020 DIAMOND FOUNDRY INC.</div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlankNotificationTemplate;
