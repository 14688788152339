import React from 'react';

import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';

import TimestampProvider from '../components/admin/TimestampProvider';
import TableComponent from '../components/admin/TableComponent';
import { Link } from '@reach/router';
import { formatUSD } from '../utils/util';
import { OrderSummaryTableColumn } from '../utils/orderUtilities';

const OrderList = ({ orders }) => {
  const orderColumns = [
    {
      name: 'doc_number',
      title: 'Sales Order ID',
      getCellValue: row => (
        <Link className="df-link" to={`/orders/${row.id}`}>
          {
            row.transactions.find(
              transaction => transaction.transaction_type === 'Sales Order'
            )?.doc_number
          }
        </Link>
      ),
    },
    {
      name: 'erp_id',
      title: 'Netsuite ID',
      getCellValue: row => row.erp_id,
    },
    {
      name: 'order_type',
      title: 'Type',
      getCellValue: row => (row.is_consignment ? 'Memo' : 'Order'),
    },
    {
      name: 'items',
      title: 'Items',
      getCellValue: row =>
        row.order_items ? row.order_items.length : console.log(row),
    },
    {
      name: 'summary',
      title: 'Summary',
      getCellValue: row => <OrderSummaryTableColumn order={row} key={row.id} />,
    },
    { name: 'created_at', title: 'Date' },
    {
      name: 'total',
      title: 'TOTAL',
      getCellValue: row => formatUSD(row.grand_total_in_cents / 100.0),
    },
  ];

  return (
    <Grid rows={orders} columns={orderColumns}>
      <SortingState
        defaultSorting={[{ columnName: 'created_at', direction: 'desc' }]}
      />

      <SearchState defaultValue="" />
      <IntegratedSorting />
      <IntegratedFiltering />
      <TimestampProvider for={['created_at']} />
      <Table
        tableComponent={TableComponent}
        columnExtensions={[{ columnName: 'id', width: '40%' }]}
      />
      <TableHeaderRow showSortingControls />
    </Grid>
  );
};

export default OrderList;
