import React from 'react';
import SVG from './SVG';

// Header for non-logged in users.
const DfNav = () => (
  <div className="Nav_wrapper Nav_wrapper--thin">
    <div className="Nav">
      <div className="Navbar">
        <a href="/">
          <SVG
            className="Navbar__logo df-show-nonmobile-only "
            viewBox="0 0 400 45"
            name="logo-transparent"
          />

          <SVG
            className="Navbar__logo__mobile df-show-mobile"
            viewBox="0 0 400 400"
            name="logo-transparent-collapse"
          />
        </a>
      </div>
    </div>
  </div>
);

export default DfNav;
