import React from 'react';
import { Router, Redirect } from '@reach/router';

import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import NotFoundPage from '../pages/NotFoundPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import ForgotPage from '../pages/ForgotPage';
import UnsubscribePage from '../pages/UnsubscribePage';

import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import DiamondsPage from '../pages/DiamondsPage';
import RoughDiamondGroupsPage from '../pages/RoughDiamondGroupsPage';
import DashboardPage from '../pages/DashboardPage';
import CartPage from '../pages/CartPage';
import CheckOutPage from '../pages/CheckOutPage';
import AddShippingAddressPage from '../pages/AddShippingAddressPage';
import RingBuilderPage from '../pages/RingBuilderPage';
import MarketingPage from '../pages/MarketingPage';
import OrdersPage from '../pages/OrdersPage';
import OrderDetailsPage from '../pages/OrderDetailsPage';
import InviteUserPage from '../pages/InviteUserPage';
import UserEditPage from '../pages/UserEditPage';
import CreditCardPage from '../pages/CreditCardPage';
import InviteSignUpPage from '../pages/InviteSignUpPage';
import WatchesPage from '../pages/WatchesPage';
import UsersIndex from '../pages/admin/UsersIndex';
import WatchesIndex from '../pages/admin/WatchesIndex';
import RemindersIndex from '../pages/admin/RemindersIndex';
import ReminderCreatePage from '../pages/admin/ReminderCreatePage';
import AdminDashboard from '../pages/admin/Dashboard';
import UserDetailsPage from '../pages/admin/UserDetailsPage';
import UserCartPage from '../pages/admin/UserCartPage';
import UserOrdersPage from '../pages/admin/UserOrdersPage';
import CustomOrdersPage from '../pages/CustomOrdersPage';
import NotifyUsersPage from '../pages/admin/NotifyUsersPage';
import CodeOfConductPage from '../pages/CodeOfConductPage';
import CustomerAccountDetailsPage from '../pages/admin/CustomerAccountDetailsPage';
import CustomerAccountsPage from '../pages/admin/CustomerAccountsPage';
import AccountStatementPage from '../pages/AccountStatementPage';
import AgingReportPage from '../pages/admin/AgingReportPage';
import ConsignmentOrdersPage from '../pages/admin/ConsignmentOrdersPage';
import AdminOrdersPage from '../pages/admin/AdminOrdersPage';
import PolishedDiamondGroupsPage from '../pages/PolishedDiamondGroupsPage';
import InventoryHoldsPage from '../pages/admin/InventoryHoldsPage';
import TransactionPage from '../pages/TransactionPage';

// if there is no route match for admin/sales users, redirect to /admin
const NoMatchAdmin = () => <Redirect noThrow to="/admin/dashboard" />;

export const AdminRoutes = ({ showToaster, closeToaster }) => (
  <Router className="main">
    <Redirect noThrow from="/" to="/admin/dashboard" />
    {/* Unprotected Routes */}
    <InviteSignUpPage
      path="/accept"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <RegisterPage
      path="/register"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <LoginPage
      path="/login"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <NotFoundPage
      default
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <ForgotPage
      path="/reset_password"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <UnsubscribePage
      path="/unsubscribe"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <TermsAndConditionsPage
      path="/terms-and-conditions"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <PrivacyPolicyPage
      path="/privacy-policy"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CodeOfConductPage
      path="/supplier-code-of-conduct"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    {/* Protected Routes */}
    <CartPage
      path="/cart"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CheckOutPage
      path="/checkout"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <DiamondsPage
      path="/diamonds"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <RoughDiamondGroupsPage
      path="/rough"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <DashboardPage
      path="/dashboard"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <OrdersPage
      path="/orders"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <OrderDetailsPage
      path="/orders/:orderId"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <AddShippingAddressPage
      path="/addshippingaddress"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <RingBuilderPage
      path="/shopify-app"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <MarketingPage
      path="/marketing-materials"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <UserEditPage
      path="/user/edit"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CreditCardPage
      path="/credit-card"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <RoughDiamondGroupsPage
      path="/rough"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <PolishedDiamondGroupsPage
      path="/polished"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    {/* Admin Routes */}
    <InviteUserPage
      path="/inviteuser"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <AdminDashboard
      path="/admin/dashboard"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <UsersIndex
      path="/admin/users"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <UserDetailsPage
      path="/admin/users/:userId"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <UserCartPage
      path="/admin/users/:userId/cart"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <UserOrdersPage
      path="/admin/users/:userId/orders"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <WatchesPage
      path="/admin/users/:userId/notifiers"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <WatchesIndex
      path="/admin/notifiers"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <WatchesPage
      path="/notifiers"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CustomOrdersPage
      path="/custom-orders"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CustomOrdersPage
      path="/users/:userId/custom-orders"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CustomOrdersPage
      path="/admin/custom-orders"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <NotifyUsersPage
      path="/admin/notify-users"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CustomerAccountsPage
      path="/admin/customers"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CustomerAccountDetailsPage
      path="/admin/customers/:customerId"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <AccountStatementPage
      path="/statement"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <AgingReportPage
      path="/admin/aging-report-page"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <ConsignmentOrdersPage
      path="/admin/memo-orders"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <AdminOrdersPage
      path="/admin/orders"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <TransactionPage
      path="/statement/transaction/:id"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <InventoryHoldsPage
      path="/admin/holds"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <RemindersIndex
      path="/admin/reminders"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <ReminderCreatePage
      path="/admin/reminders/create"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <NoMatchAdmin default />
  </Router>
);
