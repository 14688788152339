import React, { Component } from 'react';
import { connect } from 'react-redux';
import SVG from '../components/SVG';
import PasswordInput from '../components/PasswordInput';
import { Button, Alert } from 'reactstrap';
import { registerUser } from '../state/auth/actions';
import { registerPageType } from '../propTypes';
import RegistrationToggle from '../components/RegistrationToggle';
import SignUpTerms from '../components/SignUpTerms';
import { merge, omit } from 'lodash';
import SimpleReactValidator from 'simple-react-validator';

class RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ locale: 'en' });

    // this is a custom validation that validates that the password and password_confirmation fields match
    this.passwordConfirmationValidator = new SimpleReactValidator({
      validators: {
        // validation is named here
        passwordConfirmation: {
          message: 'The Password Confirmation must match the password.',
          rule: (val, params, validator) => {
            return val === this.state.password;
          },
          required: true, // this param is optional per SimpleReactValidator doc: https://github.com/dockwa/simple-react-validator
        },
      },
      locale: 'en',
    });

    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirmation: '',
      customer_account_attributes: {
        trade_references_attributes: [
          this.defaultTradeReferenceAttributes(),
          this.defaultTradeReferenceAttributes(),
          this.defaultTradeReferenceAttributes(),
        ],
        addresses_attributes: [{ country: 'US' }],
      },
      affiliation_reference_attributes: {
        company_name: null,
        df_account_manager_name: null,
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setParentState = this.setParentState.bind(this);
  }

  defaultTradeReferenceAttributes() {
    let defaultTradeReference = {
      company_name: null,
      email: null,
      phone: null,
      name: null,
    };
    return defaultTradeReference;
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  // This method is passed down the component tree and used to setState here in the RegisterPage component
  // It uses merge in several places to merge updated content via input change into the current state
  setParentState(mergeObj, idx) {
    // dynamically set the top level nested key (eg customer_account_attributes or affiliation_reference_attributes)
    const topLevelKey = Object.keys(mergeObj)[0];
    // current state of entire customer_account_attributes or affiliation_reference_attributes object
    const currentAttributes = this.state[topLevelKey];

    if (idx || idx === 0) {
      // will be trade_references_attributes or addresses_attributes
      const nestedAttributeKey = Object.keys(mergeObj[topLevelKey])[0];

      // addresses_attributes not in state by default
      if (currentAttributes[nestedAttributeKey] === undefined) {
        currentAttributes[nestedAttributeKey] = [];
      }

      const preMerge = currentAttributes[nestedAttributeKey][idx];

      // merges the mergeObj into trade_references_attributes or addresses_attributes
      const updatedNestedObj = merge(
        preMerge,
        mergeObj[topLevelKey][nestedAttributeKey][0]
      );

      // merges the updated trade_references_attributes object or addresses_attributes into customer_accounts_attributes
      const updatedState = merge(currentAttributes, mergeObj[topLevelKey]);

      this.setState({ [topLevelKey]: currentAttributes });
      return;
    }
    const newState = merge(currentAttributes, mergeObj[topLevelKey]);

    this.setState({ [topLevelKey]: newState });
  }

  constructRequestBody() {
    const rejectPairs = {
      existing: [
        'customer_account_attributes',
        'application',
        'passwordConfirmation',
        'businessTerms',
      ],
      newPartner: [
        'affiliation_reference_attributes',
        'application',
        'passwordConfirmation',
        'businessTerms',
      ],
    };
    const stateAttributes = this.state;

    const userAttributes = omit(
      stateAttributes,
      rejectPairs[this.state.application.partnerType]
    );

    return userAttributes;
  }

  handleSubmit(e) {
    e.preventDefault();

    const requestBody = this.constructRequestBody();

    if (this.validator.allValid()) {
      this.props.dispatch(registerUser({ user: requestBody }));
    } else {
      this.validator.showMessages();
    }
  }

  render() {
    const error = this.props.error;

    return (
      <div className="register-page df-logout-margin">
        <div className="register-container">
          <div className="register-headers">
            <SVG className="Svg df-logo" viewBox="0 0 603.8 40.9" name="logo" />

            <h2 className="register-subtitle">BUSINESS PORTAL</h2>

            <p className="register-instruction">
              Thank you for your interest in Diamond Foundry. Please fill out
              the below retailer application and someone from our team will
              contact you shortly to complete the registration process.
            </p>
          </div>
          <div className="register-form">
            <div className="registration-form">
              <div className="registration-form-elements left">
                <label htmlFor="first_name">First Name</label>
                <input
                  className="df-text-inputs adduser-input"
                  name="first_name"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('first_name');
                  }}
                  defaultValue={''}
                />
                {this.validator.message(
                  'first_name',
                  this.state.first_name,
                  'required'
                )}
              </div>
              <div className="registration-form-elements right">
                <label htmlFor="last_name">Last Name</label>
                <input
                  className="df-text-inputs adduser-input"
                  name="last_name"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('last_name');
                  }}
                  defaultValue={''}
                />
                {this.validator.message(
                  'last_name',
                  this.state.last_name,
                  'required'
                )}
              </div>

              <div className="registration-form-elements left">
                <label htmlFor="email">Email</label>
                <input
                  className="df-text-inputs adduser-input"
                  name="email"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('email');
                  }}
                  defaultValue={''}
                />
                {this.validator.message(
                  'email',
                  this.state.email,
                  'required|email'
                )}
              </div>
              <div className="registration-form-elements right">
                <label htmlFor="Phone">Phone</label>
                <input
                  className="df-text-inputs adduser-input"
                  name="phone"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('phone');
                  }}
                  defaultValue={''}
                  type="tel"
                />
                {this.validator.message(
                  'phone',
                  this.state.phone,
                  'required|phone'
                )}
              </div>
              <div className="registration-form-elements left">
                <label htmlFor="password">Password</label>
                <PasswordInput
                  inputStyle="df-text-inputs adduser-input"
                  name="password"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('password');
                  }}
                />
                {this.validator.message(
                  'password',
                  this.state.password,
                  'required|min:6'
                )}
              </div>
              <div className="registration-form-elements">
                <label htmlFor="passwordConfirmation">Confirm Password</label>
                <PasswordInput
                  inputStyle="df-text-inputs adduser-input"
                  name="passwordConfirmation"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.passwordConfirmationValidator.showMessages(
                      'passwordConfirmation'
                    );
                  }}
                  defaultValue={''}
                />
                {this.passwordConfirmationValidator.message(
                  'passwordConfirmation',
                  this.state.passwordConfirmation,
                  'required|passwordConfirmation'
                )}
              </div>
            </div>
            <RegistrationToggle
              setParentState={this.setParentState}
              affiliation_reference_attributes={
                this.state.affiliation_reference_attributes
              }
              customer_account_attributes={
                this.state.customer_account_attributes
              }
            />

            {error && <Alert color="danger">{error}</Alert>}

            <div className="registration-form">
              <div className="adduser-label">* these fields are mandatory</div>

              <SignUpTerms setParentState={this.setParentState} />

              <Button
                type="submit"
                className="sign-in-btn df-cta"
                onClick={e => this.handleSubmit(e)}
                disabled={
                  !(
                    this.state.application &&
                    (this.state.businessTerms || {}).agree
                  )
                }
              >
                SEND
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RegisterPage.propTypes = registerPageType;

const mapState = state => ({
  isLoggedIn: state.auth.user && !state.auth.loading,
  error: state.auth.error,
});

export default connect(mapState)(RegisterPage);
