import PolishedDiamondsGroup from '../../types/models/polishedDiamondsGroup';
import * as types from './constants';
import PolishedStatsMap from '../../types/polishedStatsMap';
import PolishedFilter from '../../types/more/polishedFilter';
import CellEntry from '../../types/more/cellEntry';

// ---------------------------------------- `types` ----------------------------------------
type OnSetPolishedDiamondGroups = {
  type: typeof types.SET_POLISHED_DIAMOND_GROUPS;
  payload: PolishedDiamondsGroup[];
};

type OnSetPolishedCellEntry = {
  type: typeof types.SET_POLISHED_CELL_ENTRY;
  payload: CellEntry<PolishedDiamondsGroup>;
};

type OnSetPolishedDiamondStats = {
  type: typeof types.SET_POLISHED_DIAMOND_STATS;
  payload: PolishedStatsMap;
};

type OnAddFilter = {
  type: typeof types.ADD_FILTER;
  payload: PolishedFilter;
};

type OnRemoveFilter = {
  type: typeof types.REMOVE_FILTER;
  payload: PolishedFilter;
};

type OnClearFilter = {
  type: typeof types.CLEAR_FILTER;
};

type OnSetPolishedCellEntries = {
  type: typeof types.SET_POLISHED_CELL_ENTRIES;
  payload: CellEntry<PolishedDiamondsGroup>[];
};

type OnAddColor = {
  type: typeof types.ADD_COLOR;
  payload: string;
};

type OnRemoveColor = {
  type: typeof types.REMOVE_COLOR;
  payload: string;
};
// ---------------------------------------- functions ----------------------------------------

export const setPolishedDiamondGroups = (payload: PolishedDiamondsGroup[]) => ({
  type: types.SET_POLISHED_DIAMOND_GROUPS,
  payload,
});

export const setPolishedDiamondStats = (payload: PolishedStatsMap) => ({
  type: types.SET_POLISHED_DIAMOND_STATS,
  payload,
});

export const addFilter = (payload: PolishedFilter) => ({
  type: types.ADD_FILTER,
  payload,
});

export const removeFilter = (payload: PolishedFilter) => ({
  type: types.REMOVE_FILTER,
  payload,
});

export const clearFilter = () => ({
  type: types.CLEAR_FILTER,
});

export const setPolishedCellEntries = (
  payload: CellEntry<PolishedDiamondsGroup>[]
) => ({
  type: types.SET_POLISHED_CELL_ENTRIES,
  payload,
});

export const setPolishedCellEntry = (
  payload: CellEntry<PolishedDiamondsGroup>
) => ({
  type: types.SET_POLISHED_CELL_ENTRY,
  payload,
});

export const addColor = (payload: string) => ({
  type: types.ADD_COLOR,
  payload,
});

export const removeColor = (payload: string) => ({
  type: types.REMOVE_COLOR,
  payload,
});
export type PolishedDiamondGroupsActionTypes =
  | OnAddColor
  | OnRemoveColor
  | OnSetPolishedCellEntry
  | OnSetPolishedCellEntries
  | OnClearFilter
  | OnRemoveFilter
  | OnAddFilter
  | OnSetPolishedDiamondGroups
  | OnSetPolishedDiamondStats;
