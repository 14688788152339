import React from 'react';
import SVG from './SVG';

import { Link } from '@reach/router';

const configureGoBack = props => {
  switch (props.parent) {
    case 'checkout':
      return (
        <Link to="/diamonds" className="df-link flex">
          <i className="material-icons">arrow_back</i>
          Continue Shopping
        </Link>
      );
    case 'reset':
    case 'forgot':
      return (
        <Link to="/" className="df-link flex">
          <i className="material-icons">arrow_back</i> Back to Login Page
        </Link>
      );
    case 'register':
      return (
        <a className="df-link flex" onClick={props.goBackToLogin}>
          <i className="material-icons">arrow_back</i>
          Back to Login Page
        </a>
      );
    default:
  }
};

const Confirmation = props => (
  <div className="Confirmation">
    <div className="close__container">
      <SVG
        className="Svg close-confirmation"
        viewBox="0 0 100 100"
        name="close"
        onClick={props.closeConfirmation}
      />
    </div>

    <SVG className="checkmark" viewBox="0 0 600 600" name="check" />
    <div className="df-title">{props.title}</div>

    {props.subtitles && (
      <div className="df-subtitles">
        {props.subtitles.map((subtitle, index) => {
          return (
            <div key={index} className="df-subtitle">
              {subtitle}
            </div>
          );
        })}
      </div>
    )}

    {props.subtitle && <div className="df-subtitle">{props.subtitle}</div>}

    {configureGoBack(props)}
  </div>
);

export default Confirmation;
