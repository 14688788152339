import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';

import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';

import WaitSpinner from '../../components/WaitSpinner';
import { adminFetchWatches, downloadWatches } from '../../api/index';

import TimestampProvider from '../../components/admin/TimestampProvider';
import WatchPropertiesFormatter from '../../components/admin/WatchPropertiesFormatter';
import TableComponent from '../../components/admin/TableComponent';
import { compareLinkText } from '../../utils/tableUtilities';
import SVG from '../../components/SVG';

const PropertiesProvider = props => (
  <DataTypeProvider formatterComponent={WatchPropertiesFormatter} {...props} />
);

class WatchesIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      watches: null,
    };

    this.fetchWatches = this.fetchWatches.bind(this);
  }

  componentDidMount() {
    this.fetchWatches();
  }

  columns() {
    return [
      {
        name: 'inventory_type',
        title: 'Inventory',
        getCellValue: row =>
          row.inventory_type === 'diamonds' ? 'Diamonds' : 'Rough Diamonds',
      },
      {
        name: 'brand',
        title: 'Brand',
        getCellValue: row => row.brand || 'DF',
      },
      { name: 'properties', title: 'Properties' },
      { name: 'created_at', title: 'Created At' },
      { name: 'last_sent_at', title: 'Last Sent At' },
      {
        name: 'user',
        title: 'Watcher',
        getCellValue: row =>
          row.user && row.user.first_name ? (
            <Link className="df-link" to={`/admin/users/${row.user_id}`}>
              {`${row.user.first_name} ${row.user.last_name}`}
            </Link>
          ) : row.user_id ? (
            <Link className="df-link" to={`/admin/users/${row.user_id}`}>
              {(row.user || {}).email || undefined}
            </Link>
          ) : (
            <>{row.to_address || undefined}</>
          ),
      },
      {
        name: 'customer_account',
        title: 'Company',
        getCellValue: row =>
          ((row.user || {}).customer_account || {}).company_name || undefined,
      },
    ];
  }

  async fetchWatches() {
    // for internal user to get all user watches instead of their own view=all must be included as query string param
    const watches = await adminFetchWatches();
    this.setState({ watches: watches });
  }

  render() {
    const watches = this.state.watches;
    const timestampColumns = ['created_at', 'last_sent_at'];
    const tableColumnExtensions = [
      { columnName: 'properties', wordWrapEnabled: true },
      { columnName: 'user', wordWrapEnabled: true, compare: compareLinkText },
      { columnName: 'customer_account', wordWrapEnabled: true },
    ];

    return (
      <div className="main-wrapper">
        <h1 className="title">All Notifiers</h1>
        <div className="subtitle">
          <a className="df-link" onClick={downloadWatches}>
            <SVG className="svg-csv" name="csv" viewBox="0 0 600 600" />
          </a>
        </div>
        {watches ? (
          <Grid rows={watches} columns={this.columns()}>
            <SortingState
              defaultSorting={[
                {
                  columnName: 'created_at',
                  direction: 'desc',
                },
              ]}
            />

            <SearchState defaultValue="" />
            <IntegratedSorting columnExtensions={tableColumnExtensions} />
            <IntegratedFiltering />
            <TimestampProvider for={timestampColumns} />
            <PropertiesProvider for={['properties']} />

            <Table
              tableComponent={TableComponent}
              columnExtensions={tableColumnExtensions}
            />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <SearchPanel />
          </Grid>
        ) : (
          <div className="WaitSpinner__container">
            <WaitSpinner />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(WatchesIndex);
