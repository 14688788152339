import React, { Component } from 'react';
import { inviteUserType } from '../propTypes';
import { navigate } from '../utils/routerHistory';
import Select from 'react-select';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import Button from 'reactstrap/lib/Button';
import { inviteUser } from '../api/index';
import { getCompanyList } from '../state/auth/actions';
import { salesOrAdmin } from '../utils/util';

class InviteUser extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      email: '',
      companyName: '',
      selectedCompany: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    const { userDetails } = this.props;

    if (userDetails && salesOrAdmin(userDetails.roles)) {
      this.props.getCompanyList();
    } else {
      navigate('/');
    }
  }

  onSelect(e) {
    this.setState({
      companyName: e.value,
      selectedCompany: e,
    });
  }

  onSave() {
    const { email, companyName } = this.state;
    const { showToaster } = this.props;
    if (this.validator.allValid()) {
      inviteUser({ user: { email, customer_account_id: companyName } })
        .then(response => {
          if (response.status === 200) {
            showToaster(
              'Successfully added the user, Email will send to the user!'
            );
            this.setState(
              {
                email: '',
                companyName: '',
                selectedCompany: [],
              },
              () => {
                this.validator.hideMessages();
                this.forceUpdate();
              }
            );
          }
        })
        .catch(error => {
          if (error && error.response && error.response.status === 400) {
            showToaster('Email has already taken');
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  render() {
    const { email, selectedCompany, companyName } = this.state;
    const { companyList } = this.props;
    return (
      <main>
        <div className="invite-user-wrapper">
          <div className="invite-user-content">
            <div className="head">Invite a user</div>
            <div className="wrapper">
              <div className="input-wrapper">
                <input
                  name="email"
                  className="df-text-inputs df-select-container"
                  value={email}
                  onChange={e => {
                    this.handleChange(e);
                    this.validator.showMessages('email');
                  }}
                  placeholder=" Email*"
                />
                {this.validator.message('email', email, 'required|email')}
              </div>

              <div className="drop-down">
                <Select
                  onChange={e => {
                    this.onSelect(e);
                    this.validator.showMessages('companyName');
                  }}
                  instanceId="shape-selector"
                  className="df-select-container"
                  classNamePrefix="df-select"
                  placeholder="Select company"
                  label="Single select"
                  value={selectedCompany}
                  options={companyList}
                  name="companyName"
                  theme={theme => ({
                    ...theme,
                    padding: 0,
                    controlHeight: 36,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: 'black',
                    },
                  })}
                />
                {this.validator.message('companyName', companyName, 'required')}
              </div>
            </div>
            <div className="submit-button">
              <Button className="df-cta" onClick={this.onSave}>
                SEND INVITATION
              </Button>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

InviteUser.propTypes = inviteUserType;

const mapDispatch = { getCompanyList };
const mapStateToProps = state => {
  return {
    companyList: state.auth.user.companyList,
    userDetails: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatch)(InviteUser);
