import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPolishedParcels,
  fetchStatsPolishedParcels,
} from '../api/diamonds';
import useOnStart from '../hooks/useOnStart';
import {
  clearFilter,
  setPolishedCellEntries,
  setPolishedDiamondGroups,
  setPolishedDiamondStats,
} from '../state/polishedDiamondGroups/actions';
import RootState from '../types/redux/rootState';
import PolishedDiamondGroupFilterGrid from '../components/polished/PolishedDiamondGroupFilterGrid';
import PolishedDiamondGroupList from '../components/polished/PolishedDiamondGroupList';
import Fetch from '../components/ui/Fetch';
import CellEntry from '../types/more/cellEntry';
import PolishedDiamondsGroup from '../types/models/polishedDiamondsGroup';
import PolishedFilter from '../types/more/polishedFilter';
import PolishedMultiselectFooter from '../components/polished/PolishedMultiselectFooter';
import PolishedCartItem from '../types/models/polishedCartItem';
import { calculateTotalPrice } from '../state/action/diamondCart';
import CartItem from '../types/models/cartItem';
import { updateCart } from '../api';
import { filterNameStore } from '../components/polished/PolishedDiamondsFilterColorCart';
import { Link } from '@reach/router';

type Props = {
  showToaster?: () => void;
  closeToaster?: () => void;
};

export type FilterFunc<T> = (array: T[]) => T[];

export type CellEntryFilterFunc = FilterFunc<CellEntry<PolishedDiamondsGroup>>;

export type CellEntryFilterFuncs = CellEntryFilterFunc[];

const PolishedDiamondGroupsPage = ({ showToaster, closeToaster }: Props) => {
  const dispatch = useDispatch();
  const selector = useSelector(
    (state: RootState) => state.polishedDiamondGroups
  );

  const cartSelector = useSelector((state: RootState) => state.diamondCart);
  const { polishedDiamondsGroups, loading } = selector;
  useOnStart(() => {
    fetchPolishedParcels().then(r =>
      dispatch(setPolishedDiamondGroups(r.data))
    );
    fetchStatsPolishedParcels().then(r => {
      dispatch(setPolishedDiamondStats(r.data));
    });
  });

  const onSelectAll = () => {
    dispatch(
      setPolishedCellEntries(
        data.map(e => {
          return {
            ...e,
            selected: true,
          };
        })
      )
    );
  };
  const onUnselectAll = () => {
    dispatch(
      setPolishedCellEntries(
        data.map(e => {
          return {
            ...e,
            selected: false,
          };
        })
      )
    );
  };

  const onClearFilters = () => {
    dispatch(clearFilter());
  };
  const makeFilterByCaratAndShape = (
    cellEntries: CellEntry<PolishedDiamondsGroup>[]
  ) => {
    /*
    If the array used to filter is empty, then no point.
    In fact, if you remove this statement, it won't work.
     */
    if (selector.filters.length === 0) {
      return cellEntries;
    }

    return cellEntries.filter((cell: CellEntry<PolishedDiamondsGroup>) => {
      const Q = cell.entry.diamonds.filter(
        diamond =>
          selector.filters.filter(
            (f: PolishedFilter) =>
              filterNameStore[f.shape] === diamond.shape &&
              diamond.carat >= f.carat.min &&
              diamond.carat <= f.carat.max
          ).length > 0
      );
      return Q.length > 0;
    });
  };

  const makeFilterByColor = (
    cellEntries: CellEntry<PolishedDiamondsGroup>[]
  ) => {
    /*
   If the array used to filter is empty, then no point.
   In fact, if you remove this statement, it won't work.
    */
    if (selector.color.length === 0) {
      return cellEntries;
    }
    return cellEntries.filter((cell: CellEntry<PolishedDiamondsGroup>) => {
      const Q = cell.entry.diamonds.filter(
        diamond =>
          selector.color.filter((c: string) => diamond.color === c).length > 0
      );
      return Q.length > 0;
    });
  };
  /*
  Uses each filter functions as it passes through.
   */
  const makeFilter = (...fns: CellEntryFilterFuncs) => (
    x: CellEntry<PolishedDiamondsGroup>[]
  ) => {
    return fns.reduce((v, f) => f(v), x);
  };
  /*
  Pipe filter functions.
   */
  const data = makeFilter(
    makeFilterByCaratAndShape,
    makeFilterByColor
  )(selector.cellEntries);
  const selectedEntries = selector.cellEntries.filter(e => e.selected);

  const onAddToCart = () => {
    const cartItems: PolishedCartItem[] = selectedEntries.map(cell => {
      return {
        id: cell.entry.id,
        name: cell.entry.name,
        netsuite_id: cell.entry.netsuite_id,
        quantity: cell.entry.total_program_count,
        orderType: 'PolishedParcel',
        netsuite_program_type: cell.entry.netsuite_program_type,
        price: +cell.entry.parcel_price,
      };
    });
    // Find unique
    const ids = new Set<string>(cartItems.map(item => item.id));
    const uniqueCartItems: CartItem[] = [
      ...cartItems,
      ...cartSelector.cart.filter(item => !ids.has(item.id)),
    ];
    // Add to store
    dispatch({
      type: 'ADD_MULTIPLE_ITEMS_TO_CART',
      items: cartItems,
      totalPrice: calculateTotalPrice(uniqueCartItems),
      updated: 'unique',
    });
    onUnselectAll();
    // Update backend
    updateCart({
      items: uniqueCartItems,
    });
  };
  return (
    <main>
      <div className="Diamonds">
        <div className="df-headline-container">
          <div className="df-headline">
            Our Diamonds:&nbsp;<Link to="/diamonds">Uniques</Link> | Program
          </div>
        </div>

        <PolishedDiamondGroupFilterGrid
          showClearFiltersButton={selector.filters.length > 0}
          onClearFilters={onClearFilters}
        />

        <Fetch
          isEmpty={!loading && polishedDiamondsGroups.length === 0}
          empty={
            <div className="no-diamond-data">
              No program diamond parcels currently fit your criteria, try
              expanding your search with our filter options.
            </div>
          }
          render={
            <PolishedDiamondGroupList
              onSelectAll={onSelectAll}
              onUnselectAll={onUnselectAll}
              data={data}
            />
          }
        />
      </div>
      <PolishedMultiselectFooter
        onAddToCart={onAddToCart}
        selected={selectedEntries}
      />
    </main>
  );
};

export default PolishedDiamondGroupsPage;
