import React, { Component } from 'react';
import { connect } from 'react-redux';

class RingBuilderPage extends Component {
  render() {
    const { auth } = this.props;
    const { user } = auth;
    let apiKey = user.authentication_token;

    return (
      <main>
        <div className="digital-solutions-page main-wrapper padded">
          <div className="inner-wrapper">
            <div className="df-headline-container">
              <div className="df-headline">
                <h2>Diamond Foundry Shopify App</h2>
              </div>
            </div>

            <div>
              <p>
                Shopify users, we invite you to install our app to display our
                diamond inventory on your website. The app displays our
                exclusive stock in real time, so that you can increase sales
                conversions online with its multiple functionalities. The app
                can be downloaded from Shopify here: &#8239;
                <a
                  href="https://df-product-bundler.herokuapp.com/"
                  target="_blank"
                >
                  https://df-product-bundler.herokuapp.com/
                </a>
                .
              </p>
              <p>
                Through our proprietary app, you can pair your settings with our
                diamonds, and or feature it as a loose diamond table. When a
                customer purchases a Diamond Foundry diamond on your website, it
                is reserved by the app for you. You can customize the app to
                complement your store, and sell efficiently with automatic order
                notifications that go directly to our fulfillment team each time
                a diamond is sold. Orders are processed and shipped within 1-2
                business days.
              </p>
              <p>
                Installation is easy, and our technical support team is
                available to assist you throughout the process. Please e-mail
                &#8239;
                <a href="mailto:b2b@diamondfoundry.com">
                  b2b@diamondfoundry.com
                </a>
                &#8239; with any questions.
              </p>

              <div className="df-headline-container">
                <div className="df-headline">
                  <h2>Diamond Foundry API</h2>
                </div>
              </div>
              <p>Your api key: {apiKey}</p>
              <p>
                To integrate Diamond Foundry&apos;s realtime product inventory
                and data into your eCommerce platform, please have your
                developer view our &#8239;
                <a href="https://diamondfoundry.github.io/slate/?shell#introduction">
                  API Documentation
                </a>
                .
              </p>
              <p>Please note:</p>
              <ul>
                <li>
                  All stones .85 and larger are accompanied by a 360 video.
                </li>
                <li>
                  All stones are accompanied by an in house Diamond Foundry
                  certificate. The Diamond Foundry Certificate guarantees the
                  grade of your diamond and is backed by a Full Lifetime
                  Warranty.
                </li>
                <li>
                  The lab name which represents our certificates on your website
                  should read &quot;DFC&quot; or &quot;Diamond Foundry.&quot;
                </li>
              </ul>
              <p>
                For more information about our Digital Solutions please contact
                &#8239;
                <a href="mailto:b2b@diamondfoundry.com">
                  b2b@diamondfoundry.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(RingBuilderPage);
