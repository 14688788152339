import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      contact: null,
    };

    this.toggle = this.toggle.bind(this);
  }

  async toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { auth } = this.props;
    const { user } = auth;
    const salesRep = (user.customer_account || {}).sales_representative || {
      email: 'b2b@diamondfoundry.com',
      first_name: 'B2B Support',
      last_name: '',
    };
    let salesRepName = `${salesRep.first_name} ${salesRep.last_name}`;

    return (
      <div>
        <a
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.toggle();
          }}
        >
          <span className="underline">Need help?</span>
          <span>Contact {salesRepName}</span>
        </a>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="footer-pages"
        >
          <ModalHeader className="df-title" toggle={this.toggle}>
            Contact Us
          </ModalHeader>
          <ModalBody className="df-page-body text-align-center">
            <p>
              We look forward to hearing from you. For any inquiries, please
              email {salesRepName} at:
              <a href={`mailto:${salesRep.email}`}>{salesRep.email}</a>
            </p>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ContactUs);
