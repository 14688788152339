import React from 'react';
import { getDiamondColorCatalogForFilter } from '../../utils/util';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../types/redux/rootState';
import { PolishedDiamondGroupState } from '../../state/polishedDiamondGroups/reducer';
import {
  addColor,
  removeColor,
} from '../../state/polishedDiamondGroups/actions';

type Props = {
  colors?: string[];
};
// Fetch color used to filter.
const COLORS = getDiamondColorCatalogForFilter();
// PolishedFilterColor.tsx
const PolishedFilterColor = ({ colors = COLORS }: Props) => {
  const dispatch = useDispatch();
  const selector = useSelector<RootState, PolishedDiamondGroupState>(
    state => state.polishedDiamondGroups
  );

  const isSelected = (color: string) => {
    if (!selector || !selector.color) {
      return false;
    }
    return selector.color.includes(color);
  };

  const toggleFilter = (color: string) => {
    if (isSelected(color)) {
      dispatch(removeColor(color));
    } else {
      dispatch(addColor(color));
    }
  };
  return (
    <div className="FilterColor">
      <div className="legend">Color</div>
      {colors.map((color, index) => (
        <a
          data-testid={`${color}`}
          key={`${color}:${index}`}
          onClick={() => toggleFilter(color)}
          className="DiamondFilter-color"
        >
          <div className={`df-cta ${isSelected(color) ? 'active' : ''}`}>
            {color}
          </div>
        </a>
      ))}
    </div>
  );
};

export default PolishedFilterColor;
