import React, { Component } from 'react';
import { connect } from 'react-redux';

import BackButton from '../../components/BackButton';
import WaitSpinner from '../../components/WaitSpinner';

import { fetchCart } from '../../api/index';
import { formatUSD } from '../../utils/util';

class UserCartPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cart: null,
    };
  }

  componentDidMount() {
    this.fetchCart();
  }

  async fetchCart() {
    const cart = await fetchCart(this.props.userId);
    this.setState({ cart: cart });
  }

  render() {
    if (!this.state.cart) {
      return <WaitSpinner />;
    }

    let grandTotal = formatUSD(this.state.cart.grand_total / 100.0);
    let count = this.state.cart.items.length || 0;

    return (
      <main>
        <div className="user-details-page main-wrapper padded">
          <BackButton
            link={`/admin/users/${this.props.userId}`}
            text="User Details"
          />

          <div className="table-wrapper">
            <h1 className="title">Shopping Cart</h1>
            <div className="row">
              <div className="column">Total:</div>
              <div className="column">{grandTotal}</div>
            </div>
            <div className="row">
              <div className="column">Items:</div>
              <div className="column">{count}</div>
            </div>
          </div>
          <div className="table-wrapper">
            <h1>Items</h1>
            {this.state.cart.items &&
              this.state.cart.items.map((item, index) => {
                return (
                  <div className="row" key={`cart-item-${index}`}>
                    <div className="column">{item.lot_id}</div>
                    <div className="column">{item.name}</div>
                    <div className="column">
                      {formatUSD(item.price)}
                      <br />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
  };
};

export default connect(mapStateToProps)(UserCartPage);
