import React from 'react';

type Props = {
  hidden?: boolean;
  children: React.ReactNode;
};
const CanHide = ({ hidden = false, children }: Props) => {
  if (hidden) {
    return null;
  }
  return <> {children} </>;
};

export default CanHide;
