import React from 'react';
import Diamond from '../../../types/models/diamond';
import Option from '../../../types/more/option';

type Props = {
  data: Diamond;
  header: Option<string, string>[];
};

const PolishedDiamondListItem = ({ data, header }: Props) => {
  const map: any = {
    ...data,
    total_program_count: 10,
  };

  return (
    <div className="DiamondListItem">
      <div className="tr">
        {header.map((h: Option<string, string>, index: number) => {
          if (map[h.key] === undefined) {
            throw new Error(`Error: ${h.key} undefined`);
          }

          return (
            <div className="td" key={`${h.key}-${index}`}>
              {map[h.key]}
            </div>
          );
        })}
        <div className="td">{`${data.length_mm} x ${data.width_mm} x ${data.depth_mm}`}</div>
      </div>
    </div>
  );
};

export default PolishedDiamondListItem;
