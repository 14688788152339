import React from 'react';
import { formatUSD } from '../utils/util';

import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';

import TableComponent from '../components/admin/TableComponent';
import { DateProvider, compareTimestamps } from '../utils/dateUtilities';
import { compareCurrency } from '../utils/tableUtilities';

import SVG from './SVG';

const InvoiceList = ({ invoices, noDataCell }) => {
  const columns = [
    {
      name: 'trandate',
      title: 'Date',
    },
    {
      name: 'doc_number',
      title: 'Description',
      getCellValue: row => `${row.transaction_type} #${row.doc_number}`,
    },
    {
      name: 'netsuite_status',
      title: 'Status',
    },
    {
      name: 'transaction_total',
      title: 'Amount',
      getCellValue: row =>
        formatUSD((row.transaction_total - row.paid_total) / 100.0),
    },
    {
      name: 'due_at',
      title: 'Due',
    },
    {
      name: 'id',
      title: 'View',
      getCellValue: row => {
        if (
          row.transaction_type === 'Invoice' ||
          row.transaction_type === 'Credit Memo'
        ) {
          return (
            <a href={`/statement/transaction/${row.id}`} target="_blank">
              <SVG
                className="svg-icon-csv"
                name="eye-open"
                viewBox="0 0 600 600"
              />
            </a>
          );
        }
      },
    },
  ];

  // transactions in transaction set are now flat instead of handling deep nested child transactions here
  // filtering out of transactions related to the open invoices that should not be displayed occurs here
  let invoiceSet = invoices.filter(
    invoice =>
      invoice.transaction_type != 'Return Authorization' &&
      invoice.transaction_type != 'Item Receipt'
  );

  const tableColumnExtensions = [
    { columnName: 'doc_number', wordWrapEnabled: true },
    { columnName: 'trandate', wordWrapEnabled: true },
    { columnName: 'due_at', wordWrapEnabled: true },
    { columnName: 'transaction_total', wordWrapEnabled: true },
  ];

  const sortingColumnExtensions = [
    { columnName: 'trandate', compare: compareTimestamps },
    { columnName: 'due_at', compare: compareTimestamps },
    { columName: 'transaction_total', compare: compareCurrency },
  ];

  // <SortingState> component is used here to guarantee initial sorting state only (defaultSorting)
  // Initial sort by trandate is necessary here because the order of the transactions within the flattened transaction set is not guaranteed
  return (
    <Grid rows={invoiceSet} columns={columns}>
      <DateProvider for={['due_at', 'trandate']} />
      <SortingState
        defaultSorting={[{ columnName: 'trandate', direction: 'desc' }]}
      />
      <IntegratedSorting />
      {noDataCell ? (
        <Table
          tableComponent={TableComponent}
          columnExtensions={tableColumnExtensions}
          noDataCellComponent={noDataCell}
        />
      ) : (
        <Table
          tableComponent={TableComponent}
          columnExtensions={tableColumnExtensions}
        />
      )}
      <TableHeaderRow showSortingControls />
    </Grid>
  );
};

export default InvoiceList;
