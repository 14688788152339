import React, { Component } from 'react';
import { connect } from 'react-redux';

class ReferenceRow extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { reference } = this.props;
    const caption = this.props.caption || 'Reference';

    return (
      <div className="row">
        <div className="column">{caption}</div>
        <div className="column">
          {reference && (
            <div>
              Account Manager: {reference.df_account_manager_name}
              {reference.df_account_manager_name && <br />}
              {reference.name}
              {reference.name && <br />}
              {reference.phone}
              {reference.phone && <br />}
              {reference.email}
              {reference.email && <br />}
              {reference.company_name}
              {reference.company_name && <br />}
              {reference.address}
              {reference.address && <br />}
              {reference.type_of_business}
              {reference.type_of_business && <br />}
              {reference.payment_terms}
              {reference.payment_terms && <br />}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect()(ReferenceRow);
