import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { toMonthDayYear } from '../utils/dateUtilities';
import { salesOrAdmin } from '../utils/util';
import {
  fetchAllCustomOrders,
  fetchCustomOrders,
  deleteCustomOrder,
  updateCustomOrder,
} from '../api/index';
import WatchPropertiesFormatter from '../components/admin/WatchPropertiesFormatter';
import TableComponent from '../components/admin/TableComponent';
import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import WaitSpinner from '../components/WaitSpinner';
import { compareLinkText } from '../utils/tableUtilities';

const PropertiesProvider = props => (
  <DataTypeProvider formatterComponent={WatchPropertiesFormatter} {...props} />
);
const DeleteCell = props => {
  return (
    <IconButton
      onClick={() => props.onDeleteClick(props.recurringOrder)}
      title="Delete Custom Order"
    >
      <DeleteIcon />
    </IconButton>
  );
};
const UpdateCustomOrder = props => {
  const statuses = {
    pending: [
      { actionText: 'Approve', newStatus: 'approved' },
      { actionText: 'Reject', newStatus: 'rejected' },
    ],
    rejected: [{ actionText: 'Approve', newStatus: 'approved' }],
    approved: [{ actionText: 'Reject', newStatus: 'rejected' }],
  };

  const transitionSet = statuses[props.customOrder.status];

  return transitionSet.map(transition => (
    <Button
      title={transition.actionText}
      onClick={() =>
        props.onUpdateCustomOrderClick(props.customOrder, transition.newStatus)
      }
      key={`${props.customOrderId}-${transition.newStatus}-${transition.actionText}`}
      className="df-cta monthly-order-btn"
    >
      {transition.actionText}
    </Button>
  ));
};
const ActionsRow = props => {
  return (
    <React.Fragment>
      <DeleteCell
        id={props.id}
        recurringOrder={props.customOrder}
        onDeleteClick={props.onDeleteClick}
      />
      {props.adminOrSales && (
        <UpdateCustomOrder
          id={props.id}
          customOrder={props.customOrder}
          onUpdateCustomOrderClick={props.onUpdateCustomOrderClick}
        />
      )}
    </React.Fragment>
  );
};

class CustomOrdersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customOrders: null,
    };

    this.fetchCustomOrders = this.fetchCustomOrders.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onUpdateCustomOrderClick = this.onUpdateCustomOrderClick.bind(this);
    this.titleText = this.titleText.bind(this);
  }

  componentDidMount() {
    this.fetchCustomOrders();
  }

  async fetchCustomOrders() {
    if (!!this.props.auth.user.id && !this.state.customOrders) {
      const adminOrSales =
        this.props.auth.user && salesOrAdmin(this.props.auth.user.roles);
      let customOrders = [];

      if (this.props.userId && adminOrSales) {
        customOrders = await fetchCustomOrders(this.props.userId);
      } else if (adminOrSales && this.props.path === '/admin/custom-orders') {
        customOrders = await fetchAllCustomOrders();
      } else {
        customOrders = await fetchCustomOrders(this.props.auth.user.id);
      }
      this.setState({ customOrders: customOrders });

      const query = queryString.parse(this.props.location.search);
      window.history.replaceState(
        {},
        document.title,
        window.location.origin + window.location.pathname
      );
      if (query.new) {
        const message = 'Custom order created successfully!';
        this.props.showToaster(message);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Fetch recurring orders when the user is loaded. This is necessary when loading this page directly from a link.
    if (prevProps.auth.user.id !== this.props.auth.user.id) {
      this.fetchCustomOrders();
    }
    // customOrder observer - notify when order removed
    if (prevState.customOrders && this.state.customOrders) {
      if (prevState.customOrders.length !== this.state.customOrders.length) {
        if (prevState.customOrders.length > this.state.customOrders.length) {
          const message = `Custom order removed.`;
          this.props.showToaster(message);
        }
      }
    }
  }

  async onDeleteClick(recurringOrder) {
    const resp = await deleteCustomOrder(
      recurringOrder.created_by_id,
      recurringOrder.id
    );

    if (resp.status !== 200) {
      this.props.showToaster('Failed to delete order');
    } else {
      this.props.showToaster('Your order was successfully deleted');
      const customOrdersCopy = this.state.customOrders;

      this.setState({
        customOrders: customOrdersCopy.filter(
          order => order.id !== recurringOrder.id
        ),
      });
    }
    return;
  }

  async onUpdateCustomOrderClick(customOrder, newStatus) {
    let customOrderParams = {
      status: newStatus,
    };

    const resp = await updateCustomOrder(
      customOrder.created_by_id,
      customOrder.id,
      customOrderParams
    );

    if (resp.status !== 200) {
      this.props.showToaster('Failed to update custom order');
    } else {
      this.props.showToaster('Your custom order successfully updated');
      let customOrdersCopy = this.state.customOrders;

      let customOrderCopy = customOrder;
      customOrderCopy.status = newStatus;

      Object.assign([], customOrdersCopy, {
        [customOrdersCopy.indexOf(customOrder)]: customOrderCopy,
      });

      this.setState({
        customOrders: customOrdersCopy,
      });
    }
    return;
  }

  columns() {
    let columns = [
      {
        name: 'Actions',
        getCellValue: row => (
          <ActionsRow
            id={row.id}
            customOrder={row}
            onUpdateCustomOrderClick={this.onUpdateCustomOrderClick}
            onDeleteClick={this.onDeleteClick}
            adminOrSales={
              this.props.auth.user && salesOrAdmin(this.props.auth.user.roles)
            }
          />
        ),
      },
      { name: 'order_type', title: 'Type' }, // recurring or one-time
      {
        name: 'properties',
        title: 'Properties',
      },
      { name: 'quantity', title: 'quantity' },
      { name: 'discount_code', title: 'Discount %' },
      {
        name: 'created_at',
        title: 'Created',
        getCellValue: row => toMonthDayYear(row.created_at),
      },
      {
        name: 'need_by',
        title: 'needed By',
        getCellValue: row => toMonthDayYear(row.requested_delivery_at),
      },
      {
        name: 'status',
        title: 'Status',
      },
    ];
    if (this.props.path === '/admin/custom-orders') {
      columns.push(
        {
          name: 'name',
          title: 'User',
          getCellValue: row => (
            <Link
              className="df-link flex"
              to={`/admin/users/${row.created_by_id}`}
            >
              {row.created_by.first_name && row.created_by.last_name
                ? `${row.created_by.first_name} ${row.created_by.last_name}`
                : row.created_by.email}
            </Link>
          ),
        },
        {
          name: 'company_name',
          title: 'Company',
          getCellValue: row => (
            <Link
              className="df-link flex"
              to={`/admin/customers/${row.customer_account.id}`}
            >
              {row.customer_account.company_name}
            </Link>
          ),
        },
        {
          name: 'sales_representative',
          title: 'Sales Rep',
          getCellValue: row =>
            ((row.customer_account || {}).sales_representative || {}).email ||
            '',
        }
      );
    }
    return columns;
  }

  titleText() {
    if (this.props.userId) {
      return 'User Custom Orders';
    }
    return this.props.path === '/admin/custom-orders'
      ? 'All Custom Orders'
      : 'My Custom Orders';
  }

  render() {
    const adminOrSales =
      this.props.auth.user && salesOrAdmin(this.props.auth.user.roles);

    let integratedSortingColumnExtensions = [];
    let tableColumnExtensions = [
      { columnName: 'properties', width: '200px' },
      { columnName: 'order_type', width: '100px' },
      { columnName: 'quantity', width: '100px' },
      { columnName: 'discount_code', width: '120px' },
      { columnName: 'created_at', width: '130px' },
      { columnName: 'need_by', width: '130px' },
    ];

    if (adminOrSales) {
      tableColumnExtensions = tableColumnExtensions.concat(
        { columnName: 'company_name', width: '130px', wordWrapEnabled: true },
        { columnName: 'name', width: '130px', wordWrapEnabled: true },
        { columnName: 'Actions', width: '230px', wordWrapEnabled: true },
        { columnName: 'order_type', width: '130px', wordWrapEnabled: true },
        { columnName: 'name', width: '130px', wordWrapEnabled: true },
        {
          columnName: 'sales_representative',
          width: '130px',
          wordWrapEnabled: true,
        }
      );

      integratedSortingColumnExtensions = integratedSortingColumnExtensions.concat(
        { columnName: 'name', compare: compareLinkText },
        { columnName: 'company_name', compare: compareLinkText }
      );
    }

    return (
      <main>
        <div className="custom-orders-page main-wrapper">
          <h1 className="title">{this.titleText()}</h1>
          <p className="instructions">
            To place a custom order, go to{' '}
            <Link to="/diamonds">the diamonds page</Link>, select the shape/size
            from the filter grid at the top, then click the &quot;Place
            Order&quot; button underneath the filter grid.
          </p>
          {!this.state.customOrders && <WaitSpinner />}
          {this.state.customOrders && (
            <Grid rows={this.state.customOrders || []} columns={this.columns()}>
              <SortingState
                defaultSorting={[
                  {
                    columnName: 'created_at',
                    direction: 'desc',
                  },
                ]}
              />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              <IntegratedFiltering />
              <PropertiesProvider for={['properties']} />
              <Table
                tableComponent={TableComponent}
                columnExtensions={tableColumnExtensions}
              />
              <TableHeaderRow showSortingControls />
            </Grid>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(CustomOrdersPage);
