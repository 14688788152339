import React, { Component } from 'react';
import { connect } from 'react-redux';
import SVG from './SVG';

import { removeItemFromCart } from '../state/action/diamondCart';
import {
  getDiamondIconSrc,
  formatPricing,
  shapeHasDiamondPhoto,
} from '../utils/util';

class CartListItemDiamond extends Component {
  render() {
    return (
      <div className="CartListItem">
        <div className="diamond-info-left">
          <div className="diamond-icon-container">
            {shapeHasDiamondPhoto(this.props.shape) && (
              <img
                className="diamond-icon"
                src={getDiamondIconSrc(this.props.shape)}
                alt="Diamond Icon"
              />
            )}
          </div>
          <ul className="unstyled">
            <li>
              <span className="shape">{this.props.shape}</span>
            </li>
            {this.props.carat && (
              <li>
                <span className="fieldName">Carat:</span> {this.props.carat}
              </li>
            )}
            {this.props.color && (
              <li>
                <span className="fieldName">Color:</span> {this.props.color}
              </li>
            )}
            {!this.props.color && (
              <li>
                <span className="fieldName">Color:</span> I+
              </li>
            )}
            {this.props.clarity && (
              <li>
                <span className="fieldName">Clarity:</span> {this.props.clarity}
              </li>
            )}
            {!this.props.clarity && (
              <li>
                <span className="fieldName">Clarity:</span> SI1+
              </li>
            )}
            {this.props.quantity && (
              <li className="quantity">
                <span className="fieldName">Qty:</span>
                {this.props.quantity}
              </li>
            )}
            {this.props.sku && (
              <li className="diamond-sku">{this.props.sku}</li>
            )}
            {this.props.lot_id && (
              <li>
                <span className="fieldName">Lot Id: </span>
                {this.props.lot_id}
              </li>
            )}
          </ul>
        </div>
        <ul className="unstyled diamond-info-right">
          {this.props.orderType !== 'bulk' && (
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.cert_url}
                className="df-link diamond-certificate"
              >
                Diamond Certificate
                <i className="material-icons chevron_right">chevron_right</i>
              </a>
            </li>
          )}

          {!this.props.checkedOut && (
            <li>
              <SVG
                className="delete"
                name="trash"
                viewBox="0 0 600 600"
                onClick={e => {
                  e.preventDefault();
                  this.props.dispatch(removeItemFromCart(this.props.id));
                }}
              />
            </li>
          )}

          {this.props.price && (
            <li className="diamond-price">{formatPricing(this.props.price)}</li>
          )}
        </ul>
      </div>
    );
  }
}

export default connect()(CartListItemDiamond);
