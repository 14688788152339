import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import { navigate } from '../../utils/routerHistory';

import BackButton from '../../components/BackButton';
import WaitSpinner from '../../components/WaitSpinner';
import ReferenceRow from '../../components/admin/ReferenceRow';

import { fetchUser, updateUser } from '../../api/index';
import { formatDateRelative } from '../../utils/dateUtilities';
import { formatPhoneNumber, formatUSD, salesOrAdmin } from '../../utils/util';

// These are for selecting a user's affiliation.
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SelectCompany from '../../components/admin/SelectCompany';

import { storeSelectedUsers } from '../../state/users/actions';
import { storeDiamondsFromAPI } from '../../state/action/diamondSearch';
import { fetchDiamondStats, fetchDiamonds } from '../../api/index';
import { setDiamondStats } from '../../state/stats/actions';

class UserDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      selectAffiliationAnchorElement: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.approveUser = this.approveUser.bind(this);
    this.basicUser = this.basicUser.bind(this);
    this.banUser = this.banUser.bind(this);
    this.shopForUser = this.shopForUser.bind(this);
    this.sendAccountSummary = this.sendAccountSummary.bind(this);
  }

  componentDidMount() {
    this.fetchUser();
  }

  async fetchUser() {
    const user = await fetchUser(this.props.userId).then(response => {
      return response.data || {};
    });
    this.setState({ user: user });
  }

  selectAffiliation() {
    this.setState({ selectAffiliationAnchorElement: true });
  }

  closeModal() {
    this.setState({ selectAffiliationAnchorElement: false });
    this.fetchUser(); // pick up the new user details
  }

  approveUser() {
    this.updateUserStatus('approved');
  }

  basicUser() {
    this.updateUserStatus('basic');
  }

  banUser() {
    this.updateUserStatus('banned');
  }

  sendAccountSummary() {
    // store this user as the selected user into redux
    let users = [this.state.user];
    this.props.dispatch(storeSelectedUsers(users));

    // navigate to the create email page with the pre-filled information to send an account summary report to this user
    navigate('/admin/notify-users?ar=true');
  }

  shopForUser() {
    const user = this.state.user;
    const requestBody = {
      user: {
        on_behalf_of_user_id: user.id,
      },
    };

    updateUser(requestBody, this.props.currentUser)
      .then(response => {
        if (response.statusText === 'OK') {
          // Update the current user using response.data
          this.props.dispatch({ type: 'USER', payload: response.data });
        }
      })
      .then(async response => {
        // clear the cart because prices are different for different users
        this.props.dispatch({ type: 'RESET_CART' });
        // reload the diamonds since prices will change
        this.props.dispatch({
          type: 'IS_DIAMONDS_LOADING',
          payload: { isDiamondsLoading: true },
        });
        const diamondList = await fetchDiamonds();
        this.props.dispatch(storeDiamondsFromAPI({ diamonds: diamondList }));
        const stats = await fetchDiamondStats();
        this.props.dispatch(setDiamondStats(stats));
      })
      .then(response => {
        navigate('/diamonds'); // go to the diamonds list
      })
      .catch(error => {
        this.props.showToaster(
          'You are unable to place an order for this user.'
        );
      });
  }

  updateUserStatus(newStatus) {
    const user = this.state.user;
    const requestBody = {
      user: {
        status: newStatus,
      },
    };

    updateUser(requestBody, user)
      .then(response => {
        if (response.statusText === 'OK') {
          this.setState({ user: response.data });
        }
      })
      .catch(error => {
        this.props.showToaster(
          'Status change failed. You must assign a customer account before you can approve a user!'
        );
      });
  }

  render() {
    if (!this.state.user) {
      return <WaitSpinner />;
    }

    let currentUserIsAdmin =
      this.props.currentUser.roles[0] &&
      this.props.currentUser.roles[0] === 'admin';
    let currentUserIsSalesOrAdmin = salesOrAdmin(this.props.currentUser.roles);

    const { user } = this.state;

    let name = '',
      email = '',
      phone = '',
      status = '';
    let affiliationReference = null;
    let customerAccount = null;
    let tradeReferences = [];

    if (user) {
      name = `${user.first_name} ${user.last_name}`;
      email = user.email;
      phone = user.phone;
      status = user.status;
      affiliationReference = user.affiliation_reference;
      customerAccount = user.customer_account;

      if (!!customerAccount && !!customerAccount.trade_references) {
        customerAccount.trade_references.forEach((value, index) => {
          tradeReferences.push(
            <ReferenceRow
              key={index}
              caption={`Trade Reference ${index + 1}:`}
              reference={value}
            />
          );
        });
      }
    }

    return (
      <main>
        <div className="user-details-page main-wrapper padded">
          <BackButton link="/admin/users" text="Users List" />

          <div className="table-wrapper">
            <h1 className="title">User</h1>
            <div className="row">
              <div className="column">Email:</div>
              <div className="column">{email}</div>
            </div>
            <div className="row">
              <div className="column">Name:</div>
              <div className="column">{name}</div>
            </div>
            <div className="row">
              <div className="column">Phone:</div>
              <div className="column">{formatPhoneNumber(phone)}</div>
            </div>
            <div className="row">
              <div className="column">Status:</div>
              <div className="column">{status}</div>
            </div>
            <h1 className="title">Actions</h1>
            {currentUserIsSalesOrAdmin && (
              <div className="row">
                <div className="column">Place Order:</div>
                <div className="column">
                  <button
                    className="df-cta btn btn-secondary"
                    onClick={this.shopForUser}
                  >
                    Place Order for this User
                  </button>
                </div>
              </div>
            )}
            {currentUserIsSalesOrAdmin && customerAccount && (
              <div className="row">
                <div className="column">Send Account Summary:</div>
                <div className="column">
                  <button
                    className="df-cta btn btn-secondary"
                    onClick={this.sendAccountSummary}
                  >
                    Email User Account Summary
                  </button>
                </div>
              </div>
            )}
            {!customerAccount && affiliationReference && (
              <ReferenceRow
                caption="Affiliation Reference:"
                reference={affiliationReference}
              />
            )}
            {!customerAccount && currentUserIsAdmin && (
              <div className="row">
                <div className="column">Confirm Affiliation:</div>
                <div className="column">
                  <button
                    className="df-cta btn btn-secondary"
                    onClick={e => this.selectAffiliation(e)}
                  >
                    Select Customer Account
                  </button>
                  <span className="df-small-text">
                    By setting this user&#39;s customer account you are
                    permitting this user to purchase on behalf of their
                    employer.
                  </span>
                  <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={this.state.selectAffiliationAnchorElement}
                    onClose={this.closeModal}
                    aria-labelledby="max-width-dialog-title"
                  >
                    <DialogTitle>Select Company</DialogTitle>
                    <DialogContent>
                      <SelectCompany
                        selectedUser={user}
                        closeModal={this.closeModal}
                        showToaster={this.props.showToaster}
                      />

                      <DialogContentText>
                        If you do not see the company in the list, you must add
                        it in Netsuite first.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeModal} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            )}
            {user.status !== 'basic' && currentUserIsAdmin && (
              <div className="row">
                <div className="column">Must use CC:</div>
                <div className="column">
                  <button
                    className="df-cta btn btn-secondary"
                    onClick={this.basicUser}
                  >
                    Force User to Use Credit Card
                  </button>
                  <span className="df-small-text">
                    By setting this user as a basic user, you are forcing them
                    to pay with a credit card on the b2b portal.
                  </span>
                </div>
              </div>
            )}
            {user.status !== 'approved' && currentUserIsAdmin && (
              <div className="row">
                <div className="column">Approve User:</div>
                <div className="column">
                  <button
                    className="df-cta btn btn-secondary"
                    onClick={this.approveUser}
                  >
                    Approve User to Pay on Terms
                  </button>
                  <span className="df-small-text">
                    By approving this user, you are allowing them to pay on
                    credit. For each of their purchases, their company will be
                    billed at a later date.
                  </span>
                </div>
              </div>
            )}
            {user.status !== 'banned' && currentUserIsAdmin && (
              <div className="row">
                <div className="column">Ban User:</div>
                <div className="column">
                  <button
                    className="df-cta btn btn-secondary"
                    onClick={this.banUser}
                  >
                    Ban User
                  </button>
                  <span className="df-small-text">
                    By banning this user, you are preventing them from signing
                    in using this email. They can still create a new account
                    with a separate email.
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="table-wrapper">
            <h1>Activity</h1>
            <div className="row">
              <div className="column">Last Active:</div>
              <div className="column">
                {formatDateRelative(user.last_active_at)}
              </div>
            </div>
            <div className="row">
              <div className="column">Last Login:</div>
              <div className="column">
                {formatDateRelative(user.last_login_at)}
              </div>
            </div>
            <div className="row">
              <div className="column">Last Added to Cart:</div>
              <div className="column">
                {formatDateRelative(user.last_added_to_cart_at)}
              </div>
            </div>
            {currentUserIsAdmin && (
              <div className="row">
                <div className="column">Tax Identifier:</div>
                <div className="column">
                  {(user.customer_account || {}).decrypted_tax_identifier}
                </div>
              </div>
            )}
            <div className="row">
              <div className="column">Items in Cart:</div>
              <div className="column inline-block">
                {user.number_of_items_in_cart}{' '}
                <Link to={`/admin/users/${this.props.userId}/cart`}>
                  View Cart
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="column">Notifiers:</div>
              <div className="column inline-block">
                {user.number_of_watches}{' '}
                <Link to={`/admin/users/${this.props.userId}/notifiers`}>
                  View Notifiers
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="column">Orders:</div>
              <div className="column">
                <Link to={`/admin/users/${this.props.userId}/orders`}>
                  View Orders
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="column">Monthly Orders:</div>
              <div className="column">
                <Link to={`/users/${this.props.userId}/custom-orders`}>
                  View Monthly Orders
                </Link>
              </div>
            </div>
          </div>

          {customerAccount && (
            <div className="table-wrapper">
              <h1>Customer Account</h1>
              <div className="row">
                <div className="column">Company Name:</div>
                <div className="column">
                  <Link
                    className="df-link flex"
                    to={`/admin/customers/${customerAccount.id}`}
                  >
                    {customerAccount ? customerAccount.company_name : ''}
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="column">Credit Limit:</div>
                <div className="column">
                  {formatUSD(customerAccount.credit_limit / 100.0)}
                </div>
              </div>
              <div className="row">
                <div className="column">Balance:</div>
                <div className="column">
                  {formatUSD(customerAccount.balance / 100.0)}
                </div>
              </div>
              <div className="row">
                <div className="column">Phone:</div>
                <div className="column">{customerAccount.phone}</div>
              </div>
              <div className="row">
                <div className="column">Address:</div>
                <div className="column">
                  {customerAccount.addresses && customerAccount.addresses[0] && (
                    <div>
                      {customerAccount.addresses[0].attention}
                      {customerAccount.addresses[0].attention && <br />}
                      {customerAccount.addresses[0].street_address}
                      {customerAccount.addresses[0].street_address && <br />}
                      {customerAccount.addresses[0].line2}
                      {customerAccount.addresses[0].line2 && <br />}
                      {customerAccount.addresses[0].line3}
                      {customerAccount.addresses[0].line3 && <br />}
                      {customerAccount.addresses[0].city},{' '}
                      {customerAccount.addresses[0].state},{' '}
                      {customerAccount.addresses[0].country}.{' '}
                      {customerAccount.addresses[0].zip}
                      <br />
                      {customerAccount.addresses[0].phone}
                      {customerAccount.addresses[0].phone && <br />}
                    </div>
                  )}
                </div>
              </div>
              {tradeReferences}
              {customerAccount && currentUserIsAdmin && (
                <div className="row">
                  <div className="column">Change Customer Account:</div>
                  <div className="column">
                    <button
                      className="df-cta btn btn-secondary"
                      onClick={e => this.selectAffiliation(e)}
                    >
                      Change Customer Account
                    </button>
                    <span className="df-small-text">
                      By setting this user&#39;s customer account you are
                      permitting this user to purchase on behalf of their
                      employer.
                    </span>
                    <Dialog
                      fullWidth={true}
                      maxWidth="lg"
                      open={this.state.selectAffiliationAnchorElement}
                      onClose={this.closeModal}
                      aria-labelledby="max-width-dialog-title"
                    >
                      <DialogTitle>Select Company</DialogTitle>
                      <DialogContent>
                        <SelectCompany
                          selectedUser={user}
                          closeModal={this.closeModal}
                          showToaster={this.props.showToaster}
                        />

                        <DialogContentText>
                          If you do not see the company in the list, you must
                          add it in Netsuite first.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.closeModal} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.user,
  };
};
export default connect(mapStateToProps)(UserDetailsPage);
