import React, { Component } from 'react';
import { connect } from 'react-redux';

import { formatDate } from '../utils/dateUtilities';
import { formatUSD, isInternalUser } from '../utils/util';

class OrderDetails extends Component {
  render() {
    const order = this.props.order;

    let paymentMethod =
      order.payment_method === 'B2B Stripe' ? 'Credit Card' : 'Terms on File';
    let internalUser = isInternalUser(this.props.auth.user.roles);

    return (
      <div className="table-wrapper">
        <h1 className="df-title left">
          {order.is_consignment ? 'MEMO DETAILS' : 'ORDER DETAILS'}
        </h1>
        <div className="row">
          <div className="column">Sales Order ID:</div>
          <div className="column">
            {
              order.transactions.find(
                transaction => transaction.transaction_type === 'Sales Order'
              )?.doc_number
            }
          </div>
        </div>
        <div className="row">
          <div className="column">Order Date:</div>
          <div className="column">{formatDate(order.created_at)}</div>
        </div>
        <div className="row">
          <div className="column">Total:</div>
          <div className="column">
            {formatUSD(order.grand_total_in_cents / 100.0)}
          </div>
        </div>
        <div className="row">
          <div className="column">Payment Method:</div>
          <div className="column">{paymentMethod}</div>
        </div>
        {internalUser && (
          <div className="row">
            <div className="column">Source:</div>
            <div className="column">{order.source}</div>
          </div>
        )}
        {internalUser && !!order.placed_by_user_name && (
          <div className="row">
            <div className="column">Placed By:</div>
            <div className="column">{order.placed_by_user_name}</div>
          </div>
        )}
        {internalUser && paymentMethod === 'Credit Card' && (
          <div className="row">
            <div className="column">Stripe Charge:</div>
            <div className="column">
              <a
                href={`https://dashboard.stripe.com/payments/${order.stripe_charge_id}`}
                target="_blank"
              >
                {order.stripe_charge_id}
              </a>
            </div>
          </div>
        )}
        {this.props.invoices.length === 1 && (
          <div className="row">
            <div className="column">Invoice Date:</div>
            <div className="column">
              {formatDate(this.props.invoices[0].trandate)}
            </div>
          </div>
        )}
        {this.props.invoices.length === 1 &&
          !!this.props.invoices[0].netsuite_status && (
            <div className="row">
              <div className="column">Invoice Status:</div>
              <div className="column">
                {this.props.invoices[0].netsuite_status}
              </div>
            </div>
          )}

        {this.props.fulfillments.length === 1 &&
          !!this.props.fulfillments[0].tracking_number && (
            <div className="row">
              <div className="column">Tracking Number:</div>
              <div className="column">
                {this.props.fulfillments[0].tracking_number}
              </div>
            </div>
          )}
        {this.props.fulfillments.length === 1 &&
          !!this.props.fulfillments[0].carrier && (
            <div className="row">
              <div className="column">Carrier:</div>
              <div className="column">{this.props.fulfillments[0].carrier}</div>
            </div>
          )}
        {this.props.salesOrders.length === 1 &&
          !!this.props.salesOrders[0].shipdate && (
            <div className="row">
              <div className="column">Ship Date:</div>
              <div className="column">
                {formatDate(this.props.salesOrders[0].shipdate)}
              </div>
            </div>
          )}
        {this.props.salesOrders.length === 1 &&
        !!this.props.salesOrders[0].carrier &&
        this.props.fulfillments.length === 0 && ( // if there are no fullfillments, then the carrier may be on the sales order
            <div className="row">
              <div className="column">Carrier:</div>
              <div className="column">{this.props.salesOrders[0].carrier}</div>
            </div>
          )}
        {this.props.returnAuthorizations.length === 1 &&
          !!this.props.returnAuthorizations[0].netsuite_transaction_id && (
            <div className="row">
              <div className="column">Return Auth. Number:</div>
              <div className="column">
                {this.props.returnAuthorizations[0].netsuite_transaction_id}
              </div>
            </div>
          )}
        {order.is_consignment && (
          <div className="row">
            <div className="column">Due Date:</div>
            <div className="column">{formatDate(order.due_at)}</div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(OrderDetails);
