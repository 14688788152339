import { useEffect } from 'react';

/* An abstraction when functional component on init */

const useOnStart = (callback: () => void) => {
  useEffect(() => {
    callback();
  }, []);
};

export default useOnStart;
