import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RoughDiamondListItem from './RoughDiamondListItem';
import { debounce } from '../../utils/util';
import WaitSpinner from '../WaitSpinner';
import RoughDiamondListHeader from './RoughDiamondListHeader';

class RoughDiamondList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedRows: 25, // the number of diamonds that should be loaded into the diamonds list
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.updateDisplayedRows = this.updateDisplayedRows.bind(this);

    // set up a debounced method for loading more rows into the diamonds list
    this.debouncedUpdateDisplayedRows = debounce(this.updateDisplayedRows, 50);
    this.debouncedUpdateDisplayedRows = this.debouncedUpdateDisplayedRows.bind(
      this
    );
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.debouncedUpdateDisplayedRows();
  }

  updateDisplayedRows() {
    let newDisplayedRows = 25 + window.scrollY / 50;
    if (newDisplayedRows > this.state.displayedRows)
      this.setState({ displayedRows: newDisplayedRows });
  }

  // props will have props.diamonds (array)
  render() {
    return (
      <div className="DiamondList RoughDiamondList">
        <div className={'table'}>
          <RoughDiamondListHeader
            applyRoughDiamondSort={this.props.applyRoughDiamondSort}
            activeSortParameter={this.props.activeSortParameter}
            reverseSort={this.props.reverseSort}
          />
          <div className="tbody">
            {!this.props.loading &&
              this.props.roughDiamonds &&
              this.props.roughDiamonds.map((roughDiamond, index) => {
                if (index <= this.state.displayedRows) {
                  return (
                    <RoughDiamondListItem
                      key={index}
                      {...roughDiamond}
                      setIndex={this.props.setIndex}
                      currentIndex={index}
                      showToaster={this.props.showToaster}
                      expanded={roughDiamond.id === this.props.index}
                      selected={roughDiamond.isSelected}
                    />
                  );
                }
              })}
            {(this.props.loading ||
              this.state.displayedRows < this.props.roughDiamonds.length) && (
              <WaitSpinner />
            )}
          </div>
        </div>
      </div>
    );
  }
}

RoughDiamondList.propTypes = {
  applyRoughDiamondSort: PropTypes.func.isRequired,
  roughDiamonds: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect()(RoughDiamondList);
