import React from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import SVG from './SVG';
import { logout } from '../state/auth/actions';
import ContactUs from './ContactUs';

const Sidenav = ({
  navOpen,
  toggleNav,
  closeNav,
  salesOrAdmin,
  logout,
  navItems,
}) => (
  <ul
    id="Sidenav"
    onClick={toggleNav}
    className={navOpen ? 'unstyled active' : 'unstyled'}
  >
    <li className="nav-link side-nav-header">
      <Link to="/">
        <SVG className="Navbar__logo" viewBox="0 0 603.8 40.9" name="logo" />
      </Link>

      <img
        alt="Close Menu"
        className="close-side-nav"
        onClick={closeNav}
        src="https://img.icons8.com/ios/50/000000/delete-sign.png"
      />
    </li>

    {navItems.map((navItem, index) => {
      return (
        <li key={`nav-item-${index}`} className="nav-link">
          <Link to={navItem.url}>
            <SVG
              className="Svg"
              name={navItem.url.substr(1)}
              viewBox="0 0 600 600"
            />
            {navItem.title}
          </Link>
        </li>
      );
    })}

    <li className="nav-link">
      <a href="/" onClick={() => logout()}>
        <SVG className="Svg" name="signout" viewBox="0 0 600 600" />
        Sign out
      </a>
    </li>
    <li className="help">
      <ContactUs />
    </li>
    {salesOrAdmin && (
      <li key={`nav-item-0`} className="admin_link">
        <Link to={`/admin/dashboard`}>
          <SVG
            className="Svg"
            name={`/admin/dashboard`.substr(1)}
            viewBox="0 0 600 600"
          />
          Admin Dashboard
        </Link>
      </li>
    )}
  </ul>
);

const mapState = state => ({
  error: state.auth.error,
});
const mapDispatch = { logout };

export default connect(mapState, mapDispatch)(Sidenav);
