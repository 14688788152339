import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../utils/routerHistory';
import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  SearchState,
  SelectionState,
  IntegratedSelection,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';

import TableComponent from '../components/admin/TableComponent';
import { userColumns, compareLinkText } from '../utils/tableUtilities';
import { compareRelativeTimes } from '../utils/dateUtilities';
import WaitSpinner from './WaitSpinner';
import { isInternalUser } from '../utils/util';
import { storeSelectedUsers } from '../state/users/actions';
import UserListActions from './UserListActions';
import { Link } from '@reach/router';

const UserFormatter = ({ value, row }) => {
  return (
    <Link className="df-link flex" to={`/admin/users/${row.id}`}>
      {value}
    </Link>
  );
};

const UserProvider = props => (
  <DataTypeProvider formatterComponent={UserFormatter} {...props} />
);

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUsers: [],
    };

    this.handleCopyEmailAddressesClick = this.handleCopyEmailAddressesClick.bind(
      this
    );
    this.handleSendAccountSummaryClick = this.handleSendAccountSummaryClick.bind(
      this
    );
    this.handleEmailUsersClick = this.handleEmailUsersClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(value) {
    let newSelectedUsers = [...value];
    this.setState({ selectedUsers: newSelectedUsers });
    return newSelectedUsers;
  }

  // See discussion of copying to clipboard here: https://stackoverflow.com/questions/39501289/in-reactjs-how-to-copy-text-to-clipboard
  handleCopyEmailAddressesClick() {
    // pull out the selected user emails
    let emails = [];
    for (let i = 0; i < this.state.selectedUsers.length; i++) {
      emails.push(this.props.users[this.state.selectedUsers[i]].email);
    }

    if (navigator.clipboard.writeText(emails.join(','))) {
      this.props.showToaster('Email addresses copied to clipboard!');
    } else {
      this.props.showToaster('Error while trying to copy to clipboard!');
    }
  }

  handleSendAccountSummaryClick() {
    this.handleEmailUsersClick(true);
  }

  handleEmailUsersClick(includeAccountSummary) {
    // pull out the selected users
    let users = [];
    for (let i = 0; i < this.state.selectedUsers.length; i++) {
      users.push(this.props.users[this.state.selectedUsers[i]]);
    }

    // store the selected users into redux
    this.props.dispatch(storeSelectedUsers(users));

    // navigate to the create email page
    let url = '/admin/notify-users';
    if (includeAccountSummary) url = url + '?ar=true';
    navigate(url);
  }

  render() {
    let internalUser = isInternalUser(this.props.auth.user.roles);

    const tableColumnExtensions = [
      { columnName: 'email', wordWrapEnabled: true },
      { columnName: 'company_name', wordWrapEnabled: true },
      { columnName: 'name', wordWrapEnabled: true },
    ];

    const integratedSortingColumnExtensions = [
      { columnName: 'User', compare: compareLinkText },
      { columnName: 'last_active_at', compare: compareRelativeTimes },
      { columnName: 'created_at', compare: compareRelativeTimes },
    ];

    return (
      <React.Fragment>
        {!this.props.loading && (
          <React.Fragment>
            <Grid rows={this.props.users} columns={userColumns()}>
              <SortingState
                defaultSorting={[
                  { columnName: 'last_active_at', direction: 'asc' },
                ]}
              />

              <SearchState defaultValue="" />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              <IntegratedFiltering />
              <Table
                tableComponent={TableComponent}
                columnExtensions={tableColumnExtensions}
              />
              <TableHeaderRow showSortingControls />
              <Toolbar />
              <SearchPanel />

              <UserProvider for={['User']} />
              <SelectionState
                selection={this.state.selectedUsers}
                onSelectionChange={this.handleSelect}
              />
              <IntegratedSelection />
              {!this.props.readOnly && <TableSelection showSelectAll />}
            </Grid>
            <UserListActions
              handleSendAccountSummaryClick={this.handleSendAccountSummaryClick}
              handleCopyEmailAddressesClick={this.handleCopyEmailAddressesClick}
              handleEmailUsersClick={this.handleEmailUsersClick}
              showInternalActions={
                internalUser && this.state.selectedUsers.length > 0
              }
            />
          </React.Fragment>
        )}
        {this.props.loading && (
          <div className="WaitSpinner__container">
            <WaitSpinner />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(UserList);
