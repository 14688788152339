import React, { useEffect, useState } from 'react';
import Diamond from '../../../types/models/diamond';
import PolishedDiamondListHeader from './PolishedDiamondListHeader';
import Option from '../../../types/more/option';
import TableBody from './TableBody';
import { compare } from '../../../utils/util';
import styled from '@emotion/styled';

const Outer = styled.div`
  width: 100%;
`;
type Props = {
  data: Diamond[];
};

export const HEADER: Option<string, string>[] = [
  { label: 'Shape', key: 'shape' },
  { label: 'Color', key: 'color' },
  { label: 'Carat', key: 'carat' },
  { label: 'Clarity', key: 'clarity' },
];

const PolishedDiamondList = ({ data }: Props) => {
  const [statefulData, setStatefulData] = useState(data);
  const [isSorting, setIsSorting] = useState(false);
  const [activeSortParameter, setActiveSortParameter] = useState<string | null>(
    null
  );
  const [reverseSort, setReverseSort] = useState(false);
  // When data changes
  useEffect(() => {
    setStatefulData(data);
    sort();
  }, [data]);

  const onSort = (parameter: string) => {
    if (isSorting || !parameter) {
      return;
    }
    setIsSorting(true);
    setActiveSortParameter(parameter);
    setReverseSort(r => !r);

    sort().then(() => setIsSorting(false));
  };

  const sort = async () => {
    if (activeSortParameter === null) {
      return;
    }
    const sign = reverseSort ? -1 : 1;
    const sorted = statefulData.sort((a, b) => {
      /*
      This is a special case. If there is too much special cases in the future than a new class/interface
      is required.
       */
      if (activeSortParameter === 'measurements') {
        return (
          sign *
          (a.depth_mm * a.width_mm * a.length_mm -
            b.depth_mm * b.width_mm * b.length_mm)
        );
      }
      const map1: any = {
        ...a,
      };
      const map2: any = {
        ...b,
      };
      return sign * compare(map1, map2, activeSortParameter);
    });
    setStatefulData(sorted);
  };

  return (
    <Outer className="DiamondList">
      <div className="table">
        <PolishedDiamondListHeader
          header={HEADER}
          onSort={onSort}
          reverseSort={reverseSort}
          activeSortParameter={activeSortParameter}
        />
        <TableBody data={data} header={HEADER} />
      </div>
    </Outer>
  );
};

export default PolishedDiamondList;
