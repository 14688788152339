import PropTypes from 'prop-types';

const { string, func, object, array, bool } = PropTypes;

export const loginPageType = {
  isLoggedIn: string,
  authenticate: func,
  error: string,
};

export const appType = {
  checkAuthentication: func,
};

export const registerPageType = {
  isLoggedIn: bool,
  register: func,
  error: string,
};

export const inviteUserType = {
  userDetails: object,
  showToaster: func.isRequired,
  companyList: array,
};
