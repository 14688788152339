import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LocationProvider } from '@reach/router';

import { history } from './utils/routerHistory';
import RouteSwitcher from './routing/RouteSwitcher';
import NavSwitcher from './components/NavSwitcher';
import TrackPageViews from './components/TrackPageViews';
import Footer from './components/Footer';
import { Alert } from 'reactstrap';

import { checkAuthentication } from './state/auth/actions';
import { getCartItems } from './state/action/diamondCart';
import { appType } from './propTypes';

import ReactGA from 'react-ga';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { muiTheme } from './utils/mui';

import 'bootstrap/dist/css/bootstrap.css';
import './css/styles.scss';

// only include Google Analytics when in production
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA‌-67550058-4');
}

// This is not really App.js as it is traditionally used in React. See src/index.js
export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      user: null,
      menuItems: null,
      showToast: false,
      message: '',
    };

    this.getCart = this.getCart.bind(this);
    this.showToaster = this.showToaster.bind(this);
    this.closeToaster = this.closeToaster.bind(this);
  }

  // After this page loads, check whether the user is logged in or not
  async componentDidMount() {
    await this.props.checkAuthentication(this.props.auth.user);
    this.getCart();
  }

  async getCart() {
    if (this.props.auth.token) {
      await (async () => {
        this.props.getCartItems();
      })();
    }
  }

  showToaster(message) {
    this.setState({
      showToast: true,
      message,
    });
    setTimeout(() => {
      this.closeToaster();
    }, 5000);
  }

  closeToaster() {
    this.setState({
      showToast: false,
    });
  }

  isLoggedIn = () => {
    const { auth } = this.props;
    if (!auth) {
      return false;
    }
    return auth.user && auth.userRoute in ['adminUser', 'salesUser', 'user'];
  };
  render() {
    return (
      <LocationProvider history={history}>
        <MuiThemeProvider theme={muiTheme}>
          <Alert
            color="primary"
            className={`info-toast ${this.state.showToast ? 'open' : ''}`}
          >
            {this.state.message}
          </Alert>
          <NavSwitcher />
          <RouteSwitcher
            showToaster={this.showToaster}
            closeToaster={this.closeToaster}
          />
          <TrackPageViews />
          {/* Only show the footer when logged in. 
            This is tech debt due to the contractor CSS on the login page, forgot password page, 
            and several other unauthenticated pages. This eventually needs to be fixed, 
            but it will require some refactoring and CSS work. */}
          {this.isLoggedIn() && <Footer />}
        </MuiThemeProvider>
      </LocationProvider>
    );
  }
}

App.propTypes = appType;

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatch = { checkAuthentication, getCartItems };

export default connect(mapStateToProps, mapDispatch)(App);
