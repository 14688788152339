export const getDiamondShapeAbbreviation = shape => {
  switch (shape.toLowerCase().trim()) {
    case 'round brilliant':
      return 'Round';
    case 'hexagon rose':
      return 'Hex Rose';
    case 'super emerald':
      return 'Sup Emrld';
    case 'bright square':
      return 'Brt Square';
    case 'round cut princess':
      return 'Rnd Cut Princ.';
    case 'tapered baguette':
      return 'Tapered Bag.';
    case 'cushion princess':
      return 'Cushion Princ.';
    case 'long octagon':
      return 'Long Oct.';
    case 'long hexagon':
      return 'Long Hex.';
    default:
      return shape;
  }
};

export const getTwoLetterShapeAbbreviation = shape => {
  if (!shape) {
    return '';
  }

  switch (shape.toLowerCase().trim()) {
    case 'round brilliant':
      return 'RB';
    case 'oval':
      return 'OV';
    case 'pear':
      return 'PR';
    case 'emerald':
      return 'EM';
    case 'cushion':
      return 'CN';
    case 'marquise':
      return 'MQ';
    case 'trillion':
      return 'TR';
    case 'asscher':
      return 'AS';
    case 'princess':
      return 'PR';
    case 'baguette':
      return 'BG';
    case 'radiant':
      return 'RD';
    default:
      return shape.substring(0, 2).toUpperCase();
  }
};

export const getFilterGridShapeList = () => {
  return [
    'Round Brilliant',
    'Oval',
    'Pear',
    'Emerald',
    'Cushion',
    'Marquise',
    'Trillion',
    'Asscher',
    'Princess',
    'Radiant',
    'Rose',
    'Other',
  ];
};

// This is just a convenience method
export const getFilterGridShapeListLowercaseWithoutOther = () => {
  return getFilterGridShapeList().map(shape => shape.toLowerCase());
};
