import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
} from 'reactstrap';

import { Link } from '@reach/router';
import { formatPricing, formatPhoneNumber } from '../utils/util';
import SVG from '../components/SVG';
import { fetchOrders, fetchCart, fetchCustomOrders } from '../api/index';
import { salesOrAdmin } from '../utils/util';
import { toMonthDayYear } from '../utils/dateUtilities';
import WatchPropertiesFormatter from '../components/admin/WatchPropertiesFormatter';

const CustomOrderCardContent = ({ customOrder }) => {
  return (
    <React.Fragment>
      <div>
        Most recent: {toMonthDayYear(customOrder.created_at)}
        <br />
        Order Type: {customOrder.order_type}
        <br />
        <WatchPropertiesFormatter
          key={`${customOrder.id}-properties`}
          value={customOrder.properties}
        />
      </div>
    </React.Fragment>
  );
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: null,
      cart: null,
      customOrders: null,
    };

    this.getLatestOrder = this.getLatestOrder.bind(this);
    this.getCart = this.getCart.bind(this);
    this.fetchCustomOrders = this.fetchCustomOrders.bind(this);
  }

  componentDidMount() {
    this.getLatestOrder();
    this.getCart();
    this.fetchCustomOrders();
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth } = this.props;
    if (prevProps.auth.user.id !== auth.user.id) {
      this.fetchCustomOrders();
    }
  }

  async fetchCustomOrders() {
    const { auth } = this.props;

    if (!!auth.user.id && !this.state.customOrders) {
      const adminOrSales = auth.user && salesOrAdmin(auth.user.roles);

      let customOrders = [];
      customOrders = await fetchCustomOrders(auth.user.id);

      this.setState({ customOrders: customOrders });
    }
  }

  // TODO: Fix this to only fetch the single most recent order.
  async getLatestOrder() {
    const orders = await fetchOrders();

    const latestOrder = {
      price: 0,
      quantity: 0,
    };

    if (!!orders && orders.length > 1) {
      latestOrder.price = orders[0].grand_total_in_cents / 100.0;

      orders[0].order_items.forEach(orderItem => {
        latestOrder.quantity += orderItem.quantity;
      });
    }
    this.setState({ order: latestOrder });
  }

  async getCart() {
    const cart = await fetchCart();
    this.setState({ cart: cart });
  }

  render() {
    const { auth } = this.props;
    const { user } = auth;

    let customerAccount,
      firstName,
      lastName,
      companyName = '';
    let addresses = [];
    let addressLine1 = [];

    if (user) {
      customerAccount = user.customer_account;
      firstName = user.first_name;
      lastName = user.last_name;
      companyName = (customerAccount || {}).company_name || '';
      addresses = (customerAccount || {}).addresses;

      if (addresses !== undefined && addresses.length > 0) {
        const { state = '', city = '', zip = '' } = addresses[0];
        addressLine1 = `${city}, ${state}. ${zip}`;
      }
    }

    const { order } = this.state;
    const { cart } = this.state;

    let grandTotal = 0;
    let diamondCount = 0;

    if (cart) {
      grandTotal = cart.grand_total / 100.0;
      if (cart.items) {
        diamondCount = cart.items.length;
      }
    }

    // Set up the default sales rep if the user doesn't have an assigned sales rep...
    const salesRep = (user.customer_account || {}).sales_representative || {
      email: 'b2b@diamondfoundry.com',
      first_name: 'B2B Support Team',
      last_name: '',
      phone: '',
    };

    const customOrders = this.state.customOrders || [];
    const recentCustomOrder =
      customOrders && customOrders.length > 0 ? customOrders[0] : null;

    return (
      <main>
        <div className="page-wrapper-with-padding">
          <div className="main-wrapper">
            <div className="dashboard-header">
              <div className="dashboard-header-column name dashboard-header-column-vertical">
                <div className="company-name">{companyName}</div>
              </div>
              <div className="dashboard-header-column">
                {addresses !== undefined && addresses[0] && (
                  <ul className="unstyled">
                    {companyName && <li>{companyName}</li>}
                    <li>
                      {firstName}&nbsp;
                      {lastName}
                    </li>
                    <li>{addresses[0].street_address}</li>
                    <li>{addressLine1}</li>
                    <li>{addresses[0].country}</li>
                    <li>{formatPhoneNumber(addresses[0].phone)}</li>
                  </ul>
                )}
                <ul className="unstyled">
                  {!customerAccount && (
                    <li>
                      Your affiliation has not yet been approved by your sales
                      representative.
                    </li>
                  )}
                  <li>
                    <Link to="/user/edit">Edit Your Profile</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dashboard-edit"></div>

            <div className="dashboard-summaries">
              <div className="dashboard-summary">
                <Card>
                  <CardHeader>
                    <SVG className="svg" name="invoice" viewBox="0 0 600 600" />{' '}
                    MY ORDERS
                  </CardHeader>
                  {order && (
                    <CardBody>
                      <CardTitle className="current-amount">
                        {formatPricing(order.price)}
                      </CardTitle>
                      <CardText className="dashboard-text">
                        {order.quantity} Diamonds
                      </CardText>
                    </CardBody>
                  )}
                  {!order && (
                    <CardBody>
                      <CardTitle className="current-amount">
                        {formatPricing(0.0)}
                      </CardTitle>
                      <CardText className="dashboard-text">0 Diamonds</CardText>
                    </CardBody>
                  )}

                  <CardFooter>
                    <Link to="/orders" className="df-link flex">
                      View more
                      <i className="material-icons">arrow_forward</i>
                    </Link>
                    <div className="invoice-status" />
                  </CardFooter>
                </Card>
              </div>
              <div className="dashboard-summary">
                <Card>
                  <CardHeader>
                    <SVG className="svg" name="cart" viewBox="0 0 600 600" /> MY
                    CART
                  </CardHeader>
                  <CardBody>
                    <CardTitle className="current-amount">
                      {formatPricing(grandTotal)}
                    </CardTitle>
                    <CardText className="dashboard-text">
                      {diamondCount} Diamonds
                    </CardText>
                  </CardBody>
                  {cart && cart.items.length > 0 && (
                    <CardFooter>
                      <Link to="/cart" className="df-link flex">
                        Check Out
                        <i className="material-icons">arrow_forward</i>
                      </Link>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </div>
            {salesRep && (
              <div className="dashboard-summaries">
                <div className="dashboard-summary">
                  <Card>
                    <CardHeader>MY SALES REPRESENTATIVE</CardHeader>
                    <CardBody>
                      <div className="text-left">
                        Name: {`${salesRep.first_name} ${salesRep.last_name}`}
                        <br />
                        Email: {salesRep.email}
                        {!!salesRep.phone && (
                          <React.Fragment>
                            <br />
                            Phone: {formatPhoneNumber(salesRep.phone)}
                          </React.Fragment>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    cartPrice: state.diamondCart.totalPrice,
    cartItems: state.diamondCart.cart,
  };
};

export default connect(mapStateToProps)(Dashboard);
