import getEnvConfig from '../env';
import { onDelete, onGet, onPost } from './helpers';
import PolishedDiamondsGroup from '../types/models/polishedDiamondsGroup';
import PolishedStatsMap from '../types/polishedStatsMap';
import InventoryHold from '../types/models/inventoryHold';
import Diamond from '../types/models/diamond';
import OrderRate from '../types/more/orderRate';
import { AxiosResponse } from 'axios';
import Transaction from '../types/transaction';

export const { DF_API_HOST } = getEnvConfig();

/* API calls for diamond related */

export const fetchPolishedParcels = () => {
  const path = 'api/v1/polished_parcels';
  return onGet<PolishedDiamondsGroup[]>(path);
};

export const fetchStatsPolishedParcels = () => {
  const path = 'api/v1/stats/polished_parcels';
  return onGet<PolishedStatsMap>(path);
};

export const onCreateHold = (
  holdable_id: string,
  holdable_type = 'Diamond'
) => {
  const path = 'api/v1/inventory_holds';
  const data = {
    holdable_id,
    holdable_type,
  };

  return onPost(path, data);
};

export const fetchInventoryHolds = () => {
  const path = 'api/v1/inventory_holds';
  return onGet<InventoryHold[]>(path);
};

type UserInventoryHoldsRequest = {
  active?: boolean | null;
};

export const fetchUsersInventoryHolds = ({
  active = null,
}: UserInventoryHoldsRequest) => {
  const path = `/api/v1/inventory_holds/all?active=${active}`;
  return onGet<InventoryHold[]>(path);
};

export const onDeleteHold = (
  id: string,
  customer_account_id: string | null = null
) => {
  const path = `api/v1/inventory_holds/${id}?customer_account_id=${customer_account_id}`;
  return onDelete(path);
};

export const fetchDiamond = (id: string) => {
  const path = `api/v2/diamonds/${id}`;
  return onGet<Diamond>(path);
};

export interface Weight {
  value: number;
  units: string;
}

export interface Dimensions {
  units: string;
  length: number;
  width: number;
  height: number;
}

export type OrderRatesRequest = {
  carrierCode: string;
  serviceCode?: string;
  packageCode?: string;
  fromPostalCode: string;
  toState?: string;
  toCountry: string;
  toPostalCode: string;
  toCity?: string;
  weight: Weight;
  dimensions?: Dimensions;
  confirmation?: string;
  residential?: boolean;

  sortByPrice?: boolean;
  limit?: number;
  serviceCodes?: string[];
};

export const fetchOrderRates = (
  request: OrderRatesRequest
): Promise<AxiosResponse<OrderRate[]>> => {
  return onPost<OrderRate[]>('api/v1/order_rates', request);
};
/*
When `desc` the transactions list is newest to oldest by `trandate`.
 */
export const fetchInvoices = (customer_account_id: string, sort = 'desc') => {
  const path = `api/v1/customer_accounts/${customer_account_id}/transactions/?transaction_type=Invoice&sort=${sort}`;
  return onGet<Transaction[]>(path);
};
