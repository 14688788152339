import React from 'react';
import SVG from '../components/SVG';
import AgingReportPeriods from '../components/AgingReportPeriods';
import InvoiceList from '../components/InvoiceList';
import { downloadStatementCsv } from '../api/index';

const AccountStatement = props => {
  const agingReportPeriods = props.agingReportPeriods;

  return (
    <React.Fragment>
      <div>
        <h1 className="df-title">
          <span>Account Statement</span>

          <a onClick={e => downloadStatementCsv(props.customerAccountId)}>
            {<SVG className="svg-icon-csv" name="csv" viewBox="0 0 600 600" />}
          </a>
        </h1>
      </div>

      {agingReportPeriods && (
        <AgingReportPeriods
          arPeriods={agingReportPeriods}
          openBalance={props.openBalance || 0}
        />
      )}
      <InvoiceList
        invoices={props.agingReportTransactions || []}
        companyName={props.company_name}
        noDataCell={props.noDataCell}
      />
    </React.Fragment>
  );
};

export default AccountStatement;
