import React from 'react';
import { useSelector } from 'react-redux';

import WaitSpinner from '../components/WaitSpinner';
import { AdminRoutes } from './AdminRoutes';
import { UnprotectedRoutes } from './UnprotectedRoutes';
import { ProtectedRoutes } from './ProtectedRoutes';
import RootState from '../types/redux/rootState';
import Auth from '../types/redux/auth';

type Props = {
  showToaster: () => void;
  closeToaster: () => void;
};

const RouteSwitcher = ({ showToaster, closeToaster }: Props) => {
  const auth = useSelector<RootState, Auth>((state: RootState) => state.auth);
  const { loading, userRoute } = auth;
  if (loading || !userRoute) {
    return (
      <div className="WaitSpinner__container">
        <WaitSpinner />
      </div>
    );
  }
  switch (userRoute) {
    case 'adminUser':
    case 'salesUser':
      return (
        <AdminRoutes showToaster={showToaster} closeToaster={closeToaster} />
      );
    case 'user':
      return (
        <ProtectedRoutes
          showToaster={showToaster}
          closeToaster={closeToaster}
        />
      );
    default:
      return (
        <UnprotectedRoutes
          showToaster={showToaster}
          closeToaster={closeToaster}
        />
      );
  }
};

export default RouteSwitcher;
