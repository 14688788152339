import { STORE_USERS, STORE_SELECTED_USERS } from './constants';

export const storeUsersFromApi = users => {
  return {
    type: STORE_USERS,
    payload: users,
  };
};

export const storeSelectedUsers = users => {
  return {
    type: STORE_SELECTED_USERS,
    payload: users,
  };
};
