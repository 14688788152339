import { navigate } from '../../utils/routerHistory';
import {
  AUTHENTICATE,
  AUTHENTICATE_SUCCESS,
  DEAUTHENTICATE,
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  ADD_ADDRESS,
  DELETE_ADDRESS,
  GET_COMPANY_LIST,
  USER,
  SET_USER_ROUTE,
  UPDATE_METADATA,
} from './constants';
import { setCookie, removeCookie, getCookie } from '../../utils/cookie';
import { getPropValue } from '../../utils/util';
import axios from 'axios';
import ReactGA from 'react-ga';
import {
  DF_API_HOST,
  loginUser,
  fetchUser,
  fetchCompanyList,
  logoutUser,
} from '../../api/index';
import { getCartItems } from '../action/diamondCart';

const grabJwtToken = response => {
  const token = response.headers.authorization.split(' ')[1];

  setCookie('token', token);

  return token;
};

/*
  The contractors wrote very heavy redux-thunk actions into this particular reducer.
  For an explanation of redux-thunk, see https://redux.js.org/advanced/async-actions.

  We have tended to add light-weight actions that are more like event definitions,
  but these heavy actions sort of make sense for authentication.
*/

// register user
export const registerUser = requestObj => {
  return dispatch => {
    axios
      .post(`${DF_API_HOST}/api/v1/register`, requestObj)
      .then(response => {
        if (response.status === 200) {
          grabJwtToken(response);

          fetchUser()
            .then(response => {
              dispatch({
                type: SET_USER_ROUTE,
                payload: extractUserRoute(response.data),
              });
              dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data,
              });
            })
            .then(() => navigate('/')); // The root URL will get redirected to the user's default page.
        }
      })
      .catch(error => {
        dispatch({
          type: LOGIN_ERROR,
          payload: `Error: ${error.response.data.message ||
            'An error occurred in signup.'}`,
        });
      });
  };
};

/**
 * registerInviteUser registers users who have been sent an email to complete their registration (invite flow)
 * @param { Object } requestObj 
 *         {
              user: {
                first_name,
                last_name,
                phone,
                password,
                invitation_token
              }
            }
 */
export const registerInviteUser = requestObj => {
  return dispatch => {
    axios
      .put(`${DF_API_HOST}/api/v1/users/invitation`, requestObj)
      .then(response => {
        if (response.status === 200) {
          return navigate('/login');
        }
      })
      .catch(() => {
        dispatch({
          type: LOGIN_ERROR,
          payload: 'Your invite token is invalid',
        });
      });
  };
};

// This function checks the roles on a user to determine what pages they can access.
export const extractUserRoute = fetchedUserData => {
  const { roles, status } = fetchedUserData;

  if (roles.includes('admin')) {
    return 'adminUser';
  } else if (roles.includes('sales')) {
    return 'salesUser';
  } else if (status === 'approved' || status === 'basic') {
    return 'user';
  }

  // The last case is for an unauthenticated or logged out user.
  // We can't use the empty string, because we need a value that
  // coerces to true to know that we have loaded the routes.
  return 'unknown';
};

export const getUserId = fetchedUserData => {
  return getPropValue(fetchedUserData, 'id');
};

export const checkAuthentication = user => {
  return dispatch => {
    const token = getCookie('token');

    if (token) {
      dispatch({ type: AUTHENTICATE, payload: token });

      if (!user.email) {
        fetchUser().then(response => {
          if (typeof response === 'undefined') {
            navigate('/login');

            return dispatch({
              type: LOGIN_ERROR,
              payload: {
                error: { message: 'Invalid token. Please login.. ' },
                loading: false,
              },
            });
          }

          if (response && response.status >= 400) {
            navigate('/login');
          } else {
            dispatch({
              type: SET_USER_ROUTE,
              payload: extractUserRoute((response || {}).data),
            });
            dispatch({
              type: LOGIN_SUCCESS,
              payload: (response || {}).data,
            });
          }
        });
      }
      dispatch({ type: AUTHENTICATE_SUCCESS });
    } else {
      dispatch({ type: DEAUTHENTICATE });
    }
  };
};

export const login = (username, password) => {
  return dispatch => {
    dispatch({ type: LOGIN_BEGIN });

    loginUser(username, password)
      .then(response => {
        const token = grabJwtToken(response);
        dispatch({
          type: AUTHENTICATE,
          payload: token,
        });
      })
      .then(() => {
        fetchUser().then(response => {
          dispatch({
            type: SET_USER_ROUTE,
            payload: extractUserRoute(response.data),
          });
          dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data,
          });
          dispatch(getCartItems());
          if (process.env.NODE_ENV === 'production') {
            ReactGA.set({
              userId: getUserId(response.data),
            });
          }
        });
      })
      .then(() => navigate('/')) // The root url should redirect to the user's default route. See src/components/RouteSwitcher.tsx
      .catch(error => {
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            error: error,
            loading: false,
          },
        });
        navigate('/login');
      });
  };
};

// removing the token
export const logout = () => {
  return dispatch => {
    logoutUser().then(() => {
      removeCookie('token');
      if (process.env.NODE_ENV === 'production') {
        ReactGA.set({ userId: null });
      }
      dispatch({ type: DEAUTHENTICATE });
      navigate('/login');
    });
  };
};

export const addShippingAddress = address => {
  return dispatch => {
    dispatch({ type: ADD_ADDRESS, payload: address });
  };
};

export const deleteShippingAddress = id => {
  return dispatch => {
    dispatch({ type: DELETE_ADDRESS, payload: id });
  };
};

export const getCompanyList = () => {
  return dispatch => {
    fetchCompanyList().then(companyList => {
      dispatch({
        type: GET_COMPANY_LIST,
        payload: companyList || [],
      });
    });
  };
};

// Updates auth.user after a PUT request has been made to update User
export const setAuthUser = userData => {
  return {
    type: USER,
    payload: userData,
  };
};

// Adds/updates a setting in user.metadata
export const saveMetadata = metaData => {
  return {
    type: UPDATE_METADATA,
    payload: metaData,
  };
};
