import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';

import WaitSpinner from '../../components/WaitSpinner';
import {
  adminFetchReminders,
  downloadReminders,
  deleteReminderByEmail,
  deleteReminderByPhone,
  adminSendReminder,
  adminSendOutreachEmail,
} from '../../api/index';
import ReminderMetaFormatter from '../../components/admin/ReminderMetaFormatter';

import TimestampProvider from '../../components/admin/TimestampProvider';
import TableComponent from '../../components/admin/TableComponent';
import SVG from '../../components/SVG';

import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import IconButton from '@material-ui/core/IconButton';

const MetaProvider = props => (
  <DataTypeProvider formatterComponent={ReminderMetaFormatter} {...props} />
);

class RemindersIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reminders: null,
      email: '',
    };

    this.fetchReminders = this.fetchReminders.bind(this);
    this.ReminderActionCell = this.ReminderActionCell.bind(this);
    this.deleteReminder = this.deleteReminder.bind(this);
    this.sendReminder = this.sendReminder.bind(this);
    this.sendOutreachEmail = this.sendOutreachEmail.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  ReminderActionCell(props) {
    return (
      <>
        <IconButton
          onClick={() => this.deleteReminder(props.reminder)}
          title="Delete Reminder"
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={() => this.sendReminder(props.reminder)}
          title="Send Reminder"
        >
          <EmailIcon />
        </IconButton>
      </>
    );
  }

  componentDidMount() {
    this.fetchReminders();
  }

  columns() {
    return [
      {
        name: 'Actions',
        getCellValue: row =>
          this.ReminderActionCell({
            id: row.id,
            reminder: row,
          }),
      },
      { name: 'customer_email', title: 'Email' },
      { name: 'customer_phone', title: 'Phone' },
      { name: 'communication_preference', title: 'Medium' },
      { name: 'occasion_type', title: 'Occasion' },
      { name: 'occasion_at', title: 'Date' },
      { name: 'meta', title: 'Meta' },
      { name: 'created_at', title: 'Created At' },
      { name: 'last_sent_at', title: 'Last Sent At' },
    ];
  }

  async fetchReminders() {
    const reminders = await adminFetchReminders();
    this.setState({ reminders: reminders });
  }

  async deleteReminder(reminder) {
    let result = null;
    if (reminder.customer_email) {
      result = await deleteReminderByEmail(
        reminder.customer_email,
        reminder.id
      );
    } else if (reminder.customer_phone) {
      result = await deleteReminderByPhone(
        reminder.customer_phone,
        reminder.id
      );
    }

    window.location.reload(); // refresh the page
  }

  async sendReminder(reminder) {
    await adminSendReminder(reminder);
    this.props.showToaster(`Reminder sent.`);
  }

  async sendOutreachEmail() {
    await adminSendOutreachEmail(this.state.email);
    this.props.showToaster(`Outreach email sent to ${this.state.email}.`);
  }

  handleInputChange(e) {
    if (e.target) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  render() {
    const reminders = this.state.reminders;
    const timestampColumns = ['occasion_at', 'created_at', 'last_sent_at'];
    const tableColumnExtensions = [
      { columnName: 'meta', wordWrapEnabled: true },
    ];

    return (
      <div className="main-wrapper">
        <h1 className="title">All Reminders</h1>
        <div className="subtitle">
          <a className="df-link" onClick={downloadReminders}>
            <SVG className="svg-csv" name="csv" viewBox="0 0 600 600" />
          </a>
          <a className="df-link" href={'/admin/reminders/create'}>
            Create a reminder
          </a>
        </div>
        <div>
          <label htmlFor="email">Send an outreach email:</label>
          <input
            type="text"
            name="email"
            value={this.state.email}
            onChange={this.handleInputChange}
          />
          <button onClick={this.sendOutreachEmail}>Send</button>
        </div>
        {reminders ? (
          <Grid rows={reminders} columns={this.columns()}>
            <SortingState
              defaultSorting={[
                {
                  columnName: 'created_at',
                  direction: 'desc',
                },
              ]}
            />

            <SearchState defaultValue="" />
            <IntegratedSorting columnExtensions={tableColumnExtensions} />
            <IntegratedFiltering />
            <TimestampProvider for={timestampColumns} />
            <MetaProvider for={['meta']} />

            <Table
              tableComponent={TableComponent}
              columnExtensions={tableColumnExtensions}
            />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <SearchPanel />
          </Grid>
        ) : (
          <div className="WaitSpinner__container">
            <WaitSpinner />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(RemindersIndex);
