import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Alert } from 'reactstrap';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { registerPageType } from '../../propTypes';
import SimpleReactValidator from 'simple-react-validator';
import { createReminder } from '../../api/index';
import { navigate } from '../../utils/routerHistory';

const BUDGETS = [
  [0, 500],
  [500, 1000],
  [1000, 2500],
  [2500, 10000],
];

class ReminderCreatePage extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ locale: 'en' });

    this.state = {
      customer_email: '',
      customer_phone: '',
      customer_first_name: '',
      customer_last_name: '',
      customer_currency: 'USD',
      customer_locale: 'en_US',
      recipient_name: '',
      occasion_type: 'Anniversary',
      occasion_at: new Date(),
      communication_preference: 'email',
      meta: {
        budget: 0,
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    if (e.target) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleDateChange(newDate) {
    this.setState({ occasion_at: newDate });
  }

  handleBudgetChange(e) {
    if (e.target) {
      const { value } = e.target;
      this.setState({ meta: { budget: value } });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    if (this.validator.allValid()) {
      let newReminder = { ...this.state };
      newReminder.meta = { budget: BUDGETS[this.state.meta.budget] };
      await createReminder(newReminder);
      navigate('/admin/reminders'); // redirect to the admin reminders page
    } else {
      this.validator.showMessages();
    }
  }

  render() {
    const error = this.props.error;

    return (
      <div className="main-wrapper">
        <h1 className="title">Create Reminder</h1>
        <div className="register-container">
          <div className="register-headers">
            <p className="register-instruction">
              Create a reminder on behalf of a customer.
            </p>
          </div>
          <div className="register-form">
            <div className="registration-form">
              <div className="registration-form-elements left">
                <label htmlFor="customer_email">Customer Email</label>
                <input
                  className="df-text-inputs adduser-input"
                  name="customer_email"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('customer_email');
                  }}
                  defaultValue={''}
                />
                {this.validator.message(
                  'customer_email',
                  this.state.customer_email,
                  'required'
                )}
              </div>
              <div className="registration-form-elements right">
                <label htmlFor="customer_phone">Customer Phone</label>
                <input
                  className="df-text-inputs adduser-input"
                  name="customer_phone"
                  onChange={this.handleInputChange}
                  defaultValue={''}
                />
                <span className="instructions">
                  Numbers only. Example: 12223334444
                </span>
              </div>

              <div className="registration-form-elements left">
                <label htmlFor="first_name">Customer First Name</label>
                <input
                  className="df-text-inputs adduser-input"
                  name="customer_first_name"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('customer_first_name');
                  }}
                  defaultValue={''}
                />
                {this.validator.message(
                  'customer_first_name',
                  this.state.customer_first_name,
                  'required'
                )}
              </div>
              <div className="registration-form-elements right">
                <label htmlFor="last_name">Customer Last Name</label>
                <input
                  className="df-text-inputs adduser-input"
                  name="customer_last_name"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('customer_last_name');
                  }}
                  defaultValue={''}
                />
                {this.validator.message(
                  'customer_last_name',
                  this.state.customer_last_name,
                  'required'
                )}
              </div>

              <div className="registration-form-elements left">
                <label htmlFor="customer_currency">Customer Currency</label>
                <select
                  className="admin-select"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('customer_currency');
                  }}
                  value={this.state.customer_currency}
                  name="customer_currency"
                >
                  <option value="USD">USD</option>
                  <option value="AUD">AUD</option>
                  <option value="CAD">CAD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                </select>
                {this.validator.message(
                  'customer_currency',
                  this.state.customer_currency,
                  'required'
                )}
              </div>
              <div className="registration-form-elements right">
                <label htmlFor="customer_locale">Customer Locale</label>
                <select
                  className="admin-select"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('customer_locale');
                  }}
                  value={this.state.customer_locale}
                  name="customer_locale"
                >
                  <option value="en_US">en_US</option>
                  <option value="de">de</option>
                  <option value="es">es</option>
                  <option value="fr">fr</option>
                </select>
                {this.validator.message(
                  'customer_locale',
                  this.state.customer_locale,
                  'required'
                )}
              </div>

              <div className="registration-form-elements left">
                <label htmlFor="recipient_name">Recipient Name</label>
                <input
                  className="df-text-inputs adduser-input"
                  name="recipient_name"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('recipient_name');
                  }}
                  defaultValue={''}
                />
                {this.validator.message(
                  'recipient_name',
                  this.state.customer_currency,
                  'required'
                )}
              </div>
              <div className="registration-form-elements right">
                <label htmlFor="meta.budget">Budget</label>
                <select
                  className="admin-select"
                  onChange={e => {
                    this.handleBudgetChange(e);
                    this.validator.showMessages('meta.budget');
                  }}
                  value={this.state.meta.budget}
                  name="meta.budget"
                >
                  <option value={0}>0 - 500</option>
                  <option value={1}>500 - 1000</option>
                  <option value={2}>1000 - 2,500</option>
                  <option value={3}>2,500+</option>
                </select>
                {this.validator.message(
                  'meta.budget',
                  this.state.meta.budget,
                  'required'
                )}
              </div>

              <div className="registration-form-elements left">
                <label htmlFor="occastion_type">Occasion</label>
                <input
                  className="df-text-inputs adduser-input"
                  name="occasion_type"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('occasion_type');
                  }}
                  value={this.state.occasion_type}
                />
                {this.validator.message(
                  'occasion_type',
                  this.state.occasion_type,
                  'required'
                )}
              </div>
              <div className="registration-form-elements right">
                <label htmlFor="occasion_at">Date</label>
                <div className="date">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      format="MM/dd/yyyy"
                      name="occasion_at"
                      value={this.state.occasion_at}
                      onChange={newDate => {
                        this.handleDateChange(newDate);
                        this.validator.showMessages('occasion_at');
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                {this.validator.message(
                  'occasion_at',
                  this.state.occasion_at,
                  'required'
                )}
              </div>

              <div className="registration-form-elements left">
                <label htmlFor="communication_preference">
                  Communication Preference
                </label>
                <select
                  className="admin-select"
                  onChange={e => {
                    this.handleInputChange(e);
                    this.validator.showMessages('communication_preference');
                  }}
                  value={this.state.communication_preference}
                  name="communication_preference"
                >
                  <option value="email">email</option>
                  <option value="text">text</option>
                  <option value="phone">phone</option>
                  <option value="none">none</option>
                </select>
                {this.validator.message(
                  'communication_preference',
                  this.state.communication_preference,
                  'required'
                )}
              </div>
              <div className="registration-form-elements right"></div>
            </div>
            {error && <Alert color="danger">{error}</Alert>}

            <div className="registration-form">
              <Button
                type="submit"
                className="sign-in-btn df-cta"
                onClick={e => this.handleSubmit(e)}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReminderCreatePage.propTypes = registerPageType;

const mapState = state => ({
  isLoggedIn: state.auth.user && !state.auth.loading,
  error: state.auth.error,
});

export default connect(mapState)(ReminderCreatePage);
