import CustomerAccount from './customerAccount';
import Diamond from './diamond';
import RoughDiamond from './roughDiamond';
import StatsPolishedParcels from './statsPolishedParcels';
import moment from 'moment';

interface InventoryHold {
  expires_at: string;
  start_at: string;
  holdable_id: string;
  holdable_type: string;
  id: string;
  customer_account_id: string;

  customer_account?: CustomerAccount;
  holdable?: Holdable;
}

const getShortTimeZone = (date: Date) => {
  return date
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2];
};

export type DateTimeUTC = string;

export const getTimeWithZone = (expires: DateTimeUTC) => {
  const S = getShortTimeZone(new Date(expires)).toString();
  return moment(expires).format(`MM/DD/YYYY [at] h:mm a [(${S})]`);
};

export type Holdable = Diamond | RoughDiamond | StatsPolishedParcels;

export default InventoryHold;
