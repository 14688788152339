import React, { useState } from 'react';
import Option from '../../types/more/option';
import PolishedDiamondsGroup from '../../types/models/polishedDiamondsGroup';
import SVG from '../SVG';
import PolishedDiamondList from './diamonds/PolishedDiamondList';
import { Button } from '@material-ui/core';
import DiamondFoundryCaption from '../DiamondFoundryCaption';
import CellEntry from '../../types/more/cellEntry';
import PolishedCartItem from '../../types/models/polishedCartItem';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../types/redux/rootState';
import CartItem from '../../types/models/cartItem';
import { calculateTotalPrice } from '../../state/action/diamondCart';
import { updateCart } from '../../api';
import { formatUSD } from '../../utils/util';

type Props = {
  header: Option<string, string>[];
  data: CellEntry<PolishedDiamondsGroup>;
  onChange: (data: CellEntry<PolishedDiamondsGroup>) => void;
};

const PolishedDiamondGroupListItem = ({ header, data, onChange }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const cartSelector = useSelector((state: RootState) => state.diamondCart);

  const addToCart = () => {
    const cartItem: PolishedCartItem = {
      id: data.entry.id,
      name: data.entry.name,
      netsuite_id: data.entry.netsuite_id,
      quantity: data.entry.total_program_count,
      orderType: 'PolishedParcel',
      price: +data.entry.parcel_price,
    };
    const uniqueCartItems: CartItem[] = [
      cartItem,
      ...cartSelector.cart.filter(item => cartItem.id !== item.id),
    ];

    // Add to store
    dispatch({
      type: 'ADD_ITEM_TO_CART',
      item: cartItem,
      totalPrice: calculateTotalPrice(uniqueCartItems),
      updated: 'unique',
    });
    // Update backend
    updateCart({
      items: uniqueCartItems,
    });
  };
  const onRowClicked = () => {
    setExpanded(e => !e);
  };
  const map: any = {
    ...data.entry,
  };

  return (
    <div
      className={
        expanded
          ? 'PolishedDiamondGroupListItem DiamondListItem DiamondListItem__expanded'
          : 'PolishedDiamondGroupListItem DiamondListItem'
      }
    >
      <div className="tr" onClick={onRowClicked}>
        <div className="td checkbox-cell">
          <div className="checkbox-container">
            <input
              type="checkbox"
              className="form-check-input"
              checked={data.selected}
              onChange={() => {}}
              onClick={(e: React.FormEvent<HTMLInputElement>) => {
                e.stopPropagation();
                onChange({
                  entry: data.entry,
                  selected: !data.selected,
                });
              }}
            />
          </div>
        </div>
        {header.map((h, index) => {
          if (map[h.key] === undefined) {
            throw new Error(`Error: ${h.key} undefined`);
          }
          return (
            <div key={index} className="td">
              {h.key === 'parcel_price' ? formatUSD(map[h.key]) : map[h.key]}
            </div>
          );
        })}
      </div>
      {expanded && (
        <div className="DiamondListItem__dropdown">
          <div className="close__container">
            <SVG
              className="Svg close"
              name="close-diamond-table"
              viewBox="0 0 34 32"
              onClick={() => setExpanded(false)}
            />
          </div>

          <div className="DiamondListItem__column detail">
            <span className="diamond-header headerinfo">{`Polished Parcel`}</span>
            <PolishedDiamondList data={data.entry.diamonds} />
            <div className="cart-cta__container">
              <Button className="df-cta" onClick={addToCart}>
                ADD TO CART
              </Button>
              <DiamondFoundryCaption />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PolishedDiamondGroupListItem;
