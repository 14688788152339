import React from 'react';
import SVG from './SVG';

class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
  }

  render() {
    return (
      <div className="PasswordInput">
        <input
          className={this.props.inputStyle}
          type={this.state.hidden ? 'password' : 'text'}
          name={this.props.name}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          ref={this.props.inputRef ? this.props.inputRef : ''}
        />
        {this.state.hidden && (
          <SVG
            className="password-icon"
            viewBox="0 0 600 600"
            name="eye-open"
            onClick={e => this.setState({ hidden: !this.state.hidden })}
          />
        )}

        {!this.state.hidden && (
          <SVG
            className="password-icon"
            viewBox="0 0 600 600"
            name="eye-closed"
            onClick={e => this.setState({ hidden: !this.state.hidden })}
          />
        )}
      </div>
    );
  }
}

export default PasswordInput;
