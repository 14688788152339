import React from 'react';
import { Router, Redirect } from '@reach/router';

import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import NotFoundPage from '../pages/NotFoundPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import ForgotPage from '../pages/ForgotPage';
import UnsubscribePage from '../pages/UnsubscribePage';

import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import InviteSignUpPage from '../pages/InviteSignUpPage';
import ChangePasswordPage from '../pages/ChangePasswordPage';
import CodeOfConductPage from '../pages/CodeOfConductPage';

// if there is no route match for unprotected users, redirect to /login
const NoMatchUnprotected = () => <Redirect noThrow to="/login" />;

export const UnprotectedRoutes = ({ showToaster, closeToaster }) => (
  <Router className="main">
    <Redirect noThrow from="/" to="/login" />
    {/* Unprotected Routes */}
    <InviteSignUpPage
      path="/accept"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <RegisterPage
      path="/register"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <LoginPage
      path="/login"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <NotFoundPage
      default
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <ForgotPage
      path="/reset_password"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <UnsubscribePage
      path="/unsubscribe"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <ChangePasswordPage
      path="/reset"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <TermsAndConditionsPage
      path="/terms-and-conditions"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <PrivacyPolicyPage
      path="/privacy-policy"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <CodeOfConductPage
      path="/supplier-code-of-conduct"
      showToaster={showToaster}
      closeToaster={closeToaster}
    />
    <NoMatchUnprotected default />
  </Router>
);
