import {
  SET_DIAMOND_STATS,
  SET_ROUGH_DIAMOND_STATS,
  SET_ROUGH_DIAMOND_GROUP_STATS,
} from './constants';

const initialState = {
  diamonds: {},
  roughDiamonds: {},
  roughDiamondGroups: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DIAMOND_STATS:
      return Object.assign({}, state, { diamonds: action.payload });
    case SET_ROUGH_DIAMOND_STATS:
      return Object.assign({}, state, { roughDiamonds: action.payload });
    case SET_ROUGH_DIAMOND_GROUP_STATS:
      return Object.assign({}, state, { roughDiamondGroups: action.payload });
    default:
      return state;
  }
};
