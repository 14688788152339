const initialState = {
  customerAccounts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_CUSTOMER_ACCOUNTS':
      return { ...state, customerAccounts: action.customerAccounts };
    default:
      return state;
  }
};
