import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import { fetchConsignmentOrders } from '../../api/index';
import TableComponent from '../../components/admin/TableComponent';
import {
  toMonthDayYearTime,
  compareTimestamps,
} from '../../utils/dateUtilities';
import { formatUSDWithNoDecimals } from '../../utils/util';
import { compareLinkText, compareCurrency } from '../../utils/tableUtilities';

import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';

class ConsignmentOrdersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: null,
    };

    this.fetchConsignmentOrders = this.fetchConsignmentOrders.bind(this);
  }

  componentDidMount() {
    this.fetchConsignmentOrders();
  }

  async fetchConsignmentOrders() {
    const consignmentOrders = await fetchConsignmentOrders();

    this.setState({ orders: consignmentOrders });
  }

  render() {
    let columns = [
      {
        name: 'id',
        title: 'ID',
        getCellValue: row => (
          <Link className="df-link flex" to={`/orders/${row.id}`}>
            {row.erp_id || `Order Details`}
          </Link>
        ),
      },
      {
        name: 'company_name',
        title: 'Company',
        getCellValue: row => {
          if (row.customer_account) {
            return (
              <Link
                className="df-link flex"
                to={`/admin/customers/${row.customer_account_id}`}
              >
                {row.customer_account.company_name}
              </Link>
            );
          }
        },
      },
      {
        name: 'placed_by',
        title: 'Placed By',
        getCellValue: row =>
          row.placed_by && row.placed_by.first_name
            ? `${row.placed_by.first_name} ${row.placed_by.last_name}`
            : (row.placed_by || {}).email || undefined,
      },
      {
        name: 'grand_total_in_cents',
        title: 'Grand Total',
        getCellValue: row =>
          formatUSDWithNoDecimals(row.grand_total_in_cents / 100.0),
      },
      {
        name: 'created_at',
        title: 'Created',
        getCellValue: row => toMonthDayYearTime(row.created_at),
      },
    ];

    const integratedSortingColumnExtensions = [
      { columnName: 'id', compare: compareLinkText },
      { columnName: 'company_name', compare: compareLinkText },
      { columnName: 'grand_total_in_cents', compare: compareCurrency },
      { columnName: 'created_at', compare: compareTimestamps },
    ];

    return (
      <main>
        <div className="main-wrapper">
          <Grid rows={this.state.orders || []} columns={columns}>
            <SortingState
              defaultSorting={[
                {
                  columnName: 'created_at',
                  direction: 'desc',
                },
              ]}
            />
            <IntegratedSorting
              columnExtensions={integratedSortingColumnExtensions}
            />
            <Table tableComponent={TableComponent} />
            <TableHeaderRow showSortingControls />
          </Grid>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(ConsignmentOrdersPage);
