import { uniqBy } from 'lodash';

const diamondCartReducerDefaultState = {
  cart: [],
  totalPrice: 0,
  updated: '',
};

export default (state = diamondCartReducerDefaultState, action) => {
  switch (action.type) {
    case 'ADD_ITEM_TO_CART':
      return {
        ...state,
        cart: [...state.cart, action.item],
        totalPrice: action.totalPrice,
        updated: action.updated,
      };
    case 'ADD_MULTIPLE_ITEMS_TO_CART':
      return {
        ...state,
        cart: uniqBy([...state.cart, ...action.items], 'id'),
        totalPrice: action.totalPrice,
        updated: action.updated,
      };
    case 'REMOVE_ITEM_FROM_CART':
      return {
        ...state,
        cart: state.cart.filter(({ id }) => id !== action.id),
        totalPrice: action.totalPrice,
        updated: action.updated,
      };
    case 'IMPORT_ITEMS_TO_CART':
      return {
        ...state,
        cart: action.items,
        totalPrice: action.totalPrice,
        updated: action.updated,
      };
    case 'ADD_QUANTITY_TO_ITEM':
      return { ...state, cart: [...state.cart], totalPrice: action.totalPrice };
    case 'REMOVE_QUANTITY_FROM_ITEM':
      return { ...state, cart: [...state.cart], totalPrice: action.totalPrice };
    case 'RESET_CART':
      return { ...state, ...diamondCartReducerDefaultState };
    case 'STORE_CART':
      return { ...state, cart: action.cart };
    default:
      return state;
  }
};
