import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CSVLink } from 'react-csv';
import RoughDiamondGroupListItem from './RoughDiamondGroupListItem';
import SVG from '../SVG';
import { debounce } from '../../utils/util';
import { getRoughCSVHeaders, getRoughCSVData } from '../../utils/csvUtilities';
import WaitSpinner from '../WaitSpinner';
import RoughDiamondGroupListHeader from './RoughDiamondGroupListHeader';

class RoughDiamondGroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedRows: 25, // the number of diamonds that should be loaded into the diamonds list
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.updateDisplayedRows = this.updateDisplayedRows.bind(this);

    // set up a debounced method for loading more rows into the diamonds list
    this.debouncedUpdateDisplayedRows = debounce(this.updateDisplayedRows, 50);
    this.debouncedUpdateDisplayedRows = this.debouncedUpdateDisplayedRows.bind(
      this
    );
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.debouncedUpdateDisplayedRows();
  }

  updateDisplayedRows() {
    let newDisplayedRows = 25 + window.scrollY / 50;
    if (newDisplayedRows > this.state.displayedRows)
      this.setState({ displayedRows: newDisplayedRows });
  }

  // props will have props.diamonds (array)
  render() {
    if (
      !this.props.loading &&
      this.props.roughDiamondGroups &&
      this.props.roughDiamondGroups.length === 0
    ) {
      return (
        <div className="no-diamond-data">
          No rough diamonds currently fit your criteria, try expanding your
          search with our filter options.
        </div>
      );
    }
    return (
      <div className="DiamondList RoughDiamondGroupList">
        <div className="Diamond_selectors">
          <div className="DiamondList__selector-container">
            Select:
            <div
              className="DiamondList__selectors allselect"
              onClick={e => this.props.selectAllDiamond(e)}
            >
              <i className="material-icons check">check</i>
              All
            </div>
            <div
              className="DiamondList__selectors clearall"
              onClick={e => this.props.deselectAllDiamond(e)}
            >
              <i className="material-icons clear">clear</i>
              Clear
            </div>
          </div>
          <CSVLink
            headers={getRoughCSVHeaders()}
            data={getRoughCSVData(this.props.roughDiamondGroups)}
            filename="DiamoundFoundryRough.csv"
            target="_blank"
          >
            {<SVG className="Svg" name="csv" viewBox="0 0 600 600" />}
          </CSVLink>
        </div>

        <div className={'table'}>
          <RoughDiamondGroupListHeader
            applyRoughDiamondGroupSort={this.props.applyRoughDiamondGroupSort}
            activeSortParam={this.props.activeSortParam}
            isReversedParam={this.props.isReversedParam}
          />
          <div className="tbody">
            {!this.props.loading &&
              this.props.roughDiamondGroups &&
              this.props.roughDiamondGroups.map((roughDiamondGroup, index) => {
                if (index <= this.state.displayedRows) {
                  return (
                    <RoughDiamondGroupListItem
                      key={index}
                      {...roughDiamondGroup}
                      setIndex={this.props.setIndex}
                      showToaster={this.props.showToaster}
                      expanded={roughDiamondGroup.id === this.props.index}
                      selected={roughDiamondGroup.isSelected}
                    />
                  );
                }
              })}
            {(this.props.loading ||
              this.state.displayedRows <
                this.props.roughDiamondGroups.length) && <WaitSpinner />}
          </div>
        </div>
      </div>
    );
  }
}

RoughDiamondGroupList.propTypes = {
  roughDiamondGroups: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect()(RoughDiamondGroupList);
