import React from 'react';

const ReminderMetaFormatter = ({ value }) => {
  function formatProperty(attribute, propertyValue) {
    const attributeJoinSeperators = {
      budget: ' - ',
    };

    if (Array.isArray(propertyValue)) {
      // formatter for arrays
      const seperator = attributeJoinSeperators[attribute] || ', ';
      const newPropertyValue = propertyValue.join(seperator);

      propertyValue = newPropertyValue;
    }

    let formattedAttributeName = attribute;

    return `${formattedAttributeName}: ${propertyValue}`; // formatter for single values
  }
  const reminderProperties = value;

  return Object.keys(reminderProperties).map((key, index) => (
    <div key={index}>{formatProperty(key, reminderProperties[key])}</div>
  ));
};

export default ReminderMetaFormatter;
