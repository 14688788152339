import React, { useEffect, useState } from 'react';
import PolishedDiamondsGroup from '../../types/models/polishedDiamondsGroup';
import Option from '../../types/more/option';
import PolishedDiamondGroupHeader from './PolishedDiamondGroupHeader';
import TableBody from './TableBody';
import CellEntry from '../../types/more/cellEntry';
import { compare } from '../../utils/util';
import styled from '@emotion/styled';

const Outer = styled.div`
  .table {
    .DiamondListItem__dropdown {
      .DiamondListItem__column {
        width: 100%;
      }
    }
  }
`;
/*
Define the columns.
 */
export const HEADERS: Option<string, string>[] = [
  {
    label: 'ID',
    key: 'name',
  },
  {
    label: 'Program Type',
    key: 'netsuite_program_type',
  },
  {
    label: 'Parcel Number',
    key: 'program_parcel_id',
  },
  {
    label: 'Items',
    key: 'total_program_count',
  },
  {
    label: 'Price',
    key: 'parcel_price',
  },
];

type Props = {
  data: CellEntry<PolishedDiamondsGroup>[];
  onSelectAll: () => void;
  onUnselectAll: () => void;
};

const PolishedDiamondGroupList = ({
  data,
  onSelectAll,
  onUnselectAll,
}: Props) => {
  /*
  This component should only be concern with sorting related issues. Not filtering.
   */
  const [statefulData, setStatefulData] = useState(data);

  useEffect(() => {
    setStatefulData(data);
    sortData();
  }, [data]);

  const [activeSortParameter, setActiveSortParameter] = useState<string | null>(
    null
  );
  const [reverseSort, setReverseSort] = useState(false);
  const [isSorting, setIsSorting] = useState(false);

  const onSort = (parameter: string) => {
    if (isSorting || !parameter) {
      return;
    }
    setIsSorting(true);
    setActiveSortParameter(parameter);
    setReverseSort(r => !r);
    sortData().then(() => setIsSorting(false));
  };

  const sortData = async () => {
    if (activeSortParameter === null) {
      return;
    }
    const sign = reverseSort ? -1 : 1;
    const sorted = statefulData.sort((a, b) => {
      const map1: any = {
        ...a.entry,
      };
      const map2: any = {
        ...b.entry,
      };
      return sign * compare(map1, map2, activeSortParameter);
    });
    setStatefulData(sorted);
  };
  return (
    <Outer className="DiamondList">
      <div className="Diamond_selectors">
        <div className="DiamondList__selector-container">
          Select:
          <div
            onClick={onSelectAll}
            className="DiamondList__selectors allselect"
          >
            <i className="material-icons check">check</i>
            All
          </div>
          <div
            onClick={onUnselectAll}
            className="DiamondList__selectors clearall"
          >
            <i className="material-icons clear">clear</i>
            Clear
          </div>
        </div>
      </div>

      <div className="table">
        <PolishedDiamondGroupHeader
          headers={HEADERS}
          reverseSort={reverseSort}
          activeSortParameter={activeSortParameter}
          onSort={onSort}
        />
        <TableBody data={statefulData} header={HEADERS} />
      </div>
    </Outer>
  );
};

export default PolishedDiamondGroupList;
