import React, { useEffect, useState } from 'react';
import { fetchUsersInventoryHolds } from '../../api/diamonds';
import InventoryHold from '../../types/models/inventoryHold';
import Diamond from '../../types/models/diamond';
import Fetch from '../../components/ui/Fetch';
import { OnWaitSpinner } from '../../utils/orderUtilities';
import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import TimestampProvider from '../../components/admin/TimestampProvider';
import TableComponent from '../../components/admin/TableComponent';
import { Link } from '@reach/router';
import { getTwoLetterShapeAbbreviation } from '../../utils/shapeUtilities';

type Props = {
  showToaster: (arg: string) => void;
  closeToaster: (arg: string) => void;
};

type HoldColumn = {
  name: string;
  title: string;
  getCellValue: (hold: InventoryHold) => React.ReactNode;
};

/* Display all the holds */
const InventoryHoldsPage = ({ showToaster, closeToaster }: Props) => {
  const [loading, setLoading] = useState(false);
  const [inventory, setInventory] = useState<InventoryHold[]>([]);

  // Does a server side fetch.
  const fetchAndSetHolds = async () => {
    fetchUsersInventoryHolds({ active: true }).then(res => {
      setInventory(res.data);
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchAndSetHolds();
    setLoading(false);
  }, []);

  const getAbbreviated = (hold: InventoryHold) => {
    const { holdable } = hold;
    if (!holdable) {
      return null;
    }
    if (holdable as Diamond) {
      const d: Diamond = holdable as Diamond;
      return `F${d.lot_id}, ${d.carat} ct ${d.clarity}, ${
        d.color
      }, ${getTwoLetterShapeAbbreviation(d.shape)}`;
    }
    return null;
  };

  const COLUMNS: HoldColumn[] = [
    {
      name: 'abbreviation',
      title: 'Name',
      getCellValue: hold => {
        return <> {getAbbreviated(hold)} </>;
      },
    },
    {
      name: 'start_at',
      title: 'Starts at',
      getCellValue: hold => {
        return hold.start_at;
      },
    },
    {
      name: 'expires_at',
      title: 'Expires at',
      getCellValue: hold => {
        return hold.expires_at;
      },
    },
    {
      name: 'customer_account',
      title: 'Company',
      getCellValue: hold => {
        if (!hold.customer_account) {
          return null;
        }
        return (
          <Link
            className="df-link"
            to={`/admin/customers/${hold.customer_account.id}`}
          >
            {hold.customer_account.company_name}
          </Link>
        );
      },
    },
  ];

  return (
    <div className="main-wrapper">
      <h1 className="title">All Holds</h1>
      <Fetch
        render={
          <Grid rows={inventory} columns={COLUMNS}>
            <SortingState
              defaultSorting={[
                {
                  columnName: 'start_at',
                  direction: 'desc',
                },
              ]}
            />
            <TimestampProvider for={['expires_at', 'start_at']} />
            <SearchState defaultValue="" />
            <IntegratedSorting />
            <IntegratedFiltering />
            <Table tableComponent={TableComponent} />
            <TableHeaderRow showSortingControls />
            <Toolbar />
          </Grid>
        }
        empty={<span className="tail-message">There are no current holds</span>}
        isEmpty={inventory.length === 0}
        loading={<OnWaitSpinner />}
        isLoading={loading}
      />
    </div>
  );
};

export default InventoryHoldsPage;
