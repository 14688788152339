import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import React, { Component } from 'react';

import { connect } from 'react-redux';
import TimestampProvider from '../components/admin/TimestampProvider';

import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';

import {
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';

import WatchPropertiesFormatter from '../components/admin/WatchPropertiesFormatter';
import TableComponent from '../components/admin/TableComponent';

import { deleteWatch } from '../state/watches/actions';
import { bindActionCreators } from 'redux';

const PropertiesProvider = props => (
  <DataTypeProvider formatterComponent={WatchPropertiesFormatter} {...props} />
);

const NotifierActionCell = props => {
  return (
    <IconButton
      onClick={() => props.dispatch(deleteWatch(props.notifier))}
      title="Delete Notifier"
    >
      <DeleteIcon />
    </IconButton>
  );
};

class WatchList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const columns = [
      {
        name: 'Actions',
        getCellValue: row => (
          <NotifierActionCell
            id={row.id}
            notifier={row}
            dispatch={this.props.dispatch}
          />
        ),
      },
      {
        name: 'inventory_type',
        title: 'Inventory',
        getCellValue: row =>
          row.inventory_type === 'diamonds' ? 'Diamonds' : 'Rough Diamonds',
      },
      { name: 'properties', title: 'Properties' },
      { name: 'created_at', title: 'Created At' },
      { name: 'last_sent_at', title: 'Last Sent at' },
    ];
    const watches = this.props.watches;

    return (
      <Grid rows={watches} columns={columns}>
        <SortingState
          defaultSorting={[{ columnName: 'created_at', direction: 'desc' }]}
        />

        <IntegratedSorting />
        <IntegratedFiltering />
        <PropertiesProvider for={['properties']} />
        <TimestampProvider for={['created_at', 'last_sent_at']} />
        <Table
          tableComponent={TableComponent}
          columnExtensions={[
            { columnName: 'properties', wordWrapEnabled: true },
          ]}
        />
        <TableHeaderRow showSortingControls />
        <Toolbar />
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ deleteWatch }, dispatch),
  };
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.auth.user,
    watches: state.watches,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchList);
