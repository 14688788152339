import { v4 as uuidv4 } from 'uuid';
import { fetchCart, updateCart } from '../../api/index';
import { uniqBy } from 'lodash';
import {
  getItemPrice,
  getDiamondPrice,
  roughGroupPrice,
} from '../../utils/util';
import { deselectAllRoughDiamondGroups } from '../roughDiamondGroups/actions';

export const calculateTotalPrice = items => {
  if (items === undefined || items === null || items.length === 0) {
    return 0;
  }

  const initValue = 0;
  return items
    .filter(item => item.price) // Find items with price in JSON.
    .reduce((acc, item) => acc + item.price * 100, initValue); // Calculate the sum
};

export const addItemToCart = (
  {
    name = '',
    shape = '',
    color = '',
    carat = '',
    clarity = '',
    sku = '',
    price = '',
    quantity = '',
    gcal_cert_id = '',
    lot_id = '',
    updated = '',
    orderType = '',
    id = '',
    cert_url = '',
  },
  toastCallback
) => {
  return (dispatch, getState) => {
    const state = getState();
    const item = {
      // diamond id for 'Special Order' is bogusly a randomly generated UUID
      id: id === '' ? uuidv4() : id,
      name,
      shape,
      color,
      carat,
      clarity,
      sku,
      price,
      quantity,
      orderType,
      gcal_cert_id,
      lot_id,
      cert_url,
    };

    const isAlreadyAdded =
      state.diamondCart.cart.filter(cartItem => cartItem.id === item.id)
        .length > 0;

    const cart = {
      items: [...state.diamondCart.cart, item],
    };

    if (!isAlreadyAdded) {
      dispatch({
        type: 'ADD_ITEM_TO_CART',
        item,
        totalPrice: calculateTotalPrice(cart.items),
        updated,
      });
      updateCart(cart);
    }
    toastCallback(isAlreadyAdded);
  };
};

export const resetCart = () => {
  return (dispatch, getState) => {
    const state = getState();
    const cart = { items: [] };
    state.diamondCart.diamondCart = cart;
    updateCart(cart);

    dispatch({
      type: 'RESET_CART',
    });
  };
};

export const removeItemFromCart = id => {
  return (dispatch, getState) => {
    const state = getState();

    const cart = {
      items: state.diamondCart.cart.filter(cartItem => cartItem.id !== id),
    };

    dispatch({
      type: 'REMOVE_ITEM_FROM_CART',
      id,
      totalPrice: calculateTotalPrice(cart.items),
      updated: '',
    });
    updateCart(cart);
  };
};

export const importItemsToCart = (items, grandTotal) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'IMPORT_ITEMS_TO_CART',
      items,
      totalPrice: +grandTotal,
      updated: '',
    });
  };
};

let clickTimeout = null;

export const addOneToQuantity = (id = '') => {
  return (dispatch, getState) => {
    const state = getState();

    const shoppingCart = state.diamondCart.cart;
    const updatedCart = shoppingCart.map((item, index) => {
      if (id === item.id && +item.quantity < 1000) {
        item.quantity = +item.quantity + 1;
        item.price = getItemPrice(item.carat, item.quantity);
      }
      return item;
    });

    const totalPrice = calculateTotalPrice(updatedCart);

    const cart = {
      items: updatedCart,
    };

    dispatch({
      type: 'ADD_QUANTITY_TO_ITEM',
      totalPrice,
    });

    clearTimeout(clickTimeout);
    clickTimeout = setTimeout(() => {
      updateCart(cart);
    }, 1000);
  };
};

export const removeOneFromQuantity = (id = '') => {
  return (dispatch, getState) => {
    const state = getState();

    const shoppingCart = state.diamondCart.cart;
    const updatedCart = shoppingCart.map(item => {
      if (id === item.id && +item.quantity > 1) {
        item.quantity = +item.quantity - 1;
        item.price = getItemPrice(item.carat, item.quantity);
      }
      return item;
    });

    const totalPrice = calculateTotalPrice(updatedCart);

    const cart = {
      items: updatedCart,
    };

    dispatch({
      type: 'REMOVE_QUANTITY_FROM_ITEM',
      totalPrice,
    });

    clearTimeout(clickTimeout);
    clickTimeout = setTimeout(() => {
      updateCart(cart);
    }, 1000);
  };
};

export const addMultipleItemsToCart = () => {
  return (dispatch, getState) => {
    // get the state of the component
    const state = getState();

    // get the selected diamonds
    const selectedItems = state.diamonds.rendered.filter(
      item => item.isSelected
    );

    // make the cart items
    const cartItems = selectedItems.map(cartitem => {
      if (cartitem.orderType === 'PolishedParcel') {
        return { ...cartitem };
      }
      return {
        id: cartitem.id,
        name: cartitem.shape,
        shape: cartitem.shape.toLowerCase(),
        color: cartitem.color,
        carat: cartitem.carat.toString(),
        clarity: cartitem.clarity,
        price: +getDiamondPrice(cartitem),
        quantity: cartitem.quantity,
        gcal_cert_id: cartitem.gcal_cert_id,
        cert_url: cartitem.cert_url,
        lot_id: cartitem.lot_id,
        orderType: 'Diamond',
      };
    });

    const cart = {
      items: uniqBy([...state.diamondCart.cart, ...cartItems], 'id'),
    };

    dispatch({
      type: 'ADD_MULTIPLE_ITEMS_TO_CART',
      items: cartItems,
      totalPrice: calculateTotalPrice(cart.items),
      updated: 'unique',
    });

    dispatch({
      type: 'DESELECT_ALL_DIAMONDS',
    });
    updateCart(cart);
  };
};

export const addMultipleRoughDiamondGroupsToCart = () => {
  return (dispatch, getState) => {
    // get the state of the component
    const state = getState();

    // get the selected diamonds
    const selectedItems = state.roughDiamondGroups.selected;

    // make the cart items
    const cartItems = selectedItems.map(cartitem => {
      return {
        id: cartitem.id,
        name: cartitem.name,
        netsuite_id: cartitem.netsuite_id,
        price: roughGroupPrice(cartitem.parcel_price, cartitem.default_price),
        carat_weight_mean: cartitem.carat_weight_mean,
        carat_weight_sum: cartitem.carat_weight_sum,
        estimated_polish_color: cartitem.estimated_polish_color,
        quantity: cartitem.quantity,
        orderType: 'RoughDiamondGroup',
      };
    });

    const cart = {
      items: uniqBy([...state.diamondCart.cart, ...cartItems], 'id'),
    };

    dispatch({
      type: 'ADD_MULTIPLE_ITEMS_TO_CART',
      items: cartItems,
      totalPrice: calculateTotalPrice(cart.items),
      updated: 'unique',
    });

    dispatch(deselectAllRoughDiamondGroups());
    updateCart(cart);
  };
};

export const getCartItems = () => {
  return dispatch => {
    fetchCart().then(response => {
      if (response) {
        dispatch({
          type: 'IMPORT_ITEMS_TO_CART',
          items: response.items,
          totalPrice: +response.grand_total,
          updated: 'unique',
        });
      }
    });
  };
};

export const storeUpdatedCart = cart => {
  updateCart({ items: cart });

  return dispatch => {
    dispatch({ type: 'STORE_CART', cart });
  };
};
