import {
  AUTHENTICATE,
  DEAUTHENTICATE,
  AUTHENTICATE_SUCCESS,
  USER,
  LOGIN_ERROR,
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  ADD_ADDRESS,
  DELETE_ADDRESS,
  GET_COMPANY_LIST,
  SET_USER_ROUTE,
  UPDATE_METADATA,
} from './constants';

const initialState = {
  token: null,
  user: {
    first_name: null,
    last_name: null,
    email: null,
    roles: [],
    customer_account: {
      company_name: null,
      addresses: [],
    },
    companyList: [],
  },
  userRoute: '', // This flag value indicates that we don't know what this user can access yet.
  error: null,
  loading: true, // This initial loading flag allows us to NOT show the UI until we know who is using the site
};

const logoutState = {
  token: null,
  user: {
    first_name: null,
    last_name: null,
    email: null,
    roles: [],
    customer_account: {
      company_name: null,
      addresses: [],
    },
    companyList: [],
  },
  userRoute: 'unknown', // This flag value indicates that a user is logged out. See extractUserRoute
  error: null,
  loading: false, // This initial loading flag allows us to NOT show the UI until we know who is using the site
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_BEGIN:
    case SET_USER_ROUTE:
      return Object.assign({}, state, {
        userRoute: action.payload,
        loading: false,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, { user: action.payload, loading: false });
    case AUTHENTICATE:
      return Object.assign({}, state, { token: action.payload, loading: true });
    case AUTHENTICATE_SUCCESS:
      return Object.assign({}, state, { loading: false });
    case USER:
      return Object.assign({}, state, { user: action.payload, loading: false });
    case DELETE_ADDRESS:
      // if there is an on_behalf_of user, then delete the address to them
      if (!!state.user.on_behalf_of_user_id && !!state.user.on_behalf_of) {
        return Object.assign({}, state, {
          user: {
            ...state.user,
            on_behalf_of: {
              ...state.user.on_behalf_of,
              customer_account: {
                ...state.user.on_behalf_of.customer_account,
                addresses: [
                  ...state.user.on_behalf_of.customer_account.addresses.filter(
                    address => address.id !== action.payload
                  ),
                ],
              },
            },
          },
        });
      }
      // otherwise, delete it from the main user
      return Object.assign({}, state, {
        user: {
          ...state.user,
          customer_account: {
            ...state.user.customer_account,
            addresses: [
              ...state.user.customer_account.addresses.filter(
                address => address.id !== action.payload
              ),
            ],
          },
        },
      });
    case ADD_ADDRESS:
      // if there is an on_behalf_of user, then add the address to them
      if (!!state.user.on_behalf_of_user_id && !!state.user.on_behalf_of) {
        return Object.assign({}, state, {
          user: {
            ...state.user,
            on_behalf_of: {
              customer_account: {
                ...state.user.on_behalf_of.customer_account,
                addresses: [
                  ...state.user.on_behalf_of.customer_account.addresses,
                  action.payload,
                ],
              },
            },
          },
        });
      }
      // otherwise, add it to the main user
      return Object.assign({}, state, {
        user: {
          ...state.user,
          customer_account: {
            ...state.user.customer_account,
            addresses: [
              ...state.user.customer_account.addresses,
              action.payload,
            ],
          },
        },
      });
    case UPDATE_METADATA:
      let newMetadata = Object.assign({}, state.user.metadata, action.payload);
      return Object.assign({}, state, {
        user: {
          ...state.user,
          metadata: newMetadata,
        },
      });
    case GET_COMPANY_LIST:
      return Object.assign({}, state, {
        user: {
          ...state.user,
          companyList: action.payload.map(item => ({
            label: item.company_name,
            value: item.id,
          })),
        },
      });
    case DEAUTHENTICATE:
      return Object.assign({}, state, logoutState);
    case LOGIN_ERROR:
      let errorMessage =
        ((action.payload || {}).error || {}).message ||
        action.payload ||
        'Invalid username or password.';
      if (errorMessage.includes('401')) {
        errorMessage = 'Invalid username or password'; // convert to human-readable error message
      }
      return Object.assign({}, state, {
        error: errorMessage,
        userRoute: 'unknown',
        loading: false,
      });
    default:
      return state;
  }
};
