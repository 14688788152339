import React from 'react';
import { Location } from '@reach/router';
import ReactGA from 'react-ga';

const track = pathname => {
  if (process.env.NODE_ENV === 'production') {
    // Update the user's current page
    ReactGA.set({
      page: pathname,
    });
    // Record a pageview for the given page
    ReactGA.pageview(pathname);
  }
};

const TrackPageViews = () => (
  <Location>
    {({ location }) => (
      <LocationListener
        location={location}
        didMount={() => track(location.pathname)}
        didUpdate={({ prevProps }) => {
          if (prevProps.location !== location) {
            track(location.pathname);
          }
        }}
      />
    )}
  </Location>
);
class LocationListener extends React.Component {
  render() {
    return <>{this.props.children}</>;
  }
  componentDidMount() {
    this.props.didMount();
  }
  componentDidUpdate(prevProps) {
    this.props.didUpdate({ prevProps });
  }
}

export default TrackPageViews;
