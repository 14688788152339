import React, { Component } from 'react';

class SignupReferenceInput extends Component {
  render() {
    return (
      <input
        id={this.props.inputID}
        className="df-text-inputs adduser-input"
        type={this.props.inputType}
        name={this.props.inputName}
        onChange={this.props.onChangeHandler}
        defaultValue={''}
      />
    );
  }
}
export default SignupReferenceInput;
