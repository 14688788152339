// Diamond Filter Reducer

// Expected values in the reducer
const diamondFilterReducerDefaultState = {
  color: [], // strings
  shapeCarat: [], // Array of objects { shape: 'oval', carat: [1.0, 1.5] }
  diamonds: [], // objects
  shape: [], // strings
  carat: [], // 2 integers - DEPRECATED
  clarity: [], // strings - DEPRECATED
  cut: [], // strings - DEPRECATED
  price: [], // 2 integers - DEPRECATED
};

export default (state = diamondFilterReducerDefaultState, action) => {
  switch (action.type) {
    case 'ADD_SHAPECARAT_FILTER':
      return { ...state, shapeCarat: [...state.shapeCarat, action.value] };
    case 'REMOVE_SHAPECARAT_FILTER':
      // First, find the index of the element to be removed.
      let targetIndex = -1;
      for (let i = 0; i < state.shapeCarat.length; i++) {
        if (
          state.shapeCarat[i].shape === action.value.shape &&
          state.shapeCarat[i].caratRangeIndex === action.value.caratRangeIndex
        ) {
          targetIndex = i;
          break;
        }
      }
      if (targetIndex === -1) return { ...state };

      // Then slice around the element to be removed
      return {
        ...state,
        shapeCarat: [
          ...state.shapeCarat.slice(0, targetIndex),
          ...state.shapeCarat.slice(targetIndex + 1),
        ],
      };
    case 'ADD_SHAPE_FILTER':
      return { ...state, shape: [...state.shape, action.value] };
    case 'REMOVE_SHAPE_FILTER':
      const shapeIndex = state.shape.indexOf(action.value);
      return {
        ...state,
        shape: [
          ...state.shape.slice(0, shapeIndex),
          ...state.shape.slice(shapeIndex + 1),
        ],
      };
    case 'ADD_COLOR_FILTER':
      return { ...state, color: [...state.color, action.value] };
    case 'REMOVE_COLOR_FILTER':
      const colorIndex = state.color.indexOf(action.value);
      return {
        ...state,
        color: [
          ...state.color.slice(0, colorIndex),
          ...state.color.slice(colorIndex + 1),
        ],
      };
    case 'ADD_CLARITY_FILTER':
      return { ...state, clarity: [...state.clarity, action.value] };
    case 'REMOVE_CLARITY_FILTER':
      const clarityIndex = state.clarity.indexOf(action.value);
      return {
        ...state,
        clarity: [
          ...state.clarity.slice(0, clarityIndex),
          ...state.clarity.slice(clarityIndex + 1),
        ],
      };
    case 'ADD_CUT_FILTER':
      return { ...state, cut: [...state.cut, action.value] };
    case 'REMOVE_CUT_FILTER':
      const cutIndex = state.cut.indexOf(action.value);
      return {
        ...state,
        cut: [
          ...state.cut.slice(0, cutIndex),
          ...state.cut.slice(cutIndex + 1),
        ],
      };
    case 'SET_FILTERS':
      return { ...action.filters };
    case 'ADD_PRICE_FILTER':
      return { ...state, price: [...action.value] };
    case 'REMOVE_PRICE_FILTER':
      return { ...state, price: [...action.value] };
    case 'ADD_CARAT_FILTER':
      return { ...state, carat: [...action.value] };
    case 'REMOVE_CARAT_FILTER':
      return { ...state, carat: [...action.value] };
    case 'RESET_FILTER':
      return { ...state, ...diamondFilterReducerDefaultState };
    default:
      return state;
  }
};
