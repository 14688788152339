import React, { useEffect, useState } from 'react';
import InventoryHold from '../../types/models/inventoryHold';
import {
  fetchDiamond,
  fetchInventoryHolds,
  onDeleteHold,
} from '../../api/diamonds';
import Diamond from '../../types/models/diamond';
import styled from '@emotion/styled';
import DiamondHoldItem from './DiamondHoldItem';
import CartItem from '../../types/models/cartItem';
import DiamondCartItem from '../../types/models/diamondCartItem';
import { exists, getDiamondPrice } from '../../utils/util';
import { calculateTotalPrice } from '../../state/action/diamondCart';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../types/redux/rootState';
import { updateCart } from '../../api';

type Props = {
  cart: CartItem[];
  onNumberOfHolds?: (count: number) => void;
};

const Outer = styled.div`
  margin: 15px auto;
  max-width: 500px;
  padding-bottom: 2rem;
`;

const InventoryHoldList = ({ cart, onNumberOfHolds }: Props) => {
  const [diamonds, setDiamonds] = useState<Diamond[]>([]);
  const [inventoryHolds, setInventoryHolds] = useState<InventoryHold[]>([]);
  const selector = useSelector((state: RootState) => state.diamondCart);
  const dispatch = useDispatch();

  // Does a server side fetch.
  const fetchAndSetHolds = async () => {
    try {
      const res = await fetchInventoryHolds();
      setInventoryHolds(res.data);
    } catch (e) {}
  };
  useEffect(() => {
    fetchAndSetHolds();
  }, [cart]);
  const onAddToCart = (diamond: Diamond) => {
    const cartItem: DiamondCartItem = {
      id: diamond.id,
      name: diamond.shape,
      shape: diamond.shape.toLowerCase(),
      color: diamond.color,
      carat: '',
      clarity: diamond.clarity,
      price: +getDiamondPrice(diamond),
      quantity: diamond.quantity || 1,
      gcal_cert_id: diamond.gcal_cert_id,
      cert_url: diamond.cert_url,
      lot_id: diamond.lot_id,
      orderType: 'Diamond',
    };
    const uniqueCartItems: CartItem[] = [
      cartItem,
      ...selector.cart.filter(item => cartItem.id != item.id),
    ];
    dispatch({
      type: 'ADD_MULTIPLE_ITEMS_TO_CART',
      items: [cartItem],
      totalPrice: calculateTotalPrice(uniqueCartItems),
      updated: 'unique',
    });

    updateCart({
      items: uniqueCartItems,
    });

    setDiamonds(diamonds.filter(d => d.id !== diamond.id));
    setInventoryHolds(inventoryHolds.filter(i => i.holdable_id !== diamond.id));
  };
  const fetchDiamondsAndSet = async () => {
    let diamonds: Diamond[] = [];
    for (const hold of inventoryHolds) {
      const res = await fetchDiamond(hold.holdable_id);
      if (res.data) {
        diamonds = [...diamonds, res.data];
      }
    }

    setDiamonds(diamonds);
  };

  useEffect(() => {
    if (onNumberOfHolds) {
      onNumberOfHolds(inventoryHolds.length);
    }
    if (inventoryHolds.length === 0) {
      return;
    }
    // Clear
    setDiamonds([]);
    fetchDiamondsAndSet();
  }, [inventoryHolds]);
  if (inventoryHolds.length === 0) {
    return null;
  }
  const isInCart = (diamond: Diamond) => {
    return cart.filter((item: CartItem) => item.id === diamond.id).length > 0;
  };

  const onDelete = async (diamond: Diamond) => {
    const holds = inventoryHolds.filter(h => h.holdable_id === diamond.id);
    if (holds.length !== 1) {
      return;
    }
    const hold = holds[0];
    if (!exists(hold)) {
      return;
    }
    try {
      const _ = await onDeleteHold(hold.id);
      // Does another server side fetch.
      fetchAndSetHolds();
    } catch (e) {}
  };

  return (
    <Outer>
      <h1 className="df-title">MY HOLDS</h1>
      {diamonds.map((diamond, index) => (
        <DiamondHoldItem
          onAddToCart={
            isInCart(diamond)
              ? undefined
              : () => {
                  onAddToCart(diamond);
                  fetchAndSetHolds();
                }
          }
          onDelete={() => onDelete(diamond)}
          key={index}
          entry={{
            diamond,
            hold: inventoryHolds.filter(i => i.holdable_id === diamond.id)[0],
          }}
        />
      ))}
    </Outer>
  );
};

export default InventoryHoldList;
