import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';

import BackButton from '../../components/BackButton';
import TableComponent from '../../components/admin/TableComponent';
import WaitSpinner from '../../components/WaitSpinner';

import { fetchOrders } from '../../api/index';
import { formatUSD } from '../../utils/util';
import { dateFormatter } from '../../utils/dateUtilities';

import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';

import {
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';

const TimestampProvider = props => (
  <DataTypeProvider formatterComponent={dateFormatter} {...props} />
);

class UserOrdersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: null,
    };

    this.priceReducer = this.priceReducer.bind(this);
  }

  componentDidMount() {
    this.fetchOrders();
  }

  async fetchOrders() {
    const orders = await fetchOrders(this.props.userId);
    this.setState({ orders: orders });
  }

  priceReducer(runningTotal, order) {
    return runningTotal + parseFloat(order.sold_price);
  }

  // define the columns for the orders table
  columns() {
    return [
      {
        name: 'erp_id',
        title: 'Netsuite ID',
        getCellValue: row => (
          <Link className="df-link" to={`/orders/${row.id}`}>
            {row.id}
          </Link>
        ),
      },
      { name: 'source', title: 'Source' },
      {
        name: 'payment_method',
        title: 'Payment Method',
        getCellValue: row =>
          row.payment_method === 'B2B Stripe' ||
          row.payment_method === 'credit_card'
            ? 'Credit Card'
            : 'Terms',
      },
      {
        name: 'count',
        title: 'Items',
        getCellValue: row => row.order_items.length,
      },
      {
        name: 'total',
        title: 'Total',
        getCellValue: row =>
          formatUSD(row.order_items.reduce(this.priceReducer, 0)),
      },
      { name: 'created_at', title: 'Created At' },
    ];
  }

  render() {
    if (!this.state.orders) {
      return <WaitSpinner />;
    }

    return (
      <main>
        <div className="user-details-page main-wrapper">
          <BackButton
            link={`/admin/users/${this.props.userId}`}
            text="User Details"
          />

          <h1 className="title">Orders</h1>
          <Grid rows={this.state.orders} columns={this.columns()}>
            <SortingState
              defaultSorting={[{ columnName: 'created_at', direction: 'desc' }]}
            />

            <SearchState defaultValue="" />
            <IntegratedSorting />
            <IntegratedFiltering />
            <TimestampProvider for={['created_at']} />
            <Table tableComponent={TableComponent} />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <SearchPanel />
          </Grid>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    orders: state.orders,
  };
};

export default connect(mapStateToProps)(UserOrdersPage);
