import React from 'react';
import PolishedCartItem from '../../types/models/polishedCartItem';
import { formatPricing } from '../../utils/util';
import SVG from '../SVG';
import { useDispatch } from 'react-redux';
import { removeItemFromCart } from '../../state/action/diamondCart';

type Props = {
  item: PolishedCartItem;
  checkedOut: boolean;
};

const CartListItemPolishedGroup = ({ checkedOut, item }: Props) => {
  const dispatch = useDispatch();

  return (
    <div className="CartListItem">
      <div className="diamond-info-left">
        <ul className="unstyled">
          <li>
            <span className="shape">Polished Parcel</span>
          </li>

          <li>
            <span className="fieldName">ID:</span> {item.name}
          </li>

          <li>
            <span className="fieldName">Items:</span> {item.quantity}
          </li>
          <li>
            <span className="fieldName">Kind:</span>{' '}
            {item.netsuite_program_type}
          </li>
        </ul>
      </div>
      <ul className="unstyled diamond-info-right">
        {!checkedOut && (
          <li>
            <SVG
              className="delete"
              name="trash"
              viewBox="0 0 600 600"
              onClick={(e: React.FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                dispatch(removeItemFromCart(item.id));
              }}
            />
          </li>
        )}

        <li className="diamond-price">{formatPricing(item.price)}</li>
      </ul>
    </div>
  );
};

export default CartListItemPolishedGroup;
