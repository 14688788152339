const savedFiltersIntialState = {
  filters: [],
};

export default (state = savedFiltersIntialState, action) => {
  switch (action.type) {
    case 'STORE_SAVED_FILTERS':
      return { ...state, filters: action.filters };
    case 'DELETE_FILTER':
      const deletedFilter = state.filters.filter(
        item => item !== action.filter
      );
      return { ...state, filters: deletedFilter };
    case 'RESET_USER':
      return { ...state, ...savedFiltersIntialState };
    default:
      return state;
  }
};
