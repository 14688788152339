import { STORE_USERS, STORE_SELECTED_USERS } from './constants';

const initialState = {
  original: [],
  selected: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_USERS:
      return { ...state, original: action.payload };
    case STORE_SELECTED_USERS:
      return { ...state, selected: action.payload };
    default:
      return state;
  }
};
