import React, { useEffect, useState } from 'react';
import CartItem from '../types/models/cartItem';
import CartListItem from './CartListItem';
import { Link } from '@reach/router';
import { formatPricingFromPennies } from '../utils/util';
import Dollar from '../types/more/dollar';
import Address from '../types/models/address';
import { fetchOrderRates, OrderRatesRequest, Weight } from '../api/diamonds';
import Fetch from './ui/Fetch';
import OrderRate from '../types/more/orderRate';
import WaitSpinner from './WaitSpinner';
import styled from '@emotion/styled';
import FlexCenter from './ui/FlexCenter';

export type ShippingInfo = {
  ship_carrier: string;
  ship_method: string;
  ship_cost: number;
};

type Props = {
  shippingCost: Dollar;
  totalPriceInPennies: number;
  cart: CartItem[];
  nsCategory: String;
  nsUser: any;
  selectedAddress: Address;
  onFetch?: (predicate: boolean) => void;
  onShippingDidChange: (info: ShippingInfo) => void;
};
/*
Default values relating to business logic.
 */
const DF_LA_ZIP_CODE = '90014';
const DEFAULT_LIMIT = 1;
const DEFAULT_SORT_BY_PRICE = true;
const DEFAULT_SERVICE_CODE = 'fedex_standard_overnight';
const DEFAULT_WEIGHT: Weight = {
  value: 1,
  units: 'lbs',
};

const DEFAULT_CARRIER_CODE = 'fedex';

const FIFTY_DOLLARS: Dollar = 50;
const HUNDRED_DOLLARS: Dollar = 100;
const FREE: Dollar = 0;
const US_CHANNELS = [115, 139, 120]; // Ecom net, VRAI Aps, Designers
const INTERNATIONAL_CHANNELS = [108, 117, 119, 116]; //Chains, Distributor, Independents, Key Accounts

export const FEDEX_INTERNATIONAL_PRIORITY = 'fedex_international_priority';
export const FEDEX_STANDARD_OVERNIGHT = 'fedex_standard_overnight';
export const FEDEX_2DAY = 'fedex_2day';

export const getShipCode = (international: boolean) => {
  return international ? FEDEX_INTERNATIONAL_PRIORITY : FEDEX_2DAY;
};

export const getOrderRate = (data: OrderRate[], shipMethod: string) => {
  return data.filter(o => o.serviceCode === shipMethod)[0];
};

export const getCost = (S: OrderRate) => {
  return S.shipmentCost + S.otherCost;
};

const dummy = (s: boolean) => {};

const WaitSpinnerOuter = styled.div`
  height: 100px;
`;

const CostOrdersList = ({
  cart,
  totalPriceInPennies,
  selectedAddress,
  onFetch = dummy,
  onShippingDidChange,
  shippingCost,
  nsCategory,
  nsUser,
}: Props) => {
  const [fetching, setFetching] = useState(false);
  const fetchAndTell = (predicate: boolean) => {
    setFetching(predicate);
    onFetch(predicate);
  };

  // returns the customer netsuitId
  // if the order is placed on behalf of a person, use that netsuite Id
  const getNsCustomerId = () => {
    const nsCustomerId = nsUser.customer_account.ns_channel_id;
    const onBehalfOfNsId = nsUser.on_behalf_of?.customer_account?.ns_channel_id;

    if (onBehalfOfNsId) return onBehalfOfNsId;
    return nsCustomerId;
  };

  const innerOnShippingDidChange = (info: ShippingInfo) => {
    const flag = false;
    const { country } = selectedAddress;
    const international =
      country !== 'US' && country !== 'United States' && country !== 'USA';

    if (getShipCode(international) !== info.ship_method) {
      return onShippingDidChange({
        ...info,
        ship_method: getShipCode(international),
      });
    } else {
      onShippingDidChange(info);
    }
  };

  const calculateShippingCost = async () => {
    if (!selectedAddress) {
      return;
    }
    const { country, city, state, zip } = selectedAddress;
    const shipCode = getShipCode(
      country !== 'US' && country !== 'United States' && country !== 'USA'
    );
    // USD.
    const totalPrice = totalPriceInPennies / 100;

    // Domestic order ship with fedex_2day  < 1000 shipcost $50
    if (US_CHANNELS.includes(getNsCustomerId())) {
      if (totalPrice < 1000 && totalPrice > 0) {
        innerOnShippingDidChange({
          ship_cost: FIFTY_DOLLARS,
          ship_carrier: 'FedEx',
          ship_method: shipCode,
        });
        return;
      }
    }

    // Domestic order ship with fedex_2day > 1000, but < 10,000 - shipcost Free
    if (US_CHANNELS.includes(getNsCustomerId())) {
      if (totalPrice > 1000 && totalPrice < 10000) {
        innerOnShippingDidChange({
          ship_cost: FREE,
          ship_carrier: 'FedEx',
          ship_method: shipCode,
        });
        return;
      }
    }

    // Domestic order ship fedex standard overnight > 10,000 - shipcost Free
    if (US_CHANNELS.includes(getNsCustomerId())) {
      if (totalPrice > 10000) {
        innerOnShippingDidChange({
          ship_cost: FREE,
          ship_carrier: 'FedEx',
          ship_method: shipCode,
        });
        return;
      }
    }

    // International order fedex international priority > 10,000 shipcost Free
    if (INTERNATIONAL_CHANNELS.includes(getNsCustomerId())) {
      if (totalPrice > 10000) {
        innerOnShippingDidChange({
          ship_cost: FREE,
          ship_carrier: 'FedEx',
          ship_method: shipCode,
        });
        return;
      }
    }

    // International order fedex international priority <10,000 - shipcost $100
    if (INTERNATIONAL_CHANNELS.includes(getNsCustomerId())) {
      if (totalPrice < 10000) {
        innerOnShippingDidChange({
          ship_cost: HUNDRED_DOLLARS,
          ship_carrier: 'FedEx',
          ship_method: shipCode,
        });
        return;
      }
    }

    if (!country) {
      return;
    }

    const REQUEST1: OrderRatesRequest = {
      carrierCode: DEFAULT_CARRIER_CODE,
      fromPostalCode: DF_LA_ZIP_CODE,
      toState: state,
      toCountry: country,
      toPostalCode: zip,
      toCity: city,
      serviceCodes: [DEFAULT_SERVICE_CODE],
      weight: DEFAULT_WEIGHT,
      sortByPrice: DEFAULT_SORT_BY_PRICE,
    };

    const REQUEST2: OrderRatesRequest = {
      carrierCode: DEFAULT_CARRIER_CODE,
      fromPostalCode: DF_LA_ZIP_CODE,
      toState: state,
      toCountry: country,
      toPostalCode: zip,
      toCity: city,
      weight: DEFAULT_WEIGHT,
      sortByPrice: DEFAULT_SORT_BY_PRICE,
    };

    try {
      fetchAndTell(true);
      const response = await fetchOrderRates(REQUEST1);
      const order = getOrderRate(response.data, shipCode);
      const cost = getCost(order);
      const info: ShippingInfo = {
        ship_carrier: 'FedEx',
        ship_method: order.serviceCode,
        ship_cost: cost,
      };
      innerOnShippingDidChange(info);
    } catch (e) {
      try {
        fetchAndTell(true);
        const response = await fetchOrderRates(REQUEST2);
        const order = getOrderRate(response.data, shipCode);
        const cost = getCost(order);
        const info: ShippingInfo = {
          ship_carrier: 'FedEx',
          ship_method: order.serviceCode,
          ship_cost: cost,
        };
        innerOnShippingDidChange(info);
      } catch (e) {}
    } finally {
      fetchAndTell(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = calculateShippingCost();
  }, [selectedAddress, totalPriceInPennies]);

  return (
    <div className="cart-section">
      <div className="cart-heading">
        <span>Cart: </span>
        <span>{cart.length} Items</span>
      </div>
      <Fetch
        render={
          <div className="cart-list">
            {cart.map(item => (
              <CartListItem key={`cart-item-${item.id}`} checkedOut {...item} />
            ))}
          </div>
        }
        empty={
          <div className="cart-list">
            <div className="empty-cart">
              <div className="title">No item in Cart</div>
              <div className="blurb">
                Add Item to Cart
                <Link to="/diamonds" className="df-link">
                  Shop Now
                </Link>
              </div>
            </div>
          </div>
        }
        isEmpty={cart.length === 0}
      />
      <Fetch
        isEmpty={fetching}
        render={
          <div>
            <div className="total-price">
              <span>SHIPPING:</span>
              <span id={`shipping-cost`} data-testid={`shipping-cost`}>
                {formatPricingFromPennies(shippingCost * 100)}
              </span>
            </div>
            <div className="total-price">
              <span>TOTAL: </span>
              <span>
                {formatPricingFromPennies(
                  totalPriceInPennies + shippingCost * 100
                )}
              </span>
            </div>
          </div>
        }
        empty={
          <WaitSpinnerOuter>
            <FlexCenter column={false}>
              <WaitSpinner />
            </FlexCenter>
          </WaitSpinnerOuter>
        }
      />
    </div>
  );
};

export default CostOrdersList;
