import React, { Component } from 'react';
import SignupReferenceInput from '../components/SignupReferenceInput';

import SimpleReactValidator from 'simple-react-validator';

class TradeReferenceInputs extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ locale: 'en' });
  }

  buildReferenceInput(attributeName, index) {
    const placeHolders = {
      company_name: 'Company Name*',
      email: 'Email*',
      phone: 'Phone*',
    };

    const attributeRequired = placeHolders[attributeName].includes('*');
    const rule =
      attributeName === ('email' || 'phone')
        ? `required|${attributeName}`
        : 'required';

    return (
      <div
        className={`registration-form-elements ${
          index % 2 === 0 ? 'left' : 'right'
        } ${index === 0 ? 'with-circle' : ''}`}
        key={`trade-ref-${this.props.tradeReferenceKey}-${attributeName}`}
      >
        <label
          htmlFor={`trade_references_attributes[${this.props.tradeReferenceKey -
            1}]-${attributeName}`}
        >
          {placeHolders[attributeName]}
        </label>
        <SignupReferenceInput
          key={`trade_references_attributes[${this.props.tradeReferenceKey -
            1}]-${attributeName}`}
          inputID={`trade_references_attributes[${this.props.tradeReferenceKey -
            1}]-${attributeName}`}
          inputType="text"
          inputName={`trade_references_attributes[${this.props.tradeReferenceKey}][${attributeName}]`}
          onChangeHandler={e => {
            this.props.setParentState(
              {
                customer_account_attributes: {
                  trade_references_attributes: [
                    { [attributeName]: e.target.value },
                  ],
                },
              },
              this.props.tradeReferenceKey - 1
            );
            if (attributeRequired) {
              this.validator.showMessages(attributeName);
            }
          }}
        />

        {attributeRequired &&
          this.validator.message(
            attributeName,
            this.props.trade_references_attributes[attributeName],
            rule
          )}
      </div>
    );
  }

  render() {
    const inputs = ['company_name', 'email', 'phone'];
    return (
      <React.Fragment>
        <div className="registration-form">
          <div className="circled-number">{this.props.tradeReferenceKey}</div>
          {inputs.map((attr, index) => this.buildReferenceInput(attr, index))}
        </div>
      </React.Fragment>
    );
  }
}

export default TradeReferenceInputs;
