import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  removeShapeCaratFilter,
  addShapeCaratFilter,
} from '../state/action/diamondFilters';

class DiamondGridButton extends Component {
  constructor(props) {
    super(props);

    this.toggleFilter = this.toggleFilter.bind(this);
    this.className = this.className.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  // determine if this button is selected or not
  isSelected() {
    let shapeCaratFilters = this.props.filters.shapeCarat;
    if (shapeCaratFilters.length > 0) {
      for (let i = 0; i < shapeCaratFilters.length; i++) {
        if (
          shapeCaratFilters[i].shape === this.props.shape &&
          shapeCaratFilters[i].caratRangeIndex === this.props.caratRangeIndex
        ) {
          return true;
        }
      }
    }

    return false;
  }

  toggleFilter(e) {
    // dispatch action that filters diamond depending on the state
    if (this.isSelected()) {
      this.props.dispatch(
        removeShapeCaratFilter({
          shape: this.props.shape,
          carat: this.props.caratRange,
          caratRangeIndex: this.props.caratRangeIndex,
        })
      );
    } else {
      this.props.dispatch(
        addShapeCaratFilter({
          shape: this.props.shape,
          carat: this.props.caratRange,
          caratRangeIndex: this.props.caratRangeIndex,
        })
      );
    }
  }

  className() {
    return this.isSelected() ? 'selected' : '';
  }

  render() {
    return (
      <a
        className={`DiamondGridButton DiamondGridButton-${this.props.shape}-${
          this.props.caratRangeIndex
        } ${this.className()}`}
        onClick={this.toggleFilter}
      >
        {this.props.caption}
      </a>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.filters,
  };
};

export default connect(mapStateToProps)(DiamondGridButton);
