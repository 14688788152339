import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { postOrder, postSpecialOrder, fetchCreditCard } from '../api/index';
import { Link } from '@reach/router';
import Confirmation from '../components/Confirmation';
import {
  importItemsToCart,
  resetCart,
  storeUpdatedCart,
} from '../state/action/diamondCart';
import { formatPricingFromPennies, permittedToMemo } from '../utils/util';

import ShippingAddress from '../components/ShippingAddress';
import WaitSpinner from '../components/WaitSpinner';
import ConsignmentTerms from '../components/ConsignmentTerms';
import { filter, difference } from 'lodash';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { navigate } from '../utils/routerHistory';
import CanHide from '../components/ui/CanHide';
import CostOrdersList from '../components/CostOrdersList';
import CheckoutButton from '../components/CheckoutButton';

/*
This represent users did not select payment method.
 */
const PAYMENT_METHOD_NONE = { text: '', value: '-1' };

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.unmounted = false;

    this.state = {
      isOrdered: false,
      errorMessage: '',
      commentValue: '',
      selectedAddress: {},
      credit_card: null,
      loading: true,
      payment_method: PAYMENT_METHOD_NONE.value, // default to none
      orderOptionsModal: false,
      creditLimitModal: false,
      isConsignment: false,
      shippingCost: 0,
      shippingMethod: '',
      shippingCarrier: '',
    };

    this.handleAddress = this.handleAddress.bind(this);
    this.clientUser = this.clientUser.bind(this);
    this.getOrderDetail = this.getOrderDetail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleMoreOptionsClick = this.handleMoreOptionsClick.bind(this);
    this.handleConsignmentClick = this.handleConsignmentClick.bind(this);
    this.toggleCreditLimitModal = this.toggleCreditLimitModal.bind(this);
    this.handleCreditCardOption = this.handleCreditCardOption.bind(this);
    this.sendEmailToSaleRep = this.sendEmailToSaleRep.bind(this);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  componentDidMount() {
    this.getCreditCard();
  }

  async getCreditCard() {
    const cc = await fetchCreditCard();

    if (!!cc)
      this.setState({
        credit_card: cc,
        loading: false,
      });
    else this.setState({ loading: false });
  }

  getOrderDetail() {
    const {
      selectedAddress,
      commentValue,
      isOrdered,
      shippingCost,
      shippingCarrier,
      shippingMethod,
      payment_method,
    } = this.state;
    const { showToaster, cart } = this.props;

    if (payment_method === PAYMENT_METHOD_NONE.value) {
      showToaster('Please select payment method');
      return;
    }

    const cartItems = this.props.cart;

    // special orders (eg those with bulk quantities of 'program diamonds' are not valid orders to post as an Order to DF API/Netsuite
    // so we filter for them here
    const bulkCartItems = filter(cartItems, { orderType: 'bulk' });

    const user = this.clientUser();

    // Special Order's get posted to the /api/v2/special_order endpoint instead of /api/v1/order
    if (bulkCartItems.length > 0) {
      postSpecialOrder({
        specialOrder: {
          items: bulkCartItems,
          comment: commentValue,
          source: 'b2b_portal',
        },
      })
        .then(response => {
          if (response.status === 200) {
            showToaster('Special order request successful!');

            // remove the bulkCartItems and leave the non special order items in cart
            const bulkItemsRemovedCart = difference(cartItems, bulkCartItems);

            if (bulkItemsRemovedCart) {
              // when there are still items remaining show an additional toaster
              showToaster(
                'Successfully requested special order; to buy individual stones please checkout again.'
              );
            }
            this.props.storeUpdatedCart(bulkItemsRemovedCart);
            this.setState({ isOrdered: true });
          }
        })
        .catch(error => {
          this.setState({
            errorMessage:
              'Something went wrong with request. Please try again later.',
          });
        });
      return;
    }

    if (!this.unmounted) {
      const shippingAddress = selectedAddress;

      if (Object.keys(shippingAddress).length !== 0) {
        let paymentMethod = '';

        // If the user is approved, and they have a credit limit greater than 10k, and they have a credit card, then allow them to choose.
        if (
          user.status === 'approved' &&
          user.customer_account.credit_limit &&
          user.customer_account.credit_limit / 100.0 > 10000.0
        ) {
          if (!!this.state.credit_card) {
            paymentMethod = this.state.payment_method;
          } else {
            // If the user is approved and they DON'T have a credit card, then use lay-away / blank.
            paymentMethod = '';
          }
        } else {
          paymentMethod = 'B2B Stripe';
        }

        // regardless of user status 'approved' or 'basic' consignment must always have paymentMethod set to empty string
        if (this.state.isConsignment) {
          paymentMethod = '';
        }

        let newOrder = {
          comment: commentValue,
          source: 'b2b_portal',
          payment_method: paymentMethod,
          is_consignment: this.state.isConsignment,
          customer_shipping_cost: shippingCost,
          ship_method: shippingMethod,
          ship_carrier: shippingCarrier,
        };

        // set up the shipping address
        if (!!shippingAddress.id) {
          newOrder.shipping_address_id = shippingAddress.id;
        } else {
          newOrder.shipping_address_attributes = shippingAddress;
        }

        // set up the order items
        let orderItems = [];

        cart.forEach(cartItem => {
          if (
            cartItem.orderType === 'RoughDiamond' ||
            cartItem.orderType === 'Diamond' ||
            cartItem.orderType === 'RoughDiamondGroup' ||
            cartItem.orderType === 'PolishedParcel'
          ) {
            orderItems.push({
              item_id: cartItem.id,
              item_type: cartItem.orderType,
              sold_price: cartItem.price,
              quantity: cartItem.quantity,
            });
          } else {
            // for backwards compatability with items stored with diamond_id in cart
            orderItems.push({
              diamond_id: cartItem.id,
              sold_price: cartItem.price,
              quantity: cartItem.quantity,
            });
          }
        });
        newOrder.order_items_attributes = orderItems;

        postOrder({ order: newOrder })
          .then(() => {
            this.setState({ isOrdered: !isOrdered });
            this.props.resetCart();
          })
          .catch(error => {
            let detailedMessage =
              error.response.data.message ||
              error.message ||
              'Please try again later.';
            if (detailedMessage !== 'Amount exceeds credit limit.') {
              this.setState({
                errorMessage: `Unable to process order. ${detailedMessage}`,
              });
            } else {
              this.toggleCreditLimitModal();
            }
          });
      } else {
        showToaster(
          'Address missing. Please select or add a shipping address.'
        );
      }
    }
  }

  handleComment(event) {
    this.setState({ commentValue: event.target.value });
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleAddress(address) {
    let user = this.clientUser(); // either the current user or the on_behalf_of user
    let customer_account = user.customer_account;

    this.setState({
      selectedAddress: {
        ...address,
        company_name: customer_account.company_name,
        line_2: '',
        line_3: '',
        attention: '',
      },
    });
  }

  // When the user click "More Options" under the "Place Order" CTA.
  handleMoreOptionsClick() {
    this.setState(prevState => ({
      orderOptionsModal: !prevState.orderOptionsModal,
    }));
  }

  handleConsignmentClick() {
    const { isConsignment } = this.state;

    if (!isConsignment) {
      /* Set to terms on file when future `isConsignment` is true. */
      this.setState({
        isConsignment: true,
        orderOptionsModal: false,
        creditLimitModal: false,
        payment_method: '',
      });
    } else {
      this.setState({
        isConsignment: false,
        orderOptionsModal: false,
      });
    }
  }

  async handleCreditCardOption() {
    if (!!this.state.credit_card) {
      await this.setState({
        creditLimitModal: false,
        payment_method: 'B2B Stripe',
      });
      this.getOrderDetail();
    } else {
      navigate('/credit-card');
    }
  }

  toggleCreditLimitModal() {
    this.setState(prevState => ({
      creditLimitModal: !prevState.creditLimitModal,
    }));
  }

  sendEmailToSaleRep(e, salesRep) {
    window.open(`mailto:${salesRep.email}`);
    this.toggleCreditLimitModal();
  }

  clientUser() {
    // Get the current user, but if there is an on_behalf_of user present then switch to that.
    const { user } = this.props.auth;
    if (!!user.on_behalf_of_user_id && !!user.on_behalf_of) {
      return user.on_behalf_of;
    }
    return user;
  }

  userMustAddCreditCard(paymentMethods) {
    return paymentMethods.length === 0;
  }

  checkoutButtonEnabled(paymentMethods) {
    return paymentMethods.length > 0;
  }

  showPaymentMethods(paymentMethods) {
    return paymentMethods.length > 1;
  }

  render() {
    let { openToggle, cart, totalPrice } = this.props;
    let {
      shippingCost,
      commentValue,
      errorMessage,
      payment_method,
      selectedAddress,
      isConsignment,
      isOrdered,
    } = this.state;

    const user = this.clientUser();
    let customer_account = user.customer_account;

    let checkoutButtonEnabled = false;
    let userMustAddCreditCard = false;
    let showCreditCardInfo = false; // should we show the card being charged?
    let showPaymentMethods = false; // should we show the option to pay via credit card or lay-away?
    let buttonCaption = 'SUBMIT REQUEST'; // this is dynamic based on the payment method
    let paymentMethods = [PAYMENT_METHOD_NONE]; // the list of valid payment methods

    let company_name = '',
      addresses = [];

    const salesRep = (user.customer_account || {}).sales_representative || {
      email: 'b2b@diamondfoundry.com',
      first_name: 'B2B Support',
      last_name: '',
    };

    // set showMoreOptions bool to display consignment option or not
    const showMoreOptions = permittedToMemo(
      this.props.auth.user,
      user.customer_account
    );
    // if we are done loading...
    if (!this.state.loading) {
      if (!!customer_account) {
        company_name = customer_account.company_name;
        addresses = customer_account.addresses;

        if (!this.state.isConsignment) {
          /**
           * Only allow the user to proceed if they have entered a credit card.
           */
          if (!!this.state.credit_card) {
            paymentMethods.push({ text: 'Credit Card', value: 'B2B Stripe' });

            if (this.state.payment_method === 'B2B Stripe') {
              showCreditCardInfo = true;
            }

            /**
             * If the user is approved to pay on credit, AND they have a credit limit, AND the credit limit
             * is greater than $10,000, then allow them t pay on terms.
             */
            if (
              user.status === 'approved' &&
              user.customer_account.credit_limit &&
              user.customer_account.credit_limit / 100.0 > 10000.0
            ) {
              paymentMethods.push({ text: 'Terms on File', value: '' });

              if (this.state.payment_method === '') {
                showCreditCardInfo = false;
              }
            }

            checkoutButtonEnabled = this.checkoutButtonEnabled(paymentMethods);
          } else {
            // if the user has NOT entered a credit card, then force them to do so
            userMustAddCreditCard = true;
          }
        } else {
          checkoutButtonEnabled = true;
          paymentMethods.push({ text: 'Terms on File', value: '' });
        }
      } else {
        // If the user has no customer account, then disable the button and show a message.
        // This may occur with users who claim an affiliation, but are not yet verified.
        errorMessage =
          'You must contact customer support before you can check out.';
        checkoutButtonEnabled = false;
      }
    }
    showPaymentMethods = this.showPaymentMethods(paymentMethods);

    return (
      <main>
        <div className="checkout-page">
          {!isOrdered && (
            <React.Fragment>
              <h1 className="df-title">CHECKOUT</h1>
              <div className="main-wrapper">
                <div className="column-left">
                  <ShippingAddress
                    addresses={addresses}
                    openToggle={openToggle}
                    company_name={company_name}
                    customerAccount={customer_account}
                    onAddressSelect={this.handleAddress}
                  />
                </div>

                <div className="column-right">
                  <CostOrdersList
                    nsUser={this.props.auth.user}
                    nsCategory={
                      this.props.auth.user.customer_account.ns_category
                    }
                    cart={cart}
                    shippingCost={shippingCost}
                    totalPriceInPennies={totalPrice}
                    selectedAddress={selectedAddress}
                    onFetch={loading => {
                      this.setState({ loading });
                    }}
                    onShippingDidChange={info => {
                      this.setState({
                        shippingCost: info.ship_cost,
                        shippingMethod: info.ship_method,
                        shippingCarrier: info.ship_carrier,
                      });
                    }}
                  />
                  <div
                    className="form-group comment-box"
                    onChange={e => this.handleComment(e)}
                  >
                    <textarea
                      className="md-textarea form-control user-comment"
                      id="comnttextarea"
                      maxLength={100}
                      value={commentValue}
                      onChange={this.handleComment.bind(this)}
                      placeholder="Comment"
                      rows="3"
                    />
                    <label htmlFor="comment" className="label-comment">
                      <input type="text" id="comment" className="label-input" />
                      {commentValue.length}/100 max characters
                    </label>
                  </div>

                  {this.state.isConsignment && <ConsignmentTerms />}

                  {this.state.loading && <WaitSpinner />}

                  {!this.state.loading && (
                    <div className="df-cta-container">
                      {userMustAddCreditCard && (
                        <span className="checkout-error credit-card">
                          <Link to="/credit-card">
                            You must add a credit card before you can check out.
                          </Link>
                        </span>
                      )}
                      {showPaymentMethods && (
                        <div className="payment-method-container">
                          <label
                            htmlFor="payment_method"
                            className="payment_method"
                          >
                            Payment Method:
                          </label>
                          <select
                            type="select"
                            name="payment_method"
                            id="payment_method"
                            onChange={this.handleChange}
                            value={payment_method}
                          >
                            {paymentMethods.map(method => (
                              <option key={method.value} value={method.value}>
                                {method.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {showCreditCardInfo && (
                        <span className="payment-cc-info">
                          {formatPricingFromPennies(
                            totalPrice + shippingCost * 100
                          )}{' '}
                          will be charged to&nbsp;
                          <Link to="/credit-card">
                            the card ending in {this.state.credit_card.last4}
                          </Link>
                          .
                        </span>
                      )}

                      <span className="checkout-error">{errorMessage}</span>
                      <CanHide hidden={!(cart.length > 0)}>
                        <CheckoutButton
                          disabled={!checkoutButtonEnabled}
                          onClick={this.getOrderDetail}
                          isConsignment={isConsignment}
                          payment_method={payment_method}
                        />
                      </CanHide>
                      {showMoreOptions && (
                        <div>
                          <span className="more-options-wrapper">
                            <Button
                              className="df-cta checkout-button"
                              onClick={this.handleMoreOptionsClick}
                            >
                              More Options
                            </Button>
                          </span>
                          <Modal
                            isOpen={this.state.orderOptionsModal}
                            toggle={this.handleMoreOptionsClick}
                            className="footer-pages"
                          >
                            <ModalHeader
                              className="df-title"
                              toggle={this.handleMoreOptionsClick}
                            >
                              Order Options
                            </ModalHeader>
                            {!this.state.isConsignment && (
                              <ModalBody className="df-page-body text-align-center">
                                <p>
                                  You may request these diamonds on 30-day memo
                                  (consignment), after which you will be
                                  charged. Memo is subject to additional{' '}
                                  <a
                                    href="/terms-and-conditions#memo-diamonds"
                                    target="_blank"
                                  >
                                    terms of service
                                  </a>
                                  .
                                </p>
                                <p>
                                  <Button
                                    className="df-cta enable-consignment"
                                    onClick={this.handleConsignmentClick}
                                  >
                                    Request these diamonds on 30-day memo.
                                  </Button>
                                </p>
                              </ModalBody>
                            )}
                            {this.state.isConsignment && (
                              <ModalBody className="df-page-body text-align-center">
                                <p>
                                  You may change back to a standard order rather
                                  than a memo.
                                </p>
                                <p>
                                  <Button
                                    className="df-cta disable-consignment"
                                    onClick={this.handleConsignmentClick}
                                  >
                                    Order these diamonds now.
                                  </Button>
                                </p>
                              </ModalBody>
                            )}
                          </Modal>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
          {isOrdered && (
            <Confirmation
              parent="checkout"
              title={
                this.state.isConsignment
                  ? 'YOUR MEMO REQUEST HAS BEEN SUBMITTED'
                  : 'YOUR ORDER HAS BEEN PLACED!'
              }
              subtitle="Your Account Manager will be reaching shortly with your order confirmation and shipping details."
            />
          )}
        </div>

        <Modal
          isOpen={this.state.creditLimitModal}
          toggle={this.toggleCreditLimitModal}
          className="modal-credit-limit"
        >
          <ModalHeader>There was an issue with your order.</ModalHeader>
          <ModalBody>
            <div className="blurb">
              This order has been rejected due to your credit limit. Select one
              of the following options.
            </div>
            <div className="cta">
              <Button className="df-cta" onClick={this.handleCreditCardOption}>
                <span>Pay with Credit Card</span>
              </Button>
              <Button
                className="df-cta"
                onClick={e => this.sendEmailToSaleRep(e, salesRep)}
              >
                <span>Contact sales representative</span>
              </Button>
            </div>
          </ModalBody>

          <div className="modal-close" onClick={this.toggleCreditLimitModal} />
        </Modal>
      </main>
    );
  }
}

Checkout.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  cart: PropTypes.instanceOf(Array).isRequired,
  showToaster: PropTypes.func.isRequired,
};

const mapDispatch = { importItemsToCart, resetCart, storeUpdatedCart };
const mapStateToProps = state => {
  return {
    cart: state.diamondCart.cart,
    totalPrice: state.diamondCart.totalPrice,
    updated: state.diamondCart.updated,
    auth: state.auth,
    customer_account: state.auth.user.customer_account,
  };
};

export default connect(mapStateToProps, mapDispatch)(Checkout);
