function getEnvConfig() {
  switch (process.env.REACT_APP_DEPLOYMENT_ENV) {
    case 'production':
      return require('./env.config.production');
    case 'staging':
      return require('./env.config.staging');
    case 'test':
      return require('./env.config.test');
    default:
      return require('./env.config.development');
  }
}
module.exports = getEnvConfig;
