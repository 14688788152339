const diamondReducerDefaultState = {
  original: [],
  rendered: [],
  selected: [],
  page: 1,
  limit: 20,
  searchKey: '',
  isgcalchecked: false,
  diamondToFocus: '',
};

export default (state = diamondReducerDefaultState, action) => {
  switch (action.type) {
    case 'STORE_DIAMONDS_FROM_API':
      return { ...state, original: action.diamonds };
    case 'SET_DIAMONDS_TO_RENDER':
      return { ...state, rendered: action.diamonds };
    case 'SELECT_ALL_DIAMONDS':
      const rendered = state.rendered.map(item => {
        item.isSelected = true;
        return item;
      });
      return { ...state, rendered: rendered, selected: rendered };
    case 'DESELECT_ALL_DIAMONDS':
      const renderedItems = state.rendered.map(item => {
        item.isSelected = false;
        return item;
      });
      return { ...state, rendered: renderedItems, selected: [] };
    case 'ADD_SEARCH_KEY':
      return { ...state, searchKey: action.searchKey };
    case 'ADD_GCAL_CHECK':
      return { ...state, isgcalchecked: !state.isgcalchecked };
    case 'SELECT_A_DIAMOND':
      return Object.assign({}, state, {
        ...state,
        rendered: state.rendered.map(item => {
          if (item.id === action.id) {
            item.isSelected = !item.isSelected;
          }
          return item;
        }),
        selected: state.rendered.filter(item => item.isSelected),
      });
    case 'UPDATE_DIAMOND_TO_FOCUS':
      return { ...state, diamondToFocus: action.id };
    case 'RESET_SEARCH':
      return { ...state, ...diamondReducerDefaultState };
    default:
      return state;
  }
};
