import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCompanyList } from '../../state/auth/actions';
import { updateUser } from '../../api';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import { reject } from 'lodash';
import { bindActionCreators } from 'redux';
import { storeUsersFromApi } from '../../state/users/actions';

class SelectCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyName: '',
      selectedCompanyID: null,
    };

    this.validator = new SimpleReactValidator();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getCompanyList();
  }

  onCompanySelect(e) {
    this.setState({
      companyName: e.label,
      selectedCompanyID: e.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const user = this.props.selectedUser;

    const requestBody = {
      user: {
        customer_account_id: this.state.selectedCompanyID,
      },
    };

    updateUser(requestBody, user)
      .then(response => {
        if (response.statusText === 'OK') {
          this.props.closeModal();

          // remove selected user in users state
          const updatedUsersList = reject(this.props.users.original, user);

          const users = updatedUsersList.concat(
            Object.assign(user, {
              customer_account: {
                company_name: this.state.companyName,
                id: this.state.selectedCompanyID,
              },
            })
          );

          this.props.dispatch(storeUsersFromApi(users));
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.props.showToaster(
            'You must assign a customer account to approve user!'
          );
        }
      });
    return;
  }

  render() {
    const { selectedCompany, companyName } = this.state;

    const { companyList } = this.props;

    return (
      <div className="select-company-modal">
        <form onSubmit={this.handleSubmit}>
          <div className="drop-down">
            <Select
              onChange={e => {
                this.onCompanySelect(e);
                this.validator.showMessages('companyName');
              }}
              instanceId="shape-selector"
              className="df-select-container"
              classNamePrefix="df-select"
              placeholder="Select company"
              label="Single select"
              value={selectedCompany}
              options={companyList}
              name="companyName"
              theme={theme => ({
                ...theme,
                controlHeight: 36,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: 'black',
                },
              })}
            />
            {this.validator.message('companyName', companyName, 'required')}
          </div>
          <div className="admin-submit-btn-container">
            <button
              type="submit"
              className="df-cta btn btn-secondary"
              disabled={this.state.selectedCompanyID ? false : true}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ getCompanyList, storeUsersFromApi }, dispatch),
  };
}

const mapStateToProps = state => {
  return {
    companyList: state.auth.user.companyList,
    auth: state.auth,
    users: state.users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCompany);
