import React, { Component } from 'react';
import { connect } from 'react-redux';
import WaitSpinner from '../../components/WaitSpinner';

import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { Link } from '@reach/router';

import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import TableComponent from '../../components/admin/TableComponent';
import { compareCurrency, compareLinkText } from '../../utils/tableUtilities';

import { fetchAgingReport } from '../../api/index';
import { formatUSDWithNoDecimals } from '../../utils/util';

class AgingReportPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerAccounts: null,
    };
    this.fetchAgingReport = this.fetchAgingReport.bind(this);
  }

  componentDidMount() {
    this.fetchAgingReport();
  }

  async fetchAgingReport() {
    const customerAccounts = await fetchAgingReport();
    this.setState({ customerAccounts: customerAccounts });
  }

  formatAgingReportCell(row, key) {
    return formatUSDWithNoDecimals(
      (row.aging_report_periods || {})[key] / 100.0
    );
  }

  render() {
    const integratedSortingColumnExtensions = [
      { columnName: 'company_name', compare: compareLinkText },
      { columnName: 'current', compare: compareCurrency },
      { columnName: '1-30 days', compare: compareCurrency },
      { columnName: '31-60 days', compare: compareCurrency },
      { columnName: '61-90 days', compare: compareCurrency },
      { columnName: 'Over 90 days', compare: compareCurrency },
    ];

    const columns = [
      {
        name: 'company_name',
        title: 'Company',
        getCellValue: row => {
          return (
            <Link to={`/admin/customers/${row.id}`}>{row.company_name}</Link>
          );
        },
      },
      {
        name: 'current',
        title: 'Current',
        getCellValue: row => this.formatAgingReportCell(row, 'current'),
      },
      {
        name: '1-30 days',
        title: '1-30 days',
        getCellValue: row => this.formatAgingReportCell(row, '1-30 days'),
      },
      {
        name: '31-60 days',
        title: '31-60 days',
        getCellValue: row => this.formatAgingReportCell(row, '31-60 days'),
      },
      {
        name: '61-90 days',
        title: '61-90 days',
        getCellValue: row => this.formatAgingReportCell(row, '61-90 days'),
      },
      {
        name: 'Over 90 days',
        title: 'Over 90 days',
        getCellValue: row => this.formatAgingReportCell(row, 'Over 90 days'),
      },
    ];

    const columnExtensions = [
      {
        columnName: 'company_name',
        wordWrapEnabled: true,
        width: '230px',
      },
      {
        columnName: 'current',
        width: '180px',
      },
      {
        columnName: '1-30 days',
        width: '180px',
      },
      {
        columnName: '31-60 days',
        width: '180px',
      },
      {
        columnName: '61-90 days',
        width: '180px',
      },
      {
        columnName: 'Over 90 days',
        width: '180px',
      },
    ];

    return (
      <main>
        <div className="main-wrapper">
          <h1 className="title">Aging Report</h1>
          {!this.state.customerAccounts && <WaitSpinner />}
          {this.state.customerAccounts && (
            <Grid rows={this.state.customerAccounts || []} columns={columns}>
              <SortingState
                defaultSorting={[
                  {
                    columnName: 'company_name',
                    direction: 'asc',
                  },
                ]}
              />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              <Table
                tableComponent={TableComponent}
                columnExtensions={columnExtensions}
              />
              <TableHeaderRow showSortingControls />
            </Grid>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AgingReportPage);
