import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './state/store';
import App from './App';
import * as serviceWorker from './serviceWorker';

//Sentry error tracing
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

//log sentry errors in production
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://ce576b959c4e482d95e4a8c167011966@o280204.ingest.sentry.io/5974673',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
