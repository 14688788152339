export const formatCarats = value => {
  return String(value).substring(0, 4);
};

export const formatCaratRange = value => {
  return `${formatCarats(value[0])} - ${formatCarats(value[1])}`;
};

export const getFilterGridCaratRanges = () => {
  return [
    [0.8, 1.0],
    [1.0, 1.4],
    [1.4, 1.8],
    [1.8, 2.5],
    [2.5, 3.99],
    [4.0, 5.99],
    [6.0, 20.0],
  ];
};

// This is the table of actual tolerance ranges when users order from the grid, which has continuous ranges.
export const getToleranceCaratRange = caratRangeIndex => {
  return [
    [0.23, 0.28], // starting here, these are the program stone tolerances
    [0.28, 0.32],
    [0.38, 0.42],
    [0.48, 0.52],
    [0.57, 0.63],
    [0.71, 0.78],
    [0.78, 1.0], // starting here, these are NOT the program stone tolerances as these will be sold as unique stones
    [1.0, 1.4],
    [1.4, 1.8],
    [1.8, 2.5],
    [2.5, 20.0],
  ][caratRangeIndex];
};

export const getCaratLabel = value => {
  const range = {
    0: '0.25',
    0.23: '0.25',
    0.28: '0.3',
    0.38: '0.4',
    0.48: '0.5',
    0.57: '0.6',
    0.58: '0.6',
    0.68: '0.75',
    0.71: '0.75',
    0.78: '0.8+',
    1.0: '1.0+',
    1.24: '1.4+',
    1.4: '1.4+',
    1.8: '1.8+',
    2.5: '2.5+',
  };
  return range[value];
};

export const getCaratRangeLabel = caratRangeIndex => {
  let caratRangeLabelsShort = [
    '0.25',
    '0.3',
    '0.4',
    '0.5',
    '0.6',
    '0.75',
    '0.8+',
    '1.0+',
    '1.4+',
    '1.8+',
    '2.5+',
  ];

  if (caratRangeIndex) {
    return caratRangeLabelsShort[caratRangeIndex];
  }
};

export const getRoughDiamondCaratRanges = () => {
  return [
    [0.0, 4.0],
    [4.0, 5.0],
    [5.0, 6.0],
    [6.0, 7.0],
    [7.0, 8.0],
    [8.0, 9.0],
    [9.0, 10.0],
    [10.0, 30.0],
  ];
};

export const estimatedPolishColorToLabel = color => {
  const labels = {
    J: 'JK',
    K: 'JK',
    G: 'GHI',
    I: 'GHI',
    H: 'GHI',
    E: 'EF',
    'R&D': 'R&D',
  };
  /*
  Check if color is defined and in the dictionary.
   */
  if (color && color.toUpperCase() in labels) {
    return labels[color.toUpperCase()];
  } else {
    return 'GHI';
  }
};
