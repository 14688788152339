import axios, { AxiosResponse } from 'axios';
import getEnvConfig from '../env';
import { headersAuthOnly } from './index';
export const { DF_API_HOST } = getEnvConfig();

export const onGet = <T>(path: string): Promise<AxiosResponse<T>> => {
  const url = [DF_API_HOST, path].join('/');
  return axios.get<T>(url, headersAuthOnly());
};

export const onPut = <T>(
  path: string,
  data: any
): Promise<AxiosResponse<T>> => {
  const url = [DF_API_HOST, path].join('/');
  return axios.put(url, data, headersAuthOnly());
};

export const onPost = <T>(
  path: string,
  data: any
): Promise<AxiosResponse<T>> => {
  const url = [DF_API_HOST, path].join('/');
  return axios.post(url, data, headersAuthOnly());
};

export const onDelete = (path: string) => {
  const url = [DF_API_HOST, path].join('/');
  return axios.delete(url, headersAuthOnly());
};
