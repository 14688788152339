import {
  SET_DIAMOND_STATS,
  SET_ROUGH_DIAMOND_STATS,
  SET_ROUGH_DIAMOND_GROUP_STATS,
} from './constants';

// updates/sets redux store diamond stats
export const setDiamondStats = stats => {
  return {
    type: SET_DIAMOND_STATS,
    payload: stats,
  };
};

// updates/sets redux store rough diamond stats
export const setRoughDiamondStats = stats => {
  return {
    type: SET_ROUGH_DIAMOND_STATS,
    payload: stats,
  };
};

// updates/sets redux store rough diamond group stats
export const setRoughDiamondGroupStats = stats => {
  return {
    type: SET_ROUGH_DIAMOND_GROUP_STATS,
    payload: stats,
  };
};
