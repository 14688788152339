import { setDiamonds } from './diamondList';

export const getCaratRange = ({ rangeFilter = [], diamonds = [] } = {}) => {
  return {
    type: 'GET_CARAT_RANGE',
    rangeFilter,
    diamonds,
  };
};

export const setCaratFilter = ({ rangeFilter = [], diamonds = [] } = {}) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_CARAT_FILTER', rangeFilter });

    const state = getState();
    const diamondsToFilter =
      state.filteredDiamond.length > 0
        ? state.filteredDiamond
        : state.diamonds.list;
    const caratRangeFilter = state.rangeFilters.carat;

    const filteredDiamonds = diamondsToFilter.filter(
      diamond =>
        diamond.carat >= caratRangeFilter[0] &&
        diamond.carat <= caratRangeFilter[1]
    );

    dispatch(setDiamonds({ list: filteredDiamonds }));
  };
};

export const setPriceFilter = ({ rangeFilter = [], diamonds = [] } = {}) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_PRICE_FILTER', rangeFilter });

    const state = getState();
    const diamondsToFilter =
      state.filteredDiamond.length > 0
        ? state.filteredDiamond
        : state.diamonds.list;
    const caratRangeFilter = state.rangeFilters.carat;

    const filteredDiamonds = diamondsToFilter.filter(
      diamond =>
        diamond.carat >= caratRangeFilter[0] &&
        diamond.carat <= caratRangeFilter[1]
    );

    dispatch(setDiamonds({ list: filteredDiamonds }));
  };
};

export const getPriceRange = ({ rangeFilter = [], diamonds = [] } = {}) => ({
  type: 'GET_PRICE_RANGE',
  rangeFilter,
  diamonds,
});
