import React from 'react';
import { Button } from 'reactstrap';

type Props = {
  disabled?: boolean;
  onClick?: () => void;
  isConsignment: boolean;
  payment_method?: string;
};

const dummy = () => {};

/*
A polymorphic button.
 */
const CheckoutButton = ({
  disabled = false,
  onClick = dummy,
  isConsignment,
  payment_method = '',
}: Props) => {
  const getLabel = () => {
    if (!isConsignment) {
      switch (payment_method) {
        case 'B2B Stripe':
          return 'PLACE ORDER';

        case '':
        default:
          return 'SUBMIT REQUEST';
      }
    } else {
      return 'Request 30-Day Memo';
    }
  };
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className="df-cta checkout-button"
    >
      {getLabel()}
    </Button>
  );
};

export default CheckoutButton;
