import React, { Component } from 'react';

import AffiliationReferenceForm from '../components/AffiliationReferenceForm';
import NewPartnerForm from '../components/NewPartnerForm';

import { Button } from 'reactstrap';

class RegistrationToggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partnerType: null,
    };

    this.toggleSelectClick = this.toggleSelectClick.bind(this);
    this.partnerReferenceForm = this.partnerReferenceForm.bind(this);
  }

  toggleSelectClick(e) {
    e.preventDefault();

    // nested under application because setParentState is handling nested attributes in all other cases
    this.props.setParentState(
      { application: { partnerType: e.target.id } },
      null
    );
    this.setState({ partnerType: e.target.id });
  }

  partnerReferenceForm() {
    switch (this.state.partnerType) {
      case 'existing':
        return (
          <AffiliationReferenceForm
            setParentState={this.props.setParentState}
            affiliation_reference_attributes={
              this.props.affiliation_reference_attributes
            }
          />
        );
      case 'newPartner':
        return (
          <NewPartnerForm
            setParentState={this.props.setParentState}
            customer_account_attributes={this.props.customer_account_attributes}
          />
        );
      default:
        return '';
    }
  }

  render() {
    const partnerType = this.state.partnerType;

    const partnerTypeComponent = this.partnerReferenceForm();

    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="register-headers">
            <p className="register-instruction">
              Do you have an existing B2B account or applying for a new account?
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-4">
            <Button
              id="existing"
              className="df-cta account-type-btn"
              onClick={e => this.toggleSelectClick(e)}
            >
              Existing Account
            </Button>
          </div>
          <div className="col-4">
            <Button
              id="newPartner"
              className="df-cta account-type-btn"
              onClick={e => this.toggleSelectClick(e)}
            >
              New Account
            </Button>
          </div>
        </div>

        {partnerType && partnerTypeComponent}
      </React.Fragment>
    );
  }
}
export default RegistrationToggle;
