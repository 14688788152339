import React from 'react';

const CodeOfConductPage = () => (
  <main>
    <div className="page-wrapper-with-padding">
      <div className="main-wrapper terms">
        <h1>Supplier Code of Conduct</h1>
        <p>
          At Diamond Foundry, Inc, we pride ourselves on honesty, integrity and
          excellence. We hold our Suppliers to the same high standards to which
          we hold ourselves. Our customers and stakeholders demand no less.
        </p>
        <p>
          Diamond Foundry, Inc, and its subsidiaries and affiliates
          (collectively, “Diamond Foundry”) therefore require that our Suppliers
          share our commitment to human rights, fair and safe labor practices,
          environmental protection and ethical business conduct. We expect our
          Suppliers to fully comply with all applicable laws, rules and
          regulations. We also expect Suppliers to go beyond legal compliance
          and strive to meet internationally recognized standards for the
          advancement of human rights, business ethics, and social and
          environmental responsibility. Suppliers are encouraged to align with
          International Labor Organization (“ILO”) conventions and the United
          Nations Global Compact, Guiding Principles on Business and Human
          Rights and Sustainable Development Goals, and work conscientiously to
          operate within these frameworks.
        </p>
        <p>
          This Code of Conduct sets out general principles and requirements
          applicable to all Diamond Foundry Suppliers. Additional descriptions
          regarding these requirements for manufacturing and supply chain
          partners can be found in the Diamond Foundry Supplier Code Guidance.
        </p>

        <h1>Requirements for All Suppliers</h1>

        <h3>Legal Compliance</h3>
        <p>
          Suppliers shall comply with and have a process to identify, monitor
          and understand all applicable laws, rules and regulations, including
          those concerning labor, health and safety, human rights, environmental
          protection and corruption and bribery. In the event this Code of
          Conduct differs or conflicts with local law, the higher standard shall
          prevail.
        </p>

        <h3>Business Integrity</h3>
        <p>
          The highest standards of integrity must be upheld in all business
          interactions. Suppliers shall conduct business ethically and all
          business dealings must be transparent and accurately reflected on
          their business books and records. Suppliers shall have a policy
          mandating business integrity.
        </p>

        <h3>Anti-Corruption and Bribery and Financial Offenses</h3>
        <p>
          Suppliers shall not engage in any form of corrupt practices, including
          bribery, extortion, embezzlement, fraud, money laundering or any
          business related to terrorist or armed activity. Suppliers shall not
          violate or cause Diamond Foundry to violate the United States Foreign
          Corrupt Practices Act or any other applicable anti-corruption or
          bribery laws. Monitoring and enforcement procedures shall be
          implemented to ensure compliance with applicable anti-corruption and
          bribery laws.
        </p>

        <h3>Voluntary Employment</h3>
        <p>
          All workers shall be free to leave their employment without threat or
          coercion. Suppliers shall not employ forced, indentured, debt-bonded
          or convict labor, nor use any practice to coerce the continued
          employment of any person, such as requiring employees to pay
          recruitment commissions or withholding any personal or travel
          documents. Suppliers shall monitor relationships with recruitment
          agencies or contracted labor for risk of human trafficking and
          compliance with applicable anti-slavery laws.
        </p>

        <h3>Child Labor</h3>
        <p>
          Suppliers shall not employ any persons under the age of 15, the age
          for completing compulsory education, the legal minimum age for
          employment in the country, or the minimum age permitted under ILO
          conventions, whichever standard is greatest.
        </p>

        <h3>Juvenile Workers</h3>
        <p>
          Suppliers shall ensure any young workers (those between the minimum
          age described above and 18 years old) are employed under well-defined
          circumstances, such as vocational training programs, that are not
          harmful to the health, safety or morals of young workers and that
          comply with applicable laws.
        </p>

        <h3>Working Hours</h3>
        <p>
          Suppliers shall comply with local laws and seek to align with ILO
          conventions intended to ensure employees do not work excessive hours
          per week. At a minimum, both regular and overtime hours must be taken
          into account, and all employees must be allowed one day off in seven.
          Suppliers shall keep accurate and transparent records of working hours
          at all times.
        </p>

        <h3>Wages and Benefits</h3>
        <p>
          Suppliers are encouraged to provide a level of compensation for a
          regular work week that is sufficient to meet basic needs and provide
          discretionary income. At a minimum, Suppliers shall comply with
          applicable law in the payment of wages and overtime pay and the
          provision of benefits, including holidays, leaves and statutory
          severance. Suppliers shall provide wage statements to employees for
          each pay period. Employees must be compensated for overtime hours at
          the rate legally required by local law or, where such laws do not
          exist, overtime should be at least 125% of the regular hourly
          compensation rate in accordance with ILO conventions.
        </p>

        <h3>Fair and Equal Treatment</h3>
        <p>
          Suppliers shall not use corporal punishment, threats of violence or
          other forms of mental or physical coercion. Sexual harassment of
          workers shall not be tolerated. There will be no discrimination in any
          part of the employment relationship on the grounds of race, religion
          or belief, political opinion, membership in worker organizations, age,
          national origin, sexual orientation, gender, marital status, parental
          status, disability, social group or ethnic origin, veteran’s status or
          any other basis specified by law.
        </p>

        <h3>Freedom of Association</h3>
        <p>
          Suppliers shall recognize and respect the right of employees to
          freedom of association and collective bargaining. Where laws prohibit
          these freedoms, Suppliers shall allow the development of parallel
          means for independent and free association and bargaining.
        </p>

        <h3>Grievance Mechanisms and Remedy</h3>
        <p>
          Suppliers shall maintain grievance mechanisms to respond to concerns
          and complaints that ensure effective, timely, respectful and
          transparent communication among workers, their representatives,
          management and the community.
        </p>

        <h3>Health and Safety</h3>
        <p>
          Suppliers shall provide a safe and healthy work environment and fully
          comply with all applicable safety laws, regulations and industry
          standards. Suppliers shall regularly assess the workplace for hazards
          and implement appropriate programs and engineering controls to
          minimize the risks of work-related accidents. Suppliers will strive to
          continually improve the effectiveness of their health and safety
          programs and seek to implement best practices in their industries.
        </p>

        <h3>Environment</h3>
        <p>
          Suppliers shall meet or exceed all environmental laws and regulations,
          and strive to meet or exceed the standards of international
          environmental treaties and best practices in their industries.
          Suppliers shall identify environmental risks and impacts, as well as
          opportunities for improving environmental performance. Suppliers shall
          implement and regularly review controls to mitigate identified
          environmental risks and minimize environmental impacts, including
          resource use, discharges, emissions and waste disposal, and take a
          proactive approach to monitoring and collecting data on these topics.
          Supplier operations and sourcing practices should strive to place
          special emphasis on the mitigation of climate change and the
          preservation and rehabilitation of biodiversity and ecosystems.
        </p>

        <h3>Host Communities</h3>
        <p>
          Suppliers are encouraged to proactively engage with and support
          development in the communities where their operations and supply
          chains are located, and to employ, procure and build within those
          communities.
        </p>

        <h3>Security</h3>
        <p>
          Suppliers shall ensure the safety and security of all workers and
          visitors. Suppliers shall assess security risks and establish measures
          to prevent product or intellectual property theft, unauthorized access
          to or loss of employee or customer personal information, and loss,
          damage or substitution of product while on site, off site or in
          transit. Suppliers shall take steps to ensure that human rights are
          protected in all aspects of its security operations, including in
          interactions among security personnel, workers and visitors. Suppliers
          are encouraged to align with the Voluntary Principles on Security and
          Human Rights where applicable.
        </p>

        <h3>Traceability and Due Diligence</h3>
        <p>
          Manufacturing and supply chain partners are encouraged to meet the
          standards of the OECD Due Diligence guidance and achieve full
          traceability throughout their supply chains. They shall also follow
          Diamond Foundry’s supply chain policies and requirements.
        </p>

        <h3>APPLICATION AND COMPLIANCE</h3>
        <p>
          Suppliers are responsible for ensuring that this Code of Conduct is
          understood and followed throughout their operations. Accordingly,
          Suppliers must share this Code of Conduct with all workers in their
          local language. Suppliers are also responsible for ensuring that any
          approved subcontractors involved in providing goods or services to
          Diamond Foundry understand and comply with this Code of Conduct.
        </p>
        <p>
          Suppliers are expected to self-monitor in line with this Code of
          Conduct. Suppliers must also permit Diamond Foundry or its agents to
          audit or inspect their facilities to verify compliance. During such
          visits, which may be scheduled or unscheduled, all workers must be
          free to communicate outside the presence of management and without the
          threat of reprisal.
        </p>
        <p>
          Diamond Foundry seeks to work collaboratively with its Suppliers
          towards continuous improvement in responsible business practices.
          However, we reserve the right to cancel outstanding purchase
          contracts, suspend future purchase contracts or terminate our
          relationship with a Supplier where circumstances demand.
        </p>
        <p>
          For more information about Diamond Foundry’s sustainability practices,
          please visit{' '}
          <a
            href="https://diamondfoundry.com/pages/just-diamond"
            target="_blank"
          >
            https://diamondfoundry.com/pages/just-diamond
          </a>
          .
        </p>
      </div>
    </div>
  </main>
);

export default CodeOfConductPage;
